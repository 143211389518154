import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('stock').controller('StockManagementCtrl', StockManagementCtrl);

StockManagementCtrl.$inject = ["$filter", "$scope", "$rootScope", "$translate", "restManager", "stockSummary", "stockExportDialog", "toast", "user", "util", "entityManager", "stockLocations", "barcodeManager"];

function StockManagementCtrl($filter, $scope, $rootScope, $translate, restManager, stockSummary, stockExportDialog, toast, user, util, entityManager, stockLocations, barcodeManager) {
    $scope.user = user;
    $scope.topbar_context = {
        sidenav_label: $translate.instant('STOCK.MANAGEMENT.STOCKS'),
        mode: 'showcase',
        viewmode: 'management',
        stockLocations: stockLocations,
        stockLocationId: 1,
        itemsPerPageList: [50, 100, 200, 500]
    };

    $scope.tab = 'items-simple';
    $scope.loadingInProgress = false;
    $scope.pagedStockElementResult = {};

    $scope.filterObject = {
        page: 0,
        available: null,
        perPage: 50,
        searchText: '',
        tab: 'items-simple'
    };

    $scope.topbar_context.filterObject = $scope.filterObject;

    $scope.topbar_context.resetPagedStockElementResult = function() {
        $scope.pagedStockElementResult = {};
        $scope.filterObject.searchText = '';
    };

    $scope.switchToTab = function(tab) {
        $scope.tab = tab;
        getStockManagementPagedData();
    };

    $scope.topbar_context.onStockLocationChange = () => {
        getStockManagementPagedData();
    };

    $scope.topbar_context.changeListSize = function(value) {
        $scope.topbar_context.filterObject.perPage = value;
        getStockManagementPagedData();
    };

    $scope.topbar_context.onSubmitBarcode = function(barcodeInput) {
        barcodeManager.manageBarcodeInput(barcodeInput);
    };

    $scope.$on("barcodeManager:Item", async (event, eventData) => {
        if(eventData.itemData.data[0]?.item_id){
            $scope.filterObject.item_id = eventData.itemData.data[0].item_id;

            if(eventData.itemData.data[0].combination_id){
                $scope.filterObject.combination_id = eventData.itemData.data[0].combination_id;
            }
        }

        getStockManagementPagedData();
    });

    $scope.changeAvailability = function(available) {
        if (available !== 'all') {
            $scope.filterObject.available = available;
        } else {
            $scope.filterObject.available = undefined;
        }
        getStockManagementPagedData();
    };

    $scope.getColorAvailiable = function(available) {
        switch(available) {
            case 'available': case 'producible':
                return '#a5d6a7';
            case 'unavailable':
                return '#ff5252';
            case 'alert':
                return '#ffe082';
            default:
                return null;
        }
    };

    $scope.hasNoResults = function() {
        return _.isEmpty($scope.pagedStockElementResult);
    };

    $scope.decrementPage = function() {
        if ($scope.pagedStockElementResult.page > 0) {
            $scope.pagedStockElementResult.page--;
            $scope.filterObject.page = $scope.pagedStockElementResult.page;
            getStockManagementPagedData();
        }
    };

    $scope.incrementPage = function() {
        if ($scope.pagedStockElementResult.page < $scope.pagedStockElementResult.pages - 1) {
            $scope.pagedStockElementResult.page++;
            $scope.filterObject.page = $scope.pagedStockElementResult.page;
            getStockManagementPagedData();
        }
    };

    $scope.goToFirstPage = function() {
        if ($scope.pagedStockElementResult.page > 0) {
            $scope.filterObject.page = 0;
            getStockManagementPagedData();
        }
    };

    $scope.goToLastPage = function() {
        if ($scope.filterObject.page < $scope.pagedStockElementResult.pages - 1) {
            $scope.filterObject.page = $scope.pagedStockElementResult.pages - 1;
            getStockManagementPagedData();
        }
    };

    $scope.exitSearch = function() {
        $scope.topbar_context.mode = 'showcase';
        $scope.filterObject.searchText = '';
        getStockManagementPagedData();
    };

    $scope.clearSearchText = function() {
        $scope.filterObject.searchText = '';
        $scope.pagedStockElementResult = {};
    };

    $scope.searchStockItems = function() {
        if ($scope.filterObject.searchText.length < 3) {
            $scope.pagedStockElementResult = {};
        } else {
            $scope.loadingInProgress = true;
            initializeSearch();
        }
    };

    $scope.getStockElementType = function(stockElement) {
        if(stockElement.raw_material_id) {
            return 'MP';
        } else {
            if(stockElement.item_id) {
                if(stockElement.combination_id) {
                    return 'PFC';
                } else {
                    return 'PF';
                }
            }
        }
    };

    $scope.topbar_context.exportStock = function() {
        stockExportDialog.show().then(function(answer) {
            answer.stock_location_id = $scope.topbar_context.stockLocationId;
            restManager.getOne("stock", "export", answer).then(function() {
                toast.show({
                    message: $translate.instant('STOCK.MANAGEMENT.EMAIL_SENT'),
                    hideDelay: 5000
                });
            }, function(error) {
                var errMsg;
                switch(_.get(error, ["data", "error", "message"])) {
                    case 'email param not found':
                        errMsg = $translate.instant('STOCK.MANAGEMENT.NOT_VALID_EMAIL');
                        break;
                    default:
                        errMsg = $translate.instant('STOCK.MANAGEMENT.ERROR_WHILE_SENDING');
                        break;
                }
                toast.show({
                    message: errMsg,
                    hideDelay: 5000
                });
            });
        });
    };

    $scope.topbar_context.showSummary = function() {
        stockSummary.show();
    };

    var initializeSearch = function() {
        getStockManagementPagedData();
    };

    const getRawMaterialsFromIds = async (rawMaterialRows) => {
        let rawMaterialMap = {};
        let rawMaterialIDList = _(rawMaterialRows).map('raw_material_id').uniq().filter((val) => (!_.isNil(val)) ).value();

        for(let id of rawMaterialIDList) {
            try {
                let raw_material = await entityManager.rawMaterials.fetchOneOfflineFirst(id);
                rawMaterialMap[id] = raw_material;
            } catch(err) {}
        }

        return rawMaterialMap;
    };

    const getStockManagementPagedData = async () => {
        if ($scope.topbar_context.mode === 'showcase' || ($scope.topbar_context.mode === 'search' && $scope.topbar_context.filterObject.searchText.length >= 3)) {
            let targetLoader = ($scope.topbar_context.mode === 'showcase') ? "stockManagementShowcaseLoader" : "stockManagementSearchLoader";

            $rootScope.$broadcast("loader:changeStatus", targetLoader, { enabled: true });
            $scope.loadingInProgress = true;

            $scope.pagedStockElementResult.results = [];

            let query = {
                pagination: true,
                per_page: $scope.topbar_context.filterObject.perPage,
                page: $scope.topbar_context.filterObject.page,
                barcode: $scope.topbar_context.filterObject.barcode,
                orderby_asc: 'name',
                stock_location_id: $scope.topbar_context.stockLocationId
            };

            let xQuery = [];

            if(!_.isEmpty($scope.topbar_context.filterObject.searchText)) {
                let encodedSearchText = util.fixedEncodeURIComponent($scope.topbar_context.filterObject.searchText);

                xQuery.push(`OR(name_like=${encodedSearchText},item_id=${encodedSearchText},item.sku_like=${encodedSearchText},item.department.name_like=${encodedSearchText},item.category.name_like=${encodedSearchText},code=${encodedSearchText})`);
            }

            if(_.includes(['available'], $scope.topbar_context.filterObject.available)) {
                xQuery.push(`OR(available=${util.fixedEncodeURIComponent('available')},available=${util.fixedEncodeURIComponent('producible')})`);
            } else {
                query.available = $scope.topbar_context.filterObject.available;
            }

            if(!_.isEmpty(xQuery)) {
                query.x_query = xQuery;
            }

            const item_id = $scope.topbar_context.filterObject.item_id || 'notnull';
            const combination_id = $scope.topbar_context.filterObject.combination_id || 'notnull';

            switch ($scope.tab) {
                case 'items-simple':
                    Object.assign(query, {
                        item_id,
                        combination_id: 'null',
                        raw_material_id: 'null',
                        stock_quantity: 'notnull'
                    });
                    break;
                case 'items-bom':
                    Object.assign(query, {
                        item_id,
                        combination_id: 'null',
                        raw_material_id: 'null',
                        stock_quantity: 'null'
                    });
                    break;
                case 'combinations':
                    Object.assign(query, {
                        item_id,
                        combination_id,
                        raw_material_id: 'null',
                    });
                    break;
                case 'raw-materials':
                    Object.assign(query, {
                        item_id: 'null',
                        combination_id: 'null',
                        raw_material_id: 'notnull',
                    });
                    break;
                default:
                    break;
            }

            try {
                let result = await entityManager.stock.fetchCollectionOnline(query);

                $scope.topbar_context.filterObject.barcode = undefined;
                $scope.pagedStockElementResult = result;
                $scope.filterObject.item_id = null;
                $scope.filterObject.combination_id = null;

                let itemsMap;
                let rawMaterialMap;
                if($scope.tab === 'raw-materials') {
                    rawMaterialMap = await getRawMaterialsFromIds(result.results);
                } else {
                    itemsMap = await util.getItemsFromIds(result.results);
                }

                for(let element of $scope.pagedStockElementResult.results) {
                    element.details = (element.options_values && element.combination ? element.options_values + ' ' + element.combination : element.options_values || '' + element.combination + '');

                    if (element.options_values && element.combination) {
                        element.details = element.options_values + ' ' + element.combination;
                    } else {
                        element.details = '';
                        if (element.options_values) {
                            element.details = element.options_values;
                        } else if (element.combination) {
                            element.details = element.combination;
                        }
                    }

                    if(element.item_id && itemsMap[element.item_id]) {
                        element.cost = itemsMap[element.item_id].cost;
                    } else {
                        if (element.raw_material_id && rawMaterialMap[element.raw_material_id]) {
                            element.cost = rawMaterialMap[element.raw_material_id].cost;
                        }
                    }
                }
            } catch(err) {}

            $rootScope.$broadcast("loader:changeStatus", targetLoader, { enabled: false });
            $scope.loadingInProgress = false;
        }
    };

    $scope.getValue = (stock) => {
        return stock.cost ? $filter('sclCurrency')(stock.stock_quantity * stock.cost) : '';
    };
}
