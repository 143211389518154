import {Component, Injectable, inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {util} from 'app/ajs-upgraded-providers';
import {MagicPortalDialogComponent} from "../magic-portal-dialog";
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { lastValueFrom } from "rxjs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";

export type SupportDialogData = {query:string};

@Component({
    selector: 'app-support-dialog',
    host: {class:'settingsTheme supportDialog'},
    templateUrl: './support-dialog.component.html',
    styleUrls: ['./support-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, TilbyDialogToolbarComponent, TilbyDialogContentComponent, FormsModule, ReactiveFormsModule, MatDialogModule, MatFormFieldModule, MatButtonModule, MatIconModule, MatInputModule, TranslateModule]
})
export class SupportDialogComponent {
        private readonly dialogRef= inject(MatDialogRef<MagicPortalDialogComponent<SupportDialogComponent>>);
        public readonly data: SupportDialogData = inject(MAT_DIALOG_DATA);
        private readonly util: any = inject(util);

    hide() {
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }

    search(){
        const query = this.data.query.trim().toLowerCase();
        if (query && query !== '') {
            this.util.openExternalLink("https://support.tilby.com/hc/it/search?query=" + query);
            this.hide();
        }
    }

    consultManuals() {
        this.util.openExternalLink("https://support.tilby.com");
        this.hide();
    }

    openTicket() {
        this.util.openExternalLink("https://support.tilby.com/hc/requests/new");
        this.hide();
    }

    shareScreen() {
        this.util.openExternalLink("https://support.tilby.com/hc/articles/8356211710877");
        this.hide();
    }

}

@Injectable({
    providedIn: 'root'
})
export class SupportDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?:NonNullableConfigData<SupportDialogData >){
        const configEdited:NonNullableConfigData<SupportDialogData>={...this.switchMobileDesktopDimensions(), disableClose:true, ...config,data:{query:config?.data?.query||''}, panelClass: 'support-dialog-container'};
        return lastValueFrom(this.dialogRef.open(SupportDialogComponent,configEdited).afterClosed());
    }
}
