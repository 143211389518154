import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomForm, CustomFormGroup, TilbyMagicFormComponent } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { RightSidenavService } from 'src/app/core';
import { CashregisterStateService, CashregisterTopbarSettingsFields } from '../../services/cashregister.state.service';
import { skip } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { ItemReorderDialogService } from 'src/app/dialogs/cashregister/item-reorder-dialog';
import { OpenDialogsService } from 'src/app/dialogs';

@Component({
    standalone: true,
    selector: 'app-cashregister-topbar-settings',
    templateUrl: './cashregister-topbar-settings.component.html',
    styleUrls: ['./cashregister-topbar-settings.component.scss'],
    imports: [CommonModule, MatIconModule, TranslateModule, TilbyMagicFormComponent, MatDialogModule, MatButtonModule]
})
export class CashregisterTopbarSettingsComponent implements OnInit {

    cashregisterTopbarSettingsForm :CustomFormGroup<CustomForm<CashregisterTopbarSettingsFields>>;

    private readonly itemReorderDialogService = inject(ItemReorderDialogService);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly translateService = inject(TranslateService);

    constructor(
        private rightSidenavService: RightSidenavService<any>,
        private renderer: Renderer2
    ) {
        this.cashregisterTopbarSettingsForm = this.cashregisterStateService.createCashregisterTopbarSettingsForm();
    }

    ngOnInit() {
        this.category.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ category: value });
        });

        this.category_grid_rows.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ category_grid_rows: value });
        });

        this.category_grid_columns.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ category_grid_columns: value });
        });

        this.items.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ item: value.item });
        });

        this.item_grid_columns.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ item_grid_columns: value });
        });

        this.view.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ view: value.view });
        });

        this.order.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ order: value.order });
        });

        this.availability.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ availability: value.availability === "true" });
        });

        this.search_category.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ search_in_all_categories: value.searchCategory === "true" });
        });

        this.show_stock_level.valueChanges.pipe(skip(1)).subscribe((value) => {
            this.cashregisterStateService.eventsSettings.next({ show_stock_quantity: !!value.showStock });
        });
    }

    private setReorderButtonStatus(buttonReorder: any, value: string) {
        if (value == "+index") {
            this.renderer.removeAttribute(buttonReorder, 'disabled');
        } else {
            this.renderer.setAttribute(buttonReorder, 'disabled', 'false');
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.createButtonReorder();

            const divReorder = this.renderer.selectRootElement('#div-reorder', true);
            const buttonReorder = divReorder.querySelectorAll('button')[0];

            const initialOrderValue = this.order.getRawValue().order;

            this.setReorderButtonStatus(buttonReorder, initialOrderValue);

            this.order.valueChanges.subscribe((value) => this.setReorderButtonStatus(buttonReorder, value.order));
        }, 1);
    }

    createButtonReorder() {
        const elementA = this.renderer.selectRootElement('#item-order-id', true);
        const radioButtons = elementA.querySelectorAll('mat-radio-button');
        const secondRadioButton = radioButtons[1];
        const div1 = secondRadioButton.querySelectorAll('div');
        const div = this.renderer.createElement('div');
        this.renderer.setAttribute(div, 'id', 'div-reorder');
        const button = this.renderer.createElement('button');
        const buttonText = this.renderer.createText(this.translateService.instant('CASHREGISTER.TOPBAR.SETTINGS.REORDER.BTN_REORDER'));
        this.renderer.appendChild(button, buttonText);
        this.renderer.addClass(button, 'mat-stroked-button');
        this.renderer.listen(button, 'click', () => {
            this.callDialog().then(() => {
            }).catch((error) => {
              console.error('Errore durante la chiamata :', error);
            });
          });
        this.renderer.appendChild(div, button);
        this.renderer.appendChild(div1[0], div);
    }

    protected async callDialog() {
        const res = await this.itemReorderDialogService.openDialog();

        if (!res || !res.data.result) {
            return
        }

        if(res.data.result.success) {
            this.openDialogsService.openSnackBarTilby('CASHREGISTER.TOPBAR.SETTINGS.REORDER.REORDER_OK', 'MISC.OK', { duration: 3000 });
            return;
        }

        this.openDialogsService.openSnackBarTilby('CASHREGISTER.TOPBAR.SETTINGS.REORDER.REORDER_KO', 'MISC.OK', { duration: 3000 });
    }

    closeSidenav() {
        this.rightSidenavService.isOpen = false;
    }

    get category() {return this.cashregisterTopbarSettingsForm.controls.categories.controls.category};
    get category_grid_rows() {return this.cashregisterTopbarSettingsForm.controls.categories.controls.category_grid_rows};
    get category_grid_columns() {return this.cashregisterTopbarSettingsForm.controls.categories.controls.category_grid_columns};
    get items() {return this.cashregisterTopbarSettingsForm.controls.items};
    get item_grid_columns() {return this.cashregisterTopbarSettingsForm.controls.items.controls.item_grid_columns};
    get view() {return this.cashregisterTopbarSettingsForm.controls.view};
    get order() {return this.cashregisterTopbarSettingsForm.controls.order};
    get availability() {return this.cashregisterTopbarSettingsForm.controls.availability};
    get search_category() {return this.cashregisterTopbarSettingsForm.controls.search_in_all_category};
    get show_stock_level() {return this.cashregisterTopbarSettingsForm.controls.show_stock_level};
}
