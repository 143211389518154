import { Component, EventEmitter, Input, OnInit, Output, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

const MAX_INTEGER_VALUE_LENGTH = 7;
const MAX_DECIMAL_VALUE_LENGTH = 2;
const MAX_VALUE_LENGTH_WITHOUT_COMMA = MAX_INTEGER_VALUE_LENGTH + MAX_DECIMAL_VALUE_LENGTH;
const MAX_VALUE_LENGTH_WITH_COMMA = MAX_VALUE_LENGTH_WITHOUT_COMMA + 1;

@Component({
    selector: "app-cashregister-keypad",
    templateUrl: "./cashregister-keypad.component.html",
    styleUrls: ["./cashregister-keypad.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        TilbyGesturesDirective,
        MatButtonModule
    ],
    host: {
        class: 'tw-grid tw-grid-cols-3 tw-gap-1'
    }
})
export class CashregisterKeypadComponent implements OnInit {
    @Input() integerMultiply = false;
    @Input() field: string = "000";
    @Output() fieldChange = new EventEmitter<string>();

    protected keypadKeys = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '00'];
    protected clickEnabled = signal(true);

    private decimalSeparator = TilbyCurrencyPipe.currency.decimal_separator;
    private decimalDigits = TilbyCurrencyPipe.currency.decimal_digits;

    // START - LIFECYCLE
    ngOnInit() {
        if (this.integerMultiply) {
            this.setIntegerMultiplyMode()
        }
    }
    // END - LIFECYCLE

    private setIntegerMultiplyMode() {
        this.keypadKeys.splice(-1, 1, ',')
        this.field = this.field == '000'
            ? '0'
            : this.field;
    }

    updateField(elabField: string) {
        if (this.integerMultiply) {
            //field 000=>0, 0056=>56  0000,324=> 0,324
            let valueCorrected = elabField.replace(/^0+((?=[1-9])|(?!,|$))/, "");
            //keep comma before the last 2 numbers  0,324 => 3,24
            if (valueCorrected.split(',')[1]?.length > 2) {
                let valueCorrectedArray = valueCorrected.replace(',', '').split('');
                valueCorrectedArray.splice(-2, 0, ',')
                valueCorrected = valueCorrectedArray.join('').replace(/^0+((?=[1-9])|(?!,|$))/, "");
            }
            //Assign to this.field
            this.field = valueCorrected || '0';
        } else {
            const wholePart = parseInt(elabField.slice(0, elabField.length - this.decimalDigits)) || 0;

            if (this.decimalDigits) {
                const decimalPart = elabField.slice(elabField.length - this.decimalDigits, elabField.length);

                this.field = `${wholePart}${this.decimalSeparator}${decimalPart}`;
            } else {
                this.field = wholePart.toString();
            }
        }

        this.fieldChange.emit(this.field);
    }

    pressKey(key: string) {
        if (this.integerMultiply) {
            if (key == 'back') {
                this.field = this.field.slice(0, -1);
            } else if ((this.field.length + key.length) <= ((this.field + key).includes(',') ? MAX_VALUE_LENGTH_WITH_COMMA : MAX_INTEGER_VALUE_LENGTH)) {
                this.field += key;
            }

            this.updateField(this.field);
        } else {
            let elabField = this.field.replace(this.decimalSeparator, '');

            if (key == 'back') {
                if (parseFloat(elabField) !== 0) {
                    elabField = elabField.slice(0, elabField.length - 1);
                }
            } else {
                if ((elabField.length + key.length) <= MAX_VALUE_LENGTH_WITHOUT_COMMA) {
                    elabField += key;
                }
            }

            this.updateField(elabField);
        }
    }

    clear() {
        this.clickEnabled.set(false);

        if (this.integerMultiply) {
            this.updateField('0');
        } else {
            this.updateField("000");
        }
    }

    protected isAlreadyFloat(key: string) {
        return key == ',' && this.field.includes(',');
    }
}
