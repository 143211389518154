import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    FormControl,
    FormGroup,
    Validators
} from "@angular/forms";
import {PrepaidMovementForm, PrepaidMovementValidators} from "./movement-form.model";
import {TranslateService} from "@ngx-translate/core";
import {DataPrepaidMovementDetailsDialog} from "../dialog.model";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {POST} from "../../features/customers/customers.model";
import { MovementTypeExtendedLabels} from "../../models";
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { MagicPortalDialogComponent } from '../magic-portal-dialog';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'app-prepaid_credit-movement-details-dialog',
    templateUrl: './prepaid_credit-movement-details-dialog.component.html',
    styleUrls: ['./prepaid_credit-movement-details-dialog.component.scss'],
    providers: [OnDestroyService]
})
export class Prepaid_creditMovementDetailsDialogComponent {
    private readonly onDestroyService= inject(OnDestroyService);
    private readonly dialogRef= inject(MatDialogRef<MagicPortalDialogComponent<Prepaid_creditMovementDetailsDialogComponent>>);
    public readonly data: DataPrepaidMovementDetailsDialog= inject(MAT_DIALOG_DATA);
    private readonly translate= inject(TranslateService);
    private readonly tilbyDatePipe= inject(TilbyDatePipe);

    prepaidMovementForm?: FormGroup<PrepaidMovementForm>;
    validators: PrepaidMovementValidators = {
        notes:9999
    };
    types: MovementTypeExtendedLabels = {
        load: this.translate.instant('PREPAID.ADD_SHOW_MOVEMENT.LOAD'),
        unload: this.translate.instant('PREPAID.ADD_SHOW_MOVEMENT.UNLOAD')
    };
    viewMode = !!this.data.movement?.id;
    addMode = !this.data.movement?.id;
    appearance: MatFormFieldAppearance|'legacy'|'none' = this.addMode?'legacy':'none';


    constructor() {
        MagicPortalDialogComponent.confirm$.pipe(this.onDestroyService.takeUntilDestroy).subscribe(res=>!!res&&this.submit(this.prepaidMovementForm))
    }

    ngOnInit() {
        if(this.viewMode) {
            this.types = {
                ...this.types,
                cancel: this.translate.instant('PREPAID.ADD_SHOW_MOVEMENT.CANCEL'),
                setup: this.translate.instant('PREPAID.ADD_SHOW_MOVEMENT.SETUP'),
                bonus: this.translate.instant('PREPAID.ADD_SHOW_MOVEMENT.BONUS')
            }
        }
        this.createForm();
    }

    createForm(){
        const {movement:m} = this.data;
        this.prepaidMovementForm = new FormGroup<PrepaidMovementForm>({
            valid_from: new FormControl(this.addMode ? this.tilbyDatePipe.transform(TilbyDatePipe.date()) : this.tilbyDatePipe.transform(TilbyDatePipe.date({date:m?.valid_from})) || null, [Validators.required]),
            movement_type: new FormControl(m.movement_type || null, [Validators.required]),
            is_unclaimed: new FormControl(this.addMode? 0 : m.is_unclaimed || 0, [Validators.required]),
            shop_uuid: new FormControl(m.shop_uuid || null, []),
            amount: new FormControl(m.movement_type==='setup'? m.credit : m.amount ?? m.credit, [Validators.pattern(/^[0-9]+([.,][0-9]+)?$/)]),
            ticket_amount: new FormControl(m.movement_type==='setup'? m.ticket_credit : m.ticket_amount ?? m.ticket_credit, []),
            notes: new FormControl(m.notes || null, [Validators.maxLength(this.validators.notes)])

        });
        this.valid_from?.disable();
        this.shop_uuid?.disable();
        this.ticket_amount?.disable();

        if(this.viewMode){
            this.prepaidMovementForm.disable();
        }

        MagicPortalDialogComponent.setStatusValidity(this.prepaidMovementForm.status);
        this.prepaidMovementForm.statusChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(status=>MagicPortalDialogComponent.setStatusValidity(status));

    }

    submit(movementForm?: FormGroup<PrepaidMovementForm>) {
        const {amount,is_unclaimed,movement_type,notes} = movementForm?.value||{};
        const {uuid:customer_uuid} = this.data.params.customer || {};
        const movementToPost: POST.PrepaidMovement = {
            amount:amount||0,
            customer_uuid,
            is_unclaimed,
            movement_type,
            notes
        };
        this.dialogRef.close(movementToPost);
    }

    get valid_from() {
        return this.prepaidMovementForm?.get('valid_from');
    }
    get movement_type() {
        return this.prepaidMovementForm?.get('movement_type');
    }
    get is_unclaimed() {
        return this.prepaidMovementForm?.get('is_unclaimed');
    }
    get shop_uuid() {
        return this.prepaidMovementForm?.get('shop_uuid');
    }
    get amount() {
        return this.prepaidMovementForm?.get('amount');
    }
    get ticket_amount() {
        return this.prepaidMovementForm?.get('ticket_amount');
    }
    get notes() {
        return this.prepaidMovementForm?.get('notes');
    }

}
