export enum EpsonAlign {
    LEFT = "left",
    RIGHT = "right",
    CENTER = "center"
};

export enum EpsonColor {
    NONE = "color_none",
    COLOR_1 = "color_1",
    COLOR_2 = "color_2",
    COLOR_3 = "color_3",
    COLOR_4 = "color_4"
}

export enum EpsonCut {
    NO_FEED = "no_feed",
    FEED = "feed",
    RESERVE = "reserve"
}

export enum EpsonDrawer {
    DRAWER_1 = "drawer_1",
    DRAWER_2 = "drawer_2"
}

export enum EpsonPulse {
    PULSE_100 = "pulse_100",
    PULSE_200 = "pulse_200",
    PULSE_300 = "pulse_300",
    PULSE_400 = "pulse_400",
    PULSE_500 = "pulse_500"
}

export enum EpsonLevel {
    LEVEL_0 = "level_0",
    LEVEL_1 = "level_1",
    LEVEL_2 = "level_2",
    LEVEL_3 = "level_3",
    LEVEL_4 = "level_4",
    LEVEL_5 = "level_5",
    LEVEL_6 = "level_6",
    LEVEL_7 = "level_7",
    LEVEL_8 = "level_8",
    LEVEL_L = "level_l",
    LEVEL_M = "level_m",
    LEVEL_Q = "level_q",
    LEVEL_H = "level_h",
    LEVEL_DEFAULT = "default"
}

export enum EpsonSymbol {
    PDF417_STANDARD = "pdf417_standard",
    PDF417_TRUNCATED = "pdf417_truncated",
    QRCODE_MODEL_1 = "qrcode_model_1",
    QRCODE_MODEL_2 = "qrcode_model_2",
    QRCODE_MICRO = "qrcode_micro",
    MAXICODE_MODE_2 = "maxicode_mode_2",
    MAXICODE_MODE_3 = "maxicode_mode_3",
    MAXICODE_MODE_4 = "maxicode_mode_4",
    MAXICODE_MODE_5 = "maxicode_mode_5",
    MAXICODE_MODE_6 = "maxicode_mode_6",
    GS1_DATABAR_STACKED = "gs1_databar_stacked",
    GS1_DATABAR_STACKED_OMNIDIRECTIONAL = "gs1_databar_stacked_omnidirectional",
    GS1_DATABAR_EXPANDED_STACKED = "gs1_databar_expanded_stacked",
    AZTECCODE_FULLRANGE = "azteccode_fullrange",
    AZTECCODE_COMPACT = "azteccode_compact",
    DATAMATRIX_SQUARE = "datamatrix_square",
    DATAMATRIX_RECTANGLE_8 = "datamatrix_rectangle_8",
    DATAMATRIX_RECTANGLE_12 = "datamatrix_rectangle_12",
    DATAMATRIX_RECTANGLE_16 = "datamatrix_rectangle_16"
}

export enum EpsonBarcode {
    UPC_A = 'upc_a',
    UPC_E = 'upc_e',
    EAN13 = 'ean13',
    JAN13 = 'jan13',
    EAN8 = 'ean8',
    JAN8 = 'jan8',
    CODE39 = 'code39',
    ITF = 'itf',
    CODABAR = 'codabar',
    CODE93 = 'code93',
    CODE128 = 'code128',
    GS1_128 = 'gs1_128',
    GS1_DATABAR_OMNIDIRECTIONAL = 'gs1_databar_omnidirectional',
    GS1_DATABAR_TRUNCATED = 'gs1_databar_truncated',
    GS1_DATABAR_LIMITED = 'gs1_databar_limited',
    GS1_DATABAR_EXPANDED = 'gs1_databar_expanded'
}

export enum EpsonHRI {
    NONE = 'none',
    ABOVE = 'above',
    BELOW = 'below',
    BOTH = 'both'
}

export enum EpsonFont {
    FONT_A = 'font_a',
    FONT_B = 'font_b',
    FONT_C = 'font_c',
    FONT_SPECIAL_A = 'special_a',
    FONT_SPECIAL_B = 'special_b',
}

//NFThermalPrinter static interface
export interface NFThermalPrinterConstructor {
    new(ipAddress: string, port?: number, configuration?: any): NFThermalPrinter;
    connectAttempt(ip: string, port: number, callback?: Function): void
    displayText(printer: any, textLines: string[]): Promise<void>
}

//NFThermalPrinter instance interface
export interface NFThermalPrinter {
    addBarcode(data: string, type: EpsonBarcode, hri?: EpsonHRI, font?: EpsonFont, width?: number, height?: number): void;
    addCut(type ?: EpsonCut): void;
    addLogo(key1?: number, key2?: number): void;
    addPulse(drawer?: EpsonDrawer, time?: EpsonPulse): void;
    addSymbol(data: string, type: EpsonSymbol, level?: EpsonLevel, width?: number, height?: number, size?: number): void;
    addText(text: string): void;
    addTextAlign(align: EpsonAlign): void;
    addTextDouble(dw: boolean, dh: boolean): void;
    addTextLineSpace(linespc: number): void;
    addTextSize(w: number, h: number): void;
    addTextStyle(reverse ?: boolean, ul ?: boolean, em ?: boolean, color ?: EpsonColor): void;
    send(callback: Function): void;
    readonly FONT_A: EpsonFont
    readonly FONT_B: EpsonFont
    readonly FONT_C: EpsonFont
    readonly FONT_SPECIAL_A: EpsonFont
    readonly FONT_SPECIAL_B: EpsonFont
    readonly ALIGN_CENTER: EpsonAlign
    readonly ALIGN_LEFT: EpsonAlign
    readonly ALIGN_RIGHT: EpsonAlign
    readonly COLOR_NONE: EpsonAlign
    readonly COLOR_1: EpsonColor
    readonly COLOR_2: EpsonColor
    readonly COLOR_3: EpsonColor
    readonly COLOR_4: EpsonColor
    readonly BARCODE_UPC_A: EpsonBarcode
    readonly BARCODE_UPC_E: EpsonBarcode
    readonly BARCODE_EAN13: EpsonBarcode
    readonly BARCODE_JAN13: EpsonBarcode
    readonly BARCODE_EAN8: EpsonBarcode
    readonly BARCODE_JAN8: EpsonBarcode
    readonly BARCODE_CODE39: EpsonBarcode
    readonly BARCODE_ITF: EpsonBarcode
    readonly BARCODE_CODABAR: EpsonBarcode
    readonly BARCODE_CODE93: EpsonBarcode
    readonly BARCODE_CODE128: EpsonBarcode
    readonly BARCODE_GS1_128: EpsonBarcode
    readonly BARCODE_GS1_DATABAR_OMNIDIRECTIONAL: EpsonBarcode
    readonly BARCODE_GS1_DATABAR_TRUNCATED: EpsonBarcode
    readonly BARCODE_GS1_DATABAR_LIMITED: EpsonBarcode
    readonly BARCODE_GS1_DATABAR_EXPANDED: EpsonBarcode
    readonly HRI_NONE: EpsonHRI
    readonly HRI_ABOVE: EpsonHRI
    readonly HRI_BELOW: EpsonHRI
    readonly HRI_BOTH: EpsonHRI
    readonly CUT_NO_FEED: EpsonCut
    readonly CUT_FEED: EpsonCut
    readonly CUT_RESERVE: EpsonCut
    readonly DRAWER_1: EpsonDrawer
    readonly DRAWER_2: EpsonDrawer
    readonly PULSE_100: EpsonPulse
    readonly PULSE_200: EpsonPulse
    readonly PULSE_300: EpsonPulse
    readonly PULSE_400: EpsonPulse
    readonly PULSE_500: EpsonPulse
    readonly LEVEL_0: EpsonLevel
    readonly LEVEL_1: EpsonLevel
    readonly LEVEL_2: EpsonLevel
    readonly LEVEL_3: EpsonLevel
    readonly LEVEL_4: EpsonLevel
    readonly LEVEL_5: EpsonLevel
    readonly LEVEL_6: EpsonLevel
    readonly LEVEL_7: EpsonLevel
    readonly LEVEL_8: EpsonLevel
    readonly LEVEL_L: EpsonLevel
    readonly LEVEL_M: EpsonLevel
    readonly LEVEL_Q: EpsonLevel
    readonly LEVEL_H: EpsonLevel
    readonly LEVEL_DEFAULT: EpsonLevel
    readonly SYMBOL_PDF417_STANDARD: EpsonSymbol
    readonly SYMBOL_PDF417_TRUNCATED: EpsonSymbol
    readonly SYMBOL_QRCODE_MODEL_1: EpsonSymbol
    readonly SYMBOL_QRCODE_MODEL_2: EpsonSymbol
    readonly SYMBOL_QRCODE_MICRO: EpsonSymbol
    readonly SYMBOL_MAXICODE_MODE_2: EpsonSymbol
    readonly SYMBOL_MAXICODE_MODE_3: EpsonSymbol
    readonly SYMBOL_MAXICODE_MODE_4: EpsonSymbol
    readonly SYMBOL_MAXICODE_MODE_5: EpsonSymbol
    readonly SYMBOL_MAXICODE_MODE_6: EpsonSymbol
    readonly SYMBOL_GS1_DATABAR_STACKED: EpsonSymbol
    readonly SYMBOL_GS1_DATABAR_STACKED_OMNIDIRECTIONAL: EpsonSymbol
    readonly SYMBOL_GS1_DATABAR_EXPANDED_STACKED: EpsonSymbol
    readonly SYMBOL_AZTECCODE_FULLRANGE: EpsonSymbol
    readonly SYMBOL_AZTECCODE_COMPACT: EpsonSymbol
    readonly SYMBOL_DATAMATRIX_SQUARE: EpsonSymbol
    readonly SYMBOL_DATAMATRIX_RECTANGLE_8: EpsonSymbol
    readonly SYMBOL_DATAMATRIX_RECTANGLE_12: EpsonSymbol
    readonly SYMBOL_DATAMATRIX_RECTANGLE_16: EpsonSymbol
}