import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { Customers } from 'tilby-models';

const tabCount = 4;
@Component({
    selector: 'app-tab-customer',
    templateUrl: './tab-customer.component.html',
    styleUrls: ['./tab-customer.component.scss'],
})
export class TabCustomerComponent {
    @Input() customer? : Customers;
    @Output() tabPanelChange = new EventEmitter<string>();
    demo1TabIndex = 0;

    swipeRight() {
        if (this.demo1TabIndex)
        this.demo1TabIndex -=1;
    }

    swipeLeft() {
        if (this.demo1TabIndex<(tabCount-1)){
            this.demo1TabIndex+=1;
        }
    }
}
