import { 
    Directive, 
    ElementRef, 
    Injector, 
} from '@angular/core';

import {UpgradeComponent} from "@angular/upgrade/static";
import * as angular from 'angular';

// AngularJs component con ui-view (per utilizzare ui-router)
class RouterOutletWrapper {}

let routerOutletWrapper = {
    controller: RouterOutletWrapper,
    template: `<div class="ui-view" style="height:100%"/>`
};

angular
    .module('TilbyApp')
    .component('routerOutletWrapper', routerOutletWrapper);

// UpgradeComponent per Angular con ui-view Upgradato (per utilizzare ui-router in contesto Angular)
@Directive({
    standalone:true,
    selector: '[appUpgradeUiView]',
    host: {style:  'height: 100%'}

})

export class UpgradeUiViewDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('routerOutletWrapper', elementRef, injector);
    }
}