import {
    Injectable,
    inject
} from '@angular/core';

import {
    ConfigurationManagerService,
    EntityManagerService
} from 'src/app/core';

import {TranslateService} from '@ngx-translate/core';

import {
    AlertDialogService,
    ConfirmDialogService,
    OpenDialogsService,
    SaleDocumentsViewerDialogService,
    WaitDialogService,
    WaitDialogState
} from 'src/app/dialogs';

import {
    documentPrinter, FiscalPrinters,
    FiscalProviders,
    fiscalUtils, optionsDialog,
    printerErrorFiscal,
    saleUtils,
    util
} from 'app/ajs-upgraded-providers';

import {
    Printers,
    Sales,
    SalesCustomer,
    SalesDocuments,
    SalesItems,
    SalesPayments,
    SalesPriceChanges
} from 'tilby-models';

import {autobind} from '../../../../models/decorators.model';
import {GridServerSideComponent} from '../../../../shared/components/grid-server-side/grid-server-side.component';
import {HistoryUtilsService} from './history-utils.service';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { REFUND_CAUSES } from 'src/app/core/constants/refund-causes';
import { ExpenseReportDialogService } from 'src/app/dialogs/history/expense-report-dialog/expense-report-dialog.component';
import { SaleUtilsService } from 'src/app/features';
import { DocumentPrinterOptions } from 'src/app/shared/model/document-printer.model';
import { Subject } from 'rxjs';
import { MathUtils } from 'src/app/shared/utils';

type CreateSaleOptions = {
    addInvoice?: boolean;
    returnOnly?: boolean;
}

type SaleReference = {
    reference_sale_id: number;
    reference_sequential_number?: number;
    reference_date?: Date;
    reference_text?: string;
}

@Injectable({
    providedIn: 'root'
})
export class HistorySalesService {

    private printInProgress = false;

    isSelectingClaimed = false;
    isSelectingUnclaimed = false;

    // public printInProgressChange: Subject<boolean> = new Subject<boolean>();
    selectedSales: Sales[] = [];

    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly documentPrinterService = inject(documentPrinter);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly expenseReportDialogService = inject(ExpenseReportDialogService);
    private readonly FiscalPrintersService = inject(FiscalPrinters);
    private readonly FiscalProvidersService = inject(FiscalProviders);
    private readonly fiscalUtilsService = inject(fiscalUtils);
    private readonly historyUtilsService = inject(HistoryUtilsService);
    private readonly oldSaleUtilsService = inject(saleUtils);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly optionsDialogService = inject(optionsDialog);
    private readonly printerErrorFiscalService = inject(printerErrorFiscal);
    private readonly saleDocumentsViewerDialogService = inject(SaleDocumentsViewerDialogService);
    private readonly saleUtilsService = inject(SaleUtilsService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly translate = inject(TranslateService);
    private readonly utilService = inject(util);
    private readonly waitDialogService = inject(WaitDialogService);

    isEInvoice(sale: Sales) {return sale?.e_invoice?.invoice_progressive;}

    isCreditNote(sale: Sales) { return sale?.final_amount && sale?.final_amount < 0;};

    async createSale(targetSale: Sales, saleType: any, refundCause: any, saleCustomer?: SalesCustomer | string, options?: CreateSaleOptions) {
        const firstDoc = targetSale.sale_documents?.[0];
        const refSeqNum = firstDoc?.sequential_number;
        const refDate = firstDoc?.date;

        let saleItems = (targetSale.sale_items || [])
            .filter(saleItem => !['refund', 'gift'].includes(saleItem.type))
            .map(saleItem => structuredClone(saleItem));

        if (!['void_doc', 'e_credit_note'].includes(saleType)) {
            saleItems = await this.openDialogsService.openRefundItemsSelectorDialog({ data: { saleItems: saleItems } })
                .then(res => res ? this.refundItems(res) : []);

            if (!saleItems.length) {
                return;
            }
        }

        for (const saleItem of saleItems) {
            Object.assign(saleItem, {
                quantity: -saleItem.quantity,
                type: "refund",
                refund_cause_id: refundCause.id,
                refund_cause_description: this.translate.instant(refundCause.translation_id),
                reference_sequential_number: refSeqNum,
                reference_date: refDate
            });
        }

        const salePayments: SalesPayments[] = (targetSale.payments || []).map((payment) => ({
            amount: -payment.amount,
            date: new Date().toISOString() as unknown as Date,
            payment_method_id: payment.payment_method_id,
            payment_method_name: payment.payment_method_name,
            payment_method_type_id: payment.payment_method_type_id,
            payment_method_type_name: payment.payment_method_type_name,
            payment_data: [33].includes(payment.payment_method_type_id) ? payment.payment_data : undefined
        }));

        const saleChange = targetSale.change || 0;

        // Remove change from payments
        if(saleChange) {
            let targetPayment: SalesPayments | undefined;

            switch(targetSale.change_type) {
                case "cash":
                    targetPayment = salePayments.find((payment) => (
                        this.fiscalUtilsService.isCashPayment(payment.payment_method_type_id) && 
                        Math.abs(payment.amount) >= saleChange
                    ));
                    break;
            }

            if(targetPayment) {
                targetPayment.amount = MathUtils.round(targetPayment.amount + saleChange);
            }
        }

        return this.historyUtilsService.createSale({
            e_invoice: options?.addInvoice ? targetSale.e_invoice : undefined,
            name: targetSale.name,
            sale_items: saleItems,
            // @ts-ignore
            sale_customer: saleCustomer,
            // @ts-ignore
            payments: salePayments,
            overrides: {
                sale_parent_id: targetSale.id,
                sale_parent_uuid: targetSale.uuid
            }
        }, {
            saleType: saleType,
            returnOnly: !!options?.returnOnly
        });
    };

    refundItems(saleItems: SalesItems[]) {
        return saleItems;
    }

    public async refundVoid(sale: Sales, gridRef: GridServerSideComponent) {
        if(!sale) {
            return;
        }

        try {
            if (!this.configurationManagerService.isModuleEnabled('cashregister')) {
                throw 'CASHREGISTER_MODULE_NOT_ENABLED';
            }

            //Ask refund cause to the user
            const refCauses = REFUND_CAUSES.map(cause => ({
                ...cause,
                name: this.translate.instant(cause.translation_id)
            }));

            const refundCause = await this.openDialogsService.openRadioListSelectorDialog({
                data: {
                    elements: refCauses,
                    label: this.translate.instant('HISTORY.SALE.REASON')
                }
            });

            if(typeof refundCause?.id !== 'number') {
                return;
            }

            const targetSale = structuredClone(sale);
            const saleCustomer = targetSale.sale_customer ? structuredClone(targetSale.sale_customer) : targetSale.customer_tax_code;
    
            if (this.isEInvoice(sale)) {
                return this.createSale(targetSale, 'e_credit_note', refundCause, saleCustomer, { addInvoice: true });
            }
    
            const printers = await this.entityManagerService.printers.fetchCollectionOffline();
    
            //Use dedicated routine if we are dealing with a fiscal provider
            const fiscalProviderDocument = targetSale.sale_documents?.find((doc) => doc.document_type === 'fiscal_provider');
    
            if (fiscalProviderDocument) {
                await this.voidFiscalProviderDocument(targetSale, fiscalProviderDocument, printers, refundCause, saleCustomer, gridRef);
                return;
            }
    
            //Deal with all other cases
            const rtPrinters = printers.filter((printer) => printer.type === 'rt');
            const hasRTPrinter = !!printers.length
            const docType = targetSale.sale_documents?.[0]?.document_type;
    
            switch (docType) {
                case 'commercial_doc':
                    const printerSerial = targetSale.sale_documents?.[0]?.printer_serial;
    
                    //We cannot void a commercial document if we don't have the printer serial or a configured RT printer
                    if (refundCause.id === 6 && (!hasRTPrinter || !printerSerial)) {
                        throw 'SALE.CANNOT_CANCEL_DOCUMENT';
                    }
    
                    switch (refundCause.id) {
                        case 6: //VOID
                            if ((targetSale.sale_items || []).every((si) => si.price === 0)) {
                                throw 'SALE.CANNOT_CANCEL_ZERO_RECEIPT';
                            }
    
                            return this.voidRTCommercialDocument(targetSale, rtPrinters, printerSerial, refundCause, saleCustomer, gridRef);
                        default: //Refund
                            return this.createSale(targetSale, 'refund_doc', refundCause, saleCustomer);
                    }
                    break;
                case 'fiscal_receipt':
                case 'invoice':
                case 'receipt_invoice':
                case 'summary_invoice':
                case 'summary_e_rc':
                case 'summary_e_nrc':
                case 'shipping_invoice':
                    if (hasRTPrinter && !this.configurationManagerService.getPreference('history.override_credit_note_check')) {
                        throw 'SALE.CANNOT_CANCEL_DOCUMENT';
                    }
    
                    return this.createSale(targetSale, 'credit_note', refundCause, saleCustomer);
                case 'generic_receipt':
                case 'generic_invoice':
                case 'generic_document':
                    return this.createSale(targetSale, docType, refundCause, saleCustomer);
                default:
                    break;
            }
        } catch (err) {
            if (typeof err === 'string') {
                let translateOptions;

                if (err === 'SALE.CANNOT_CANCEL_ZERO_RECEIPT') {
                    translateOptions = {value: this.tilbyCurrencyPipe.transform(0, undefined, 0)};
                }

                const errCorrected = err.indexOf('CASHREGISTER.') > -1 ? err : `HISTORY.${err}`;
                if (errCorrected === 'CASHREGISTER.ACTIVE_SALE.FISCAL_PROVIDER_DOCUMENT_OPEN_FAILED') {
                    return;
                }

                await this.alertDialogService.openDialog({data: {
                    messageLabel: this.translate.instant(errCorrected, translateOptions)
                }});
            }
        }
    }

    public async createExpenseReport(sale: Sales, templateId: number, gridRef: GridServerSideComponent) {
        const template = await this.entityManagerService.nonfiscalDocuments.fetchOneOffline(templateId);

        if(!template) {
            //TODO: show error
            return;
        }

        const printerId = sale.sale_documents?.[0]?.printer_id;

        if(!printerId) {
            //TODO: show error
            return;
        }

        const targetPrinter = await this.entityManagerService.printers.fetchOneOffline(printerId);

        if(!targetPrinter) {
            //TODO: show error
            return;
        }

        const res = await this.expenseReportDialogService.openDialog({data: { sale: sale }});

        if(!res) {
            return;
        }

        const expenseReportSale = await this.oldSaleUtilsService.getSaleTemplate() as Sales;
        expenseReportSale.sale_items = await this.saleUtilsService.repartitionSaleItemsByDepartment(sale, 'by_covers', res.covers, undefined, res.amount, { singleQuantity: true });

        for(const item of expenseReportSale.sale_items) {
            item.name = `${res.covers}x ${this.translate.instant('HISTORY.EXPENSE_REPORT.SALE_ITEM_NAME')}`;
        }

        this.saleUtilsService.calculateSalePrices(expenseReportSale);

        const printingConfig: DocumentPrinterOptions = {
            printer: targetPrinter,
            document_type: { id: 'expense_report' },
            document_template: template,
            options: {}
        }

        await this.documentPrinterService.printDocument(expenseReportSale, printingConfig);

        this.entityManagerService.sales.postOneOfflineFirst({
            ...expenseReportSale,
            //@ts-expect-error
            closed_at: new Date().toISOString(),
            sale_parent_uuid: sale.uuid,
            status: 'closed'
        });

        await new Promise(resolve => setTimeout(resolve, 1000));

        gridRef.refreshGrid();
    }

    public async printCourtesyReceipt(sale: Sales) {
        if (this.printInProgress) {
            return;
        }

        try {
            if (!this.configurationManagerService.isModuleEnabled('cashregister')) {
                throw 'NO_CASHREGISTER_MODULE';
            }

            const printerDefId = parseInt(this.configurationManagerService.getPreference('fiscalprinter.def.id') || '0');

            if (!printerDefId) {
                throw 'NO_DEFAULT_PRINTER';
            }

            this.printInProgress = true;

            const voidPromise = new Promise(async (resolve, reject) => {
                try {
                    await this.FiscalPrintersService.printCourtesyReceipt(sale, printerDefId);
                    resolve(null);
                } catch (error) {
                    resolve(error);
                } finally {
                    this.printInProgress = false;
                }
            });

            const res = await this.waitDialogService.openDialog({ data: { message: 'HISTORY.SALE.PRINT_IN_PROGRESS', promise: voidPromise } });

            if (typeof res === 'string') {
                this.printerErrorFiscalService.show(res, { printerId: printerDefId });
            }
        } catch (err) { //Catches configuration/permission errors
            await this.alertDialogService.openDialog({data: {messageLabel: `HISTORY.SALE.${err}`}});
        }
    };

    public async showSaleDocuments(sale: Sales) {
        await this.saleDocumentsViewerDialogService.openDialog({
            data: {
                sale: sale
            }
        });
    }

    private async voidFiscalProviderDocument(targetSale: Sales, fiscalProviderDocument: SalesDocuments, printers: Printers[], refundCause: any, saleCustomer: SalesCustomer|string|undefined, gridRef: GridServerSideComponent) {
        const fiscalProviderName = fiscalProviderDocument.meta?.fiscal_provider;
        const defPrinterId = parseInt(this.configurationManagerService.getPreference('fiscalprinter.def.id') || '0');

        //Use the default printer or find a printer with the fiscal provider that emitted the sale
        const fiscalProviderPrinter = (
            printers.find((printer) => printer.id === defPrinterId && printer.fiscal_provider === fiscalProviderName) || 
            printers.find((printer) => printer.type === 'receipt' && printer.fiscal_provider === fiscalProviderName)
        );

        if (!fiscalProviderPrinter) {
            throw 'SALE.PRINTER_NOT_AVAILABLE';
        }

        const fiscalProvider = this.FiscalProvidersService.getFiscalProvider(fiscalProviderName);

        if (!fiscalProvider) {
            throw 'SALE.INVALID_FISCAL_PROVIDER';
        }

        if (refundCause.id === 6) { //VOID
            if (typeof fiscalProvider.voidFiscalDocument !== 'function') {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            const nonFiscalDocument = targetSale.sale_documents?.find((saleDocument) => !['fiscal_provider', 'attachment'].includes(saleDocument.document_type));

            if (!nonFiscalDocument) {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            const receiptDocuments = await this.entityManagerService.nonfiscalDocuments.fetchCollectionOffline({type: nonFiscalDocument?.document_type});

            if (!receiptDocuments.length) {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            const voidSale = await this.createSale(targetSale, 'void_doc', refundCause, saleCustomer, { returnOnly: true });

            if (!voidSale) {
                return;
            }

            const documentConfig = {
                document_template: receiptDocuments[0],
                document_type: {id: nonFiscalDocument?.document_type},
                printer: fiscalProviderPrinter,
                reference_fiscal_document: fiscalProviderDocument
            };

            const voidPromise = new Promise(async (resolve, reject) => {
                try {
                    await this.documentPrinterService.printDocument(voidSale, documentConfig);
                    this.closeVoidSale(voidSale);
                    resolve(null);
                } catch (error) {
                    reject({printError: error, printerId: fiscalProviderPrinter.id});
                }
            });

            const res = await this.waitDialogService.openDialog({ data: { message: 'HISTORY.SALE.DOCUMENT_CANCEL_IN_PROGRESS', promise: voidPromise } });

            if (res?.error?.printError) {
                this.printerErrorFiscalService.show(res.error.printError, {printerId: res.error.printerId});
            } else {
                setTimeout(() => {
                    gridRef.refreshGrid();
                }, 1100);
            }
        } else {
            //TODO: stub implementation: this needs to be finished as soon as we have a fiscal provider that allows refunds
            if (typeof fiscalProvider.refundFiscalDocument !== 'function') {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            this.createSale(targetSale, 'refund_doc', refundCause, saleCustomer);
        }
    };

    private async voidRTCommercialDocument(targetSale: Sales, rtPrinters: any, printerSerial: any, refundCause: any, saleCustomer: SalesCustomer|string|undefined, gridRef: GridServerSideComponent) {
        const waitSubject = new Subject<Partial<WaitDialogState>>();

        const printPromise = new Promise(async (resolve, reject) => {
            try {
                //Query all RT printers to find which one printed the document
                let targetPrinter;

                for (const printer of rtPrinters) {
                    try {
                        const status = await this.FiscalPrintersService.getPrinterStatus(printer);

                        if (status.printer_serial === printerSerial) {
                            targetPrinter = printer;
                            break;
                        }
                    } catch (err) {
                        //Nothing to do
                    }
                }

                if (!targetPrinter) {
                    throw 'SALE.PRINTER_NOT_AVAILABLE';
                }

                const sale = await this.createSale(targetSale, 'void_doc', refundCause, saleCustomer, { returnOnly: true });

                if (!sale) {
                    return;
                }

                waitSubject.next({ message: this.translate.instant('HISTORY.SALE.DOCUMENT_CANCEL_IN_PROGRESS') });

                try {
                    await this.documentPrinterService.printDocument(sale, {
                        printer: targetPrinter,
                        document_type: {id: 'fiscal_receipt'}
                    });
                    this.closeVoidSale(sale);
                    resolve(null);
                } catch (err) {
                    reject({printError: err, printerId: targetPrinter.id});
                }
            } catch (err) {
                reject(err);
            }
        });

        const res = await this.waitDialogService.openDialog({ data: { message: 'HISTORY.SALE.SEARCHING_PRINTER', promise: printPromise, stateSubject: waitSubject } });

        if(res?.error) {
            if (typeof res.error === 'string') {
                this.alertDialogService.openDialog({data: {messageLabel: `HISTORY.${res.error}`}});
            } else if (res.error?.printError) {
                this.printerErrorFiscalService.show(res.error.printError, {printerId: res.error.printerId});
            }
        } else {
            setTimeout(() => {
                gridRef.refreshGrid();
            }, 1100);
        }
    };

    private closeVoidSale(sale: Sales) {
        Object.assign(sale, {
            closed_at: new Date().toISOString(),
            status: 'closed'
        });

        this.entityManagerService.sales.postOneOfflineFirst(sale);
    };

    public oldSummaryInvoiceDialog(){
        return this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'TOPBAR.ACTIONS.SUMMARY_INVOICE_DIALOG'
            }
        });
    }


    @autobind
    async createSummarySale(sales: Sales[] = this.selectedSales, summaryType?: 'summary_e_rc' | 'summary_e_nrc' | 'summary') {
        if (!this.configurationManagerService.isModuleEnabled('cashregister')) {
            return this.alertDialogService.openDialog({data: {
                messageLabel: 'HISTORY.CASHREGISTER_MODULE_NOT_ENABLED'
            }});
        }

        let type = summaryType;

        if(!type) {
            if (this.isSelectingUnclaimed) {
                type = 'summary_e_rc';
            } else if (this.isSelectingClaimed) {
                type = 'summary_e_nrc';
            } else {
                type = 'summary';
            }
        }

        if (this.configurationManagerService.isFunctionEnabledOptin('fiscalprinter.override_e_invoice_checks')) {
            let [option] = await this.optionsDialogService.show(this.translate.instant('HISTORY.SALES.CREATE_SUMMARY_INVOICE_TITLE'),[
                {
                    message: this.translate.instant('HISTORY.SALES.CREATE_SUMMARY_INVOICE_DESCRIPTION'),
                    options: [
                        { name: this.translate.instant('HISTORY.SALES.CREATE_SUMMARY_INVOICE_TRADITIONAL'), value: "summary" },
                        { name: this.translate.instant('HISTORY.SALES.CREATE_SUMMARY_INVOICE_ELECTRONIC'), value: type }
                    ],
                    required: true
                }
            ]);

            if (option) {
                type = option;
            }
        }

        const customersInSales: SalesCustomer[] = [];
        const newSaleItems: SalesItems[] = [];
        const priceChanges: SalesPriceChanges[] = [];

        for(const sale of sales) {
            const saleReference = this.getSaleReference(sale);

            if (sale.sale_customer) {
                customersInSales.push(sale.sale_customer);
            }

            for(const saleItem of sale.sale_items || []) {
                const { notes, ...tmpSaleItem } = saleItem;

                newSaleItems.push({
                    ...structuredClone(tmpSaleItem),
                    ...saleReference
                });
            }

            if(sale.price_changes?.length) {
                let partialPrice = sale.amount;
    
                for(const priceChange of sale.price_changes) {
                    const pcAmount = this.fiscalUtilsService.getPriceChangeAmount(priceChange, partialPrice);
                    partialPrice = MathUtils.round(partialPrice + pcAmount);
    
                    if (pcAmount != null) {
                        const isDiscount = priceChange.type.startsWith('discount');
                        const saleDiscountLabel = isDiscount ? 'SUMMARY_SALE_DISCOUNT' : 'SUMMARY_SALE_SURCHARGE';
                        const pcType = isDiscount ? 'discount_fix' : 'surcharge_fix';
    
                        priceChanges.push({
                            index: priceChanges.length + 1,
                            type: pcType,
                            value: Math.abs(this.utilService.round(pcAmount)),
                            description: this.translate.instant(`HISTORY.SALES.${saleDiscountLabel}`, { sale: sale.name })
                        });
                    }
                }
            }
        }

        const customersInSalesWithName: { name: string, customer: SalesCustomer | null, id?: string }[] = Array.from(new Map(customersInSales.map(customer => [customer.customer_id, customer])).values())
            .map(customer => ({
                id: customer.uuid,
                name: this.utilService.getCustomerCaption(customer) || '-',
                customer: customer
            }));

        let selectedCustomer;

        switch (customersInSalesWithName.length) {
            case 0:
                break;
            case 1:
                selectedCustomer = customersInSalesWithName[0].customer;
                break;
            default:
                customersInSalesWithName.unshift({
                    id: 'none',
                    name: this.translate.instant('HISTORY.CUSTOMER_SELECT.NO_CLIENT'),
                    customer: null
                });

                const res = await this.openDialogsService.openRadioListSelectorDialog({
                    data: {
                        elements: customersInSalesWithName,
                        label: 'HISTORY.CUSTOMER_SELECT.TITLE'
                    } 
                });

                if(!res) {
                    return;
                }

                selectedCustomer = res.customer;

                break;
        }

        await this.historyUtilsService.createSale({
            sale_items: newSaleItems,
            sale_customer: selectedCustomer || undefined,
            price_changes: priceChanges
        }, { saleType: type! });
    };

    getSaleReference(sale: Sales) {
        const saleReference: SaleReference = {
            reference_sale_id: sale.id!
        };

        if (sale.sale_documents) {
            const saleReceipt = sale.sale_documents.find((saleDocument) => ['fiscal_receipt', 'commercial_doc', 'fiscal_provider'].includes(saleDocument.document_type));

            if (saleReceipt && Number.isFinite(saleReceipt.sequential_number) && saleReceipt.date) {
                switch (saleReceipt.document_type) {
                    case 'fiscal_receipt':
                        Object.assign(saleReference, {
                            reference_sequential_number: saleReceipt.sequential_number,
                            reference_date: saleReceipt.date
                        });
                    break;
                    case 'commercial_doc':
                        const seqNumStr = `${saleReceipt.sequential_number}`.padStart(8, '0');
                        const refText = [seqNumStr.substring(0, 4), seqNumStr.substring(4, 8)];

                        Object.assign(saleReference, {
                            reference_text: refText.join('-'),
                            reference_date: saleReceipt.date
                        });
                    break;
                    case 'fiscal_provider':
                        const fiscalProvider = this.FiscalProvidersService.getFiscalProvider(saleReceipt.meta?.fiscal_provider);

                        if (fiscalProvider.getSaleReference) {
                            return fiscalProvider.getSaleReference(sale);
                        }

                        Object.assign(saleReference, {
                            reference_sequential_number: saleReceipt.sequential_number,
                            reference_date: saleReceipt.date
                        });
                    break;
                    default:
                        break;
                }
            }
        }

        return saleReference;
    };

    canCheckSale(sale?: Sales & {$hasInvoice?: boolean}) {
        let claimedPayments = 0;
        let unclaimedPayments = 0;

        for(const payment of sale?.payments || []) {
            if (payment.unclaimed) {
                unclaimedPayments = unclaimedPayments + 1;
            } else {
                claimedPayments = claimedPayments + 1;
            }
        }

        let canCheck = false;

        if (claimedPayments > 0 && unclaimedPayments > 0) {
            canCheck = false;
        } else {
            if (!this.isSelectingClaimed && !this.isSelectingUnclaimed) {
                canCheck = true;
            }

            if (this.isSelectingClaimed && claimedPayments > 0) {
                canCheck = true;
            }

            if (this.isSelectingUnclaimed && unclaimedPayments > 0) {
                canCheck = true;
            }
        }

        return canCheck && sale?.final_amount && sale?.final_amount >= 0 && !sale?.is_summary && !sale?.$hasInvoice && sale?.e_invoice === null;
    };


    /**
     * DUPLICATE SALE FROM SALE CLOSED
     * @param sale
     */
    public async duplicateSale(sale: Sales) {
        const res = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.DUPLICATE_SALE.TITLE',
                confirmLabel: 'DIALOG.DUPLICATE_SALE.CONFIRM',
                cancelLabel: 'DIALOG.DUPLICATE_SALE.CANCEL'
            }
        });

        if(!res) {
            return;
        }

        this.createNewSale(structuredClone(sale));
    }

    /**
     * CREATE NEW SALE WITH SALE_ITEMS, SALE_CUSTOMER, PRICE_CHANGES, NOTES FROM ANOTHER SALE CLOSED
     * @param targetSale
     * @returns
     */
    private createNewSale(targetSale: Sales) {
        return this.historyUtilsService.createSale({
            sale_items: targetSale.sale_items,
            sale_customer: targetSale.sale_customer,
            price_changes: targetSale.price_changes,
            notes: targetSale.notes
        });
    };

}
