import {
    Component,
    inject,
} from "@angular/core";

import {
    MAT_DIALOG_DATA,
    MatDialogRef
} from "@angular/material/dialog";

import { OpenDialogsService } from "../../services";

@Component({
    selector: 'app-copy-new-static-token-dialog.component',
    templateUrl: './copy-new-static-token-dialog.component.html',
    styleUrls: ['./copy-new-static-token-dialog.component.scss']
})
export class CopyNewStaticTokenDialogComponent {
    public dialogRef: MatDialogRef<OpenDialogsService> = inject(MatDialogRef);
    public data: {token: string} = inject(MAT_DIALOG_DATA);
}
