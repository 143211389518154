import * as angular from 'angular';
import * as _ from 'lodash';
import { validate as validateUuid } from 'uuid';
import { currencies } from 'src/app/core/constants/currencies';

function HistoryEInvoicesCtrl($scope, $rootScope, $timeout, $mdSidenav, $translate, user, checkManager, entityManager, promptDialog, printerErrorFiscal, eInvoiceErrorViewer, eInvoiceSetup, eInvoiceDialog, alertDialog, optionsDialog, addSelectCustomer, HistoryUtils, toast, util, restManager) {
    _.assign($scope, {
        areAllSalesSelected: false,
        currentPage: 0,
        eInvoicesData: {},
        filteredEInvoicesData: undefined,
        loadingInProgress: true,
        sale: {},
        selectedEInvoices: {},
        selectedEInvoicesCounter: 0,
        sendingEInvoices: {},
        topbar_context: {
            eInvoicesPerPage: 50,
            isCardsSection: true,
            isDownloading: false,
            mode: "showcase",
            selected_card: 'error'
        },
        totalCountForState: {
            pending: 0,
            sent: 0,
            delivered: 0,
            error: 0
        },
        user: user
    });

    // CONTEXT FUNCTIONS

    $scope.topbar_context.showRightButtons = function() {
        return !$scope.selectedEInvoicesCounter;
    };

    $scope.topbar_context.toggleCards = function(){
        $scope.topbar_context.isCardsSection = !$scope.topbar_context.isCardsSection;
    };

    $scope.topbar_context.canDownload = function(){
        return $scope.selectedEInvoicesCounter;
    };

    $scope.topbar_context.canSendViaEmail = function(){
        return $scope.selectedEInvoicesCounter;
    };
    
    $scope.topbar_context.countSelectedEInvoices = function() {
        return $scope.selectedEInvoicesCounter + " fattur" + ($scope.selectedEInvoicesCounter === 1 ? 'a' : 'e') + " selezionat" + ($scope.selectedEInvoicesCounter === 1 ? 'a' : 'e');
    };

    $scope.topbar_context.changeQueryStatus = function() {
        $scope.currentPage = 0;

        $scope.deselectAllInvoices();

        if(!$scope.topbar_context.filters.amountFilter.to) {
            $scope.topbar_context.filters.amountFilter.to = null;
        }
        if(!$scope.topbar_context.filters.amountFilter.from) {
            $scope.topbar_context.filters.amountFilter.from = null;
        }
        if(!$scope.topbar_context.filters.company_name) {
            $scope.topbar_context.filters.company_name = null;
        }

        getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, 0);
    };

    $scope.isSending = function(id) {
        return $scope.sendingEInvoices[id] === true;
    };

    var getErrorCode = function(error) {
        if(_.isString(error)) {
            return error;
        } else if(_.get(error, 'status') === -1) {
            return 'E_INVOICE_OFFLINE';
        } else if(_.get(error, ['data', 'error_code'])) {
            return error.data.error_code;
        } else {
            return 'UNKNOWN_ERROR';
        }
    };

    $scope.sendEInvoice = async (sale) => {
        $scope.sendingEInvoices[sale.id] = true;

        try {
            let options = {};

            const previousSaleDocument = sale.sale_documents?.find((saleDoc) => ['e_invoice', 'summary_e_nrc', 'summary_e_rc'].includes(saleDoc.document_type));

            if (previousSaleDocument) {
                Object.assign(options, {
                    printer_id: previousSaleDocument.printer_id,
                    document_type: previousSaleDocument.document_type
                });

                if (previousSaleDocument.meta) {
                    Object.assign(options, _.pick(previousSaleDocument.meta, ['print_ddt', 'print_only_departments', 'save_paper']));
                }
            }

            const response = await restManager.getOne("send_e_invoice", sale.id, options);

            getEInvoicesCounts();

            if (response.e_invoice.status !== sale.e_invoice.status) {
                _.remove($scope.filteredEInvoicesData, (value, index, arr) => value.id === sale.id);
            }
        } catch (err) {
            toast.show({ message: printerErrorFiscal.translateError(getErrorCode(err)) });
        } finally {
            delete $scope.sendingEInvoices[sale.id];
        }
    };

    $scope.topbar_context.sendViaEmailSelectedEInvoices = function(){
        var keys = Object.keys($scope.selectedEInvoices);
        var ids = [];

        _.forEach(keys, function(key){
            ids.push($scope.selectedEInvoices[key].id);
        });

        ids = ids.join();

        promptDialog.show({ title: "Invia fatture selezionate via email", label: "Email", type: "email", defaultValue: checkManager.getPreference('stock.export.default_email') }).then(function(emailAddress) {
            restManager.downloadOne("sales/e_invoices/export/email","",{ ids: ids, email: emailAddress });
        });
    };
    
    $scope.sendViaEmailEInvoice = function(sale) {
        promptDialog.show({ title: "Invia fatture selezionate via email", label: "Email", type: "email", defaultValue: checkManager.getPreference('stock.export.default_email') }).then(function(emailAddress) {
            restManager.downloadOne("sales/e_invoices/export/email","",{ ids: sale.id, email: emailAddress });
        });
    };

    $scope.topbar_context.downloadSelectedEInvoices = function(){
        var keys = Object.keys($scope.selectedEInvoices);
        var ids = [];
        _.forEach(keys, function(key){
            ids.push($scope.selectedEInvoices[key].id);
        });
        ids = ids.join();
        
        $scope.topbar_context.isDownloading = true;
        restManager.downloadOne("sales/e_invoices/download","",{ids:ids}).then(function(response) {
            $scope.topbar_context.isDownloading = false;
            util.downloadFile(response.data, 'fatture.zip', 'application/zip');
        });
    };

    $scope.downloadEInvoice = function(sale){
        $scope.topbar_context.isDownloading = true;
        restManager.downloadOne("sales/e_invoices/download",sale.id).then(function(response) {
            $scope.topbar_context.isDownloading = false;
            var filename = "";
            var disposition = response.headers()['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                filename = matches[1].replace(/['"]/g, '');
                }
            }
            util.downloadFile(response.data, filename, 'application/xml');
        });
    };

    $scope.canDownloadAll = function(){
        return $scope.totalCountForState['pending'] > 0 && checkManager.getSetting("e_invoice.send_mode") === "download";
    };

    $scope.canEditState = function(){
        return _.includes(['download','ftp'], checkManager.getSetting("e_invoice.send_mode"));
    };

    $scope.downloadAllPendingEInvoices = async () => {
        let [option] = await optionsDialog.show("Fatture da Inviare", [
            {
                message: "Stai per scaricare le fatture in sospeso, come vuoi procedere?",
                default: null,
                options: [
                    { name: "Non scaricare", value: null },
                    { name: "Scarica e contrassegna come inviate", value: "sent"} ,
                    { name: "Scarica senza contrassegnare come inviate", value: "pending" }
                ],
                required: true
            }
        ]);

        if(option){
            $scope.topbar_context.isDownloading = true;

            let config;

            switch(option) {
                case 'pending':
                    config = { status:"pending" };
                break;
                case 'sent':
                    config = { set_as: "sent", status: "pending" };
                break;
            }

            if(config) {
                let response = await restManager.downloadOne("sales/e_invoices/download", "", config);

                $scope.topbar_context.isDownloading = false;

                util.downloadFile(response.data, 'fatture.zip', 'application/zip');
                getEInvoicesCounts();
            }
        }
    };

    $scope.changeSaleStatus = async (sale) => {
        let [option] = await optionsDialog.show(`Modifica stato fattura n. ${sale.e_invoice.invoice_progressive}`,
            [{
                message: "Vuoi modificare lo stato della fattura?",
                options: [
                    { name: "Da Inviare", value: "pending" },
                    { name: "Inviata", value: "sent" },
                    { name: "Consegnata", value: "delivered" },
                    { name: "Scaricata", value: "downloaded" }
                ]
            }]
        );

        if (option && option !== false) {
            let clonedSale = _.cloneDeep(sale);
            let originalState = clonedSale.e_invoice.status;
            clonedSale.e_invoice.status = option;

            try {
                $scope.sendingEInvoices[clonedSale.id] = true;

                let response = await restManager.post("sales", clonedSale, { force_update: true });

                if (response.e_invoice.status !== originalState) {
                    _.remove($scope.filteredEInvoicesData, (value, index, arr) => (value.id === clonedSale.id));
                }

                getEInvoicesCounts();
            } catch(error) {
            } finally {
                delete $scope.sendingEInvoices[clonedSale.id];
            }
        }
    };

    const updateSale = async (sale) => {
        const updatedSale = await restManager.post("sales", sale, { force_update: true });

        angular.copy(updatedSale, sale);
    };

    $scope.editCustomer = async function (sale) {
        const customer = structuredClone(sale.sale_customer);
        const saleCustomer = await addSelectCustomer.show(customer);

        if (validateUuid(saleCustomer.id)) {
            saleCustomer.uuid = saleCustomer.id;
            saleCustomer.customer_id = null;
        } else {
            saleCustomer.customer_id = saleCustomer.id;
        }

        delete saleCustomer.id;
        sale.sale_customer = saleCustomer;

        try {
            await updateSale(sale);
        } catch (err) {
            alertDialog.show(`Impossibile modificare il cliente`);
        }
    };

    $scope.editInvoiceOptions = async (sale) => {
        const eInvoice = structuredClone(sale.e_invoice || {});
        const eInvoiceOptions = await eInvoiceDialog.openDialog({ data: { e_invoice: eInvoice } });

        if (!eInvoiceOptions) {
            return;
        }

        sale.e_invoice = {
            ...sale.e_invoice,
            ...eInvoiceOptions
        };

        try {
            await updateSale(sale);
        } catch (err) {
            alertDialog.show(`Impossibile modificare le opzioni fattura`);
        }
    };

    $scope.duplicateSale = function(sale) {
        if (checkManager.isModuleEnabled('cashregister')) {
            var duplicatedSale = _.cloneDeep(sale);
            var saleCustomer = _.isEmpty($scope.sale.sale_customer) ? $scope.sale.customer_tax_code : _.cloneDeep($scope.sale.sale_customer);
            
            HistoryUtils.createSale({
                e_invoice: duplicatedSale.e_invoice,
                name: duplicatedSale.name,
                price_changes: duplicatedSale.price_changes,
                sale_customer: saleCustomer,
                sale_items: duplicatedSale.sale_items
            }, {
                saleType: 'e_invoice'
            });

        } else {
            alertDialog.show("Il modulo cassa non è abilitato per il tuo utente");
        }
    };

    $scope.resetFilters = function(){
        $scope.topbar_context.filters = {
            content: "",
            company_name: null,
            status: $scope.topbar_context.selected_card,
            amountFilter : {},
            dateFilter: {
                to: null, //new Date(now.getFullYear(), now.getMonth() , now.getDate() ),
                from: null //new Date(now.getFullYear(), now.getMonth() - 2, now.getDate() - 1)
            }
        };
    };

    $scope.changeInvoicesStatusQuery = function(status) {
        $scope.topbar_context.selected_card = status;
        $scope.onInvoiceStatusQueryChange();
    };

    $scope.onInvoiceStatusQueryChange = function() {
        $scope.resetFilters();
        $scope.topbar_context.filters.status = $scope.topbar_context.selected_card;
        $scope.topbar_context.changeQueryStatus();
    };

    $scope.updateSalesCount = function() {
        $scope.selectedEInvoicesCounter = _.keys($scope.selectedEInvoices).length;
    };

    $scope.selectSale = function(sale) {
        $scope.sale = sale;
        $mdSidenav('historySale').open();
    };

    $scope.canCheckSale = function(sale) {
        return true;
    };

    $scope.canDisplayActionsForSale = function(sale) {
        // return _.includes(['error', 'pending', 'delivered'], sale.e_invoice.status);
        return true;
    };

    $scope.openHelpMessage = function(sale) {
        eInvoiceErrorViewer.show(sale);
    };

    $scope.checkSale = function(sale) {
        $timeout(function() {
            if (sale.$checked) {
                $scope.selectedEInvoices[sale.id] = sale;
            } else {
                delete $scope.selectedEInvoices[sale.id];
            }
            $scope.updateSalesCount();
        });
    };

    $scope.deselectAllInvoices = function() {
        $scope.selectedEInvoices = {};
        $timeout(function() {
            _.each($scope.filteredEInvoicesData, function(sale) {
                sale.$checked = false;
            });
            $scope.updateSalesCount();
        });
    };


    $scope.selectAllInvoices = function() {
        if ($scope.areAllSalesSelected) {
            $scope.selectedEInvoices = {};
        }
        $timeout(function() {
            _.each($scope.filteredEInvoicesData, function(sale) {
                if ($scope.areAllSalesSelected) {
                    sale.$checked = true;
                    $scope.selectedEInvoices[sale.id] = sale;
                } else {
                    sale.$checked = false;
                    delete $scope.selectedEInvoices[sale.id];
                }
            });
            $scope.updateSalesCount();
        });
    };

    $scope.getSaleCustomer = function(sale) {
        if(sale.customer_tax_code) {
            return sale.customer_tax_code;
        } else {
            return util.getCustomerCaption(sale.sale_customer) || ' - ';
        }
    };
    
    $scope.getSaleStatus = function(sale) {
        switch(sale.e_invoice.status) {
            case "sent": return "Inviata";
            case "error": return "Errore";
            case "pending": return "Da Inviare";
            case "delivered": return "Consegnata";
            case "downloaded": return "Scaricata";
        }
    };

    $scope.startSetup = function() {
        eInvoiceSetup.show().then(function(result) {
            getEInvoicesCounts().finally(function() {
                if(!_.isObject(result)) {
                    result = {};
                }

                var resultMsg;

                switch(result.contract_status) {
                    case 'SENT':
                        resultMsg = 'NEEDS_CONTRACT_SIGN';
                        break;
                    case 'NOT_SENT':
                        resultMsg = 'CONTRACT_SEND_ERROR';
                    break;
                    default:
                        resultMsg = 'ONBOARDING_COMPLETE';
                    break;
                }

                getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, 0);
                alertDialog.show($translate.instant('HISTORY.E_INVOICE_SETUP.' + resultMsg));
            });
        }, function(error) {
            if(_.isString(error)) {
                alertDialog.show($translate.instant('HISTORY.E_INVOICE_SETUP.' + error));
            }
        });
    };

    $scope.getSaleExternalStatus = function(sale) {
        switch(sale.e_invoice.external_status) {
            //Digithera
            case "IN_PROGRESS": return "In Elaborazione";
            case "USER_VERIFY": return "In Attesa di Verifica Utente";
            case "VERIFY": return "In Verifica Utente";
            case "IS_READY_FOR_SIGNATURE": return "In Attesa di Firma";
            case "DONE": return "Fatto";
            case "IS_CREATED": return "Creata";
            case "SKIPPED": return "Saltata";
            case "IS_CANCELLED": return "Cancellata";
            case "IS_NOT_VALID": return "Non Valida";
            case "IS_DUPLICATED": return "Fattura Duplicata";
            case "IS_NOT_DELIVERED": return "Non recapitata";
            case "IS_UNDELIVERABLE": return "Non recapitabile";
            case "IS_REPLY_OK": return "Validata da PA";
            case "IS_REPLY_KO": return "Rifiutata da PA";
            case "IS_REPLY_TIMEOUT": return "Timeout Risposta PA";
            //Digital Hub
            case "DF": return "Da firmare";
            case 'DI': return 'Da integrare';
            case 'DV': return 'Da validare';
            case 'DC': return 'Da confermare';
            case 'IE': return 'In elaborazione';
            case 'IC': return 'In attesa di consegna';
            case 'MC': return 'Mancata consegna';
            case 'CO': return 'Consegnato';
            case 'RI': return 'Rifiutato';
            case 'SC': return 'Scartato';
            case 'NR': return 'Non recapitabile';
            case 'RC': return 'Rifiutato e completato';
            case 'AC': return 'Accettato';
            case 'DT': return 'Decorsi i termini';
            case 'NT': return 'No tramitazione';
        }
    };

    $scope.getSalePrinter = function(sale) {
        if (_.head(sale.sale_documents)) {
            return _.head(sale.sale_documents).printer_name;
        } else {
            return "-";
        }
    };

    $scope.hasNoResults = function() {
        // when result of get items is 404
        if ($scope.eInvoicesData === false) {
            return true;
        }
        return _.isEmpty($scope.eInvoicesData);
    };

    $scope.decrementPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage--;
            getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, $scope.currentPage);
        }
    };

    $scope.incrementPage = function() {
        if ($scope.currentPage < $scope.eInvoicesData.pages - 1) {
            $scope.currentPage++;
            getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, $scope.currentPage);
        }
    };

    $scope.goToFirstPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage = 0;
            getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, $scope.currentPage);
        }
    };

    $scope.goToLastPage = function() {
        if ($scope.currentPage < $scope.eInvoicesData.pages - 1) {
            $scope.currentPage = $scope.eInvoicesData.pages - 1;
            getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, $scope.currentPage);
        }
    };

    $scope.changePerPage = function(perPage){
        $scope.topbar_context.eInvoicesPerPage = parseInt(perPage);
        $scope.currentPage = 0;
        getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, $scope.currentPage);
    };

    $scope.topbar_context.applyFilters = function() {
        $scope.filteredEInvoicesData = $scope.eInvoicesData.results;
        _.forEach($scope.filteredEInvoicesData, function(sale) {
            sale.$checked = $scope.selectedEInvoices[sale.id] ? true : false;
        });
        $scope.areAllSalesSelected = false;
    };

    var getEInvoicesPage = function(eInvoicesPerPage, pageNumber) { //status: 0 -> closed + stored, 1-> stored, 2-> closed
        $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
            enabled: true
        });

        $scope.loadingInProgress = true;
        // let the scope.digest to hide items
        $timeout(function() {
            $scope.eInvoicesData.results = [];
            var closedAtMax;
            var closedAtSince;

            if($scope.topbar_context.filters.dateFilter.from){
                closedAtSince = new Date($scope.topbar_context.filters.dateFilter.from);
                closedAtSince.setHours(0);
                closedAtSince.setMinutes(0);
                closedAtSince.setSeconds(0);
                closedAtSince = closedAtSince.toISOString();
            }
            
            if($scope.topbar_context.filters.dateFilter.to){
                closedAtMax = new Date($scope.topbar_context.filters.dateFilter.to);
                closedAtMax.setHours(0);
                closedAtMax.setMinutes(0);
                closedAtMax.setSeconds(0);
                closedAtMax = closedAtMax.toISOString();
            }

            var query = {
                pagination: true,
                per_page: eInvoicesPerPage,
                page: pageNumber,
                orderby_desc: 'closed_at',
                status: 'closed',
                closed_at_since: closedAtSince,
                closed_at_max: closedAtMax,
                final_amount_since: $scope.topbar_context.filters.amountFilter.from,
                final_amount_max: $scope.topbar_context.filters.amountFilter.to,
                "e_invoice.invoice_progressive" : "notnull"
            };

            if($scope.topbar_context.filters.company_name){
                var encodedCustomerFilter = util.fixedEncodeURIComponent($scope.topbar_context.filters.company_name);
                query.x_query = "OR(sale_customer.first_name_like=" + encodedCustomerFilter +
                    ",sale_customer.last_name_like=" + encodedCustomerFilter +
                    ",sale_customer.company_name_like=" + encodedCustomerFilter + ')';
            }

            if($scope.topbar_context.filters.status){
                if($scope.topbar_context.filters.status !== 'all'){
                    query["e_invoice.status"] = $scope.topbar_context.filters.status;
                }
            }

            entityManager.sales.fetchCollectionOnline(query).then(function(result) {
                if(result) {
                    _.forEach(result.results, function(sale) {
                        var currencyToUse = currencies[sale.currency] || currencies['EUR'];
                        sale.$currency = currencyToUse.symbol_native;
                        sale.$digits = currencyToUse.decimal_digits;
                    });
                }

                $scope.eInvoicesData = result;

                $scope.topbar_context.applyFilters();

                $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
                    enabled: false
                });
                $scope.loadingInProgress = false;
            });
        });
    };

    var getEInvoicesCounts = function() {
        return restManager.getOne('sales/e_invoices', 'sync_status').then(function(result) {
            $scope.needsSetup = false;

            _.keys($scope.totalCountForState).forEach(function(value) {
                $scope.totalCountForState[value] = _.get(result, ['status', value], 0);
            });
        }, function(error) {
            if(_.get(error, ['data', 'error']) === 'SETUP_NEEDED') {
                $scope.needsSetup = true;
            }
        });
    };

    $scope.resetFilters();

    getEInvoicesCounts().finally(function() {
        getEInvoicesPage($scope.topbar_context.eInvoicesPerPage, 0);
    });
}

HistoryEInvoicesCtrl.$inject = ["$scope", "$rootScope", "$timeout", "$mdSidenav", "$translate", "user", "checkManager", "entityManager", "promptDialog", "printerErrorFiscal", "eInvoiceErrorViewer", "eInvoiceSetup", "eInvoiceDialog", "alertDialog", "optionsDialog", "addSelectCustomer", "HistoryUtils", "toast", "util", "restManager"];

angular.module('history').controller('HistoryEInvoicesCtrl', HistoryEInvoicesCtrl);