import * as angular from 'angular';
import moment from 'moment-timezone';

import {
    Departments,
    Printers,
    Sales,
    SalesItems,
    SalesItemsPriceChanges,
    SalesPriceChanges,
} from 'tilby-models';

import { XMLNode } from 'app/libs/XmlNode';
import { stringToLines } from 'src/app/shared/string-utils';
import { groupBy, keyBy } from 'src/app/shared/utils';

type EpsonHttpResult = {
    success: boolean;
    code: string;
    status: number;
}

type EpsonHttpAdditionalInfo = Record<string, string | null>;

export class EpsonUtilsService {
    constructor(
        private fiscalUtils: any
    ) {
    }

    public getPrinterEndpoint(ipAddress: string, useSSL: boolean) {
        return `http${useSSL ? 's' : ''}://${ipAddress}/cgi-bin/fpmate.cgi`;
    }

    public addSoapEnvelope(command: string) {
        return `<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>${command}</s:Body></s:Envelope>`;
    }

    public getDirectIOCommand(command: string, data: string) {
        return `<printerCommand><directIO command="${command}" data="${data}" /></printerCommand>`;
    }


    public parseCommercialDocumentDate(documentContent: string) {
        const date = documentContent.match(/(\d{2}-\d{2}-\d{4} \d{2}:\d{2})/);

        return date ? moment(date[0], 'DD-MM-YYYY HH:mm').toISOString() : null;
    }

    public cleanupSaleDocument(documentContent?: string) {
        let saleDoc = documentContent;

        if(!saleDoc) {
            return null;
        }

        if (saleDoc.includes('?????')) {
            saleDoc = saleDoc.replace(/\?/g, ' ');
        }

        //Epson printers start each line of the document with 3 tabs.
        saleDoc = saleDoc.replace(/^[\t]{3}/gm, '');

        return saleDoc;
    }

    public parseEpsonXml(responseData: any) {
        const parser = new DOMParser();
        //TODO: check the following line as it seems to cause issues with some Epson ePos printers.
        const text = `${responseData.data}`.replace(/ >/g, ' &gt;').replace(/ </g, ' &lt;');
        const xmlDoc = parser.parseFromString(text, "text/xml");

        const res = xmlDoc.getElementsByTagName('response');

        if (!res.length) {
            return {
                responseText: responseData.data,
                status: responseData.status
            };
        }

        const result: EpsonHttpResult = {
            success: /^(1|true)$/.test(res[0].getAttribute('success') || ''),
            code: res[0].getAttribute('code')!,
            status: parseInt(res[0].getAttribute('status') || '') || 0
        };

        let addInfo: EpsonHttpAdditionalInfo = {};

        const resAdd = res[0].getElementsByTagName('addInfo');

        if (resAdd.length) {
            const list = resAdd[0].getElementsByTagName('elementList');
            const tagNames = list[0].childNodes[0].nodeValue?.split(',') || [];

            for (let tagName of tagNames) {
                const node = resAdd[0].getElementsByTagName(tagName)[0];
                const nodeChild = node.childNodes[0];

                if (nodeChild) {
                    addInfo[tagName] = nodeChild.nodeValue;
                }
            }
        }

        return {
            result: result,
            addInfo: addInfo
        };
    }

    public async eposSend(address: string, request: string, options?: { timeout?: number }) {
        const soap = this.addSoapEnvelope(request);
        let responseData;

        const reqHeaders = {
            'Content-Type': 'text/xml; charset=UTF-8',
            'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
            'SOAPAction': '""'
        };

        try {
            responseData = await this.fiscalUtils.sendRequest(address, { data: soap, timeout: options?.timeout || 60000, headers: reqHeaders, method: 'POST' });
        } catch (responseError: any) {
            throw {
                status: responseError.status,
                responseText: responseError.data
            };
        }

        const res = this.parseEpsonXml(responseData);

        if (!res.result) {
            throw res;
        }

        return res;
    }

    public async sendCommands(printer: Printers, commands: string): Promise<[EpsonHttpResult, EpsonHttpAdditionalInfo]> {
        const printerEndpoint = this.getPrinterEndpoint(printer.ip_address || '', !!(printer.ssl));
        let res;

        try {
            res = await this.eposSend(printerEndpoint, commands);
        } catch (err: any) {
            throw 'CONNECTION_ERROR';
        }

        switch (res.result.status) {
            case 2: //OK
                return [res.result, res.addInfo];
            case 0:
                if (['FP_NO_ANSWER', 'LAN_ERROR', 'LAN_TIME_OUT'].includes(res.result.code!)) {
                    //Firmware upgrade command returns an FP_NO_ANSWER for now, so in this case we pretend to have a positive answer
                    if (res.result.code == "FP_NO_ANSWER" && (commands.includes('printContentByDate') || commands.includes('command="9011"'))) {
                        return [res.result, res.addInfo];
                    }

                    throw 'CONNECTION_ERROR';
                }

                throw `EPSON.${res.result.code}`;
            default:
                throw `EPSON.ERROR_${res.result.status}`;
        }
    }

    public getPriceChangeCommand(priceChange: SalesPriceChanges | SalesItemsPriceChanges, amount: number, departmentId?: number, options?: any) {
        if (amount == null) {
            return;
        }

        let adjustmentType;

        /*
            adjustmentType determines discount/uplift operation to perform:
            0 = Discount on last sale
            3 = Discount on a department
            5 = Uplift on lastsale
            8 = Uplift on a department
            10 = ACCONTO
            11 = OMAGGIO
            12 = BUONO MONO USO
        */

        switch (priceChange.type) {
            case 'discount_fix':
                adjustmentType = departmentId == null ? 2 : 3;
                break;
            case 'discount_perc':
                adjustmentType = departmentId == null ? 2 : 3;
                break;
            case 'surcharge_fix':
                adjustmentType = departmentId == null ? 7 : 8;
                break;
            case 'surcharge_perc':
                adjustmentType = departmentId == null ? 7 : 8;
                break;
            case 'gift':
                if (departmentId != null) {
                    adjustmentType = options?.xml7Mode ? 11 : 3;
                }
                break;
        }

        if (adjustmentType == null) {
            return;
        }

        const commandName = departmentId == null ? 'printRecSubtotalAdjustment' : 'printRecItemAdjustment';

        return new XMLNode(commandName, {
            description: priceChange.description,
            justification: 2,
            department: departmentId,
            adjustmentType: adjustmentType,
            amount: Math.abs(amount).toString().replace(".", ",")
        });
    }

    public applyPriceChanges(priceChanges: SalesPriceChanges[] | SalesItemsPriceChanges[], documentXml: XMLNode, partialPrice: number, departmentId?: number, options?: any) {
        priceChanges = priceChanges.sort((a, b) => a.index - b.index);

        for (let priceChange of priceChanges) {
            const pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice);

            if (pcAmount != null) {
                partialPrice = this.fiscalUtils.roundDecimals(partialPrice + pcAmount);
                const pcCommand = this.getPriceChangeCommand(priceChange, pcAmount, departmentId, options);

                if (pcCommand) {
                    documentXml.appendChild(pcCommand);
                }
            }
        }
    }

    public saleItemToCommands(documentXml: XMLNode, saleItem: SalesItems, options?: any) {
        let nodeType;

        let nodeContent = {
            description: saleItem.name || saleItem.department_name,
            department: saleItem.department_id
        };

        switch (saleItem.type) {
            case 'sale':
                nodeType = "printRecItem";
                break;
            case 'refund':
                nodeType = "printRecRefund";
                break;
            case 'deposit_cancellation':
            case 'coupon':
                nodeType = options?.xml7Mode ? 'printRecItemAdjustment' : "printRecRefund";
                break;
        }

        switch (nodeType) {
            case 'printRecItem':
            case 'printRecRefund':
                Object.assign(nodeContent, {
                    quantity: Math.abs(saleItem.quantity),
                    unitPrice: saleItem.price.toString().replace(".", ","),
                });
                break;
            case 'printRecItemAdjustment':
                Object.assign(nodeContent, {
                    amount: saleItem.price.toString().replace(".", ","),
                    adjustmentType: saleItem.type === 'deposit_cancellation' ? 10 : 12
                });
                break;
        }

        documentXml.appendChild(new XMLNode(nodeType, nodeContent));

        if (options?.printNotes) {
            // Item barcode (if print_notes)
            if (saleItem.barcode) {
                const barcode = saleItem.barcode.toLowerCase();

                if (!barcode.includes('p') && !barcode.includes('q')) {
                    documentXml.appendChild(new XMLNode("printRecMessage", {
                        messageType: 4,
                        message: saleItem.barcode
                    }));
                }
            }

            // Notes
            if (saleItem.notes) {
                const notesLines = stringToLines(saleItem.notes, options?.printerColumns);

                for (let noteLine of notesLines) {
                    const nl = noteLine.trim();

                    if (nl) {
                        documentXml.appendChild(new XMLNode("printRecMessage", {
                            messageType: 4,
                            message: nl
                        }));
                    }
                }
            }
        }

        //Description
        if (options?.description) {
            const descriptionLines = stringToLines(options.description, 25);

            for (let descriptionLine of descriptionLines) {
                const dl = descriptionLine.trim();

                if (dl) {
                    documentXml.appendChild(new XMLNode("printRecMessage", {
                        messageType: 4,
                        message: dl
                    }));
                }
            }
        }
    }

    public extractPayments(sale: Sales, resources?: { departments: Departments[] }, options?: any) {
        const xml7Mode = !!(options?.xml7Mode);
        const payments = this.fiscalUtils.extractPayments(sale);

        let saleType = 0; //Default to mixed

        if (xml7Mode && Array.isArray(resources?.departments)) {
            const departmentsById = keyBy(resources!.departments, (department) => department.id);
            const itemsBySalesType = groupBy(sale.sale_items || [], (saleItem) => departmentsById[saleItem.department_id]?.sales_type);

            if (itemsBySalesType.goods && itemsBySalesType.services) {
                saleType = 0; //Mixed sales type case
            } else if (itemsBySalesType.goods) {
                saleType = 1;
            } else if (itemsBySalesType.services) {
                saleType = 2;
            }
        }

        for (let payment of payments) {
            //Assign payment type
            switch (payment.method_type_id) {
                case 1: case 19: case 21: case 32: case 38: case 39: case 40:
                    payment.payment_type = 0; //Cash
                    break;
                case 3:
                    payment.payment_type = 1; //Cheque
                    break;
                case 4: case 5: case 8: case 11: case 13: case 14: case 15: case 17: case 18: case 27: case 30: case 31: case 35: case 37:
                    payment.payment_type = 2; //Credit / Credit card
                    break;
                case 20:
                    payment.payment_type = 3; //Ticket
                    break;
                case 6: case 34:
                    payment.payment_type = xml7Mode ? 4 : 3; //Ticket with number (xml7) - Ticket (legacy)
                    break;
                case 2: case 22: case 23: case 24: case 25: case 28: case 29: case 36: case 41:
                    payment.payment_type = xml7Mode ? 5 : 3; //Unclaimed (xml7) - Ticket (legacy)
                    break;
                case 10: case 26: case 33:
                    payment.payment_type = xml7Mode ? 6 : 3; //Discount on payment (xml7) - Ticket (legacy)
                    break;
                case 16: case 42:
                    payment.payment_type = payment.unclaimed ? 3 : 2; //(Ticket if unclaimed, digital if otherwise)
                    break;
                default:
                    payment.payment_type = 0;
                    break;
            }

            //Assign index
            if (xml7Mode) {
                switch (payment.method_type_id) {
                    case 1: case 19: case 21: case 32: case 38: case 39: case 40:
                        //TODO: in xml7 index controls the rounding algorithm
                        payment.index = 0;
                        break;
                    case 2:
                        payment.index = saleType;
                        break;
                    case 6: case 34:
                        payment.index = 1;
                        break;
                    case 10: case 33:
                        payment.index = 1;
                        break;
                    case 22: case 23: case 24: case 28: case 29: case 36: case 41:
                        payment.index = 3;
                        break;
                    case 25:
                        payment.index = 5;
                        break;
                    case 26:
                        payment.index = 0;
                        break;
                }
            }
        }

        return payments;
    }
};

EpsonUtilsService.$inject = ["fiscalUtils"];

angular.module('printers').service('epsonUtils', EpsonUtilsService);
