import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TranslateModule} from '@ngx-translate/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
    EditRowsDialogComponent, GenericBottomSheetComponent,
    GridDownloadDialogComponent,
    NexiDialogComponent,
    PayPalDialogComponent,
    SatispayDialogComponent,
    SearchDialogComponent,
    SupportDialogComponent,
    AddTimeSlotDialogComponent,
    QrcodeWaiterShortcutDialogComponent,
    ConfirmDialogWithRememberComponent,
    EditShopPreferenceDialogComponent,
    AutomaticPrintSettingsDialogComponent
} from './';
import {Fidelity_pointsMovementDetailsDialogComponent} from './fidelity_points-movement-details-dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {Prepaid_creditMovementDetailsDialogComponent} from './prepaid_credit-movement-details-dialog';
import {PipesModule} from '../pipes/pipes.module';
import {EmailExportDialogComponent} from './email-export-dialog';
import {RadioListSelectorDialogComponent} from './radio-list-selector-dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {FiscalGridComponent, FiscalPrinterDashboardDialogComponent} from "./fiscal-printer-dashboard-dialog";
import {AgGridModule} from "ag-grid-angular";
import {
    DashboardReportGeneratorService
} from "./fiscal-printer-dashboard-dialog/dashboard-dialog-report-generator.service";
import {GeneralDocumentPrinterSelectorDialogComponent} from "./general-document-printer-selector-dialog";
import {SharedModule} from "../shared/shared.module";
import {PrinterErrorFiscalDialogComponent} from "./printer-error-fiscal-dialog";
import {DailyClosingDialogComponent} from "./daily-closing-dialog";
import { RefundItemsSelectorDialogComponent } from './refund-items-selector-dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {EditRowsDialogWithMagicFormComponent} from "./edit-rows-dialog-with-magic-form";
import { GenericJsonDetailDialogComponent } from './generic-json-detail-dialog';
import { InsertNewDeviceWaiterDialogComponent } from './insert-new-device-waiter-dialog';
import { AddNewStaticTokenDialogComponent,LinkToDeliverectDialogComponent,CopyNewStaticTokenDialogComponent,AddNewUserDialogComponent,EditIdBadgeDialogComponent } from './settings-um';
import {TilbyMagicFormComponent,TilbyDatetimePickerComponent} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { GenericListDialogComponent } from './generic-list-dialog';
import { EditEmailDialogComponent, EditPhoneDialogComponent, EditUiLanguageDialogComponent, EditPinDialogComponent } from 'src/app/dialogs';
import { HistoricalChangesDialogComponent } from './historical-changes-dialog/historical-changes-dialog.component';
import { CashMovementDialogComponent } from './cash-movement/cash-movement.dialog.component';
import {TilbyDialogToolbarComponent} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { HistorySummaryInvoiceViewingUiDialogComponent } from './history-summary-invoice-viewing-ui-dialog';
import { MapCoordinatesDialogComponent } from './map-coordinates-dialog';
import { IntervalBetweenTwoDatesDialogComponent } from './interval-between-two-dates-dialog/interval-between-two-dates-dialog.component';
import { MatProgressSpinner } from "@angular/material/progress-spinner";

let dialogs = [
    SearchDialogComponent,
    EditRowsDialogComponent,
    EditRowsDialogWithMagicFormComponent,
    Fidelity_pointsMovementDetailsDialogComponent,
    Prepaid_creditMovementDetailsDialogComponent,
    EmailExportDialogComponent,
    RadioListSelectorDialogComponent,
    GenericJsonDetailDialogComponent,
    FiscalPrinterDashboardDialogComponent,
    GeneralDocumentPrinterSelectorDialogComponent,
    DailyClosingDialogComponent,
    RefundItemsSelectorDialogComponent,
    InsertNewDeviceWaiterDialogComponent,
    AddNewStaticTokenDialogComponent,
    LinkToDeliverectDialogComponent,
    CopyNewStaticTokenDialogComponent,
    AddNewUserDialogComponent,
    EditIdBadgeDialogComponent,
    GenericListDialogComponent,
    EditEmailDialogComponent,
    EditPhoneDialogComponent,
    EditUiLanguageDialogComponent,
    EditPinDialogComponent
]
    const standaloneDialogComponents = [
        HistoricalChangesDialogComponent ,
        CashMovementDialogComponent,
        NexiDialogComponent,
        SatispayDialogComponent,
        PayPalDialogComponent,
        TilbyDialogToolbarComponent,
        AddTimeSlotDialogComponent,
        HistorySummaryInvoiceViewingUiDialogComponent,
        SupportDialogComponent,
        QrcodeWaiterShortcutDialogComponent,
        ConfirmDialogWithRememberComponent,
        EditShopPreferenceDialogComponent,
        MapCoordinatesDialogComponent,
        IntervalBetweenTwoDatesDialogComponent,
        PrinterErrorFiscalDialogComponent,
        GridDownloadDialogComponent,
        AutomaticPrintSettingsDialogComponent
    ]

@NgModule({
    declarations: [
        dialogs,
        FiscalGridComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatToolbarModule,
        TranslateModule,
        MatRadioModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        PipesModule,
        MatProgressBarModule,
        MatTabsModule,
        NgxJsonViewerModule,
        AgGridModule,
        SharedModule,
        ScrollingModule,
        TilbyMagicFormComponent,
        TilbyDatetimePickerComponent,
        TilbyCurrencyPipe,
        MatBottomSheetModule,
        GenericBottomSheetComponent,
        ...standaloneDialogComponents,
        MatProgressSpinner
    ],
    providers: [DashboardReportGeneratorService,TilbyDatetimePickerComponent],
    exports: dialogs
})
export class DialogsModule { }
