import {
    AfterContentInit,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { CdkDrag, CdkDragEnd, CdkDragHandle } from "@angular/cdk/drag-drop";

const OPEN_DRAG_POSITION_Y = 0;

@Component({
    selector: 'app-tilby-draggable-bottom-sheet',
    standalone: true,
    imports: [CommonModule, MatIconModule, CdkDrag, CdkDragHandle],
    templateUrl: './tilby-draggable-bottom-sheet.component.html',
    styleUrls: ['./tilby-draggable-bottom-sheet.component.scss']
})
export class TilbyDraggableBottomSheetComponent implements OnChanges, AfterContentInit {
    @Input() isMobilePotrait = false;
    @Input() isOpen = false;
    @Input() isOpenBySelection = false;
    @Input() isAccessibility = false;
    @HostBinding('style.--closedDragPositionY') closedDragPositionY = OPEN_DRAG_POSITION_Y;
    @Output() isOpenChange = new EventEmitter<boolean>();
    @Output() isOpenChangeBySelection = new EventEmitter<boolean>();
    @ContentChild('draggableContent') draggableContent?: ElementRef;
    protected dragPosition = { x: 0, y: this.closedDragPositionY };

    protected isDragging = true;

    private setContentHeight() {
        this.closedDragPositionY = (this.draggableContent?.nativeElement.clientHeight || 0) + 12;
    }

    ngAfterContentInit(): void {
        if(this.isOpen) {
            setTimeout(() => this.setContentHeight());
        }
    }

    // START - LIFECYCLE
    ngOnChanges(changes: SimpleChanges) {
        this.setContentHeight();

        if (changes.isOpen || changes.isOpenBySelection) {
            this.toggleBottomSheet({ isOpen: changes.isOpen?.currentValue, isOpenBySelection: changes.isOpenBySelection?.currentValue });
        }
    }
    // END - LIFECYCLE

    protected toggleBottomSheet(params?: { isOpen?: boolean, dragEndEvent?: CdkDragEnd, isOpenBySelection?: boolean }) {
        let wasOpen = this.dragPosition.y === OPEN_DRAG_POSITION_Y;

        if (params && ('isOpen' in params || 'isOpenBySelection' in params)) {
            wasOpen = !(params.isOpen || params.isOpenBySelection);
        } else if (params?.dragEndEvent) {
            this.isDragging = true;
            wasOpen = params.dragEndEvent.distance.y > OPEN_DRAG_POSITION_Y;
        }

        this.dragPosition = {
            ...this.dragPosition,
            y: wasOpen ? this.closedDragPositionY : OPEN_DRAG_POSITION_Y
        };

        if (params && 'isOpenBySelection' in params) {
            this.isOpenChangeBySelection.emit(!wasOpen);
        } else {
            this.isOpenChange.emit(!wasOpen);
        }
    }
}
