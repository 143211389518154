import { computed, inject, Injectable, signal, WritableSignal } from "@angular/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { BehaviorSubject } from "rxjs";
import { EntityManagerService, ScreenOrientationService } from "src/app/core";
import { mobileCheck } from "src/utilities";
import { Bookings } from "tilby-models";

export type BookingsDataInput = {
    date: Date;
    type: number;
};

type BookingsDataSignal = WritableSignal<BookingsDataInput>;

@Injectable({
    providedIn: 'root'
})
export class BookingsStateService {

    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly screenOrientationService= inject(ScreenOrientationService);

    public isMobile = computed(() => this.screenOrientationService.getOrientation() && mobileCheck());

    tabIndex = signal(0);
    selectedRoom: number = 0;

    selectBookingsData: BookingsDataSignal = signal({
        date: TilbyDatePipe.date({ outputFormat: 'date' }),
        type: 1,
    });

    bookings: Bookings[] = [];
    isRefreshData = signal(false);
    bookingData = signal([] as Bookings[]);
    bookingFiltered = signal([] as Bookings[]);
    tableEventEmitter = new BehaviorSubject({} as any);
    bookingObservable = new BehaviorSubject([] as Bookings[]);

    weekDaysRange = {start: new Date(), end: new Date()};
    bookingToday: any = undefined;
    todayDate!: Date;
    todayDatePlus31!: Date;

    constructor(){
        const todayString = this.tilbyDatePipe.transform(new Date(), 'YYYY-MM-dd');
        this.todayDate = TilbyDatePipe.date({outputFormat: 'date', date: todayString || ''});
        this.todayDatePlus31 = TilbyDatePipe.date({outputFormat: 'date', date: todayString || ''});
        this.todayDatePlus31.setDate(this.todayDatePlus31.getDate() + 31);
    }

    changeType(type: number) {
        this.selectBookingsData.set({ ...this.selectBookingsData(), type: type });
    }

    changeBookingDate(date: Date) {
        this.selectBookingsData.set({ ...this.selectBookingsData(), date: date });
    }

    convertToUTC(date: Date): Date {
        const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0, 0, 0, 0
        ));
        return utcDate;
    }

    isBookingOnline(checkDate: Date) {
        return (checkDate.getTime() >= this.todayDate.getTime() && checkDate.getTime() < this.todayDatePlus31.getTime());
    }

    async getBookingsToday(currentDate: Date, typeView: number, range?: {start: Date, end: Date}): Promise<Bookings[]> {
        const isBookingOnline = this.isBookingOnline(currentDate);

        if (isBookingOnline) {
            let bookings = await this.entityManagerService.bookings.fetchCollectionOffline();

            bookings = bookings.slice().sort((a: Bookings, b: Bookings) => {
                const aDate = new Date(a.booked_for);
                const bDate = new Date(b.booked_for);
                const aTimestamp = aDate.getTime();
                const bTimestamp = bDate.getTime();
                return aTimestamp - bTimestamp;
            });

            this.bookingToday = bookings.filter((b: Bookings) => {
                return this.tilbyDatePipe.transform(currentDate, 'dd/MM/YYYY', 'UTC') === this.tilbyDatePipe.transform(new Date(b.booked_for), 'dd/MM/YYYY');
            });
        } else {
            const fromDate = currentDate;
            let toDate = new Date(currentDate.getTime());
            toDate.setHours(toDate.getHours() + 23);
            toDate.setMinutes(toDate.getMinutes() + 59);
            toDate.setSeconds(toDate.getSeconds() + 59);


            let formattedRangeEndDate = range?.end;
            formattedRangeEndDate?.setHours(formattedRangeEndDate.getHours() + 23);
            formattedRangeEndDate?.setMinutes(formattedRangeEndDate.getMinutes() + 59);
            formattedRangeEndDate?.setSeconds(formattedRangeEndDate.getSeconds() + 59);

            let payload = {};

            if(typeView !== 0) {
                payload = {
                    booked_for_since: fromDate.toISOString(),
                    booked_for_max: toDate.toISOString()
                }
            } else {
                payload = {
                    booked_for_since: range?.start,
                    booked_for_max: formattedRangeEndDate
                }
            }

            this.bookingToday = await this.entityManagerService.bookings.fetchCollectionOnline(payload);
        }

        //check if the new bookings are into this.bookings yet
        this.bookingToday.forEach((booking: Bookings) => {
            if(!this.bookings.find(b => b.id === booking.id)) this.bookings.push(booking);
        });

        this.bookingData.set(this.bookings);

        return this.bookingToday;
    }

    async getBookings() {
        this.bookings = await this.entityManagerService.bookings.fetchCollectionOffline();
        this.bookingData.set(this.bookings);
        this.bookingObservable.next(this.bookings);
    }

    addBooking(bookingToAdd: Bookings, bookingDate: Date) {
        // if isBookingOnline is true then push to bookings because we don't need to show it into full calendar
        if(this.isBookingOnline(bookingDate)) {
            const index = this.bookings.findIndex((booking: Bookings) => booking.id === bookingToAdd.id);

            if(index === -1) {
                this.bookings.push(bookingToAdd);
                this.bookingData.set(this.bookings);
                this.bookingObservable.next(this.bookings);
                this.isRefreshData.set(true);
            }
        }
    }

    editBooking(bookingToEdit: Bookings) {
        const index = this.bookings.findIndex((booking: Bookings) => booking.id === bookingToEdit.id);
        
        if(index === -1) return;
        this.bookings[index] = bookingToEdit;
        this.bookingData.set(this.bookings);
        this.bookingObservable.next(this.bookings);
        this.isRefreshData.set(true);
    }

    deleteBooking(bookingToDelete: Bookings) {
        if(this.bookings) {
            const index = this.bookings.findIndex((booking: Bookings) => booking.id === bookingToDelete.id);
            
            if (index === -1) return;

            let newBookings: Bookings[] = [];
            
            this.bookings.forEach(booking => {
                if(booking.id !== bookingToDelete.id) newBookings.push(booking);
            });

            this.bookingData.set(newBookings);
            this.bookingObservable.next(newBookings);
        }
    }  

    updateBookings(){
        if(this.selectBookingsData().type !== 1) return;
        
        const updatedDate = this.selectBookingsData().date;

        if(this.isBookingOnline(updatedDate) === false) {
            this.getBookingsToday(updatedDate, 1)
            .then(newBookingData => {
                //check if the new bookings are into this.bookings yet
                newBookingData.forEach(booking => {
                    if(!this.bookings.find(b => b.id === booking.id)) this.bookings.push(booking);
                });    

                this.bookingObservable.next(this.bookings);
            });
        }
    }
    
    emitTableEvent({table, room}: {table: any, room: any}) {
        this.tableEventEmitter.next({table, room});
    }
}
