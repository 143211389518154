import {
    inject,
    Component,
    Input,
    OnInit,
    Renderer2
} from '@angular/core';

import { GeneralInputButton } from '@tilby/tilby-ui-lib/models';
import { TilbyGeneralInputButtonComponent,TilbyGeneralInputButtonPanelComponent } from '@tilby/tilby-ui-lib/components/tilby-buttons';
import { ModuleEvents } from 'src/app/core/services';
import {ToolbarEventsContextService} from 'src/app/core/services/toolbar-events/toolbar-events-context.service';
import {ToolbarEventsService} from "src/app/core/services/toolbar-events/toolbar-events.service";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import { AsyncPipe } from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'app-buttons-bar',
    templateUrl: './buttons-bar.component.html',
    styleUrls: ['./buttons-bar.component.scss'],
    standalone: true,
    imports: [
        TilbyGeneralInputButtonComponent,
        TilbyGeneralInputButtonPanelComponent,
        MatMenuModule,
        MatButtonModule,
        AsyncPipe,
        MatIconModule,
        MatInputModule
    ],
    providers:[OnDestroyService]
})
export class ButtonsBarComponent implements OnInit {
    public toolbarEventsService = inject(ToolbarEventsService);
    public toolbarEventsContextService = inject(ToolbarEventsContextService);
    private onDestroyService = inject(OnDestroyService);
    private renderer = inject(Renderer2);

    @Input() isContextToolbar = false;
    myButtonService?: ToolbarEventsContextService | ToolbarEventsService;

    callbackEvents(event: Partial<ModuleEvents>&{id?:number}) {
        if('btnCss' in event) {
            const button = document.querySelectorAll(`[name="${event.btnCss?.button}"]`)[0];

            switch (event.btnCss?.type) {
                case 'addCssClass': 
                    this.renderer.addClass(button, event.btnCss?.cssClass || '');
                    break;
                case 'removeCssClass':
                    this.renderer.removeClass(button, event.btnCss?.cssClass || '' );
                    break;
            }
        }
    }

    ngOnInit(): void {
        this.myButtonService = this.isContextToolbar ? this.toolbarEventsContextService : this.toolbarEventsService;
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackEvents(e))
    }

    checkPanelButtons(btns: GeneralInputButton[] | undefined) {
        return btns?.some((btn) => btn.isIt() === true);
    }
}
