import {
    Directive
} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[data-test]'
})
export class DataTestDirective {

    constructor() {
    }

}
