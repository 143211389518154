<mat-tab-group #tabs [@.disabled]="true" [mat-stretch-tabs]="true" mat-align-tabs="start" class="tw-h-full tw-w-full" [selectedIndex]="tabIndex" (selectedTabChange)="onTabChange($event);">
    @for(tabLabel of tabLabels; track tabLabel; let idx = $index){
        <mat-tab [label]="tabLabel">
            <div style="padding-right: 9px;">
                <div class="tw-flex tw-justify-between tw-pt-2.5">
                    <mat-form-field class="tw-h-12 tw-pl-2" subscriptSizing="dynamic">
                        <mat-select [(ngModel)]="shift" name="shifts" (selectionChange)="onShiftChange($event)" placeholder="{{'BOOKINGS.NEW_BOOKING_LIST.ALL_SHIFTS' | translate}}">
                            <mat-option>{{"BOOKINGS.NEW_BOOKING_LIST.NO_SHIFT" | translate}}</mat-option>
                            @for (shift of shifts; track shift) {
                                <mat-option [value]="shift">{{shift.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <div id="bookings-settings-buttons" class="tw-flex">
                        <div class="tw-flex tw-justify-end tw-h-[3rem]" id="customer-select">
                            <button mat-button style="position: relative;" class="tw-h-full" (click)="filterOpen()">
                                <mat-icon class="tw-m-0" style="transform: scale(1.4);">filter_list</mat-icon>
                            </button>
                        </div>
                        <div class="tw-flex tw-justify-end tw-h-[3rem]" id="customer-select">
                            <button mat-button style="position: relative;" class="tw-h-full" (click)="addNewBooking()">
                                <mat-icon class="tw-m-0" style="transform: scale(1.4);">add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                @if(openFilter){    
                    <div class="tw-pt-2.5">
                        <mat-form-field class="tw-w-[calc(100%-9px)] tw-ml-2 tw-h-[48px]" subscriptSizing="dynamic">
                            <mat-label translate="BOOKINGS.STATUSES.FILTER"></mat-label>
                            <input matInput placeholder="" value="" type="text" [(ngModel)]="filter" (input)="onFilterChange($event)">
                        </mat-form-field>
                    </div>
                }
            </div>
            @if(!loadingData){
                @if(bookingsAll.length > 0 || bookingsConfirmed.length > 0 || bookingsProvisional.length > 0){
                    <div class="tw-h-[calc(100vh/1.5)] tw-pt-2.5" tilbyGestures (swipeRight)="swipeRight($event)" (swipeLeft)="swipeLeft($event)">
                        @for(booking of idx === 0 ? bookingsAll : idx === 1 ? bookingsConfirmed : bookingsProvisional; track booking){
                            <div id="booking" (drop)="onDrop($event, booking)" (dragover)="onDragOver($event)"  class="tw-p-6 tw-flex tw-flex-col tw-gap-2 tw-cursor-pointer hover:tw-bg-slate-100" (click)="editBooking(booking, $event)">
                                <div class="tw-flex tw-justify-between tw-items-center">
                                    <div style="display: flex !important; align-items: center;">
                                        @if(booking.channel){
                                            <div class="tw-pr-2">
                                                <img [src]="'assets/images/channels/' + booking.channel + '.png'" style="max-width: 30px; max-height: 30px; background-color: blue">
                                            </div>
                                        }
                                        <div class="tw-font-bold tw-pr-2">
                                            {{booking.booked_for | tilbyDate: 'HH:mm'}}
                                        </div>
                                        @if(!booking.pms_reservation_name) {
                                            <div class="tw-font-bold">
                                                {{booking.booking_customer?.first_name}}
                                                {{booking.booking_customer?.last_name}}
                                            </div>
                                        } @else {
                                            <div class="tw-font-bold">
                                                {{booking.pms_reservation_name}}
                                            </div>
                                        }
                                    </div>
                                    <div class="tw-flex tw-gap-2">
                                        <div class="people tw-flex tw-items-center">
                                            {{booking.people}}
                                            <span class="material-symbols-outlined">
                                                group
                                            </span>
                                        </div>

                                        <div class="duration tw-flex tw-items-center">
                                            {{booking.duration}}
                                            <span class="material-symbols-outlined">
                                                timer
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="tw-flex tw-flex-row tw-justify-between tw-h-2/3">
                                    <div class="tw-w-1/2">
                                        {{booking.notes}}
                                    </div>

                                    <div class="tw-flex tw-flex-col tw-justify-end tw-align-middle tw-gap-2">
                                        @for(table of booking.tables; track table){
                                            <div style="text-align: center;">
                                                @if(table.table_id && tablesById[table.table_id]){
                                                    @if(tablesById[table.table_id].table_name){
                                                        {{tablesById[table.table_id].table_name}}
                                                    }

                                                    @if(tablesById[table.table_id].room_name){
                                                        {{tablesById[table.table_id].room_name}}
                                                    }
                                                }
                                            </div>
                                        }

                                        <mat-form-field class="status-select tw-w-[15rem]" (click)="$event.stopPropagation();">
                                            <mat-select name="status" value="{{booking.status}}" subscriptSizing="dynamic" (selectionChange)="editBookingStatus($event, booking)" >
                                                @for(status of bookingStatuses; track status) {
                                                    <mat-option value="{{status.id}}">{{status.name}}</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        }
                    </div>
                }

                @if(bookingsAll.length === 0 && bookingsConfirmed.length === 0 && bookingsProvisional.length === 0){
                    <div class="tw-h-[calc(100vh/1.5)]" tilbyGestures (swipeRight)="swipeRight($event)" (swipeLeft)="swipeLeft($event)">
                    </div>
                }
            } @else {
                <div class="tw-px-11 tw-py-14 tw-m-auto">
                    <ngx-skeleton-loader class="flex flex-col justify-center align-middle items-center" count="6" [theme]="{ 'width': '100%','height': '56px' }">
                    </ngx-skeleton-loader>
                </div>
            }
        </mat-tab>
    }
</mat-tab-group>
