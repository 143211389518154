import {
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    signal,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";

import {
    CustomForm,
    CustomFormGroup
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';

import { TilbyGeneralInputButtonComponent } from "@tilby/tilby-ui-lib/components/tilby-buttons";
import { TilbyKeypadDisplayComponent } from "../tilby-keypad-display";
import { ScreenOrientationService } from 'src/app/core';

export type TotalFormValue = {
    cashValue: string
}
@Component({
    selector: 'app-tilby-sale-keypad',
    standalone: true,
    imports: [
        CommonModule,
        TilbyGeneralInputButtonComponent,
        TilbyKeypadDisplayComponent,
    ],
    templateUrl: './tilby-sale-keypad.component.html',
    styleUrls: ['./tilby-sale-keypad.component.scss']
})
export class TilbySaleKeypadComponent {
    private readonly screenOrientationService = inject(ScreenOrientationService);

    isMobilePortrait = this.screenOrientationService.isMobilePortrait;

    @Input({ required: true }) form!: CustomFormGroup<CustomForm<TotalFormValue>>;
    @Input({ required: true }) buttons!: GeneralInputButton[];
    @Output() activateSideKeyboard = new EventEmitter<boolean>();

    protected sideKeyboardButton: GeneralInputButton = {
        isIt: signal(true),
        name: 'collapse_content',
        class: 'material-symbols-outlined tw-flex tw-items-center tw-justify-center tw-text-5xl',
        label: signal('collapse_content'),
        click: () => this.activateSideKeyboard.emit(true)
    }
}