import {
	Component,
	inject,
	Injectable
} from '@angular/core';

import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent
} from "@angular/material/dialog";

import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import {
	BaseDialogService,
	NonNullableConfigData
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
	lastValueFrom
} from 'rxjs';

interface DataAlertDialog {
    messageLabel: string;
    confirmLabel?: string;
}

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.scss'],
	standalone: true,
	imports: [
		MatButtonModule,
		MatDialogActions,
		MatDialogContent,
		MatDialogClose,
		TranslateModule
	]
})
export class AlertDialogComponent {
	public data: DataAlertDialog = inject(MAT_DIALOG_DATA);
}

@Injectable({
	providedIn: 'root'
})
export class AlertDialogService extends BaseDialogService {
	private readonly dialogRef = inject(MatDialog);

	public openDialog(options: NonNullableConfigData<DataAlertDialog>): Promise<boolean | void> {
        const config: NonNullableConfigData<DataAlertDialog> = {
            ...this.switchMobileDesktopDimensions({ width: '600px' }),
            ...options,
			data: options.data
        };

        return lastValueFrom(this.dialogRef.open(AlertDialogComponent, config).afterClosed());
    }

	/**
	 * @deprecated needed for backwards compatibility, use `openDialog` instead
	 * 
	 */
	public show(message: string, options?: { confirmLabel?: string }): Promise<boolean | void> {
		return this.openDialog({
			data: {
				messageLabel: message,
				confirmLabel: options?.confirmLabel
			}
		});
	}
}