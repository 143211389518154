import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('printers').factory('EscPosDriver', EscPosDriver);

EscPosDriver.$inject = ['errorsLogger', 'EscPosEncoder'];

function EscPosDriver(errorsLogger, EscPosEncoder) {
    function EscPosDriver(ipAddress, port, configuration) {
        this.networkSettings = {
            ipAddress: ipAddress,
            port: port || 9100,
            connection: configuration?.connection_type
        };

        //Default style settings
        this.printSettings = {
            dw: false,
            dh: false,
            reverse: false,
            height: 1,
            width: 1,
            ul: false,
            em: false,
            linespc: configuration?.line_spacing ?? 0x50,
            color: EscPosDriver.prototype.COLOR_1,
            align: EscPosDriver.prototype.ALIGN_LEFT
        };

        this.lastPrintSettings = _.clone(this.printSettings);

        this.escPosDoc = [
            0x1b, 0x3d, 0x01, //Set peripheral device
            0x1b, 0x40, //Reset printer
            0x1b, 0x33, this.printSettings.linespc //Set line spacing
        ];

        this.codepageMapping = configuration?.codepage_mapping;
        this.eReceiptMode = !!(configuration?.eReceiptMode);
    }

    const disconnectSocket = function(socketInfo) {
        window.chrome.sockets.tcp.disconnect(socketInfo.socketId, function() {
            window.chrome.sockets.tcp.close(socketInfo.socketId);
        });
    };

    const connectAndSend = function(target, docArray, callback) {
        if(target.ipAddress === '0.0.0.0') {
            callback('PRINTED');
        } else {
            switch(target.connection) {
                case 'sp': //Serial port
                    if(window.require) {
                        const { SerialPort } = window.require('serialport');

                        let targetPort = new SerialPort({ path: target.ipAddress, baudRate: 9600, autoOpen: false });

                        targetPort.open((err) => {
                            if(err) {
                                callback('CONNECTION_ERROR');
                                return;
                            }

                            targetPort.write(docArray, (err) => {
                                callback(err ? 'CONNECTION_ERROR' : 'PRINTED');
                                targetPort.close();
                            });
                        });
                    } else {
                        callback('UNSUPPORTED_PLATFORM');
                    }
                break;
                case 'ts': //TCP Socket (Default)
                default:
                    let timeoutHandle = null;

                    window.chrome.sockets.tcp.create(function(socketInfo) {
                        let timeoutFunc = function() {
                            window.chrome.sockets.tcp.getInfo(socketInfo.socketId, function(info) {
                                if (!info.connected) {
                                    errorsLogger.debug(`[EscPosDriver][${target.ipAddress}] Connection timedout`);
                                    callback('CONNECTION_TIMEDOUT');
                                    disconnectSocket(socketInfo);
                                }
                            });
                        };

                        timeoutHandle = setTimeout(timeoutFunc, 3000);

                        // Connect
                        window.chrome.sockets.tcp.connect(socketInfo.socketId, target.ipAddress, target.port, function(result) {
                            clearTimeout(timeoutHandle);
                            // Ok
                            if (result === 0) {
                                errorsLogger.debug(`[EscPosDriver][${target.ipAddress}] Socket connected`);

                                let docBuffer = Uint8Array.from(docArray).buffer;

                                window.chrome.sockets.tcp.send(socketInfo.socketId, docBuffer, function(message) {
                                    errorsLogger.debug(`[EscPosDriver][${target.ipAddress}] Socket disconnected`);
                                    callback('PRINTED');
                                    disconnectSocket(socketInfo);
                                });
                            }
                            // Connection not ok
                            if (result < 0) {
                                callback('CONNECTION_ERROR');
                                disconnectSocket(socketInfo);
                            }
                        });
                    });
                break;
            }
        }
    };

    const encodeText = (text, codepageMapping) =>  {
        let result = [];
        let encodedData = EscPosEncoder.encode(text, codepageMapping);

        for(let set of encodedData) {
            result.push(0x1b, 0x74, set.codepageCommand, ...set.bytes);
        }

        return result;
    };

    const getLineSpace = (linespc) => [0x1b, 0x33, linespc];
    const setPrintMode = (dw, dh, em, ul) => [0x1b, 0x21, ((ul ? 128 : 0) + (dw ? 32 : 0) + (dh ? 16 : 0) + (em ? 8 : 0))];
    const getReverse = (reverse) => [0x1d, 0x42, (reverse ? 1 : 0)];
    const getSizeCommands = (width, height) => [0x1d, 0x21, ((height - 1) * 16) + (width - 1)];

    function getAlignment(align) {
        switch (align) {
            case EscPosDriver.prototype.ALIGN_CENTER:
                return [0x1b, 0x61, 0x31];
            case EscPosDriver.prototype.ALIGN_LEFT:
                return [0x1b, 0x61, 0x30];
            case EscPosDriver.prototype.ALIGN_RIGHT:
                return [0x1b, 0x61, 0x32];
            default:
                return [0x1b, 0x61, 0x30];
        }
    }

    EscPosDriver.prototype.FONT_A = 'font_a';
    EscPosDriver.prototype.FONT_B = 'font_b';
    EscPosDriver.prototype.FONT_C = 'font_c';
    EscPosDriver.prototype.FONT_SPECIAL_A = 'special_a';
    EscPosDriver.prototype.FONT_SPECIAL_B = 'special_b';
    EscPosDriver.prototype.ALIGN_CENTER = "center";
    EscPosDriver.prototype.ALIGN_LEFT = "left";
    EscPosDriver.prototype.ALIGN_RIGHT = "right";
    EscPosDriver.prototype.COLOR_NONE = "color_none";
    EscPosDriver.prototype.COLOR_1 = "color_1";
    EscPosDriver.prototype.COLOR_2 = "color_2";
    EscPosDriver.prototype.COLOR_3 = "color_3";
    EscPosDriver.prototype.COLOR_4 = "color_4";
    EscPosDriver.prototype.BARCODE_UPC_A = 'upc_a';
    EscPosDriver.prototype.BARCODE_UPC_E = 'upc_e';
    EscPosDriver.prototype.BARCODE_EAN13 = 'ean13';
    EscPosDriver.prototype.BARCODE_JAN13 = 'jan13';
    EscPosDriver.prototype.BARCODE_EAN8 = 'ean8';
    EscPosDriver.prototype.BARCODE_JAN8 = 'jan8';
    EscPosDriver.prototype.BARCODE_CODE39 = 'code39';
    EscPosDriver.prototype.BARCODE_ITF = 'itf';
    EscPosDriver.prototype.BARCODE_CODABAR = 'codabar';
    EscPosDriver.prototype.BARCODE_CODE93 = 'code93';
    EscPosDriver.prototype.BARCODE_CODE128 = 'code128';
    EscPosDriver.prototype.BARCODE_GS1_128 = 'gs1_128';
    EscPosDriver.prototype.BARCODE_GS1_DATABAR_OMNIDIRECTIONAL = 'gs1_databar_omnidirectional';
    EscPosDriver.prototype.BARCODE_GS1_DATABAR_TRUNCATED = 'gs1_databar_truncated';
    EscPosDriver.prototype.BARCODE_GS1_DATABAR_LIMITED = 'gs1_databar_limited';
    EscPosDriver.prototype.BARCODE_GS1_DATABAR_EXPANDED = 'gs1_databar_expanded';
    EscPosDriver.prototype.HRI_NONE = 'none';
    EscPosDriver.prototype.HRI_ABOVE = 'above';
    EscPosDriver.prototype.HRI_BELOW = 'below';
    EscPosDriver.prototype.HRI_BOTH = 'both';
    EscPosDriver.prototype.CUT_NO_FEED = "no_feed";
    EscPosDriver.prototype.CUT_FEED = "feed";
    EscPosDriver.prototype.CUT_RESERVE = "reserve";
    EscPosDriver.prototype.DRAWER_1 = "drawer_1";
    EscPosDriver.prototype.DRAWER_2 = "drawer_2";
    EscPosDriver.prototype.PULSE_100 = "pulse_100";
    EscPosDriver.prototype.PULSE_200 = "pulse_200";
    EscPosDriver.prototype.PULSE_300 = "pulse_300";
    EscPosDriver.prototype.PULSE_400 = "pulse_400";
    EscPosDriver.prototype.PULSE_500 = "pulse_500";
    EscPosDriver.prototype.LEVEL_0 = "level_0";
    EscPosDriver.prototype.LEVEL_1 = "level_1";
    EscPosDriver.prototype.LEVEL_2 = "level_2";
    EscPosDriver.prototype.LEVEL_3 = "level_3";
    EscPosDriver.prototype.LEVEL_4 = "level_4";
    EscPosDriver.prototype.LEVEL_5 = "level_5";
    EscPosDriver.prototype.LEVEL_6 = "level_6";
    EscPosDriver.prototype.LEVEL_7 = "level_7";
    EscPosDriver.prototype.LEVEL_8 = "level_8";
    EscPosDriver.prototype.LEVEL_L = "level_l";
    EscPosDriver.prototype.LEVEL_M = "level_m";
    EscPosDriver.prototype.LEVEL_Q = "level_q";
    EscPosDriver.prototype.LEVEL_H = "level_h";
    EscPosDriver.prototype.LEVEL_DEFAULT = "default";
    EscPosDriver.prototype.SYMBOL_PDF417_STANDARD = "pdf417_standard";
    EscPosDriver.prototype.SYMBOL_PDF417_TRUNCATED = "pdf417_truncated";
    EscPosDriver.prototype.SYMBOL_QRCODE_MODEL_1 = "qrcode_model_1";
    EscPosDriver.prototype.SYMBOL_QRCODE_MODEL_2 = "qrcode_model_2";
    EscPosDriver.prototype.SYMBOL_QRCODE_MICRO = "qrcode_micro";
    EscPosDriver.prototype.SYMBOL_MAXICODE_MODE_2 = "maxicode_mode_2";
    EscPosDriver.prototype.SYMBOL_MAXICODE_MODE_3 = "maxicode_mode_3";
    EscPosDriver.prototype.SYMBOL_MAXICODE_MODE_4 = "maxicode_mode_4";
    EscPosDriver.prototype.SYMBOL_MAXICODE_MODE_5 = "maxicode_mode_5";
    EscPosDriver.prototype.SYMBOL_MAXICODE_MODE_6 = "maxicode_mode_6";
    EscPosDriver.prototype.SYMBOL_GS1_DATABAR_STACKED = "gs1_databar_stacked";
    EscPosDriver.prototype.SYMBOL_GS1_DATABAR_STACKED_OMNIDIRECTIONAL = "gs1_databar_stacked_omnidirectional";
    EscPosDriver.prototype.SYMBOL_GS1_DATABAR_EXPANDED_STACKED = "gs1_databar_expanded_stacked";
    EscPosDriver.prototype.SYMBOL_AZTECCODE_FULLRANGE = "azteccode_fullrange";
    EscPosDriver.prototype.SYMBOL_AZTECCODE_COMPACT = "azteccode_compact";
    EscPosDriver.prototype.SYMBOL_DATAMATRIX_SQUARE = "datamatrix_square";
    EscPosDriver.prototype.SYMBOL_DATAMATRIX_RECTANGLE_8 = "datamatrix_rectangle_8";
    EscPosDriver.prototype.SYMBOL_DATAMATRIX_RECTANGLE_12 = "datamatrix_rectangle_12";
    EscPosDriver.prototype.SYMBOL_DATAMATRIX_RECTANGLE_16 = "datamatrix_rectangle_16";

    EscPosDriver.prototype.addText = function(text) {
        if(this.eReceiptMode) {
            return;
        }

        if(this.printSettings.dw !== this.lastPrintSettings.dw || this.printSettings.dh !== this.lastPrintSettings.dh || this.printSettings.em !== this.lastPrintSettings.em || this.printSettings.ul !== this.lastPrintSettings.ul) {
            this.escPosDoc.push(...setPrintMode(this.printSettings.dw, this.printSettings.dh, this.printSettings.em, this.printSettings.ul));
        }
        if(this.printSettings.width !== this.lastPrintSettings.width || this.printSettings.height !== this.lastPrintSettings.height) {
            this.escPosDoc.push(...getSizeCommands(this.printSettings.width, this.printSettings.height));
        }
        if(this.printSettings.align !== this.lastPrintSettings.align) {
            this.escPosDoc.push(...getAlignment(this.printSettings.align));
        }
        if(this.printSettings.linespc !== this.lastPrintSettings.linespc) {
            this.escPosDoc.push(...getLineSpace(this.printSettings.linespc));
        }
        if(this.printSettings.reverse !== this.lastPrintSettings.reverse) {
            this.escPosDoc.push(...getReverse(this.printSettings.reverse));
        }

        this.escPosDoc.push(...encodeText(text, this.codepageMapping));
        this.lastPrintSettings = _.clone(this.printSettings);
    };

    EscPosDriver.prototype.addTextDouble = function(dw, dh) {
        this.printSettings.dw = _.isBoolean(dw) ? dw : this.printSettings.dw;
        this.printSettings.dh = _.isBoolean(dh) ? dh : this.printSettings.dh;
    };

    EscPosDriver.prototype.addTextSize = function(w, h) {
        this.printSettings.width = _.isInteger(w) && _.inRange(w, 1, 9) ? w : this.printSettings.width;
        this.printSettings.height = _.isInteger(h) && _.inRange(h, 1, 9) ? h : this.printSettings.height;
    };

    EscPosDriver.prototype.addLogo = function(key1, key2) {
        if(this.eReceiptMode) {
            return;
        }

        this.escPosDoc.push(0x1d, 0x28, 0x4c, 0x06, 0x00, 0x30, 0x45, (_.inRange(key1, 0, 256) ? key1 : 0x20), (_.inRange(key2, 0, 256) ? key2 : 0x20), 0x01, 0x01);
    };

    EscPosDriver.prototype.addTextAlign = function(align) {
        if(_.includes([EscPosDriver.prototype.ALIGN_LEFT, EscPosDriver.prototype.ALIGN_CENTER, EscPosDriver.prototype.ALIGN_RIGHT], align)) {
            this.printSettings.align = align;
        }
    };

    EscPosDriver.prototype.addTextLineSpace = function(linespc) {
        this.printSettings.linespc = linespc;
    };

    EscPosDriver.prototype.addTextStyle = function(reverse, ul, em, color) {
        this.printSettings.reverse = _.isBoolean(reverse) ? reverse : this.printSettings.reverse;
        this.printSettings.ul = _.isBoolean(ul) ? ul : this.printSettings.ul;
        this.printSettings.em = _.isBoolean(em) ? em : this.printSettings.em;
        this.printSettings.color = _.includes([EscPosDriver.prototype.COLOR_1, EscPosDriver.prototype.COLOR_2, EscPosDriver.prototype.COLOR_3, EscPosDriver.prototype.COLOR_4, EscPosDriver.prototype.COLOR_NONE], color) ? color : EscPosDriver.prototype.COLOR_1;
    };

    const getBarcodeTypeCode = (type) => {
        switch (type) {
            case EscPosDriver.prototype.BARCODE_UPC_A:
                return 0x41;
            case EscPosDriver.prototype.BARCODE_UPC_E:
                return 0x42;
            case EscPosDriver.prototype.BARCODE_EAN13:
            case EscPosDriver.prototype.BARCODE_JAN13:
                return 0x43;
            case EscPosDriver.prototype.BARCODE_EAN8:
            case EscPosDriver.prototype.BARCODE_JAN8:
                return 0x44;
            case EscPosDriver.prototype.BARCODE_CODE39:
                return 0x45;
            case EscPosDriver.prototype.BARCODE_ITF:
                return 0x46;
            case EscPosDriver.prototype.BARCODE_CODABAR:
                return 0x47;
            case EscPosDriver.prototype.BARCODE_CODE93:
                return 0x48;
            case EscPosDriver.prototype.BARCODE_CODE128:
                return 0x49;
            case EscPosDriver.prototype.BARCODE_GS1_128:
                return 0x4a;
            case EscPosDriver.prototype.BARCODE_GS1_DATABAR_OMNIDIRECTIONAL:
                return 0x4b;
            case EscPosDriver.prototype.BARCODE_GS1_DATABAR_TRUNCATED:
                return 0x4c;
            case EscPosDriver.prototype.BARCODE_GS1_DATABAR_LIMITED:
                return 0x4d;
            case EscPosDriver.prototype.BARCODE_GS1_DATABAR_EXPANDED:
                return 0x4e;
            default:
                return 0x45; //CODE39
        }
    };

    const getHRICode = (hri) => {
        switch (hri) {
            case EscPosDriver.prototype.HRI_NONE:
                return 0x00;
            case EscPosDriver.prototype.HRI_ABOVE:
                return 0x01;
            case EscPosDriver.prototype.HRI_BELOW:
                return 0x02;
            case EscPosDriver.prototype.HRI_BOTH:
                return 0x03;
            default:
                return 0x00;
        }
    };

    const getFontCode = (font) => {
        switch (font) {
            case EscPosDriver.prototype.FONT_A:
                return 0x00;
            case EscPosDriver.prototype.FONT_B:
                return 0x01;
            case EscPosDriver.prototype.FONT_C:
                return 0x02;
            case EscPosDriver.prototype.FONT_SPECIAL_A:
                return 0x61;
            case EscPosDriver.prototype.FONT_SPECIAL_B:
                return 0x62;
            default:
                return 0x00;
        }
    };

    const getQrCodeCorrectionLevel = (level) =>  {
        switch(level) {
            case EscPosDriver.prototype.LEVEL_L:
                return 0x30;
            case EscPosDriver.prototype.LEVEL_M:
                return 0x31;
            case EscPosDriver.prototype.LEVEL_Q:
                return 0x32;
            case EscPosDriver.prototype.LEVEL_H:
                return 0x33;
            case EscPosDriver.prototype.LEVEL_DEFAULT:
                return 0x30;
        }
    };

    EscPosDriver.prototype.addBarcode = function(data, type, hri, font, width, height) {
        if(this.eReceiptMode) {
            return;
        }

        if(hri) {
            this.escPosDoc.push(...[0x1d, 0x48, getHRICode(hri)]);
        }

        if(font) {
            this.escPosDoc.push(...[0x1d, 0x66, getFontCode(font)]);
        }

        if(width && width > 0 && width < 256) {
            this.escPosDoc.push(...[0x1d, 0x77, width]);
        }

        if(height && height > 0 && height < 256) {
            this.escPosDoc.push(...[0x1d, 0x68, height]);
        }

        this.escPosDoc.push(...[0x1d, 0x6b, getBarcodeTypeCode(type), data.length, ...data.split('').map((l) => l.charCodeAt(0))]);
    };

    EscPosDriver.prototype.addSymbol = function(data, type, level, width, height, size) {
        if(this.eReceiptMode) {
            return;
        }

        const symbolCommands = [0x1d, 0x28, 0x6b];

        let typeCode;
        let dataSize = _.size(data);
        let functionParams = {};

        switch(type) {
            case EscPosDriver.prototype.SYMBOL_QRCODE_MODEL_1:
                typeCode = 0x31;
                functionParams = { 0x41: [0x31, 0x00] };
            break;
            case EscPosDriver.prototype.SYMBOL_QRCODE_MODEL_2:
                typeCode = 0x31;
                functionParams = { 0x41: [0x32, 0x00] };
            break;
            case EscPosDriver.prototype.SYMBOL_QRCODE_MICRO:
                typeCode = 0x31;
                functionParams = { 0x41: [0x33, 0x00] };
            break;
        }

        switch(typeCode) {
            case 0x31: //QR Code
                this.escPosDoc.push(...symbolCommands, 0x04, 0x00, typeCode, 0x41, ...functionParams[0x41]); //Select QR Code model
                this.escPosDoc.push(...symbolCommands, 0x03, 0x00, typeCode, 0x43, _.toInteger(width)); //Set size (use width parameter for matching the ePos API)
                this.escPosDoc.push(...symbolCommands, 0x03, 0x00, typeCode, 0x45, getQrCodeCorrectionLevel(level)); //Set correction level
                this.escPosDoc.push(...symbolCommands, (dataSize % 256) + 3, Math.floor(dataSize / 256), typeCode, 0x50, 0x30, ..._.chain(data).toString().map((l) => l.charCodeAt(0)).value()); //Store the QR Code data
                this.escPosDoc.push(...symbolCommands, 0x03, 0x00, typeCode, 0x51, 0x30); //Print the QR Code
            break;
        }
    };

    EscPosDriver.prototype.addCut = function(type) {
        if(this.eReceiptMode) {
            return;
        }

        let typeCode;

        switch(type) {
            case EscPosDriver.prototype.CUT_FEED:
                typeCode = 0x42;
                break;
            case EscPosDriver.prototype.CUT_NO_FEED:
                typeCode = 0x31;
                break;
            case EscPosDriver.prototype.CUT_RESERVE:
                typeCode = 0x62;
                break;
            default:
                typeCode = 0x42;
        }

        this.escPosDoc.push(0x0a, 0x0d, 0x0a, 0x0d, 0x1d, 0x56, typeCode);

        if(typeCode !== 49) { //FEED and RESERVE require an additional parameter
            this.escPosDoc.push(0x00);
        }
    };

    EscPosDriver.prototype.addPulse = function(drawer, time) {
        let drawerCode;
        let pulseCode;

        switch(drawer) {
            case EscPosDriver.prototype.DRAWER_1:
                drawerCode = 0;
                break;
            case EscPosDriver.prototype.DRAWER_2:
                drawerCode = 1;
                break;
            default:
                drawerCode = 0;
        }

        switch(time) {
            case EscPosDriver.prototype.PULSE_100:
                pulseCode = 50;
                break;
            case EscPosDriver.prototype.PULSE_200:
                pulseCode = 100;
                break;
            case EscPosDriver.prototype.PULSE_300:
                pulseCode = 150;
                break;
            case EscPosDriver.prototype.PULSE_400:
                pulseCode = 200;
                break;
            case EscPosDriver.prototype.PULSE_500:
                pulseCode = 250;
                break;
            default:
                pulseCode = 250;
        }

        this.escPosDoc.push(0x1b, 0x70, drawerCode, pulseCode, pulseCode);

        //Add RCH internal pulse
        this.escPosDoc.push(0x1b, 0x42, 0x01, 0x09);
    };

    EscPosDriver.prototype.send = function(callback) {
        connectAndSend(this.networkSettings, this.escPosDoc, (result) => {
            callback(this.eReceiptMode ? 'PRINTED' : result);
        });
    };

    EscPosDriver.connectAttempt = function(ip, port, callback) {
        if(ip === '0.0.0.0') {
            callback({ ip: ip, connected: true });
        } else {
            let timeoutHandle = null;

            window.chrome.sockets.tcp.create(function(socketInfo) {
                let timeoutFunc = function() {
                    window.chrome.sockets.tcp.getInfo(socketInfo.socketId, function(info) {
                        if (!info.connected) {
                            callback({
                                ip: ip,
                                connected: false
                            });
                            disconnectSocket(socketInfo);
                        }
                    });
                };

                // Connect
                window.chrome.sockets.tcp.connect(socketInfo.socketId, ip, port, function(result) {
                    clearTimeout(timeoutHandle);
                    callback({
                        ip: ip,
                        connected: (result === 0)
                    });
                    disconnectSocket(socketInfo);
                });

                timeoutHandle = setTimeout(timeoutFunc, 1000);
            });
        }
    };

    EscPosDriver.displayText = async (printer, textLines) => {
        let docToSend = [0x1b, 0x3d, 0x02, 0x1b, 0x40, 0x0c, 0x0b];
        let printerInfo = {
            ipAddress: printer.ip_address,
            port: printer.port || 9100,
            connection: printer.connection_type
        };

        for(let line of textLines) {
            docToSend.push(...encodeText(line, printer.configuration?.codepage_mapping || 'epson'));
        }

        return new Promise((resolve, reject) => {
            connectAndSend(printerInfo, docToSend, function(result) {
                if(result === 'PRINTED') {
                    resolve();
                } else {
                    reject(result);
                }
            });
        });
    };

    return EscPosDriver;
}