import { Injectable, inject } from "@angular/core";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { MagicPortalDialogComponent } from "../magic-portal-dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { RefundOrRemoveItemDialogComponent } from "./refund-or-remove-item-dialog.component";
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";
import { REFUND_CAUSES } from "src/app/core/constants/refund-causes";
import { Validators } from "@angular/forms";
import { mobileCheck } from "@tilby/tilby-ui-lib/utilities";


type RefundOrRemoveItemFormValue = {
    refund_cause: string
};

export type RefundOrRemoveItemForm = CustomFormGroup<CustomForm<RefundOrRemoveItemFormValue>>;
export type RefundOrRemoveItemParams = {enableRefund : boolean};

export type RefundOrRemoveItemFormDialogData = InputItemDataMagicForm<RefundOrRemoveItemFormValue>;

@Injectable({
    providedIn: 'root',
})
export class RefundOrRemoveItemDialogStateService {
    private readonly openDialogsService = inject(OpenDialogsService);
    protected readonly mobileCheck = mobileCheck();
    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<RefundOrRemoveItemDialogComponent>>;

    showForm$ = new BehaviorSubject(false);

    private _form?: RefundOrRemoveItemForm;

    public async openDialog(config:NonNullableConfigData<RefundOrRemoveItemParams>) {
        this.resetInitialValues();
        const {enableRefund} = config.data;
        this.createForm();

        const configPortal: NonNullablePortalConfigData<RefundOrRemoveItemFormDialogData, RefundOrRemoveItemDialogComponent> = {
            data: {
                form: this._form!,
                hideConfirm: true,
                confirmDisabled: () => (this._form!.invalid),
                component: RefundOrRemoveItemDialogComponent,
                titleLabel: 'CASHREGISTER.REFUND_REMOVE.MESSAGE',
                bottomActionButtons: this.createBottomActionButtons(enableRefund)
            }
        };
        this.dialogRef = this.openDialogsService.openPortalDialogRef<RefundOrRemoveItemDialogComponent, PortalConfigData<RefundOrRemoveItemFormDialogData, RefundOrRemoveItemDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '800px' }),
            ...configPortal
        });
        return lastValueFrom(this.dialogRef.afterClosed()).then((res : PortalConfigData<RefundOrRemoveItemFormDialogData & {action: string}, RefundOrRemoveItemDialogComponent>) => {
            if (res) {
                if (res.action !== 'remove' && res?.form.valid) {
                    return {
                        action: "refund",
                        refundCause: REFUND_CAUSES.find(({id}) => id === res?.form.value?.refund_cause)
                    };
                } else {
                    return {action: 'remove'};
                }
            } else {
                return {action: 'cancel'};
            }
        });
    }

    private createForm() {
        this._form = new CustomFormGroup<CustomForm<RefundOrRemoveItemFormValue>>({
            refund_cause: new CustomFormControl(undefined, {validators: [Validators.required]}, {
                ...new CustomFormControlProps(),
                label: ``,
                inputType: 'radio',
                matElementClass: `tw-flex-col tw-justify-normal tw-flex-auto ${this.mobileCheck ? 'tw-ml-4' : 'tw-ml-10'}`,
                inputChoices: REFUND_CAUSES.filter((c) => c.showInCashregister).map((c) => ({ key: c.translation_id, value: c.id })),
            }),
        });
    }

    private createBottomActionButtons(enableRefund : boolean = true) {
        return <GeneralInputButton[]>[
            ...(enableRefund ? [{ name: 'CASHREGISTER.REFUND_REMOVE.REFUND', click: () => this.action('refund') }] : []),
            { name: 'CASHREGISTER.REFUND_REMOVE.REMOVE', click: () => this.action('remove') },
        ];
    }

    private action(type: string) {
        type === 'refund' ? this.showForm$.next(true) : this.dialogRef?.close({action: type});
    }

    private resetInitialValues() {
        this.showForm$.next(false);
    }

}
