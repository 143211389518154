<tilby-active-sale-header-info
    [disableActions]="!!isPaymentsState"
    [showSaleFinalAmount]="true"
    [sale]="sale"
    [documentData]="documentData"
    [accessibility]="isAccessibility"
    [isOrderPrinterPermitted]="isOrderPrinterPermitted"
    [showDeselectSale]="true"
    [isPaymentsState]="!!isPaymentsState"
    [contextMenu]="contextMenu"
    (addCustomerActionEvent)="addCustomerAction()"
    (removeCustomerActionEvent)="removeCustomerAction()"
    (selectSaleActionEvent)="selectSaleAction()"
    (preBillActionEvent)="printNonFiscalSale()"
    (saleChangeNameActionEvent)="saleChangeName()"
    (deselectSaleActionEvent)="deselectSaleAction()"
    (openOrderPrinterSelectActionEvent)="printNonFiscalSale(true)"
/>
