import {
    Component,
    computed,
    effect,
    inject,
    Injector,
    Input,
    OnInit,
    Renderer2,
    signal,
} from '@angular/core';

import {UpgradeUiViewOneDirective, UpgradeUiViewTwoDirective} from 'src/Ajs<--->A14';
import {ToolbarEventsService} from 'src/app/core/services/toolbar-events/toolbar-events.service';
import {SearchBarComponent} from 'src/app/core/components/generalized-toolbar/search-bar/search-bar.component';
import {DevLogger} from 'src/app/shared/dev-logger';
import {ScreenOrientationService} from 'src/app/core/services/screen-orientation.service';
import {distinctUntilChanged} from 'rxjs/internal/operators/distinctUntilChanged';
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';
import {ModuleEvents} from 'src/app/core/services/toolbar-events/toolbar-events.model';
import {CustomForm, CustomFormGroup} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import {CashregisterStateService} from './services/cashregister.state.service';

import {
    BarcodeEvent,
    BarcodeManagerService,
    ConnectionService,
    EntityManagerService,
    EnvironmentInfoService,
    RIGHT_SIDENAV_PORTAL_DATA_TOKEN,
    RightSidenavService
} from 'src/app/core';

import {TranslateService} from '@ngx-translate/core';
import {ComponentPortal} from '@angular/cdk/portal';
import {
    CashregisterTopbarSettingsComponent
} from './components/cashregister-topbar-settings/cashregister-topbar-settings.component';
import {Categories, Channels, Departments, Items, Stock} from 'tilby-models';
import {GeneralInputButton, MenuItem} from '@tilby/tilby-ui-lib/models';
import {CommonModule} from "@angular/common";
import {CashregisterMobileViewComponent} from "./cashregister-mobile-view/cashregister-mobile-view.component";

import {
    dashboardDataManager,
    documentPrinter,
    leanPMS,
    customerDisplayManager
} from "app/ajs-upgraded-providers";

import {SclDictionary} from 'app/libs/SclDictionary';
import {CashregisterKeyboardComponent} from "./components";
import {
    TilbyDraggableBottomSheetComponent
} from "./components/cashregister-showcase/tilby-draggable-bottom-sheet/tilby-draggable-bottom-sheet.component";
import {ActiveSaleService, ActiveSaleStoreService, QuickCoupon} from "./services";
import {subscribeInComponent} from '@tilby/tilby-ui-lib/utilities';
import {DualService} from 'src/app/dual.service';
import {BehaviorSubject, filter, first, map, switchMap, tap} from "rxjs";
import {SharedModule} from "../../shared/shared.module";
import {
    AddCashMovementDialogService,
    PrinterErrorFiscalDialogService,
    WaitDialogService
} from 'src/app/dialogs';
import {
    CashregisterPaymentsMobileViewComponent
} from "./cashregister-payments-mobile-view/cashregister-payments-mobile-view.component";
import {SelectTypeDailyClosingDialogService} from 'src/app/dialogs/select-type-daily-closing-dialog';
import {ObjDataData, Today} from '../dashboard/models';
import {alignDataPrinters, getPayments, getToday, getWeatherIcon} from '../dashboard';
import {
    TilbyQuickCouponButtonsComponent
} from "./components/cashregister-keyboard/tilby-quick-coupon-buttons/tilby-quick-coupon-buttons.component";
import {TilbyCurrencyPipe} from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import {GenericListItem} from 'src/app/dialogs/generic-list-dialog/generic-list-dialog.model';
import {KeyboardParentToAddNewItemToSaleComponent} from './keyboard-parent-to-add-new-item-to-sale';

import { CustomerDisplayManagerService } from 'app/modules/cashregister/service/customer-display-manager/customer-display-manager';
import { MathUtils } from 'src/app/shared/utils';

export type ItemsByCategory = { [key: number | string]: Items[] };

export type StockDictionaryKey = `combination_id_${number}` | `item_id_${number}`;
export type StockFieldToPick = 'combination_id' | 'item_id' | 'name' | 'code' | 'combination' | 'options_values' | 'barcode' | 'unit' | 'available' | 'stock_quantity';
export type StockDictionary = SclDictionary<StockDictionaryKey,Pick<Stock, StockFieldToPick>>;

@Component({
    standalone: true,
    selector: 'app-cashregister',
    templateUrl: './cashregister.component.html',
    styleUrls: ['./cashregister.component.scss'],
    providers: [OnDestroyService],
    host: {
        class: 'tw-h-full tw-block'
    },
    imports: [
        CashregisterKeyboardComponent,
        CashregisterMobileViewComponent,
        CashregisterPaymentsMobileViewComponent,
        CommonModule,
        SharedModule,
        TilbyDraggableBottomSheetComponent,
        TilbyQuickCouponButtonsComponent,
        UpgradeUiViewOneDirective,
        UpgradeUiViewTwoDirective,
    ]
})
export class CashregisterComponent extends KeyboardParentToAddNewItemToSaleComponent implements OnInit {
    private readonly addCashMovementDialogService = inject(AddCashMovementDialogService);
    private readonly barcodeManagerService = inject(BarcodeManagerService);
    private readonly customerDisplayManager: CustomerDisplayManagerService = inject(customerDisplayManager);
    private readonly dashboardDataManagerService = inject(dashboardDataManager);
    private readonly documentPrinter = inject(documentPrinter);
    private readonly dualService = inject(DualService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly injector = inject(Injector);
    private readonly leanPMS = inject(leanPMS);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);
    private readonly renderer = inject(Renderer2);
    private readonly rightSidenavService = inject(RightSidenavService<any>);
    private readonly screenOrientationService = inject(ScreenOrientationService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translateService = inject(TranslateService);
    private readonly waitDialogService = inject(WaitDialogService);

    @Input({required: true}) items!: ItemsByCategory;
    @Input({required: true}) stockDictionary!: StockDictionary;
    @Input({required: true}) categories!: Categories[];
    @Input({required: true}) departments!: Departments[];
    @Input({required: true}) deliveryChannels!: Channels[];


    // CHECK MOBILE AND VERTICAL PORTRAIT
    protected isMobilePortrait = this.screenOrientationService.isMobilePortrait;
    protected isMobile = this.cashregisterStateService.isMobile;
    protected paymentInProgress = false;
    protected showKeyboard = true;

    private priceLists: MenuItem[] = [];
    private changePricelistIcon = new BehaviorSubject(this.priceList());
    private isAddingBarcodeItem = false;

    private readonly isCheckStockEnabled = !!this.configurationManagerService.getPreference('cashregister.check_stock');
    private readonly isPriceListUpdateEnabled = !this.configurationManagerService.getPreference("cashregister.disable_price_list_apply_prompt");
    private readonly isSwitchPriceListEnabled = !!this.configurationManagerService.getPreference('cashregister.enable_switch_price_list');
    private readonly showOpenDrawer = !this.configurationManagerService.isFunctionEnabledOptin('cashregister.hide_open_drawer');

    protected readonly isAccessibility: boolean = this.configurationManagerService.getPreference('general.simplified_accessibility') || false;

    private getAccessibilityString = this.isAccessibility ? (string: string) => signal(`CASHREGISTER.TOPBAR.${string}`) : (string: string) => signal(undefined);

    protected isLandscape = computed(() => !!this.screenOrientationService.getOrientation().includes('landscape'));

    protected mainKeyboard= computed(()=>!this.cashregisterStateService.sideKeyboard());

    supportedPMSRoomServices = [this.leanPMS];
    pmsRoomService = this.supportedPMSRoomServices.find((service: any) => service.isEnabled());

    // START - QUICK COUPONS
    protected quickCouponButtons = this.saleUtilsService.getConfiguredQuickCoupons()
        .map(quickCoupon => (<GeneralInputButton>{
            isIt: signal(true),
            name: quickCoupon.name,
            label: signal(quickCoupon.name),
            label2: signal(this.injector.get(TilbyCurrencyPipe).transform(quickCoupon.value)),
            class: 'tw-w-full tw-p-0 tw-rounded-none',
            click: () => this.addQuickCoupon(quickCoupon),
        })) || [];

    protected addQuickCoupon(coupon:QuickCoupon){
        this.log(`Clicked ${coupon.name}`,coupon);
        const targetDepartment = this.departments.find(department => department.id == coupon.department_id );

        if(targetDepartment) {
            this.activeSaleService.addDynamicItemToSale(targetDepartment, MathUtils.round(coupon.value), {
                type: 'coupon',
                quantity: -1,
                name: coupon.name
            });
        } else {
            this.alertDialogService.openDialog({data:{messageLabel:'CASHREGISTER.SHOWCASE.QUICK_COUPON_MISSING_DEPARTMENT'}});
        }
    };
    // END - QUICK COUPONS

    private wasOpenedBySelection=false;
    protected keyboardIsOpen$ = this.cashregisterStateService.keyboardIsOpen$;
    protected keyboardIsOpenBySelection$ = this.cashregisterStateService.keyboardIsOpenBySelection$.pipe(
        distinctUntilChanged(),
        tap((toggleBySelection)=>this.log('SELECTION CLICKED VALUE',toggleBySelection)),
        switchMap(toggleBySelection =>
            this.keyboardIsOpen$.pipe(
                first(),
                map(wasOpen => (this.wasOpenedBySelection&&!toggleBySelection)
                        ?false
                        :!wasOpen&&toggleBySelection
                    ),
                tap(toggleBySelection => this.wasOpenedBySelection=toggleBySelection),
                distinctUntilChanged(),
            )
        ),
    );

    private log(...args: any[]) {
        DevLogger.debug('[ CashregisterComponent ]', ...args);
    }

    // START - LIFECYCLE
    constructor() {
        super();
        this.subscriptions();
        this.checkConnection();
        this.priceList.set(this.activeSaleService?.currentSale?.sale_customer?.default_pricelist || this.saleUtilsService.getCurrentPriceList());

        effect(() => {
            this.changePricelistIcon.next(this.priceList());
            this.isLandscape() ? this.createButtonsPanelsToolbar() : this.createButtonsPanelsToolbar();
        });
    }
    ngOnInit(): void {
        this.setToolbar();
        this.priceLists = this.getPriceLists();
        this.setCashregisterState();
        this.cashregisterStateService.keyboardIsOpenBySelection$.next(false);
    }

    ngOnDestroy(): void {
        this.toolbarEventsService.centralToolbarComponent$.next(SearchBarComponent);
    }

    // END - LIFECYCLE

    private setToolbar() {
        this.setModuleTitleOnToolbar();
        this.setToolbarSearch();
    }

    private getPriceLists() {
        const priceLists = [];

        for (let idx = 1; idx <= 10; idx++) {
            // If the price list is hidden, skip it
            if (this.configurationManagerService.getPreference(`price_list_${idx}_hide`)) {
                continue;
            }

            priceLists.push({
                index: idx,
                value: this.configurationManagerService.getPreference(`price_list_${idx}_name`) || `${this.translateService.instant('CASHREGISTER.TOPBAR.PRICE_LIST_START')} ${idx}`
            });
        }

        return priceLists;
    }

    private setCashregisterState(){
        this.cashregisterStateService.initState({
            items:this.items,
            stockDictionary:this.stockDictionary,
            categories:this.categories,
            departments:this.departments,
            deliveryChannels:this.deliveryChannels
        })
    }

    // START - TOOLBAR SETTINGS METHODS
    private setModuleTitleOnToolbar() {
        this.toolbarEventsService.moduleTitle.next("CASHREGISTER");
    }
    private setToolbarSearch() {
        this.resetSearchBar();
        this.toolbarEventsService.events.pipe(distinctUntilChanged(), this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e))
        this.toolbarEventsService.searchBarIconFilled$.next('close');
        this.toolbarEventsService.searchBarAction$.next({searchBarcode: 'searchBarcode'});
        this.toolbarEventsService.searchBarActionFilled$.next({deleteAllInSearchBar: 'deleteAllInSearchBar'});
    }

    async selectPriceList() {
        const priceLists: GenericListItem[] = this.getPriceLists().map(({ index, value }) => ({
            name: value,
            nameValue: index,
            isSelected: this.priceList() == index
        }));

        const answer = await this.openDialogsService.openGenericListDialog({
            data: {
                title: {label: 'DIALOG.PRICE_LIST_DIALOG.TITLE'},
                list: priceLists,
                showCheckbox: true
            }
        });

        if (typeof answer?.nameValue !== 'number') {
            return;
        }

        this.onPriceListSelect(answer.nameValue);
    }

    createButtonsPanelsToolbar() {
        const buttons = [
            {
                isIt: signal(this.environmentInfoService.isAppleMobile()),
                isAccessibility: this.isAccessibility,
                name: 'search_sale_by_terminal_barcode',
                iconType: 'symbols',
                icon: signal('barcode'),
                label: this.getAccessibilityString('BARCODE'),
                click: (_: any) => this.onBarcodeScannedAppleMobile(),
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            },
            {
                isIt: signal(!!this.pmsRoomService),
                isAccessibility: this.isAccessibility,
                name: 'hotel',
                icon: signal('hotel'),
                label: this.getAccessibilityString('PMS_ROOM_SERVICE_ACCESSIBILITY'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_open_pms_lean: true}),
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            },
            {
                isIt: signal(this.isSwitchPriceListEnabled),
                isAccessibility: this.isAccessibility,
                name: 'local_offer',
                icon: signal('local_offer'),
                click: (_event: any, data: MenuItem) => data.index && this.onPriceListSelect(data.index),
                isMenu: true,
                label: this.getAccessibilityString('PRICE_LIST_START'),
                menuParams: { selected: this.changePricelistIcon, items: this.priceLists, showSelectedOnMainButton: true },
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            },
            {
                isIt: signal(this.showOpenDrawer),
                isAccessibility: this.isAccessibility,
                name: 'eject',
                icon: signal('eject'),
                label: this.getAccessibilityString('OPEN_DRAWER_ACCESSIBILITY'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_open_tray: true}),
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            },
            {
                isIt: signal(true),
                isAccessibility: this.isAccessibility,
                name: 'settings_backup_restore',
                icon: signal('settings_backup_restore'),
                label: this.getAccessibilityString('DAILY_CLOSING_ACCESSIBILITY'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_daily_closing: true}),
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            },
            {
                isIt: signal(this.isCheckStockEnabled),
                isAccessibility: this.isAccessibility,
                name: 'widgets',
                icon: signal('widgets'),
                label: this.getAccessibilityString('CHECK_STOCK_ACCESSIBILITY'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_open_stock: true}),
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            },
            {
                isIt: signal(true),
                isAccessibility: this.isAccessibility,
                name: 'add_cash_movements',
                icon: signal('swap_vertical_circle'),
                label: this.getAccessibilityString('ADD_CASH_MOVEMENT'),
                click: (_: any) => this.toolbarEventsService.events.next({add_cash_movement: true}),
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            },
            {
                isIt: signal(true),
                isAccessibility: this.isAccessibility,
                name: 'settings',
                icon: signal('settings'),
                label: this.getAccessibilityString('SETTINGS.TITLE'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_settings: true}),
                class: this.isAccessibility ? 'tw-w-[130px]' : ''
            }
        ]

        const panels = [
            {
                isIt: signal(this.environmentInfoService.isAppleMobile()),
                name: 'search_sale_by_terminal_barcode',
                iconType: 'symbols',
                label: signal('CASHREGISTER.TOPBAR.BARCODE'),
                icon: signal('barcode'),
                click: (_: any) => this.onBarcodeScannedAppleMobile()
            },
            {
                isIt: signal(!!this.pmsRoomService),
                name: 'hotel',
                icon: signal('hotel'),
                label: signal('CASHREGISTER.TOPBAR.PMS_ROOM_SERVICE'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_open_pms_lean: true})
            },
            {
                isIt: signal(this.isSwitchPriceListEnabled),
                name: 'local_offer',
                icon: signal('local_offer'),
                label: signal('CASHREGISTER.TOPBAR.PRICE_LIST_START'),
                iconLabel: () => this.priceList(),
                click: (_: any) => this.toolbarEventsService.events.next({ cashregister_select_pricelist: true })
            },
            {
                isIt: signal(this.showOpenDrawer),
                name: 'eject',
                icon: signal('eject'),
                label: signal('CASHREGISTER.TOPBAR.OPEN_DRAWER'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_open_tray: true})
            },
            {
                isIt: signal(true),
                name: 'settings_backup_restore',
                icon: signal('settings_backup_restore'),
                label: signal('CASHREGISTER.TOPBAR.DAILY_CLOSING'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_daily_closing: true})
            },
            {
                isIt: signal(this.isCheckStockEnabled),
                name: 'widgets',
                icon: signal('widgets'),
                label: signal('CASHREGISTER.TOPBAR.CHECK_STOCK'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_open_stock: true})
            },
            {
                isIt: signal(true),
                name: 'add_cash_movements',
                icon: signal('swap_vertical_circle'),
                label: signal('CASHREGISTER.TOPBAR.ADD_CASH_MOVEMENT'),
                click: (_: any) => this.toolbarEventsService.events.next({add_cash_movement: true})
            },
            {
                isIt: signal(true),
                name: 'settings',
                icon: signal('settings'),
                label: signal('CASHREGISTER.TOPBAR.SETTINGS.TITLE'),
                click: (_: any) => this.toolbarEventsService.events.next({cashregister_settings: true})
            }
        ]

        setTimeout(() => {
            const divGeneralizedToolbar = document.getElementById("generalized-toolbar");
            const widthGeneralizedToolbar = divGeneralizedToolbar?.offsetWidth;
            const divLeftToolbar = document.getElementById("left-toolbar");
            const widthLeftToolbar = divLeftToolbar?.offsetWidth;
            const divSearchBar = document.getElementById("search-bar");
            const widthSearchBar = divSearchBar?.offsetWidth;

            const space = widthGeneralizedToolbar! - (widthLeftToolbar! + widthSearchBar!);
            let numberOfElements = 0;
            if (!this.isAccessibility) {
                // Only icons
                numberOfElements = Math.floor(space / 48);
            } else {
                // Icons with label
                numberOfElements = Math.floor(space / 130);
            }

            let buttonsShow : any = [];
            let panelsShow : any = [];

            buttons.forEach((button: any) => {
                if (button.isIt()) {
                    buttonsShow.push(button);
                }
            });

            panels.forEach((panel: any) => {
                if (panel.isIt()) {
                    panelsShow.push(panel);
                }
            });

            let barButtons : any = [];
            let panelButtons : any = [];

            const buttonsLength = buttonsShow.length;

            let hasPanels = false;
            let checkNumber = 0;

            if (numberOfElements <= 0) {
                numberOfElements = 1;
            }
            if (buttonsLength < numberOfElements) {
                checkNumber = buttonsLength;
                hasPanels = false;
            } else {
                checkNumber = numberOfElements-1;
                hasPanels = true;
            }

            for (let i = 0; i < checkNumber; i++) {
                barButtons.push(
                    buttonsShow[i]
                )
            }

            if (hasPanels) {
                for (let i = checkNumber; i < buttonsLength; i++) {
                    panelButtons.push(panelsShow[i]);
                }
            }

            this.toolbarEventsService.buttons$.next({
                barButtons,
                panelButtons
            });
        }, 1);
    }

    ngAfterViewInit() {
        this.createButtonsPanelsToolbar();
    }

    private onPriceListSelect(priceList: number | undefined) {
        if(!this.isSwitchPriceListEnabled || !priceList) {
            return;
        }
    
        this.toolbarEventsService.events.next({ cashregister_change_pricelist: true, id: priceList });
    }

    private async callbackToToolbarClick(event: Partial<ModuleEvents>&{id?:number} = {}) {
        if ("deleteAllInSearchBar" in event) {
            this.resetSearchBar();
        } else if ("search" in event) {
            this.search(event.search);
        } else if ("searchBarcode" in event && event.searchBarcode) {
            this.onBarcodeScanned(event.searchBarcode);
        } else if ("add_cash_movement" in event) {
            this.addCashMovement();
        } else if ("cashregister_open_pms_lean" in event) {
            this.openPmsLeanDialog();
        } else if ("cashregister_open_stock" in event) {
            this.openStockDialog();
        } else if ("cashregister_open_tray" in event) {
            this.openCashDrawer();
        } else if ("cashregister_daily_closing" in event) {
            this.dailyClosing();
        } else if ("cashregister_change_pricelist" in event && event.id) {
            this.changePricelist(event.id);
        } else if ("cashregister_settings" in event) {
            this.settings();
        } else if ("cashregister_select_pricelist" in event) {
            this.selectPriceList();
        }
    }

    private resetSearchBar() {
        this.toolbarEventsService.searchBarValue$.next('');
        this.environmentInfoService.isCameraBarcodeSupported() ? this.toolbarEventsService.searchBarIcon$.next('photo_camera') : this.toolbarEventsService.searchBarIcon$.next('');
        this.cashregisterStateService.isShowSearch$.next({isSearch: false, searchValue: ''});
    }

    private search(searchValue: string | undefined) {
        searchValue ? this.cashregisterStateService.isShowSearch$.next({isSearch: true, searchValue: searchValue}) : this.cashregisterStateService.isShowSearch$.next({isSearch: false, searchValue: ''});
    }

    private async openCashDrawer() {
        this.log('OPEN CASH DRAWER');
        await this.activeSaleService.verifyPrinterDocumentDataAndSelect();

        try {
            await this.documentPrinter.openCashDrawer(this.activeSaleService.printerDocumentData);
        } catch(error) {
            this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: this.activeSaleService.printerDocumentData?.printer.id}}});
        }
    }

    private async addCashMovement() {
        this.log('ADD CASH MOVEMENT');
        await this.addCashMovementDialogService.openDialog();
    }

    private async openStockDialog() {
        this.log('OPEN STOCK');
        this.openDialogsService.openStockDialog<string, CustomFormGroup<CustomForm<any>>, any>({
            data: {
                title: 'Open Stock',
                form: this.cashregisterStateService.createCashregisterStockForm()
            }
        });
    }

    private openPmsLeanDialog() {
        if(this.pmsRoomService) {
            this.pmsRoomService.openDialog();
        }
    }

    private async loadDataForDailyClosing() {
        const dashboardData = await this.dashboardDataManagerService.fetchData();
        const printers = this.dashboardDataManagerService.getMergedResources(dashboardData.today_printers);

        const current_cash_movements = dashboardData.today_dashboard.cash_movements || {amount: 0};
        const current_cash_in_register = current_cash_movements.total.income.amount + current_cash_movements.total.outcome.amount;

        const weather_icon = getWeatherIcon(dashboardData);

        const payments = getPayments(dashboardData, this.translateService.instant('DASHBOARD.PREPAID_CARDS'));

        const today :Today = getToday(dashboardData, payments, current_cash_movements, current_cash_in_register, weather_icon);

        const data : { printers: any; today: Today; alignedPrinters?: any } = {printers: printers, today: today }

        const alignedPrinters = alignDataPrinters(data as ObjDataData, this.cashregisterStateService.deliveryChannels);

        data.alignedPrinters = alignedPrinters;

        return data;
    }

    private async dailyClosing() {

        const res = await this.injector.get(SelectTypeDailyClosingDialogService).openDialog({saleName: this.activeSaleService.currentSale.name});

        if (!res || (res.typeAction === '')) {
            return;
        }

        if(res.typeAction === 'LG') {
            let data : any;

            let voidPromise = new Promise(async (resolve, reject) => {
                try {
                    data = await this.loadDataForDailyClosing();
                    resolve(null);
                    await this.openDialogsService.openFiscalPrinterDashboardDialog({data: data ,disableClose:true});
                } catch (error) {
                    resolve(error);
                    this.alertDialogService.openDialog({data: {messageLabel:this.translateService.instant('DIALOG.SELECT_TYPE_DAILY_CLOSING.DAILY_READING_ERROR')}});
                } finally {
                }
            });

            await this.waitDialogService.openDialog({data: {message: 'DASHBOARD.WAIT_UPDATING_DATA', promise: voidPromise}});

        } else if (res.typeAction === 'CG') {
            await this.activeSaleService.verifyPrinterDocumentDataAndSelect();
            const printer = this.activeSaleService.printerDocumentData?.printer;

            if (printer) {
                const res = await this.openDialogsService.openDailyClosingDialog({data: printer});
                this.log(res);
                if(res==='cancel'){
                    return;
                }
                this.activeSaleService.resetDocumentData();
            }
        }
    }

    private changePricelist(priceList: number) {
        if(priceList === this.priceList()) {
            return;
        }

        this.priceList.set(priceList);
        this.saleUtilsService.updateCurrentPriceList(priceList);
        this.resetSearchBar();

        if (this.isPriceListUpdateEnabled) {
            this.cashregisterStateService.pricelistChangedEvent.next();
        }
    }
    private settings() {
        this.log('SETTINGS');
        const portalInjector = Injector.create({
            providers: [{
                provide: RIGHT_SIDENAV_PORTAL_DATA_TOKEN,
                useValue: {sale: null, gridRef: null}
            }],
        });
        this.rightSidenavService.componentPortal$.next(new ComponentPortal(CashregisterTopbarSettingsComponent, null, portalInjector));
        this.rightSidenavService.isOpen = true;
    }

    // END - TOOLBAR SETTINGS METHODS

    private subscriptions() {
        subscribeInComponent(ActiveSaleService.activeSaleEvents$, (event) => {
            switch (event.event) {
                case 'use-pricelist':
                    const data = event.data;
                    const newPriceList = data.priceList === 'default' ? this.saleUtilsService.getCurrentPriceList() : data.priceList;

                    if (newPriceList !== this.priceList()) {
                        this.priceList.set(newPriceList);

                        if (!data.skipNotify) {
                            this.cashregisterStateService.pricelistChangedEvent.next();
                        }
                    }
                break;
                case 'reset-pricelist':
                    this.changePricelist(this.saleUtilsService.getDefaultPriceList());
                break;
            }
        });

        subscribeInComponent(
            this.cashregisterStateService.addItemToSale$.pipe(filter(()=>this.mainKeyboard())),
            (data) => data.item && this.addItemToSale(data.item, data.combination_id, data.quantity, data.barcode)
        );

        subscribeInComponent(
            this.cashregisterStateService.addNewItemToSale$,
            () => this.addItemToSaleHandler('sale')
        );

        subscribeInComponent(BarcodeManagerService.barcodeEvents$, (event) => {
            switch(event.type) {
                case 'item':
                    if(!this.isAddingBarcodeItem) {
                        this.isAddingBarcodeItem = true;
                        this.onBarcodeItem(event).finally(() => this.isAddingBarcodeItem = false);
                    }
                break;
                case 'sale':
                    this.onBarcodeSale(event);
                break;
                case 'network-display':
                    this.onBarcodeNetworkDisplay(event);
                break;
            }
        });

        subscribeInComponent(this.dualService.currentRoute$, (currentRoute) => {
            this.paymentInProgress = currentRoute.includes("payments");
            this.showKeyboard = !this.paymentInProgress;
        });

        subscribeInComponent(
            ActiveSaleStoreService.saleUpdateError$, (error) => this.alertDialogService.openDialog({ data: { messageLabel: `CASHREGISTER.ACTIVE_SALE.${error}` } })
        );

        subscribeInComponent(
            ActiveSaleStoreService.saleUpdateNotification$, (message) => this.openDialogsService.openSnackBarTilby(`CASHREGISTER.ACTIVE_SALE.${message}`, 'MISC.OK', { duration: 5000 })
        )

        subscribeInComponent(
            ConnectionService.connectionStatus$, (data) => {
                const offline = !data.online;
                this.toolbarEventsService.isOffline$.next(offline);
            }
        )

        subscribeInComponent(
            CashregisterStateService.notifyStock$, (stockMessage : any) => {
                if(!this.configurationManagerService.getPreference('cashregister.disable_stock_notifications')) {
                    let toastMessage: string | undefined = undefined;

                    switch (stockMessage.available) {
                        case 'available': case 'producible':
                            break;
                        case 'alert':
                            toastMessage = this.translateService.instant('CASHREGISTER.SHOWCASE.ITEM_STOCK_LOW', {value: stockMessage.name});
                            break;
                        case 'unavailable':
                            toastMessage = this.translateService.instant('CASHREGISTER.SHOWCASE.ITEM_STOCK_ZERO', {value: stockMessage.name});
                            break;
                        default:
                            break;
                    }

                    if (toastMessage) {
                        if(stockMessage.item_id) {

                            this.entityManagerService.items.fetchOneOffline(stockMessage.item_id).then((item : any) => {
                                if(item && item.manual_production !== true) {
                                    this.openDialogsService.openSnackBarTilby(toastMessage!, 'MISC.OK', { duration: 0 })
                                }
                            });

                        } else {
                            this.openDialogsService.openSnackBarTilby(toastMessage, 'MISC.OK', { duration: 0 })
                        }
                    }
                }
            }
        )
    }
    public async onBarcodeNetworkDisplay(event: BarcodeEvent) {
        if (event.type !== 'network-display') {
            return;
        }

        try {
            await this.customerDisplayManager.configureNetworkDisplay(event.address, event.version);
            this.openDialogsService.openSnackBarTilby('CASHREGISTER.SHOWCASE.NETWORK_DISPLAY_QRCODE_SUCCESS', 'MISC.OK', { duration: 5000 });
        } catch (err) {
            this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.SHOWCASE.NETWORK_DISPLAY_QRCODE_ERROR' } });
        }
    }

    onBarcodeScannedAppleMobile() {
        if (this.environmentInfoService.isAppleMobile()) {
            const barcodeInput = this.renderer.selectRootElement('#barcodeInput');
            barcodeInput.focus();
            barcodeInput.value = '';
        }
    }

    protected onBarcodeScanned(barcodeInput: string) {
        this.barcodeManagerService.manageBarcodeInput(barcodeInput);
    }

    protected async onBarcodeItem(barcodeEvent: BarcodeEvent) {
        //Make sure it's an item event
        if(barcodeEvent.type !== 'item') {
            return;
        }

        // Avoid adding items when a payment is in progress
        if (this.paymentInProgress) {
            return;
        }

        const foundData = barcodeEvent.itemData
        const foundArray = foundData.data;

        if (!foundArray.length) {
            if(this.configurationManagerService.getPreference('cashregister.error_sound_on_barcode_not_found')) {
                new Audio('assets/sounds/error.mp3').play();
            }

            if (this.configurationManagerService.getPreference('cashregister.add_item_on_barcode_not_found') !== false) {
                await this.addNewItem('sale', barcodeEvent.barcodeInput);
            } else {
                await this.alertDialogService.openDialog({data: {messageLabel: 'CASHREGISTER.SHOWCASE.ARTICLE_NOT_FOUND'}});
            }

            return;
        }

        const priceListStr = `price${this.priceList()}` as `price${number}` & keyof Items;

        for (let found of foundArray) {
            try {
                if (found.quantity && !Number.isFinite(found.quantity)) {
                    await this.alertDialogService.openDialog({data: {messageLabel: 'CASHREGISTER.SHOWCASE.PRICE_UNIT_IS_ZERO'}});
                    continue;
                }

                const item = await this.entityManagerService.items.fetchOneOffline(found.item_id);

                if (!item) {
                    await this.alertDialogService.openDialog({data: {messageLabel: 'CASHREGISTER.SHOWCASE.ARTICLE_NOT_FOUND'}});
                    continue;
                }

                if (found.price != null) {
                    item[priceListStr] = found.price;
                }

                if (Number.isFinite(item[priceListStr])) {
                    this.cashregisterStateService.addItemToSale$.next({
                        item: item,
                        combination_id: found.combination_id,
                        quantity: found.quantity,
                        barcode: barcodeEvent.barcodeInput
                    });
                } else {
                    await this.alertDialogService.openDialog({data: {messageLabel: 'CASHREGISTER.SHOWCASE.ARTICLE_NOT_PRESENT_IN_PRICELIST'}});
                }
            } catch (err) {
                console.error(err);
            }
        }

        if (foundArray.length !== foundData.total) {
            await this.alertDialogService.openDialog({data: {messageLabel: 'CASHREGISTER.SHOWCASE.SOME_ITEMS_NOT_FOUND'}});
        }
    }

    protected async onBarcodeSale(barcodeEvent: BarcodeEvent) {
        //Make sure it's a sale event
        if(barcodeEvent.type !== 'sale') {
            return;
        }

        const sale = barcodeEvent.sale;

        if(sale) {
            if(sale.status === 'open') {
                await this.activeSaleService.loadSale(sale.id || sale.uuid!);
            } else {
                //TODO: open in history
            }
        }
    }


    // TILBY-KEYPAD

    protected toggleKeyboard(isOpen: boolean,bySelection?:boolean) {
        this.cashregisterStateService[`${bySelection?'keyboardIsOpenBySelection$':'keyboardIsOpen$'}`].next(isOpen);
        window.localStorage.setItem('cashregister::showKeyboard', `${isOpen}`);
    }

    private checkConnection() {
        if (this.connectionService.isOffline()) {
            this.toolbarEventsService.isOffline$.next(true);
        }
    }
}
