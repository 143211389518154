@if({
    height:shopCartHeight$|async,
    saleUpdates: saleUpdates$|async
}; as data){
    <div class="splitview-boundary" #orderView>
        <as-split direction="vertical" #splitComponent
                  [gutterSize]="30"
                  [restrictMove]="true"
                  [gutterDblClickDuration]="400"
                  [unit]="'percent'"
                  [direction]="'vertical'"
                  (dragEnd)="onResizeSplitViewer('DragEnd',$event)"
        >
            <as-split-area [size]="data.height" class="tw-overflow-y-hidden">
                <app-shop-cart-wrapper class="tw-block tw-h-full" [saleUpdates]="data.saleUpdates!"/>
            </as-split-area>
            <as-split-area [size]="100-(data.height||0)" [minSize]="actionBarHeight()" class="tw-py-0 tw-px-2">
                <div class="tw-h-full tw-overflow-y-hidden tw-flex tw-flex-col" [class.tw-flex-col-reverse]="isTablet">
                    <!-- <app-action-bar class="tw-h-fit tw-flex tw-justify-center tw-z-10"/>-->
                    <app-active-sale-action-buttons-wrapper class="tw-py-2 tw-h-[106px]"/>
                    <div [ngStyle]="{height: 'calc(100% - 106px)'}">
                        <app-cashregister-showcase class="tw-h-full"/>
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </div>
}
