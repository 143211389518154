import { Form } from 'src/app/models';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Tickets, TicketsValues } from 'tilby-models';

interface TicketsFieldsFe {
    isNew?:boolean;
}

export type TicketsFields = Tickets & TicketsFieldsFe;

export class TicketsDefault implements TicketsFields {
    id: number = -1;
    name: string = '';
    image_url?: string | undefined;
    circuit?: string;
    color?: string;
    ticket_values?: Array<TicketsValues> = [];
    isNew?: boolean | undefined;
}

export type TicketsFieldsNullable = { [prop in string & keyof TicketsFields]:TicketsFields[prop]|null }
export type TicketsForm = Partial<Form<TicketsFieldsNullable>>;

export type TicketsFormGroup = {
    generalForm: CustomFormGroup<any>
}

