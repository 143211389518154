import {Component, inject} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {GridService} from '../grid.service';
import { ResponseSettingFileImporting } from 'tilby-models';
import { $state, restManager, util } from 'app/ajs-upgraded-providers';
import { Subscription } from 'rxjs';
import {
    ConfigurationManagerService,
    ConfigurationPreferences
} from 'src/app/core';

import {
    AlertDialogService
} from 'src/app/dialogs';

@Component({
    selector: 'app-grid-clickable-button-file-importer',
    templateUrl: './grid-clickable-button-file-importer.component.html',
    styleUrls: ['./grid-clickable-button-file-importer.component.scss'],
})
export class GridClickableButtonFileImporterComponent implements ICellRendererAngularComp {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly gridService = inject(GridService);
    private readonly restManager = inject(restManager);
    private readonly state = inject($state);
    private readonly util = inject(util);
    intervalSubscription: Subscription = new Subscription();
    showDownloadDetailedErrors?: boolean;
    _params!: ICellRendererParams;
    hide = false;

    get params() {
        return this._params;
    }

    set params(v) {
        this._params = v;
        if (this.gridService.tableProperties.idsDeleting.indexOf(Number(this.params.data?.id)) > -1) this.hide = true;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        if(!this.params.data) return;
        if(this.configurationManagerService.isUserPermitted("show_developer_options") && this.params.data.totalErrors > 0){
            this.showDownloadDetailedErrors = true;
        } else {
            this.showDownloadDetailedErrors = false;
        }
    }

    refresh(): boolean {
        return false;
    }

    downloadFile = () => {
        this.restManager.getOne('importing/file', 'original', { id: this.params.data.id }).then((result: ResponseSettingFileImporting) => {
            this.util.openExternalLink(result.url);
        });
    };

    downloadErrors = () => {
        if(this.params.data.totalErrors > 0) {
            this.restManager.getOne('importing/file', 'error', { id: this.params.data.id, idStatus: this.params.data.statusId }).then((result: ResponseSettingFileImporting ) => {
                this.util.openExternalLink(result.url);
            });
        }
    };

    downloadDetailedErrors = () => {
        if(this.params.data.totalErrors > 0) {
            this.restManager.getOne('importing/file', 'error', { id: this.params.data.id, idStatus: this.params.data.statusId, detail: true }).then((result: any) => {
                this.util.openExternalLink(result.url);
            });
        }
    };

    cloneImport = () => {
        if(this.isMagoEnabled()) {
            this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.MAGO_NOT_ENABLED'}});
            return;
        } 

        const {name, primaryKey, columns: schemaImporting, type, typeInsert, modelOptions, optionsIntestation} = this.params.data;

        let fileConfig = {
            name,
            primaryKey,
            schemaImporting,
            type,
            typeInsert: (typeInsert?.toLowerCase()) + (typeInsert?.endsWith('s') ? '' : 's')
        };

        Object.assign(fileConfig, modelOptions, optionsIntestation);
        
        this.state.go('app.new.file_importer.add', {
            fileConfig: fileConfig
        });
    };

    isMagoEnabled = () => {
        const integrations_mago_publish_sales = this.configurationManagerService.getPreference("integrations.mago.publish_sales" as keyof ConfigurationPreferences);
        const integrations_mago_publish_customers = this.configurationManagerService.getPreference('integrations.mago.publish_customers' as keyof ConfigurationPreferences);

        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    preventSelection($event: MouseEvent) {
        $event.stopPropagation();
    }
}
