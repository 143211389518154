import {
    Component,
    Inject,
    OnInit
} from '@angular/core';

import {
    FormGroup,
    FormControl,
    Validators
} from '@angular/forms';

import {
    errorsLogger,
    restManager,
} from 'app/ajs-upgraded-providers';

import {
    MAT_DIALOG_DATA,
    MatDialogRef
} from '@angular/material/dialog';

import {
    TranslateService
} from '@ngx-translate/core';

import {
    Form
} from 'src/app/models';

type EditEmailFormFields = {
    email: string;
    verification_code: string;
}
type EditEmailFormFieldsNullable = {
    [prop in string & keyof EditEmailFormFields]:EditEmailFormFields[prop]|null
}
type EditEmailForm = Partial<Form<EditEmailFormFieldsNullable>>;

type EditEmailPayload = {
    email?: string | null;
    temp_code?: string | null;
}

@Component({
  selector: 'app-edit-email-dialog',
  templateUrl: './edit-email-dialog.component.html',
})
export class EditEmailDialogComponent implements OnInit {
    editEmailDialogForm: FormGroup<{email?:FormControl<string|null>, verification_code?:FormControl<string|null>}>;
    waitingCode: boolean;
    errorEmail: boolean;
    errorEmailMessage: string;
    verificationCodeError: boolean;
    verificationCodeErrorMessage: string;
    data: any;
    inProgress: boolean;
    regexEmail: RegExp;

    constructor(
        public dialogRef: MatDialogRef<EditEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: { email: string },
        @Inject(restManager) private restManagerService: any,
        @Inject(errorsLogger) private errorsLoggerService: any,
        private translate: TranslateService,

    ) {
        this.data = _data;
        this.editEmailDialogForm = new FormGroup<EditEmailForm>({
            email: new FormControl(this.data.email || null, [Validators.required, Validators.email]),
            verification_code: new FormControl('12345', Validators.required),
        });
        this.waitingCode = false;
        this.errorEmail = false;
        this.errorEmailMessage = '';
        this.verificationCodeError = false;
        this.verificationCodeErrorMessage = '';
        this.inProgress = false;
        this.regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    }
    ngOnInit(): void {

    }
    get email(){
        return this.editEmailDialogForm.controls.email;
    }
    get verification_code(){
        return this.editEmailDialogForm.controls.verification_code;
    }

    confirm(): void {
        let payload: EditEmailPayload = {};
        this.inProgress = true;
        this.errorEmail = false;
        if (!this.waitingCode) {
            payload.email = this.email?.value || 'null';
            this.restManagerService.post('sessions/current_user/email', payload).then((res: any) => {
                if(res && ('temp_email' in res)) {
                    this.waitingCode = true;
                    this.verification_code?.setValue(null);
                }
            }).catch((res: any) => {
                this.errorsLoggerService.err('POST sessions/current_user/email', res);
                if (res && res.status === 422) {
                    console.log('res', res);
                    this.errorEmailMessage = res.data.message;
                    this.errorEmail = true;
                    if(res.data.type === 'input') {
                        this.errorEmailMessage = this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.INVALID_MAIL_INPUT');
                    }
                    console.log('errorEmail', this.errorEmail);
                    console.log('errorEmailMessage', this.errorEmailMessage);
                }
            }).finally(() => {
                this.inProgress = false;
            });
        } else {
            this.verificationCodeError = false;
            payload = { temp_code: this.verification_code?.value };
            this.restManagerService.post('sessions/current_user/email/confirm', payload).then((res: any) => {
                this.dialogRef.close(res);
            }).catch((res: any) => {
                this.errorsLoggerService.err('POST sessions/current_user/email/confirm', res);
                if(res && res.status === 422) {
                    this.verificationCodeErrorMessage = res.data.message;
                    this.verificationCodeError = true;
                    if(res.data.type === 'input') {
                        this.verificationCodeErrorMessage = this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.INVALID_VERIFICATION_CODE');
                    }
                }
            }).finally(() => {
                this.inProgress = false;
            });
        }
    }
}
