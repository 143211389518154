import {
    Injectable,
    inject
} from "@angular/core";

import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";

import {
    DigitalPaymentsManager,
    documentPrinter,
    fiscalUtils
} from "app/ajs-upgraded-providers";

import {
    ConfigurationManagerService, EntityManagerService
} from "src/app/core";

import {
    AlertDialogService,
    ConfirmDialogService,
    ReceiptOptionsDialogStateService
} from "src/app/dialogs";

import {
    ActiveSaleService
} from "src/app/features/cashregister";

import {
    MathUtils
} from "src/app/shared/utils";

import {
    Sales,
    SalesCustomer, SalesPayments
} from "tilby-models";

@Injectable()
export class ActiveSalePaymentService {
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly digitalPaymentsManager = inject(DigitalPaymentsManager);
    private readonly documentPrinterService = inject(documentPrinter);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly fiscalUtils = inject(fiscalUtils);
    private readonly receiptOptionsDialogService = inject(ReceiptOptionsDialogStateService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);

    private async checkPayments() {
        const sale: Sales = this.activeSaleService.currentSale;

        //Positive amount (sales)
        if ((sale.final_amount || 0) >= 0) {
            this.activeSaleService.cleanPaymentsFromSale();
            return;
        }

        //Negative amount (refunds/voids/credit notes/etc)
        if ((sale.payments?.length || 0) > 1 && sale.sale_parent_id) { //Multiple payments
            const parentSale = await this.entityManagerService.sales.fetchOneOfflineFirst(sale.sale_parent_id);

            if (MathUtils.round(parentSale?.final_amount!) !== -MathUtils.round(sale.final_amount!)) {
                await this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE.REFUND_WITH_MULTIPLE_PAYMENTS_NOT_ALLOWED' } });
                throw 'REFUND_WITH_MULTIPLE_PAYMENTS_NOT_ALLOWED';
            }

            if (parentSale?.change) {
                let targetPayment;

                switch (parentSale.change_type) {
                    case 'cash':
                        targetPayment = sale.payments?.find((payment) => this.fiscalUtils.isCashPayment(payment.payment_method_type_id) && (Math.abs(payment.amount) > (parentSale.change || 0)));
                        break;
                    case 'ticket':
                        targetPayment = sale.payments?.find((payment) => (payment.payment_method_type_id === 6) && (Math.abs(payment.amount) > (parentSale.change || 0)));
                        break;
                }

                if (targetPayment) {
                    targetPayment.amount = MathUtils.round(targetPayment.amount + parentSale.change);
                }
            }
        } else { //Single/no payments
            let currentPayment = sale.payments?.[0] || {} as SalesPayments;

            if (currentPayment) {
                currentPayment.amount = MathUtils.round(sale.final_amount!);
            }
        }

        let digitalPayment = sale.payments?.find((payment) => this.digitalPaymentsManager.isPaymentDigital(payment.payment_method_type_id) && this.digitalPaymentsManager.paymentAllowsRefund(payment.payment_method_type_id));

        if (digitalPayment) {
            let answer = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'CASHREGISTER.ACTIVE_SALE.REFUND_DIGITAL_PAYMENT_PROMPT',
                    messageParams:
                    {
                        amount: this.tilbyCurrencyPipe.transform(Math.abs(digitalPayment.amount)),
                        paymentMethod: digitalPayment.payment_method_name
                    },
                    confirmLabel: digitalPayment.payment_method_name,
                    cancelLabel: 'CASHREGISTER.ACTIVE_SALE_MODEL.CASH'
                }
            });

            if (!answer) {
                return this.activeSaleService.cashPayment();
            }
        }
    };
    public async beforePayment(options?: { skipCheckPayments?: boolean }) {
        const { currentSale, printerDocumentData } = this.activeSaleService;

        await this.activeSaleService.verifyPrinterDocumentDataAndSelect();
        await this.activeSaleService.checkSaleConfirm();

        if (!options?.skipCheckPayments) {
            await this.checkPayments();
        }

        await this.documentPrinterService.checkSale(currentSale, printerDocumentData);
    };

    private async showReceiptOptions() {
        if (!this.activeSaleService.printerDocumentData || !this.activeSaleService.currentSale) {
            return;
        }

        if (!this.configurationManagerService.getPreference('cashregister.enable_receipt_options')) {
            return;
        }

        const res = await this.receiptOptionsDialogService.openDialog({
            data: {
                printerDocumentData: this.activeSaleService.printerDocumentData,
                saleUuid: this.activeSaleService.currentSale.uuid!,
                saleCustomer: this.activeSaleService.currentSale.sale_customer
            }
        });

        if (!res) {
            throw 'CANCELED';
        }

        const customerData: Partial<SalesCustomer> = {};

        this.activeSaleService.printerDocumentData.options.courtesyReceipt = res.courtesyReceipt || false;
        this.activeSaleService.printerDocumentData.options.eReceipt = ['email', 'sms', 'save'].includes(res.mode);

        if (res.mode === 'email') {
            customerData.email = res.email;
        }

        if (Object.keys(customerData).length) {
            this.activeSaleService.updateSaleDetails({
                sale_customer: this.activeSaleService.currentSale.sale_customer ? {
                    ...this.activeSaleService.currentSale.sale_customer,
                    ...customerData
                } : {
                    ...customerData,
                    first_name: ' ',
                    last_name: ' '
                }
            });
        }
    }

    public async emitDocument() {
        try {
            // If the cash limit is enabled, check if the sum of cash payments is greater than 5000 (only for Italy)
            if (this.configurationManagerService.getShopCountry() === 'IT' && !this.configurationManagerService.getPreference('cashregister.disable_cash_limit_exceeded_confirm')) {
                const cashSum = ((this.activeSaleService.currentSale?.payments || []) as SalesPayments[])
                    .reduce((acc, payment) => this.fiscalUtils.isCashPayment(payment.payment_method_type_id) ? acc + payment.amount : acc, 0);

                if (cashSum >= 5000) {
                    const result = await this.confirmDialogService.openDialog({
                        data: {
                            messageLabel: 'CASHREGISTER.ACTIVE_SALE.CASH_LIMIT_EXCEEDED_CONFIRM'
                        }
                    });

                    if (!result) {
                        throw { printError: 'CANCELED' };
                    }
                }
            }

            //Show receipt options if enabled
            try {
                await this.showReceiptOptions();
            } catch (e) {
                throw { printError: 'CANCELED' };
            }

            const result = await this.activeSaleService.emitDocument();

            if ('printError' in result) {
                throw result;
            } else if ('notes' in result && result.notes?.length) {
                //TODO: Support multiple warning messages
                if (result.notes[0] === "CHECK_PRINTER_CLOCK") {
                    this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE.TIME_NOT_ALIGNED' } });
                }
            }
        } catch (error: any) {
            if (error?.printError) {
                throw error.printError;
            }
        }
    }
}