import * as angular from 'angular';

import {
    LeanPMSApiService,
    LeanPMSGroupReservation,
    LeanPMSHotel,
    LeanPMSReservation,
    LeanPMSReservationExtra
} from 'app/modules/application/service/lean-pms-api/lean-pms-api';

import * as moment from 'moment-timezone';

export type LeanPMSPaymentsDialogOptions = {
    amount?: number,
    enableConfirm?: boolean,
    reservationId?: number,
}

export class LeanPMSPaymentsController {
    private reservations: LeanPMSReservation[] = [];
    private groupedReservations: LeanPMSGroupReservation[] = [];

    //UI params
    amountToPay: number;
    enableConfirm: boolean;
    fetchingReservations = false;
    hideRoomPrefix: boolean;
    hotelId: number;
    hotels: LeanPMSHotel[] = [];
    hostname = '';
    isOffline = false;
    message = '';
    mode: 'services-view' | 'payment';
    operationInProgress = false;
    password = '';
    reservationId: number | null = null;
    searchText = '';
    selectedReservation: LeanPMSReservation | LeanPMSGroupReservation | null = null;
    status: 'login' | 'reservations-select' | 'groupedreservations-select' | null = null;
    username = '';
    visibleReservations: (LeanPMSReservation[] | LeanPMSGroupReservation[]) = [];
    selectedReservationNotes: Array<any> = [];
    notes_category: string;
    notesInProgress: boolean = false;

    constructor(
        private readonly $scope: any,
        private readonly $mdDialog: any,
        private readonly $filter: any,
        private readonly translateService: any,
        private readonly leanPMSApi: LeanPMSApiService,
        checkManager: any,
        util: any,
        options: any
    ) {
        this.reservationId = options?.reservationId;
        this.amountToPay = util.round(options?.amount);
        this.mode = (options?.amount == null) ? 'services-view' : 'payment';
        this.enableConfirm = this.mode === 'payment' || options?.enableConfirm;
        this.hotelId = parseInt(checkManager.getSetting('lean_pms.hotel_id') || 0);
        this.hideRoomPrefix = checkManager.getPreference('lean_pms.hide_room_prefix');
        this.notes_category = checkManager.getPreference('lean_pms.notes_category') || '-1';
    }

    $onInit() {
        this.$scope.$on("connection:changed", (event: any, data: any) => {
            this.onConnectionChanged(data);
        });

        if (this.hotelId) {
            this.fetchReservations();
        } else {
            this.fetchHotels();
        }
    }

    private onConnectionChanged(data: any) {
        this.isOffline = data.status === 'offline';
    }

    parseLeanDate(date: string) {
        return moment(date, 'YYYY-MM-DD').format('L');
    }

    async performSearch() {
        switch (this.status) {
            case 'reservations-select':
                this.visibleReservations = this.reservations;

                const searchWords = this.searchText.split(' ');

                for (const word of searchWords) {
                    const results = [] as LeanPMSReservation[];

                    for (let value of ['room', 'contact_name', 'contact_surname']) {
                        const filterResult = this.$filter('filter')(this.visibleReservations, { [value]: word });
                        results.push(...filterResult);
                    }

                    //Deduplicate results
                    this.visibleReservations = results.filter((result, index, self) => (self.findIndex((r) => r === result) === index));
                }

                if (!this.visibleReservations.find((reservation) => reservation === this.selectedReservation)) {
                    this.selectedReservation = null;
                }
                break;
            case 'groupedreservations-select':
                const groupId = parseInt(this.searchText);

                if (!groupId) {
                    return;
                }

                try {
                    const result = await this.leanPMSApi.getGroupReservations(this.hotelId, groupId);

                    this.visibleReservations = this.groupedReservations = result.results;
                } catch (error: any) {
                    this.handleFetchError(error);
                }
                break;
        }
    }

    async login() {
        this.operationInProgress = true;
        this.message = this.translateService.instant('CASHREGISTER.LEAN_PMS_PAYMENTS.ENABLING');

        try {
            await this.leanPMSApi.setupLogin(this.hostname, this.username, this.password);

            this.message = this.translateService.instant('CASHREGISTER.LEAN_PMS_PAYMENTS.ENABLE_SUCCESSFUL');
            setTimeout(this.deleteMessage, 1500);

            this.fetchHotels();
        } catch (error: any) {
            switch (error?.status) {
                case -1:
                    this.message = '';
                    break;
                case 404: case 403: case 401:
                    this.message = this.translateService.instant('CASHREGISTER.LEAN_PMS_PAYMENTS.INVALID_CREDENTIALS');
                    break;
                default:
                    this.message = this.translateService.instant('CASHREGISTER.LEAN_PMS_PAYMENTS.ENABLE_FAILED');
                    break;
            }
        } finally {
            this.operationInProgress = false;
        }
    }

    async pairHotel() {
        this.operationInProgress = true;

        try {
            await this.leanPMSApi.setupHotel(this.hotelId);

            this.message = this.translateService.instant('CASHREGISTER.LEAN_PMS_PAYMENTS.ENABLE_SUCCESSFUL');
            setTimeout(this.deleteMessage, 1500);

            this.fetchReservations();
        } catch (error: any) {
            switch (error?.status) {
                case -1:
                    this.message = '';
                    break;
                case 404: case 403: case 401:
                    this.status = 'login';
                    break;
                default:
                    this.message = this.translateService.instant('CASHREGISTER.LEAN_PMS_PAYMENTS.ENABLE_FAILED');
                    break;
            }
        } finally {
            this.operationInProgress = false;
        }
    }

    deleteMessage() {
        this.message = '';
    }

    cancel() {
        this.$mdDialog.cancel('CANCELED');
    }

    async selectReservation(reservation: LeanPMSReservation | LeanPMSGroupReservation) {
        if ('reference' in reservation && this.hotelId) {
            // recupero le note
            try {
                this.notesInProgress = true;
                const reference = reservation.reference;
                const res = reference ? await this.leanPMSApi.getNotes(this.hotelId, reference) : null;

                if (res && res.results?.length > 0) {
                    // controllo se è settata una categoria
                    if(this.notes_category === '-1') {
                        // nessuna categoria
                        this.selectedReservationNotes = res.results[0]?.notes || [];
                    } else {
                        // con categoria
                        const filterCategory = res.results[0]?.notes?.filter((note: any) => note?.categories[0]?.name === this.notes_category);
                        this.selectedReservationNotes = filterCategory || [];
                    }
                } else {
                    this.selectedReservationNotes = [];
                }
            } catch (error: any) {
                this.$mdDialog.cancel('ERR_NOTES');
            } finally {
                this.notesInProgress = false;
            }
        } else {
            this.selectedReservationNotes = [];
        }
        this.selectedReservation = reservation;
    }

    selectGroupedReservation(groupedReservation: LeanPMSGroupReservation) {
        this.selectedReservation = groupedReservation;
    }

    changeReservationType(type: 'reservations' | 'groupedreservations') {
        this.selectedReservation = null;
        this.status = `${type}-select`;

        switch (type) {
            case 'reservations':
                this.visibleReservations = this.reservations;
                break;
            case 'groupedreservations':
                this.visibleReservations = this.groupedReservations;
                break;
            default:
                this.visibleReservations = [];
                break;
        }
    }

    confirm() {
        if (!this.selectedReservation) {
            return;
        }

        switch (this.status) {
            case 'reservations-select':
                this.$mdDialog.hide({
                    referenceData: {
                        reservation: (this.selectedReservation as LeanPMSReservation).reference,
                        hotel: this.hotelId 
                    },
                    reservation: this.selectedReservation
                });
                break;
            case 'groupedreservations-select':
                this.$mdDialog.hide({
                    referenceData: {
                        reservation_group: (this.selectedReservation as LeanPMSGroupReservation).id,
                        hotel: this.hotelId 
                    },
                    reservation: this.selectedReservation
                });
                break;
        }
    }

    isServiceAvailable(service: LeanPMSReservationExtra) {
        return (service.day === moment().format('YYYY-MM-DD'));
    }

    private handleFetchError(error: any) {
        switch (error?.status) {
            case -1:
                setTimeout(this.fetchReservations, 1000);
                break;
            case 401:
            case 403:
                this.status = 'login';
                break;
            default:
                if (error?.data?.error?.message?.error_code === 'CONNECTION_ERROR') {
                    this.$mdDialog.cancel('LEAN_PMS_OFFLINE');
                } else {
                    this.$mdDialog.cancel('UNKNOWN_ERROR');
                }
                break;
        }
    }

    private async fetchReservations() {
        if (this.$scope.$$destroyed) {
            return;
        }

        Object.assign(this, {
            fetchingReservations: true,
            status: null
        });

        try {
            let data = await this.leanPMSApi.getReservations(this.hotelId);
            this.reservations = data.results;

            Object.assign(this, {
                status: 'reservations-select',
                visibleReservations: this.reservations
            });

            if(this.reservationId) {
                this.selectedReservation = this.reservations.find((reservation) => reservation.id === this.reservationId) || null;

                if(this.selectedReservation) {
                    this.searchText = this.selectedReservation.room;
                    this.performSearch();
                }
            }
        } catch (error: any) {
            this.handleFetchError(error);
        } finally {
            this.fetchingReservations = false;
        }
    }

    private async fetchHotels() {
        try {
            let results = await this.leanPMSApi.getHotels();

            Object.assign(this, {
                hotels: results,
                status: 'hotel-select'
            });
        } catch (error) {
            this.handleFetchError(error);
        }
    };
}

export type LeanPMSReferenceData = { reservation: number, hotel: number } | { reservation_group: number, hotel: number };

export type LeanPMSPaymentsReturn = {
    referenceData: LeanPMSReferenceData,
    reservation: LeanPMSReservation | LeanPMSGroupReservation
};

export class LeanPMSPaymentsDialog {
    constructor(
        private readonly $mdDialog: any,
    ) {
    }

    public show(options?: any): Promise<LeanPMSPaymentsReturn> {
        return this.$mdDialog.show({
            controller: LeanPMSPaymentsController,
            controllerAs: '$ctrl',
            template: require('./lean-pms-payments.html'),
            locals: {
                options: options
            }
        });
    }
}

LeanPMSPaymentsDialog.$inject = ['$mdDialog'];
LeanPMSPaymentsController.$inject = ['$scope', '$mdDialog', '$filter', '$translate', 'leanPMSApi', 'checkManager', 'util', 'options'];

angular.module('cashregister').service('leanPMSPayments', LeanPMSPaymentsDialog);
