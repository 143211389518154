import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	inject,
	Input,
	OnChanges,
	Renderer2,
	RendererStyleFlags2,
	SimpleChanges,
	ViewChild
} from '@angular/core';

import { ReactiveFormsModule } from "@angular/forms";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";

@Component({
	selector: 'app-tilby-keypad-display',
	standalone: true,
	imports: [
		ReactiveFormsModule,
	],
	templateUrl: './tilby-keypad-display.component.html',
	styleUrl: './tilby-keypad-display.component.scss',
	providers: [
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
		TilbyCurrencyPipe
	]
})
export class TilbyKeypadDisplayComponent implements AfterViewInit, OnChanges {
	private readonly rendererService = inject(Renderer2);
	private initialFontSize?: number;

	@Input() inputClass = "tw-text-4xl";
	@Input({ required: true }) value!: String;

	@ViewChild("keypadDisplay") keypadDisplay?: ElementRef

	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.adjustDisplaySize();
	}

	ngAfterViewInit(): void {
		this.adjustDisplaySize();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.adjustDisplaySize();
	}

	adjustDisplaySize() {
		if (!this.keypadDisplay) {
			return;
		}

		const nativeElement = this.keypadDisplay.nativeElement as HTMLElement;
		const textField = nativeElement.querySelector('span');

		if (!textField) {
			return;
		}

		//Keep track of the initial font size
		if(this.initialFontSize == null) {
			const currentFontSize = window.getComputedStyle(textField).getPropertyValue('font-size');
			this.initialFontSize = currentFontSize.endsWith('px') ? parseInt(currentFontSize) || 36 : 36;
		}

		const textLength = this.value.length || 0;
		const fontSize = `${Math.min(10 + (nativeElement.clientWidth / 5) - (textLength * 1.5), this.initialFontSize)}px`

		this.rendererService.setStyle(textField, 'font-size', fontSize, RendererStyleFlags2.Important);
	}
}
