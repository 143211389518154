import {
    Component,
    Injectable,
    Injector,
    OnInit,
    inject,
    runInInjectionContext,
    signal
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";

import {
    filter,
    lastValueFrom,
} from 'rxjs';

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    ConfigurationManagerService,
    EntityManagerService,
    StorageManagerService
} from 'src/app/core';

import {
    ActiveSaleService,
    SaleUtilsService
} from 'src/app/features';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { Rooms, Sales } from 'tilby-models';

import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { capitalize } from 'src/utilities';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from "@angular/material/divider";
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';

type dialogOptions = {
    hideCurrentSale?: boolean //Hides current sale
    selectModeOnly?: boolean //Disables sale deselecting and creation
    message?: string, //Optional message to show below the title
    title?: string //Overrides dialog title
}

@Component({
    selector: 'app-select-sale-dialog',
    standalone: true,
    imports: [CommonModule, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent, MatDialogModule, ScrollingModule, MatIconModule, TilbyDatePipe, MatMenuModule, MatButtonModule,
        MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, TranslateModule, MatDivider],
    templateUrl: './select-sale-dialog.component.html',
    styleUrls: ['./select-sale-dialog.component.scss']
})
export class SelectSaleDialogComponent implements OnInit {
    protected readonly data: dialogOptions = inject(MAT_DIALOG_DATA);
    private readonly injector = inject(Injector);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly saleUtilsService = inject(SaleUtilsService);
    private tilbyDatePipe = inject(TilbyDatePipe);

    protected screenHeight = screen.height;
    protected isAccessibility = this.configurationManagerService.getPreference('general.simplified_accessibility') || false;
    protected isDeleteSalePermitted = this.configurationManagerService.isUserPermitted("cashregister.delete_sale");

    protected readonly dialogTitle = this.data.title || 'DIALOG.SELECT_SALE.TITLE';
    protected readonly dialogMessage = this.data.message;
    protected readonly selectModeOnly = !!this.data.selectModeOnly;
    protected readonly hideCurrentSale = !!this.data.hideCurrentSale;

    sales: Sales[] = [];
    rooms: Rooms[] = [];

    selectedSale = this.activeSaleService.currentSale.uuid || undefined;

    type: string | undefined = this.configurationManagerService.isModuleEnabled('tables') ? "TABLE" : "ALL";
    room: number | "ALL" | undefined = "ALL";
    time: string | undefined = "TODAY";

    protected customActions = this.selectModeOnly ? [] : [{
        // Deselect sale
        name: this.isAccessibility ? "DIALOG.SELECT_SALE.ACTION_BUTTONS.DESELECT" : "",
        click: () => this.matDialogRef?.close(),
        isIt: () => !!this.selectedSale,
        icon: signal("deselect")
    }, {
        // Create new sale
        name: this.isAccessibility ? "DIALOG.SELECT_SALE.ACTION_BUTTONS.NEW" : "",
        click: async () => {
            this.matDialogRef?.close();
            await this.activeSaleService.createNewSale({ saleType: "normal" });
        },
        isIt: signal(true),
        icon: signal("note_add")
    }];

    async ngOnInit() {
        this.getConfiguration();
        await this.initRooms();
        await this.fetchSales();
        this.subscriptions();
    }

    getConfiguration() {
        const filterType = this.configurationManagerService.getPreference('cashregister.saved_sales.default_type');

        if (filterType) {
            this.type = filterType;
        }
        
        const filterRoom = this.configurationManagerService.getPreference('cashregister.saved_sales.default_room');
        
        if(filterType !== 'TABLE') {
            this.room = 'ALL';
        } else {
            if(filterRoom === 'ALL') {
                this.room = 'ALL';
            } else {
                this.room = parseInt(filterRoom!) || 'ALL';
            }
        }

        const filterTime = this.configurationManagerService.getPreference('cashregister.saved_sales.default_time');

        if (filterTime) {
            this.time = filterTime;
        }
    }

    private subscriptions() {
        runInInjectionContext(this.injector, () =>  {
            subscribeInComponent(StorageManagerService.storageUpdates$.pipe(filter((data) => data.entityName === 'sales')), (data) => this.fetchSales());
        });
    }

    public async deleteSale(sale: Sales) {
        if (!sale.id) {
            return;
        }

        if (sale.id === this.activeSaleService.currentSale.id) {
            this.activeSaleService.deleteSale();
        } else {
            this.entityManagerService.sales.deleteOneOfflineFirst(sale.id);
        }
    }

    selectSale(sale: Sales) {
        this.matDialogRef?.close(sale?.uuid);
    };

    getSaleIcon(sale: Sales) {
        switch (sale.order_type) {
            case 'normal':
                return sale.table_id ? 'table_bar' : 'shopping_bag';
            case 'delivery':
                return 'directions_bike';
            case 'take_away':
                return 'local_mall';
        }

        return 'shopping_bag';
    }

    getSaleName(sale: Sales) {
        if ((!sale.order_type || sale.order_type === 'normal') && !sale.table_id) {
            return '';
        } else {
            return !sale.sale_customer ? sale.name : sale.name + ' - ' + this.getSaleCustomer(sale);
        }
    }

    getSaleCustomer(sale: Sales) {
        return sale?.sale_customer ?
            (
                (sale.sale_customer.first_name ? sale.sale_customer.first_name + ' ' : '') +
                (sale.sale_customer.last_name ? sale.sale_customer.last_name + ' ' : '') +
                (((sale.sale_customer.first_name || sale.sale_customer.last_name) && sale.sale_customer.company_name) ? ' - ' : '') +
                (sale.sale_customer.company_name ? sale.sale_customer.company_name : '')
            ) : '';
    }

    getInfoSale(sale: Sales) {
        switch (sale.order_type) {
            case 'normal':
                return sale.table_id ? (sale.room_name + ' - ' + sale.table_name) : !sale.sale_customer ? sale.name : sale.name + ' - ' + this.getSaleCustomer(sale);
            case 'delivery':
                return capitalize(sale.channel || '') + ' ' + (sale.deliver_at ? this.formattedDate(sale.deliver_at) : '') + ' - ' + sale.external_id;
            case 'take_away':
                return sale.deliver_at ? this.formattedDate(sale.deliver_at) : '' + ' ' + sale.external_id;
        }
        return !sale.sale_customer ? sale.name : sale.name + ' - ' + this.getSaleCustomer(sale);
    }

    async fetchSales() {
        const query: Partial<Sales> = {
            status: 'open'
        };

        switch (this.type) {
            case 'TABLE':
                query.order_type = 'normal';
                break;
            case 'DELIVERY':
                query.order_type = 'delivery';
                break;
            case 'TAKE_AWAY':
                query.order_type = 'take_away';
                break;
        }

        if (this.type === 'TABLE' && this.room !== 'ALL') {
            query.room_id = this.room;
        } else {
            this.room = 'ALL';
        }

        let sales = await this.entityManagerService.sales.fetchCollectionOffline(query);

        if(this.hideCurrentSale && this.selectedSale) {
            sales = sales.filter((sale) => sale.uuid !== this.selectedSale);
        }

        if (this.type === 'TABLE') {
            sales = sales.filter((sale) => sale.order_type === 'normal' && sale.table_id)
        }

        if (this.time !== 'ALWAYS') {
            sales = this.filterSales(sales);
        }

        sales.sort(this.compareByCreatedAt);

        for (const sale of sales) {
            this.saleUtilsService.calculateSalePrices(sale);
        }

        this.sales = sales || [];
    };

    onChange(event: MatSelectChange, type: string) {
        if (type === 'type') {
            this.configurationManagerService.setUserPreference('cashregister.saved_sales.default_type', event.value);
        } else if (type === 'room') {
            this.configurationManagerService.setUserPreference('cashregister.saved_sales.default_room', event.value);
        } else if (type === 'time') {
            this.configurationManagerService.setUserPreference('cashregister.saved_sales.default_time', event.value);
        }
    }

    formattedDate(date: Date) {
        let dateNow = TilbyDatePipe.shopDate() || '';
        const currentDateParts = dateNow?.split('T')[0].split('-').map(Number);
        let dateSale = TilbyDatePipe.shopDate({ date: date }) || '';
        const saleDateParts = dateSale?.split('T')[0].split('-').map(Number);
        if (saleDateParts![2] === currentDateParts![2]) {
            return this.tilbyDatePipe.transform(dateSale, 'HH:mm');
        } else {
            return this.tilbyDatePipe.transform(dateSale, 'HH:mm d/MM');
        }
    }

    compareByCreatedAt(saleA: Sales, saleB: Sales): number {
        if (saleA.created_at! > saleB.created_at!) return -1;
        if (saleA.created_at! < saleB.created_at!) return 1;
        return 0;
    }

    async initRooms() {
        this.rooms = await this.entityManagerService.rooms.fetchCollectionOffline();
    }

    filterSales(sales: Sales[]) {
        let dateToFilter: any;

        const currentDate = this.tilbyDatePipe.transform(new Date(), 'YYYY-MM-dd');
        const currentDateMonth = this.tilbyDatePipe.transform(new Date(), 'YYYY-MM');

        switch (this.time) {
            case 'TODAY':
                dateToFilter = TilbyDatePipe.shopDate({ date: `${currentDate}T${'00:00:00'}` });
                break;
            case 'THIS_WEEK':
                dateToFilter = this.getStartOfWeek(TilbyDatePipe.date({ outputFormat: 'date' }));
                break;
            case 'THIS_MONTH':
                dateToFilter = TilbyDatePipe.shopDate({ date: `${currentDateMonth}-01T${'00:00:00'}` });
                break;
        }

        if (dateToFilter !== null) {
            sales = sales.filter((sale) => {
                let filterUTCMillis = TilbyDatePipe.toUtcDateMillis(dateToFilter);
                let saleShopDate = TilbyDatePipe.shopDate({ date: sale.created_at });
                let saleUTCMillis = TilbyDatePipe.toUtcDateMillis(saleShopDate as string);

                return (saleUTCMillis > filterUTCMillis);
            })
        }

        return sales;
    }

    getStartOfWeek(date: Date): string {
        const dayOfWeek = date.getDay();
        const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        const date2 = new Date(date.setDate(diff));
        const currentDate = this.tilbyDatePipe.transform(date2, 'YYYY-MM-dd');
        return TilbyDatePipe.shopDate({ date: `${currentDate}T${'00:00:00'}` }) as string
    }
}

@Injectable({
    providedIn: 'root'
})
export class SelectSaleDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(options?: NonNullableConfigData<dialogOptions>) {
        const config: NonNullableConfigData<dialogOptions> = {
            ...this.switchMobileDesktopDimensions({ height: '70vh', width: '800px' }),
            disableClose: true,
            data: {},
            ...options
        };

        return lastValueFrom(this.dialogRef.open(SelectSaleDialogComponent, config).afterClosed()).then((res: string) => {
            return {
                saleId: res
            }
        });
    }
}
