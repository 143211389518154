
<tilby-dialog-toolbar (confirm)="confirm()" 
    title="BOOKINGS.NEW_BOOKING_DIALOG.BOOKING_DETAILS" 
    confirmLabel="BOOKINGS.NEW_BOOKING_DIALOG.SAVE" 
    [hideConfirm]="true"
    [customActions]="customActions"
    [disabled]="!options.booking && (bookingForm.invalid || tablesSelected.length === 0)"/>
@if(sendingBooking){
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<tilby-dialog-content>
    <div class="new-booking-dialog-content tw-flex tw-h-full tw-gap-4">
        <section class="tw-w-1/2">
            <form [formGroup]="bookingForm">
                <mat-form-field class="status-select tw-w-full">
                    <mat-select name="status" [(ngModel)]="booking.status" subscriptSizing="dynamic" (selectionChange)="changeBackgroundColor($event)">
                    @for(status of bookingStatuses; track status) {
                        <mat-option value="{{status.id}}">{{status.name}}</mat-option>
                    }
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="tw-w-full">
                    <mat-label translate="BOOKINGS.NEW_BOOKING_DIALOG.CUSTOMER"></mat-label>
                    <input matInput name="customer" [(ngModel)]="booking._customerName" formControlName="customer" value="{{booking._customerName}}" (click)="addEditCustomer()" readonly required="{{!booking.pms_reservation_id}}" aria-label="customer" />
                </mat-form-field>
                @if(booking && booking.pms_reservation_id){
                    <mat-form-field class="tw-w-full">
                        <mat-label translate="BOOKINGS.NEW_BOOKING_DIALOG.PMS_RESERVATION_ID"></mat-label>
                        <input matInput type="text" name="pms_reservation_id" [(ngModel)]="customerByPmsReservationId" readonly aria-label="pms_reservation_id" />
                    </mat-form-field>
                }
                <tilby-datetime-picker [(ngModel)]="booking._bookedFor" formControlName="bookedFor" label="BOOKINGS.NEW_BOOKING_DIALOG.BOOKED_FOR" />
                <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-6">
                    <div class="tw-flex tw-justify-center">
                        <div class="tw-flex tw-gap-3" [ngClass]="{'tw-w-[80%] tw-justify-center': isMobile}">
                            @if(!isMobile){
                                <mat-icon class="material-symbols-outlined">timer</mat-icon>
                            }
                            <button mat-mini-fab class="new-booking-dialog-quantity-button" isRoundButton="true" (click)="decreaseDuration($event)">
                                <mat-icon style="font-size:10px">remove</mat-icon>
                            </button>
                            <span class="tw-text-center tw-w-28">{{'BOOKINGS.NEW_BOOKING_DIALOG.DURATION_MINUTES' | translate : { duration: booking.duration } }}</span>
                            <button mat-mini-fab class="new-booking-dialog-quantity-button" (click)="increaseDuration($event)">
                                <mat-icon style="font-size:10px">add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-center">
                        <div class="tw-flex tw-gap-3" [ngClass]="{'tw-w-[80%] tw-justify-center': isMobile}">
                            @if(!isMobile){
                                <mat-icon>people</mat-icon>
                            }
                            <button mat-mini-fab class="new-booking-dialog-quantity-button" (click)="decreasePeople($event)">
                                <mat-icon style="font-size:10px">remove</mat-icon>
                            </button>
                            <span class="tw-text-center tw-w-28">{{booking.people}}</span>
                            <button mat-mini-fab class="new-booking-dialog-quantity-button" (click)="increasePeople($event)">
                                <mat-icon style="font-size:10px">add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <mat-form-field class="tw-w-full">
                    <mat-label translate="BOOKINGS.NEW_BOOKING_DIALOG.NOTES"></mat-label>
                    <textarea matInput [(ngModel)]="booking.notes" formControlName="notes" name="notes" aria-label="notes" (input)="$event.stopPropagation();"></textarea>
                </mat-form-field>
            </form>
        </section>
        <section class="tw-w-1/2">
            @if(availableTables.length) {
                <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport tw-h-full">
                    <div *cdkVirtualFor="let table of availableTables" class="example-item" [ngStyle]="{ 'height: 50px;': !isMobile}">
                        <div class="tw-flex" (click)="selectTable(table)" class="new-booking-dialog-table" [ngClass]="{ 'selected': table.selected, 'unavailable': !table.available }">
                            <mat-icon alt="Menu Icon" class="tw-text-black">table_bar</mat-icon>
                            <div class="tw-ml-4">
                                <div layout="row">
                                    <span class="new-booking-dialog-table-name">{{table.room_name}} - {{table.table_name}}</span>
                                </div>
                                <div layout="row" class="new-booking-dialog-table-info">
                                    <span class="new-booking-dialog-table-covers"> {{table.covers}}
                                        @switch (table.covers) {
                                            @case (0) {
                                                {{'BOOKINGS.NEW_BOOKING_DIALOG.NO_COVERS' | translate}}
                                            }
                                            @case (1) {
                                                {{'BOOKINGS.NEW_BOOKING_DIALOG.COVERS_FOR_ONE' | translate}}
                                            }
                                            @default {
                                                {{'BOOKINGS.NEW_BOOKING_DIALOG.COVERS_FOR_MORE' | translate}}
                                            }
                                        }
                                    </span>
                                </div>
                            </div>                                    
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </cdk-virtual-scroll-viewport>
            } @else {
                <div layout="column" layout-align="center center" class="new-booking-dialog-no-tables" flex>
                    <span translate="BOOKINGS.NEW_BOOKING_DIALOG.NO_RESOURCES"></span>
                </div>
            }
        </section>
    </div>
</tilby-dialog-content>