import {
    Injectable,
    WritableSignal,
    computed,
    signal
} from '@angular/core';

import {
    mobileCheck
} from '@tilby/tilby-ui-lib/utilities';

import {
    map,
    fromEvent,
    Observable
} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ScreenOrientationService {
    private isAppleDevice = (window.device?.manufacturer === 'Apple');
    private screenOrientation: WritableSignal<OrientationType> = signal(this.isAppleDevice ? this.getAppleOrientation() : this.getCommonOrientation());
    private _screenOrientation$: Observable<OrientationType> = this.isAppleDevice ?
        fromEvent(window, 'orientationchange').pipe(map(this.getAppleOrientation)) :
        fromEvent(screen.orientation, 'change').pipe(map(this.getCommonOrientation));

    constructor() {
        this._screenOrientation$.subscribe((orientation) => {
            this.screenOrientation.set(orientation);
        });
    }

    public getOrientation = computed(() => this.screenOrientation());

    public isMobilePortrait = computed(() => {
        const orientation = this.getOrientation();

        return mobileCheck() && (orientation === 'portrait-primary' || orientation === 'portrait-secondary');
    });

    private getAppleOrientation(): OrientationType {
        switch (window.orientation) {
            case 90:
                return 'landscape-primary';
            case -90:
                return 'landscape-secondary';
            case 0:
                return 'portrait-primary';
            case 180:
                return 'portrait-secondary';
            default:
                return 'landscape-primary';
        }
    }

    private getCommonOrientation(): OrientationType {
        return screen?.orientation?.type || 'landscape-primary'
    }

    //DEPRECATO O QUASI
    public getOrientationObservable() {
        return this._screenOrientation$;
    }
}
