import {
    Component,
    inject,
    OnInit
} from '@angular/core';

import {
    GridClientSide,
    GridComponent
} from '../grid';

import {GridReadyEvent} from 'ag-grid-community';
import {RowModelType} from '../../model/model';
import {GridService} from '../grid/grid.service';

@Component({
    selector: 'app-grid-client-side',
    templateUrl: '../grid/grid.component.html',
    styleUrls: ['../grid/grid.component.scss'],
    providers: []
})
export class GridClientSideComponent extends GridComponent implements OnInit, GridClientSide {
    protected readonly gridService = inject(GridService);

    rowModelType: RowModelType = "clientSide";

    ngOnInit(): void {
        this.defaultColDef.filter = true;
        this.initTable();
        super.ngOnInit();
    }

    onGridReady(params: GridReadyEvent) {
        super.onGridReady(params);
        this.setColumnsPreferences();
        this.gridReady(itemsRowAdded => this.gridApi?.applyTransaction({add: [itemsRowAdded]})?.add[0]);
    }

    onRemoveSelected(id?: number) {
        const selectedRows = this.gridApi?.getSelectedRows();
        const key = this.idField || 'id';
        const ids = id ? [{ [key as string]: id }] : selectedRows?.map(({ id }) => ({ [key as string]: id }));
        this.gridApi?.applyTransaction({remove: ids});
        this.deleteChange.emit(ids?.map(({ [key as string]: id })=>id));
    }
}
