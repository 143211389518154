import {
    Component,
    Injectable,
    inject
} from "@angular/core";

import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from "@angular/forms";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef
} from "@angular/material/dialog";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import { lastValueFrom } from "rxjs";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { MatToolbarModule } from "@angular/material/toolbar";

import { Form } from "src/app/models";

export type DashboardSettingsForm = Partial<Form<DashboardSettingsFields>>;

export class DashboardSettingsFields {
    amount: number | null = 0;
    yearly_progressive: number | null = 1;
}

export interface DataDashboardSettingsDialog { settings: DashboardSettingsFields }

export interface DataDashboardSettingsValidators {
    notes: number;
}

@Component({
    selector: 'app-dashboard-settings-dialog-dialog',
    templateUrl: './dashboard-settings-dialog.component.html',
    styleUrls: ['./dashboard-settings-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatDialogModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        TranslateModule
    ]
})
export class DashboardSettingsDialogComponent {
    private readonly dialogRef = inject(MatDialogRef<DashboardSettingsDialogComponent>);
    private readonly data: DataDashboardSettingsDialog = inject(MAT_DIALOG_DATA);

    dashboardSettingsForm?: FormGroup<DashboardSettingsForm>;

    ngOnInit() {
        this.createForm();
    }

    createForm(){
        this.dashboardSettingsForm = new FormGroup<DashboardSettingsForm>({
            amount: new FormControl(this.data.settings.amount ,[Validators.required]),
            yearly_progressive: new FormControl(this.data.settings.yearly_progressive),
        });
    }

    submit(DashboardSettingsForm?: FormGroup<DashboardSettingsForm>) {
        const {
            amount,
            yearly_progressive
        } = DashboardSettingsForm?.value || {};

        const result: DashboardSettingsFields = {
            amount: amount || 0,
            yearly_progressive: yearly_progressive || 1
        }

        this.dialogRef.close(result);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardSettingsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<DataDashboardSettingsDialog>): Promise<DashboardSettingsFields | undefined> {
        const configEdited = {
            ...this.switchMobileDesktopDimensions(),
            ...config
        };

        return lastValueFrom(this.dialogRef.open(DashboardSettingsDialogComponent, configEdited).afterClosed()).then((res) => typeof res === 'object' ? res : undefined);
    }
}