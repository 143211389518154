import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { TimeoutError, firstValueFrom, map, timeout } from 'rxjs';
import { SalePrintingUtilsService } from 'src/app/features';
import { ItalanFiscalPrinterDriver, PrintFreeOptions } from 'src/app/shared/model/it-fiscal-printer.model';

import { TilbyTcpSocket, TilbyTcpSocketUtils } from 'src/app/shared/net/tilby-tcp-socket';
import { Departments, Orders, Printers, Sales, SalesItems, Vat } from 'tilby-models';

type AxonPrinterPayment = {
    paymentId: string,
    paymentName: string,
    paymentType: string,
    paymentFlags: string,
    paymentSubType: string
};


type AxonDeviceStatus = {
    busy: boolean,
    fatalError: boolean,
    paperEnd: boolean,
    batteryWarning: boolean,
    printerOffline: boolean,
    fiscalFileFull: boolean,
    printerTimeout: boolean,
    cutterError: boolean,
}

type AxonFiscalStatus = {
    drawerOpen: boolean,
    dayOpen: boolean,
    transactionOpen: boolean,
    inactivePeriod: boolean,
    transactionInPayment: boolean,
    cashInOpen: boolean,
    cashOutOpen: boolean,
    EJReportOpen: boolean,
}

type AxonCommOptions = {
    timeout?: number
}

type AxonPrinterCapabilities = {
    canCut: boolean
    discountColumns: number,
    discountExtraColumns: number,
    maxColumns: number
    maxDepartments: number,
    maxHeaderRows: number,
    maxPaymentMethods: number,
    maxPLU: number,
    maxVats: number,
    saleColumns: number,
    saleExtraColumns: number,
    textColumns: number,
}

type AxonPrinterSocket = {
    capabilities: AxonPrinterCapabilities,
    deviceStatus: AxonDeviceStatus,
    fiscalStatus: AxonFiscalStatus,
    printer: Printers,
    printerFirmware: string,
    printerSerial: string,
    tcpSocket: TilbyTcpSocket,
};

type AxonPrinterCommand = string[];
type AxonPrinterResponse = string[];

const errorsTable: Record<number, string> = {
    16: 'FISCAL_CLOSING_NEEDED',
    26: 'BUSY',
    51: 'COVER_OPEN',
    81: 'DATA_READ_FINISHED',
    82: 'DATA_READ_FINISHED',
    116: 'RT_ALREADY_VOID',
    117: 'RT_ALREADY_VOID',
    118: 'RT_MODE_DISABLED',
    120: 'FISCAL_CLOSING_NEEDED',
    167: 'FISCAL_CLOSING_NEEDED',
    123: 'RT_ALREADY_VOID'
};

const axonPaymentsTable: AxonPrinterPayment[] = [
    { paymentId: '1', paymentName: 'Contanti', paymentType: '0', paymentFlags: '10001101', paymentSubType: '0' },
    { paymentId: '2', paymentName: 'Assegni', paymentType: '1', paymentFlags: '10000000', paymentSubType: '0' },
    { paymentId: '3', paymentName: 'Pagam. elettronico', paymentType: '1', paymentFlags: '10000000', paymentSubType: '0' },
    { paymentId: '4', paymentName: 'Ticket', paymentType: '2', paymentFlags: '10000000', paymentSubType: '1' },
    { paymentId: '5', paymentName: 'Non riscosso Beni', paymentType: '2', paymentFlags: '10000000', paymentSubType: '4' },
    { paymentId: '6', paymentName: 'Non riscosso Servizi', paymentType: '2', paymentFlags: '10000000', paymentSubType: '0' },
    { paymentId: '7', paymentName: 'Fattura differita', paymentType: '2', paymentFlags: '10000000', paymentSubType: '2' },
    { paymentId: '8', paymentName: 'Non riscosso SSN', paymentType: '2', paymentFlags: '10000000', paymentSubType: '3' },
    { paymentId: '9', paymentName: 'Buono multiuso', paymentType: '3', paymentFlags: '10000000', paymentSubType: '0' },
    { paymentId: '10', paymentName: 'Sconto a pagare', paymentType: '3', paymentFlags: '10000000', paymentSubType: '0' }
];

const barcodeTypeCodes: Record<string, string> = {
    'EAN13': '67',
    'EAN8': '68',
    'CODE39': '69',
    'EAN128': '73',
    'QRCODE': '99',
    'UPC': '65'
};

//Converts UInt8Array to string
const byteArrayToString = (byteArray: Uint8Array) => byteArray ? String.fromCharCode(...byteArray) : '';

const cleanUpSpecialChars = (str: string) => str.replace(/€/g, "E").replace(/[^\x00-\x7F]/g, "*").replace(/\//g, "-").replace(/TOTALE/ig, 'T0TALE');

const dateToString = (date?: string | Date) => date ? moment(date).format('DD/MM/YYYY') : '';

/**
 *  Tilby Axon Driver
 *
 *  Usage:
 *  AxonRTDriver.setup() and after call public methods:
 *
 *  - autoConfigurePrinter
 *  - printFiscalReceipt
 *  - printCourtesyReceipt
 *  - printNonFiscal
 *  - printOrder
 *  - openCashDrawer
 *  - dailyClosing
 *  - dailyRead
 *  - readDgfeBetween (read/print)
 *  - printFiscalMemoryBetween
 *
 *  - printFreeNonFiscal
 *
 */

export class AxonRTDriver implements ItalanFiscalPrinterDriver {
    private printer: any;
    private options: any;

    private paymentsTableChecked: Record<string, boolean> = {};

    constructor(
        private $http: angular.IHttpService,
        private $translate: any,
        private checkManager: any,
        private fiscalUtils: any,
        private salePrintingUtils: SalePrintingUtilsService,
        private util: any,
        private waitDialog: any
    ) {
    }

    private logEvent(...message: any[]) {
        console.debug('[ AxonRTDriver ]', ...message);
    };

    private checkQueueType(type: string): boolean {
        if (!['auto', 'manual'].includes(this.checkManager.getPreference('cashregister.queue.mode'))) {
            return false;
        }

        let queueType = [];

        try {
            queueType = JSON.parse(this.checkManager.getPreference('cashregister.queue.print_type'));
        } catch (e) { }

        return queueType.includes(type);
    }

    private getDeviceStatus(deviceStatus: string, fiscalStatus: string): { deviceStatus: AxonDeviceStatus, fiscalStatus: AxonFiscalStatus } {
        const deviceStatusInt = parseInt(deviceStatus || '0', 16);
        const fiscalStatusInt = parseInt(fiscalStatus || '0', 16);

        return {
            deviceStatus: {
                busy: !!(deviceStatusInt & 1),
                fatalError: !!(deviceStatusInt & 2),
                paperEnd: !!(deviceStatusInt & 4),
                batteryWarning: !!(deviceStatusInt & 8),
                printerOffline: !!(deviceStatusInt & 16),
                fiscalFileFull: !!(deviceStatusInt & 32),
                printerTimeout: !!(deviceStatusInt & 64),
                cutterError: !!(deviceStatusInt & 128),
            },
            fiscalStatus: {
                drawerOpen: !!(fiscalStatusInt & 1),
                dayOpen: !!(fiscalStatusInt & 2),
                transactionOpen: !!(fiscalStatusInt & 4),
                inactivePeriod: !!(fiscalStatusInt & 8),
                transactionInPayment: !!(fiscalStatusInt & 16),
                cashInOpen: !!(fiscalStatusInt & 32),
                cashOutOpen: !!(fiscalStatusInt & 64),
                EJReportOpen: !!(fiscalStatusInt & 128),
            }
        };
    };

    private linesToCommands(lines: string | string[], columns: number, options?: PrintFreeOptions): AxonPrinterCommand[] {
        if (!Array.isArray(lines)) {
            lines = lines.split("\n");
        }

        const commands = [];

        for (let line of lines) {
            commands.push(['7', '1', '1', _.chain(line).thru(cleanUpSpecialChars).truncate({ length: columns, omission: '' }).value()]);
        }

        if (options?.barcode) {
            const barcodeType = barcodeTypeCodes[options.barcode.type] || '69';
            let barcodeValue = options.barcode.value;

            //Remove last character from barcodeValue if EAN13 or EAN8
            switch (options.barcode.type) {
                case 'EAN13':
                    barcodeValue = _.truncate(barcodeValue, { length: 12, omission: '' });
                    break;
                default:
                    break;
            }

            commands.push(['[', '50', '2', '2', barcodeType, barcodeValue.length.toString(), barcodeValue]);
        }

        commands.push(['m']);

        return commands;
    }

    private async closeSocket(socket?: AxonPrinterSocket): Promise<void> {
        if (socket) {
            await socket.tcpSocket.disconnect();
        }
    }

    private async sendCommands(printerSocket: AxonPrinterSocket, commands: AxonPrinterCommand[], options?: AxonCommOptions): Promise<AxonPrinterResponse[]> {
        if (!printerSocket) {
            throw 'INVALID_SOCKET';
        }

        if (typeof options !== 'object') {
            options = {};
        }

        const responseData = printerSocket.tcpSocket.onData.pipe(
            map((packet: Uint8Array) => {
                let dataString = byteArrayToString(packet).split('/');
                let replyCode = parseInt(dataString[0], 16);

                Object.assign(printerSocket, this.getDeviceStatus(dataString[1], dataString[2]));

                //If reply code is not 0, throw the printer error
                if (replyCode) {
                    throw (errorsTable[replyCode] || `AXON.ERROR_${replyCode}`);
                }

                return dataString;
            }),
            timeout({ each: options.timeout || 10000 })
        );

        let responses: AxonPrinterResponse[] = [];

        try {
            for (let command of commands) {
                const printerCommand = command.join('/').concat('/');

                //Prepare listening to response and send the command
                let commandResponse = await new Promise<AxonPrinterResponse>((resolve, reject) => {
                    firstValueFrom(responseData).then((response: AxonPrinterResponse) => {
                        //Remove reply code, status and checksum from the response
                        resolve(response.slice(3, response.length - 1))
                    }, reject);

                    //Send command
                    const packet = printerCommand.split('').map((val) => val.charCodeAt(0));

                    printerSocket.tcpSocket.send(new Uint8Array(packet)).catch(reject);
                });

                responses.push(commandResponse);
            }
        } catch (error) {
            if (error instanceof TimeoutError) {
                throw 'REQ_TIMEOUT';
            }

            throw error;
        }

        return responses;
    }

    private async isAvailable(printer: any): Promise<AxonPrinterSocket> {
        let tcpSocket = TilbyTcpSocketUtils.getSocket();

        if (!tcpSocket) {
            throw 'UNSUPPORTED_ENVIRONMENT';
        }

        try {
            await tcpSocket.connect(printer.ip_address, printer.port || 9101, { timeout: 10000 });
        } catch (error) {
            throw 'CONNECTION_ERROR';
        }

        let printerSocket = {
            capabilities: {},
            printer: printer,
            tcpSocket: tcpSocket
        } as AxonPrinterSocket;

        try {
            const printerInfoResponses = await this.sendCommands(printerSocket, [['a'], ['v']]);
            const printerInfo = printerInfoResponses.shift();
            const printerFwInfo = printerInfoResponses.shift();

            Object.assign(printerSocket, {
                printerSerial: [printerInfo![2], printerInfo![0]].join(' '),
                printerFirmware: _.trim(printerFwInfo![0])
            });

            switch (printerInfo![2]) {
                case 'TN': case 'GE':
                    Object.assign(printerSocket.capabilities, {
                        saleColumns: 30,
                        saleExtraColumns: 30,
                        textColumns: 48,
                        discountColumns: 20,
                        discountExtraColumns: 30,
                        canCut: true
                    });
                    break;
                case 'PD':
                    Object.assign(printerSocket.capabilities, {
                        saleColumns: 20,
                        saleExtraColumns: 30,
                        textColumns: 32,
                        discountColumns: 15,
                        discountExtraColumns: 30,
                        canCut: false
                    });
                    break;
                case 'CA':
                    Object.assign(printerSocket.capabilities, {
                        saleColumns: 20,
                        saleExtraColumns: 30,
                        textColumns: 32,
                        discountColumns: 15,
                        discountExtraColumns: 30,
                        canCut: false
                    });
                    break;
                case 'VR':
                default:
                    Object.assign(printerSocket.capabilities, {
                        saleColumns: 20,
                        saleExtraColumns: 30,
                        textColumns: 32,
                        discountColumns: 15,
                        discountExtraColumns: 30,
                        canCut: false
                    });
                    break;
            }

            Object.assign(printerSocket.capabilities, {
                maxDepartments: parseInt(printerFwInfo![4]),
                maxHeaderRows: parseInt(printerFwInfo![8]),
                maxPaymentMethods: parseInt(printerFwInfo![5]),
                maxPLU: parseInt(printerFwInfo![3]),
                maxVats: parseInt(printerFwInfo![9]),
            });
        } catch (err: any) {
            this.closeSocket(printerSocket);
            throw err;
        }

        return printerSocket;
    }

    private async connectAndInitializePrinter(printer: any): Promise<AxonPrinterSocket> {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.isAvailable(printer);
            await this.clearPendingDocuments(printerSocket);
        } catch (error) {
            this.closeSocket(printerSocket);
            throw error;
        }

        return printerSocket;
    }

    private async clearPendingDocuments(printerSocket: AxonPrinterSocket): Promise<void> {
        const unlockCommands: AxonPrinterCommand[] = [];

        //End any open fiscal transaction if present
        if (printerSocket.fiscalStatus.transactionOpen) {
            unlockCommands.push(['+', '0']);
        }

        //End any open non-fiscal transaction
        unlockCommands.push(['m'])

        await this.sendCommands(printerSocket, unlockCommands);
    }

    private extractPayments(sale: Sales, resources: any) {
        if (typeof resources !== 'object') {
            resources = {};
        }

        const payments = this.fiscalUtils.extractPayments(sale) as any[];
        let servicesToPay = 0;
        let goodsToPay = 0;

        if (Array.isArray(resources.departments)) {
            const departmentsByCode = _.keyBy(resources.departments, 'printer_code');
            const itemsBySalesType = _.groupBy(sale.sale_items, (saleItem) => departmentsByCode[saleItem.department_id]?.sales_type);

            goodsToPay = _.chain(itemsBySalesType.goods).sumBy((saleItem) => (saleItem.final_price * saleItem.quantity)).toFinite().thru(this.util.round).value() as number;
            servicesToPay = _.chain(itemsBySalesType.services).sumBy((saleItem) => (saleItem.final_price * saleItem.quantity)).toFinite().thru(this.util.round).value() as number;
        }

        const getUnclaimedAmount = (payment: any) => {
            let servicesAmount = _.min([servicesToPay, payment.amount]);
            let goodsAmount = _.max([0, this.util.round(payment.amount - servicesAmount)]);

            servicesToPay = this.util.round(servicesToPay - servicesAmount);
            goodsToPay = this.util.round(goodsToPay - goodsAmount);

            return [
                _.chain(payment).clone().assign({ amount: this.util.round(servicesAmount), payment_type: 6 }).value(),
                _.chain(payment).clone().assign({ amount: this.util.round(goodsAmount), payment_type: 5 }).value()
            ].filter((payment) => payment.amount);
        };

        return _.chain(payments).map((payment) => {
            //Assign payment type
            switch (payment.method_type_id) {
                case 1: case 19: case 21: case 32: case 38: case 39: case 40:
                    if (payment?.payment_data?.rounding) {
                        payment.amount = payment?.payment_data?.original_amount ?? payment.amount;
                    }

                    payment.payment_type = 1; //Cash
                    break;
                case 3:
                    payment.payment_type = 2; //Cheque
                    break;
                case 4: case 5: case 8: case 11: case 13: case 14: case 15: case 17: case 18: case 27: case 30: case 31: case 35: case 37:
                    payment.payment_type = 3; //Credit / Credit card
                    break;
                case 6: case 34:
                    payment.payment_type = 4; //Ticket
                    break;
                case 2: //Unclaimed
                case 20:
                    return getUnclaimedAmount(payment);
                case 10: case 33:
                    payment.payment_type = 9;
                    break;
                case 22: case 23: case 24: case 28: case 29: case 36: case 41:
                    payment.payment_type = 7;
                    break;
                case 25:
                    payment.payment_type = 8;
                    break;
                case 26:
                    if (payment?.payment_data?.rounding) {
                        return null;
                    } else {
                        payment.payment_type = 10;
                    }
                    break;
                case 16: case 42:
                    //(Ticket if unclaimed, digital if otherwise)
                    if (payment.unclaimed) {
                        return getUnclaimedAmount(payment);
                    } else {
                        payment.payment_type = 3;
                    }
                    break;
                default:
                    payment.payment_type = 1;
                    break;
            }

            return payment;
        }).flatten().compact().value();
    }

    private saleToReceiptCommands(sale: Sales, resources: any, options: any, capabilities: AxonPrinterCapabilities) {
        const commands: AxonPrinterCommand[] = []; //TODO: clear printer before sending sale commands
        let isRefunding = false;

        const addTextLine = (text: string) => commands.push(['7', '1', '1', _.truncate(text, { length: capabilities.textColumns, omission: '' })]);

        if (!Array.isArray(sale.sale_items)) {
            sale.sale_items = [];
        }

        //Check if the sale contains a department out of the allowed range
        const maxDepartments = capabilities.maxDepartments;

        if(sale.sale_items.some((saleItem) => (saleItem.department_id > maxDepartments))) {
            throw 'NOT_ENOUGH_DEPARTMENTS';
        }

        if (sale.final_amount! < 0) { //Refund/Void
            const docDataItem = sale.sale_items.find((saleItem) => (saleItem.reference_sequential_number && saleItem.reference_date))

            const docData: any = {};

            if (!docDataItem) {
                throw 'MISSING_REFERENCE_DOCUMENT';
            }

            Object.assign(docData, {
                reference_sequential_number: docDataItem.reference_sequential_number,
                reference_date: docDataItem.reference_date
            });

            //Throw if the items are from different documents
            if (!sale.sale_items.every((saleItem) => ((saleItem.reference_sequential_number === docDataItem.reference_sequential_number) && (saleItem.reference_date === docDataItem.reference_date)))) {
                throw 'ITEMS_FROM_DIFFERENT_DOCUMENTS';
            }

            //Check if we are doing a void or a refund
            let hasVoid = false;
            let hasRefund = false;

            for (let saleItem of sale.sale_items) {
                if (saleItem.refund_cause_id === 6) {
                    hasVoid = true;
                } else {
                    hasRefund = true;
                }
            }

            if (hasRefund && hasVoid) {
                throw 'MIXED_REFUND_CAUSES';
            }

            const docSeqNum = this.fiscalUtils.parseRTDocumentSequentialNumber(docData.reference_sequential_number);
            const documentDate = moment(docData.reference_date).format('DDMMYYYY');

            if (hasVoid) {
                //Void
                commands.push(['+', '1', documentDate, docSeqNum.daily_closing_num, docSeqNum.document_sequential_number]);

                return commands;
            } else {
                //Refund
                commands.push(['-', documentDate, docSeqNum.daily_closing_num, docSeqNum.document_sequential_number, '']);
                isRefunding = true;
            }
        } else {
            if (sale.sale_items.some((saleItem) => (saleItem.quantity < 0))) {
                throw 'REFUNDS_NOT_ALLOWED_IN_SALES';
            }

            commands.push(['>', '', '', '']);

            // Sale Name
            const printName = (this.options.print_name === false) ? false : true;

            if(printName) {
                for(let row of this.fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                    addTextLine(`# ${row}`);
                }
            }

            //Add lottery code if available
            if (sale.lottery_code) {
                commands.push(['I', sale.lottery_code, '0']);
            } else {
                //Add customer tax code if available
                const taxCode = sale?.sale_customer?.tax_code || sale.customer_tax_code;

                if (taxCode && this.fiscalUtils.checkTaxCode(taxCode)) {
                    commands.push(['I', taxCode, '0']);
                }
            }
        }

        const printDetails = (options.print_details === false) ? false : true;

        // Sale items
        if (printDetails) {
            // Check printNotes
            const printNotes = (this.options.print_notes === false) ? false : true;

            for (let saleItem of this.fiscalUtils.extractSaleItems(sale) as SalesItems[]) {
                const siName = _.truncate(cleanUpSpecialChars(saleItem.name || saleItem.department_name || ''), { length: capabilities.saleColumns });
                const departmentCode = _.toString(saleItem.department_id);

                commands.push(['3', isRefunding ? 'N' : 'S', siName, '', Math.abs((saleItem.quantity)).toFixed(3), (saleItem.price).toFixed(2), departmentCode, '', '', '']);

                //Skip the details section if we are refunding
                if (isRefunding) {
                    continue;
                }

                if (printNotes) {
                    // Item barcode (if print_notes)
                    if (saleItem.barcode) {
                        if (saleItem.barcode.toLowerCase().indexOf('p') < 0 && saleItem.barcode.toLowerCase().indexOf('q') < 0) {
                            addTextLine(`# ${saleItem.barcode.trim()}`);
                        }
                    }

                    // Notes
                    if (saleItem.notes) {
                        for (let noteLine of saleItem.notes.split("\n")) {
                            noteLine = noteLine.trim();

                            if (noteLine) {
                                addTextLine(`# ${cleanUpSpecialChars(noteLine)}`);
                            }
                        }
                    }
                }

                // Refund cause
                if (saleItem.quantity < 0 && saleItem.refund_cause_description) {
                    addTextLine('# ' + cleanUpSpecialChars(saleItem.refund_cause_description));
                }

                // Discount / Surcharges
                // - Sort discount/surcharges by index
                if (saleItem.quantity > 0) {
                    // Discount/Surcharges
                    let partialPrice = this.fiscalUtils.roundDecimals(saleItem.price * saleItem.quantity);

                    for (let priceChange of saleItem.price_changes!) {
                        const pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice);

                        if (pcAmount) {
                            partialPrice = this.fiscalUtils.roundDecimals(partialPrice + pcAmount);

                            const adjustmentType = pcAmount < 0 ? '0' : '1';
                            const pcDesc = _.truncate(priceChange.description, { length: capabilities.discountColumns });

                            commands.push(['4', Math.abs(pcAmount).toFixed(2), pcDesc, '', adjustmentType, '0', '1']);
                        }
                    }

                    if (saleItem.type === 'gift') {
                        addTextLine("# Omaggio");
                    }
                }
            }
        } else { // Hide details
            const departmentTotals = this.fiscalUtils.extractDepartments(sale);

            for (let index in departmentTotals) {
                const depTotal = departmentTotals[index];

                let departmentCode = _.toString(depTotal.id);
                let depName = _.truncate(cleanUpSpecialChars(depTotal.name), { length: capabilities.saleColumns, omission: '' });

                commands.push(['3', 'S', depName, '', '1', (depTotal.amount).toFixed(2), departmentCode, '', '', '']);
            }
        }

        if (isRefunding) {
            commands.push(['5', '1', '0', '', '', '']);
            return commands;
        }

        // Apply discount/surcharges on subtotal
        if (printDetails) {
            let partialPrice = this.fiscalUtils.roundDecimals(sale.amount);

            const salePriceChanges = _.sortBy(sale.price_changes, ['index']);

            for (let priceChange of salePriceChanges) {
                let pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice);

                if (pcAmount) {
                    partialPrice = this.fiscalUtils.roundDecimals(partialPrice + pcAmount);

                    const adjustmentType = pcAmount < 0 ? '0' : '1';
                    const pcDesc = _.truncate(priceChange.description, { length: capabilities.discountColumns });

                    commands.push(['4', Math.abs(pcAmount).toFixed(2), pcDesc, '', adjustmentType, '1', '1']);
                }
            }
        }

        commands.push(['}']);

        for (let payment of this.extractPayments(sale, resources)) {
            const paymentName = _.truncate(cleanUpSpecialChars(payment.method_name), { length: 20, omission: '' });

            commands.push([
                '5', //1: Command code
                payment.payment_type, //2: printer payment type ID
                (payment.amount).toFixed(2), //3: payment amount
                paymentName //4: payment name
            ]);
        }

        // Ticket change
        const ticketChange = (this.options.ticket_change === false) ? false : true;

        if (ticketChange && sale.change && sale.change_type === 'ticket') {
            const ticketChangeAmount = this.util.round(sale.change);

            addTextLine(`############## RESTO TICKET ###############`);
            addTextLine(`                                          `);
            addTextLine(` QUESTO COUPON VALE:           ${ticketChangeAmount} EURO`);
            addTextLine(`                                          `);
            addTextLine(` Presenta alla cassa prima del pagamento  `);
            addTextLine(` questo coupon per avere riconosciuto il  `);
            addTextLine(` credito.                                 `);
            addTextLine(` Valido 30 giorni dalla data di emissione.`);
            addTextLine(` Non convertibile in denaro.              `);
            addTextLine(`                                          `);
            addTextLine(`###########################################`);

            const ticketBarcode = _.toString(1212000000000 + Math.round(ticketChangeAmount * 100));
            commands.push(['[', '50', '2', '2', '69', _.toString(ticketBarcode.length), ticketBarcode]);
        }

        // Add Tail (tail for this sale + general tail)
        let tail = options.tail || '';

        if (this.options.tail) {
            tail += "\n" + this.options.tail;
        }

        if (tail) {
            const tailRows = tail.split("\n");

            for (let row of tailRows) {
                addTextLine(cleanUpSpecialChars(row));
            }
        }

        const printCustomerDetail = (this.options.print_customer_detail === false) ? false : true;

        // Customer
        if (printCustomerDetail && sale.sale_customer) {
            for (let row of this.fiscalUtils.getCustomerInfo(sale.sale_customer)) {
                addTextLine(row);
            }
        }

        // Print Seller
        const printSeller = (this.options.print_seller === false) ? false : true;

        if (printSeller && sale.seller_name) {
            const sellerPrefix = this.options.seller_prefix || "Ti ha servito";

            addTextLine(`${sellerPrefix} ${sale.seller_name.trim().split(" ")[0]}`);
        }

        // Add Payment Tail
        if (options.tail && options.tail.indexOf('FIRMA') > -1) {
            const pTail = options.tail.split("\n");

            for (let row of pTail) {
                addTextLine(row);
            }
        }

        // Queue Coupon
        if (this.checkQueueType('tail')) {
            for (let row of this.fiscalUtils.getQueueCouponRows(sale, capabilities.textColumns, { doubleWidth: true })) {
                commands.push(['7', '1', row.doubleHeight ? '4' : '2', cleanUpSpecialChars(row.text)]);
            }
        }

        // Barcode
        const printBarcodeId = (this.options.print_barcode_id === false) ? false : true;

        if (printBarcodeId && Number.isInteger(sale.id)) {
            const saleIdBarcode = cleanUpSpecialChars(sale.id!.toString());

            commands.push(['[', '50', '2', '2', '69', saleIdBarcode.length.toString(), saleIdBarcode]);
        }

        if (capabilities.canCut) {
            commands.push(['7', '1', '6', '']);
        }

        return commands;
    }

    private saleToNonFiscalCommands(sale: Sales, options: any, capabilities: AxonPrinterCapabilities) {
        const savePaper = !!this.checkManager.getPreference('cashregister.save_paper_on_prebill');
		const printerColumns = this.printer.columns || capabilities.textColumns;

        // Prepare tail text
        let tail = '';

        if (options.tail && typeof options.tail === 'string') {
            tail += options.tail;
        }

        if (this.options.tail && typeof this.options.tail === 'string') {
            tail += '\n' + this.options.tail;
        }

        // Use SalePrintingUtils service to generate document lines
        const documentLines = this.salePrintingUtils.saleToNonFiscalDocument(sale, {
            printerColumns: printerColumns,
            tail: tail
        });

        const commands = [['7', '1', '20', '']];

        const addLine = (line: string) => {
            const l = cleanUpSpecialChars(line).slice(0, 48);
            commands.push(['7', '1', '1', l]);
        };

        const printName = !(savePaper || this.options.print_name === false);

        if(printName) {
            for(const row of this.fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                addLine(row);
            }
        }

        // Process document lines
        for (const line of documentLines) {
            switch (line.type) {
                case 'text':
                    addLine(line.align === 'center' ? _.pad(line.content, printerColumns, ' ') : line.content);
                    break;
                case 'qrcode':
                    // TODO: implement qrcode
                    break;
            }
        }

        commands.push(['m']);

        // Open cash drawer if allowed
        const openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

        if (openCashdrawer) {
			commands.push(['q', '1']);
        }

        return commands;
    }


    private orderToCommands(order: Orders, capabilities: AxonPrinterCapabilities) {
        const printerColumns = this.printer.columns || capabilities.textColumns;

        const border = (car?: string) => new Array(printerColumns).fill(car || '=').join('');

        const lines = [
            border(),
            `ATTENZIONE: Si e' verificato un`,
            `problema di connettivita' al`,
            `cam: ${order.operator_name}`,
            `Si prega di inserire manualmente`,
            `i dati della comanda sulla `,
            `schermata di cassa.`,
            border()
        ];

        // Order name
        if (order.name) {
            lines.push(order.name);
        }

        // Date/Time and #
        const dateNumber = `${dateToString(order.open_at)}${order.order_number ? ` #${order.order_number}` : ''}`;
        lines.push(dateNumber);

        // Delivery Date/Time
        if (order.deliver_at) {
            lines.push(`Da consegnare: ${dateToString(order.deliver_at)}`);
        }

        // Room name & Table name
        if (order.room_name && order.table_name) {
            lines.push(`${order.room_name} ${order.table_name}`);
        }

        // Covers
        if (order.covers) {
            lines.push(`Numero di coperti: ${order.covers}`);
        }

        // Customer
        if (order.order_customer) {
            const customerName = this.util.getCustomerCaption(order.order_customer);

            if (customerName) {
                lines.push(`Cliente: ${customerName}`);
            }
        }

        // Border
        lines.push(border());

        let itemsHeader = "Q.TA' / DESCRIZIONE";
        itemsHeader += _.padStart("EURO", printerColumns - itemsHeader.length, " ");

        lines.push(itemsHeader);
        lines.push('');

        // Order_items
        if (!Array.isArray(order.order_items)) {
            order.order_items = []
        }

        for (let orderItem of order.order_items) {
            const rowPrice = this.fiscalUtils.roundDecimalsToString(orderItem.price * orderItem.quantity);

            let itemLine = `${orderItem.quantity}x ${orderItem.name}`;
            itemLine += rowPrice.padStart(printerColumns - itemLine.length, " ");

            lines.push(itemLine);

            // Ingredients
            if (!Array.isArray(orderItem.ingredients)) {
                orderItem.ingredients = [];
            }

            for (let ingredient of orderItem.ingredients) {
                let ingredientRow = '';

                switch (ingredient.type) {
                    case 'added':
                        ingredientRow += "  + ";
                        break;
                    case 'removed':
                        ingredientRow += "  - ";
                        break;
                    default:
                        ingredientRow += "    ";
                }

                ingredientRow += ingredient.name!;

                if (ingredient.price_difference) {
                    const ingredientPrice = this.fiscalUtils.roundDecimalsToString(ingredient.price_difference * orderItem.quantity);

                    ingredientRow += ingredientPrice.padStart(printerColumns - ingredientRow.length, " ");
                }

                lines.push(cleanUpSpecialChars(ingredientRow));
            }

            // Variations
            if (!Array.isArray(orderItem.variations)) {
                orderItem.variations = [];
            }

            for (let variation of orderItem.variations) {
                let variationRow = `${variation.name}: ${variation.value}`;

                if (variation.price_difference) {
                    const variationPrice = this.fiscalUtils.roundDecimalsToString(variation.price_difference * orderItem.quantity);

                    variationRow += variationPrice.padStart(printerColumns - variationRow.length, " ");
                }

                lines.push(cleanUpSpecialChars(variationRow));
            }
        }

        // Border
        lines.push(border("-"));

        // Amount
        let totString = 'TOTALE EURO';
        const totAmount = this.fiscalUtils.roundDecimalsToString(order.amount);

        totString += totAmount.padStart(printerColumns - totString.length, " ");

        lines.push(totString);

        return this.linesToCommands(lines, printerColumns);
    }

    private saleToCourtesyReceiptCommands(sale: Sales, capabilities: AxonPrinterCapabilities) {
        const lines = [
            "SCONTRINO DI CORTESIA",
            "",
            sale.name,
            "",
        ];

        if (!Array.isArray(sale.sale_items)) {
            sale.sale_items = []
        }

        for (let saleItem of sale.sale_items) {
            lines.push(`${saleItem.quantity}x ${cleanUpSpecialChars(saleItem.name || saleItem.department_name || '')}`);
        }

        return [
            ['7', '1', '20', ''],
            ...this.linesToCommands(lines, capabilities.textColumns)
        ];
    }

    private isG100Printer = (printerSocket: AxonPrinterSocket) => (printerSocket.printerFirmware.indexOf('G100') != -1);

    private vatNatureToCode(vat?: Vat) {
        if (vat?.value) {
            return '';
        }

        switch (vat?.code) {
            case 'N1':
                return '0';
            case 'N2':
                return '1';
            case 'N3':
                return '2';
            case 'N4':
                return '3';
            case 'N5':
                return '4';
            case 'N6':
                return '5';
            case 'VI':
                return '6';
            default:
                return '1';
        }
    }

    private async configurationToCommands(printerSocket: AxonPrinterSocket, options: any): Promise<AxonPrinterCommand[]> {
        const resources = await this.fiscalUtils.getPrinterConfigurationResources();
        const commands: AxonPrinterCommand[] = [];

        //Check if printer firmware contains G100 in its version
        const isG100 = this.isG100Printer(printerSocket);

        // Vat/Departments setup
        const allowedVats = printerSocket.capabilities.maxVats || 5;
        const departmentsToConfigure = resources.departments as Departments[];

        const vatsToConfigure = _.chain(departmentsToConfigure)
            .map((department) => {
                const vatValue = department.vat?.value || 0;

                //Keep track of nature code for G100 series FW
                return (!vatValue && isG100) ? `${vatValue.toFixed(2)}|${this.vatNatureToCode(department.vat)}` : vatValue.toFixed(2);
            })
            .uniq()
            .orderBy()
            .value();

        if (vatsToConfigure.length > allowedVats) {
            throw 'VATS_LIMIT_EXCEEDED';
        }

        //Parse current vats map
        let currentVatsMap: Record<string, string> = {};

        const results = await this.sendCommands(printerSocket, [['e']]);

        //Check if vat configuration is actually needed to avoid unnecessary writes, as the number of times we can configure the vats is limited on the Axon printers
        const currentVats = results.shift() || [];

        //Populate current vats map with the first vat index for each found value
        for (let i = 0; i < allowedVats; i++) {
            let vatValue = currentVats[i];

            if (vatValue == '0.00' && isG100) {
                vatValue += `|${currentVats[i + 12]}`;
            }

            if (!currentVatsMap[vatValue]) {
                currentVatsMap[vatValue] = (i + 1).toString();
            }
        }

        const needsVatConfigure = vatsToConfigure.some((vat) => !currentVatsMap[vat]);

        let vatsMap = needsVatConfigure ? {} : currentVatsMap;

        if (needsVatConfigure) {
            for (let i = 0; i < allowedVats; i++) {
                //Populate missing vats
                if (!vatsToConfigure[i]) {
                    vatsToConfigure[i] = '22.00';
                }

                //Setup new vats map with the first vat index for each value
                if (!vatsMap[vatsToConfigure[i]]) {
                    vatsMap[vatsToConfigure[i]] = (i + 1).toString();
                }
            }

            const vatConfigurationCommand = [
                'b',
                ...vatsToConfigure.map((vat) => vat.split('|')[0]) //Vat value
            ];

            //Add nature and ATECO codes (set to 0 for now) if the firmware is G100 series
            if (isG100) {
                vatConfigurationCommand.push(...vatsToConfigure.map((vat) => vat.split('|')[1] || '')); //Vat nature
                vatConfigurationCommand.push(...new Array(allowedVats).fill('0')); //ATECO codes
            }

            commands.push(vatConfigurationCommand);
        }

        //Departments
        if (!Array.isArray(resources.departments)) {
            resources.departments = [];
        }

        const maxDepartments = printerSocket.capabilities.maxDepartments;

        for (let department of resources.departments as Departments[]) {
            if(department.printer_code! > maxDepartments) {
                this.logEvent(`Department ${department.printer_code} is out of range (max ${maxDepartments})`);
                continue;
            }

            const depName = _.chain(department.name).thru(cleanUpSpecialChars).truncate({ length: 30, omission: '' }).value();
            let depVat = (department?.vat?.value || 0).toFixed(2);

            if (depVat == '0.00' && isG100) {
                depVat += `|${this.vatNatureToCode(department?.vat)}`;
            }

            commands.push([
                "N", //1: Request Code
                department.printer_code!.toString(), //2: Department ID
                depName, //3: Department Name
                vatsMap[depVat].toString(), //4: Vat
                "", //5: Department category (unused)
                "0.00", //6: Department default price
                "999999.99", //7: Department max price value
                "0.00", //8: Department second price value
                [
                    '1', //Enabled
                    '1', //Free price
                    '0', //Closes the document (disabled)
                    '0', //Double height (disabled)
                    '0', //Use secondary price (disabled)
                    '0', //In package (disabled)
                    '0', //Self distribution type (disabled)
                    department.sales_type === 'goods' ? '0' : '1', //Sales type
                    '0', //Gift type (disabled)
                ].join(''), //9: Department flags
            ]);
        }

        // Payment methods
        for (let paymentMethod of axonPaymentsTable) {
            const paymentData = [
                paymentMethod.paymentId, //2: Payment method id
                paymentMethod.paymentName, //3: Payment method name
                _.chain(paymentMethod.paymentName).truncate({ length: 4, omission: '' }).toUpper().value(), //4: Payment method short name
                '1.00', //5: Payment method currency rate
                '1',  //6: Discount/Markup code
                paymentMethod.paymentType, //7: Payment method type
                paymentMethod.paymentFlags, //8: Payment method flags
                paymentMethod.paymentType == '2' ? paymentMethod.paymentSubType : '', //9: Unclaimed type
                '0', //10: Payment POS terminal
            ];

            commands.push(['E', ...paymentData]);
        }

        // Display message
        if (options.display_message) {
            const displayMessage = _.chain(options.display_message).thru(cleanUpSpecialChars).truncate({ length: 70, omission: '' }).value();

            commands.push(['<', '1', '0', displayMessage, '10']);
        } else {
            commands.push(['<', '0', '1', '', '10']);
        }

        //Header Setup
        const headerLines = ['L'];

        // Configure headers
        for (let i = 1; i <= 8; i++) {
            const headerRow = _.chain(options[`header${i}`] || '').thru(cleanUpSpecialChars).truncate({ length: printerSocket.capabilities.textColumns, omission: '' }).value();

            headerLines.push(i === 1 ? '1' : '0'); //Double Height for first line
            headerLines.push(headerRow); //Text
            headerLines.push('0'); //Align center
        }

        commands.push(headerLines);

        return commands;
    }

    private async checkPaymentsTable(printerSocket: AxonPrinterSocket): Promise<void> {
        if (this.paymentsTableChecked[printerSocket.printerSerial] || this.checkManager.getSetting('fiscalprinters.override_payments_check')) {
            return;
        }

        for (let payment of axonPaymentsTable) {
            //Get payment info from printer
            const response = await this.sendCommands(printerSocket, [['{', payment.paymentId,]]);
            const printerPayment = response.shift() || [];

            //Compare payment with printer response
            if (
                printerPayment[0] !== payment.paymentName || //Name
                printerPayment[3] !== '1' || //Discount/Markup code
                printerPayment[4] !== payment.paymentType || //Payment type
                printerPayment[6] !== payment.paymentFlags || //Payment flags
                (printerPayment[4] === '2' && printerPayment[8] !== payment.paymentSubType) //Payment subtype (if unclaimed)
            ) {
                throw 'CONFIGURATION_NEEDED';
            }
        }

        this.paymentsTableChecked[printerSocket.printerSerial] = true;
    }

    private async lastReceiptInfo(printerSocket: AxonPrinterSocket): Promise<any> {
        const result = {};

        const receiptsData = await this.sendCommands(printerSocket, [['t'], ['i'], ['X']]);
        const dateResponse = receiptsData.shift();
        const date = moment([dateResponse![0], dateResponse![1]].join(' '), 'DDMMYY HHmmss').toISOString();
        const dailyClosingNumber = receiptsData.shift()![2];
        const receiptNumber = receiptsData.shift()![1];

        Object.assign(result, {
            printer_serial: printerSocket.printerSerial,
            sequential_number: ((_.toInteger(dailyClosingNumber) + 1) * 10000) + _.toInteger(receiptNumber),
            date: date
        });

        try {
            const lines = await this.getDgfeData(printerSocket, 'READ_RECEIPT', { receiptNumber: receiptNumber });

            Object.assign(result, {
                document_content: lines.join('\n')
            });
        } catch (err) { }

        return result;
    }

    private async getDgfeData(printerSocket: AxonPrinterSocket, mode: string, options: any) {
        let dgfeCommand;

        switch (mode) {
            case 'READ_RECEIPT':
                dgfeCommand = ['@', '1', '0', '', options.receiptNumber, options.receiptNumber, '', '', '0'];
                break;
            case 'READ_RANGE':
                dgfeCommand = ['@', '3', '', '', '', '', options.dateFrom, options.dateTo, '0'];
                break;
            case 'PRINT_RANGE':
                dgfeCommand = ['@', '3', '', '', '', '', options.dateFrom, options.dateTo, '1'];
                break;
            default:
                break;
        }

        if (!dgfeCommand) {
            throw 'INVALID_MODE';
        }

        const dgfeRows = [];

        try {
            const responses = await this.sendCommands(printerSocket, [dgfeCommand]);

            if (mode === 'PRINT_RANGE') {
                return responses;
            }

            while (true) { //This while loop ends when the printer sends the DATA_READ_FINISHED string
                const rowResp = await this.sendCommands(printerSocket, [['*']]);

                dgfeRows.push(rowResp.shift()!.join('/'));
            }
        } catch (err: any) {
            if (err !== 'DATA_READ_FINISHED') {
                throw err;
            }
        }

        return dgfeRows;
    }

    private async getAEXml(printerSocket: AxonPrinterSocket): Promise<any> {
        const lastClosureCommand = ['i'];
        let dailyClosingNumber;
        let dailyClosingXml;

        for (let i = 0; i < 7 && !dailyClosingXml; i++) {
            try {
                //Find last daily closing number
                const lastClosureResults = await this.sendCommands(printerSocket, [lastClosureCommand], { timeout: 15000 });
                dailyClosingNumber = lastClosureResults.shift()![2].padStart(4, '0');

                //Obtain the documents list in the printer
                const filesListResponse = await this.$http.get(`http://${printerSocket.printer.ip_address}/_driveinfo?cmd=1&path=0:\SENT`);
                const filesListData = filesListResponse.data as any;

                if (Array.isArray(filesListData.fileslist)) {
                    //Find the correct file name in the format Z_<dailyClosingNumber>_Z<dailyClosingNumber>_*_S.XML
                    const fileToSearchRegexp = new RegExp(`Z_${dailyClosingNumber}_Z${dailyClosingNumber}_.*_S.XML`);
                    const dailyClosingXmlName = filesListData.fileslist.find((file: any) => file.name.match(fileToSearchRegexp));

                    if (dailyClosingXmlName) {
                        //Download the file
                        const dailyClosingXmlResponse = await this.$http.get(`http://${printerSocket.printer.ip_address}/0:\SENT\\${dailyClosingXmlName.name}`);
                        dailyClosingXml = dailyClosingXmlResponse.data as string;
                    }
                }
            } catch (err: any) {
                switch (err) {
                    case 'BUSY':
                        await new Promise(resolve => setTimeout(resolve, 15000));
                        break;
                    case 'REQ_TIMEOUT':
                        break;
                    default:
                        throw err;
                }
            }
        }

        const parser = new DOMParser();
        const xml = parser.parseFromString(dailyClosingXml || '', "text/xml");
        const RTData = xml.getElementsByTagName("DatiRT");

        if (!RTData) {
            throw 'CANNOT_PARSE_FILE';
        }

        const parsedDate = xml.getElementsByTagName("DataOraRilevazione")[0]?.['textContent'];
        const date = new Date(parsedDate || '');

        return {
            document_content: dailyClosingXml,
            sequential_number: _.toInteger(dailyClosingNumber),
            date: _.isDate(date) ? date.toISOString() : new Date().toISOString()
        }
    };

    private getQueueCouponNonFiscal(sale: Sales, capabilities: AxonPrinterCapabilities): AxonPrinterCommand[] {
        const commands = [['7', '1', '20', '']];

        for (let row of this.fiscalUtils.getQueueCouponRows(sale, capabilities.textColumns, { doubleWidth: true })) {
            commands.push(['7', '1', row.doubleHeight ? '4' : '2', cleanUpSpecialChars(row.text)]);
        }

        commands.push(["m"]);

        return commands;
    }

    //PUBLIC METHODS

    /**
     * @description Setup the driver
     * @param printer
     * @param options
     */
    public setup(printer: Printers, options: any) {
        if (!printer) {
            throw "Printer is undefined";
        }

        if (printer.driver !== 'axon_g100') {
            throw "Wrong driver";
        }

        if (!printer.connection_type) {
            throw "Missing connection_type";
        }

        if (!printer.ip_address) {
            throw "Missing ip_address";
        }

        this.printer = printer;
        this.options = options;
    }

    /**
     * @description Get the printer status
     */
    public async getPrinterStatus() {
        const result = {};

        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.isAvailable(this.printer);

            //TODO: add periodic check and inactive period
            const commands = [
                [',', '10'], //RT Status
            ];

            const responses = await this.sendCommands(printerSocket, commands);

            const rtStatus = responses.shift();
            const fpStatus = Array(5).fill('0');

            fpStatus[0] = printerSocket.deviceStatus.paperEnd ? '3' : '0';

            if (rtStatus![0] === '0') {
                Object.assign(result, { rtMainStatus: '01', rtSubStatus: '05' });
            } else {
                Object.assign(result, { rtMainStatus: '02', rtSubStatus: '08' });
            }

            Object.assign(result, {
                fpStatus: fpStatus.join(''),
                cpuRel: printerSocket.printerFirmware,
                rtType: 'M',
                //rtFileToSend: rtDCStatus[0],
                printer_serial: printerSocket.printerSerial
            });

            return result;
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Auto configure the printer
     * @param printer
     * @param options
     */
    public async autoConfigurePrinter(printer: Printers, options: any) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.isAvailable(printer);

            this.logEvent(`Autoconfiguring ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            const commands = await this.configurationToCommands(printerSocket, options);

            await this.sendCommands(printerSocket, commands);
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Print a fiscal receipt
     * @param sale
     * @param options
     * @param successFunction
     * @param errorFunction
     */
    public async printFiscalReceipt(sale: Sales, options: any, successFunction: Function, errorFunction: Function) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            //Check if the printer payments are properly configured
            await this.checkPaymentsTable(printerSocket);

            //Get printer config resources
            const resources = await this.fiscalUtils.getPrinterConfigurationResources();

            // Build protocol commands
            const commands = this.saleToReceiptCommands(sale, resources, options, printerSocket.capabilities);

            // Open cash drawer
            const openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

            this.logEvent(`Printing fiscal receipt on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            if (openCashdrawer) {
                commands.push(['q', '1']);
            }

            // Print Fiscal Receipt
            await this.sendCommands(printerSocket, commands);

            try {
                const documentData = await this.lastReceiptInfo(printerSocket);

                if (sale.final_amount! < 0) {
                    //We only need to check a single sale item, since the cause check has been done in the saleToReceiptCommands function
                    const saleItemToCheck = sale.sale_items?.[0];

                    switch (saleItemToCheck?.refund_cause_id) {
                        case 6:
                            documentData.document_type = "void_doc";
                            break;
                        default:
                            documentData.document_type = "refund_doc";
                    }
                } else {
                    documentData.document_type = "commercial_doc";
                }

                successFunction([documentData]);
            } catch (err) {
                successFunction([]);
            }

            if (sale.final_amount! >= 0 && this.checkQueueType('non_fiscal')) {
                try {
                    await this.sendCommands(printerSocket, this.getQueueCouponNonFiscal(sale, printerSocket.capabilities))
                } catch (err: any) { }
            }
        } catch (err) {
            errorFunction(err);
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Print a courtesy receipt
     * @param sale
     * @param options
     * @param successFunction
     * @param errorFunction
     */
    public async printCourtesyReceipt(sale: Sales, options: any, successFunction: Function, errorFunction: Function) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            // Build protocol commands
            const commands = this.saleToCourtesyReceiptCommands(sale, printerSocket.capabilities);

            this.logEvent(`Printing courtesy receipt on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, commands);

            successFunction('OK')
        } catch (err) {
            errorFunction(err);
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Print a non fiscal receipt
     * @param sale
     * @param options
     * @param successFunction
     * @param errorFunction
     */
    public async printNonFiscal(sale: Sales, options: any, successFunction: Function, errorFunction: Function) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            // Build protocol commands
            const commands = this.saleToNonFiscalCommands(sale, options, printerSocket.capabilities);

            this.logEvent(`Printing non fiscal receipt on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, commands);

            successFunction('OK')
        } catch (err) {
            errorFunction(err);
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Print a single order
     * @param order
     * @param successFunction
     * @param errorFunction
     */
    public async printOrder(order: Orders, successFunction: Function, errorFunction: Function) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            // Build protocol commands
            const commands = this.orderToCommands(order, printerSocket.capabilities);

            this.logEvent(`Printing order on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, commands);

            successFunction('OK')
        } catch (err) {
            errorFunction(err);
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Open the printer cash drawer
     * @param successFunction
     * @param errorFunction
     */
    public async openCashDrawer(successFunction: Function, errorFunction: Function) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.isAvailable(this.printer);

            // Build protocol commands
            const commands = [['q', '1']];

            this.logEvent(`Opening cash drawer on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, commands);

            successFunction('OK')
        } catch (err) {
            errorFunction(err);
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Read DGFE between two dates
     * @param mode
     * @param from
     * @param to
     */
    public async readDgfeBetween(mode: string, from: string, to: string) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            const dgfeRows = await this.getDgfeData(printerSocket, mode === 'print' ? 'PRINT_RANGE' : 'READ_RANGE', { dateFrom: from, dateTo: to });

            return dgfeRows.join('\n');
        } catch (err) {
            throw err;
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Print fiscal memory between two dates
     * @param corrispettivi
     * @param from
     * @param to
     */
    public async printFiscalMemoryBetween(corrispettivi: boolean, from: string, to: string) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            this.logEvent(`Printing fiscal memory on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, [['k', from, to, corrispettivi ? '1' : '0']]);
        } catch (err) {
            throw err;
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Perform daily closing on the printer
     * @param successFunction
     * @param errorFunction
     */
    public async dailyClosing(successFunction: Function, errorFunction: Function) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            this.logEvent(`Daily closing on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, [['x', '7', '', '', '', '', '']]);

            const result = {};

            const waitPromise = new Promise(async (resolve, reject) => {
                await this.getAEXml(printerSocket!).then((dailyClosingData) => {
                    Object.assign(result, dailyClosingData, {
                        printer_serial: printerSocket!.printerSerial
                    });

                    resolve(null);
                }, reject);
            });

            try {
                await this.waitDialog.show({ message: this.$translate.instant('PRINTERS.SENDING_AE_XML'), timeout: 110, promise: waitPromise });
            } catch (err: any) { }

            successFunction(result);
        } catch (err) {
            errorFunction(err);
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Perform daily read on the printer
     */
    public async dailyRead() {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            this.logEvent(`Daily read on ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, [['x', '1', '', '', '', '', '']]);
        } catch (err) {
            throw err;
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @description Prints a non-fiscal document
     * @param lines lines to print
     * @param options
     */
    public async printFreeNonFiscal(lines: string[], options?: PrintFreeOptions) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.connectAndInitializePrinter(this.printer);

            // Build protocol commands
            const commands = this.linesToCommands(lines, printerSocket.capabilities.textColumns, options);

            this.logEvent(`Print free non-fiscal on a ${this.printer.name} ${this.printer.ip_address} via tcp...`);

            await this.sendCommands(printerSocket, commands);
        } catch (err) {
            throw err;
        } finally {
            this.closeSocket(printerSocket);
        }
    }

    /**
     * @param successFunction success callback
     * @param errorFunction error callback
     * @description Check if the printer is reachable
     */
    public async isReachable(successFunction: Function, errorFunction: Function) {
        let printerSocket: AxonPrinterSocket | undefined;

        try {
            printerSocket = await this.isAvailable(this.printer);

            successFunction();
        } catch (err: any) {
            errorFunction(err);
        } finally {
            this.closeSocket(printerSocket);
        }
    }
}

AxonRTDriver.$inject = ["$http", "$translate", "checkManager", "fiscalUtils", "salePrintingUtils", "util", "waitDialog"];

angular.module('printers').service('AxonG100RTDriver', AxonRTDriver);
