import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as pdfMake from 'pdfmake';
import { currencies } from 'src/app/core/constants/currencies';
import { uiLanguages } from 'src/app/core/constants/ui-languages';
import { EnvironmentConfig } from 'src/environments/environment-config';

const pdfFonts = require('./libs/vfs_monospace');

const appModules = [
  'uiCropper',
  'ui.router',
  'ngMaterial',
  'ngSanitize',
  'pascalprecht.translate',
  'ngMessages',
  'slickCarousel',
  'hmTouchEvents',
  'core',
  'application',
  'tables',
  'orders',
  'printers',
  'cashregister',
  'items',
  'history',
  'customers',
  'digitalPayments',
  'settings',
  'stock',
  'fidelity',
  'prepaid',
  'giftcard',
  'dashboard',
  'bookings',
  'kiosk',
  'promotions',
  'fileImporter',
  'customersNew',
  'suppliersNew',
  'historyNew',
  'dashboardNew',
  'giftcardNew',
  'settingsNew',
  'tablesNew',
  'cashregisterNew',
  'fileImporterNew',
  'fileExporterNew',
  'bookingsNew',
];

angular.module('TilbyApp', appModules)
    .run(tilbyBootstrap)
    .config(tilbyConfig);

tilbyConfig.$inject = ["$urlRouterProvider", "$locationProvider", "$translateProvider", "$compileProvider", "$mdInkRippleProvider", "$mdDateLocaleProvider", "$mdAriaProvider", "$mdThemingProvider"];

function tilbyConfig($urlRouterProvider, $locationProvider, $translateProvider, $compileProvider, $mdInkRippleProvider, $mdDateLocaleProvider, $mdAriaProvider, $mdThemingProvider) {
    let localeUrlPrefix = '/';
    let html5Mode = true;

    switch(EnvironmentConfig.clientId) {
        case 'Scloby3PG':
            localeUrlPrefix = document.URL.substring(0, document.URL.lastIndexOf("/")).replace(/.*?:\/\//g, "") + '/';
            if(window.device.manufacturer == 'Apple') {
                html5Mode = false;
            }
            break;
        case 'SclobyElectron3':
            localeUrlPrefix = './';
            break;
        default: break;
    }

    $locationProvider.html5Mode({
        enabled: html5Mode,
        requireBase: true
    });

    $mdDateLocaleProvider.formatDate = function(date) {
        var m = moment(date);
        var finalDate = m.isValid() ? m.add(m.utcOffset(),'m').format('L') : '';
        return finalDate;
    };

    $mdDateLocaleProvider.parseDate = function(dateString) {
        var m = moment(dateString, 'L', true);
        return m.toDate();
    };

    let languageKeys = [];
    let languageMap = {};

    const browserLanguage = navigator.language.substring(0, 2);
    let preferredLanguage = 'it';

    for(const languageId in uiLanguages) {
        languageKeys.push(languageId);

        for(const territory of uiLanguages[languageId].territories) {
            languageMap[territory] = languageId;
        }

        if(languageId.substring(0, 2) == browserLanguage) {
            preferredLanguage = languageId;
        }
    }

    if(EnvironmentConfig.enableLanguageTestMode) {
        languageKeys.push('xx');
    }

    $translateProvider
        .useLoader('$translatePartialLoader', {
            urlTemplate: localeUrlPrefix + 'l10n/{lang}/{part}.json'
        })
        .registerAvailableLanguageKeys(languageKeys, languageMap)
        .addInterpolation('$translateMessageFormatInterpolation')
        .uniformLanguageTag('java');

    if(!EnvironmentConfig.enableLanguageTestMode) {
        $translateProvider
            .fallbackLanguage('it')
            .preferredLanguage(preferredLanguage);
    }

    /* Add New States Above */
    $urlRouterProvider.otherwise('/');

    $compileProvider.imgSrcSanitizationWhitelist(/^\s*((https?|ftp|file|blob|chrome-extension):|data:image\/)/);
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob|chrome-extension):/);

    if(!['unstable', 'local'].includes(EnvironmentConfig.environment)) {
        $compileProvider.debugInfoEnabled(false);
    }

    $mdInkRippleProvider.disableInkRipple();
    $mdAriaProvider.disableWarnings();

    $mdThemingProvider.theme('default')
        .primaryPalette('blue')
        .accentPalette('blue');
}

tilbyBootstrap.$inject = ["$rootScope", "$state", "$transitions", "$window", "$mdDialog", "$timeout", "appToApp", "errorsLogger", "environmentInfo", "confirmDialog", "legacyBroadcaster", "coreState"];

function tilbyBootstrap($rootScope, $state, $transitions, $window, $mdDialog, $timeout, appToApp, errorsLogger, environmentInfo, confirmDialog, legacyBroadcaster, coreState) {
    legacyBroadcaster.init();

    $rootScope.currentCurrency = _.cloneDeep(currencies.EUR);

    $rootScope.startupWatchdogFunction = function() {
        confirmDialog.show("L'applicazione sembra non rispondere. Vuoi ripristinarla?").then(function(answer) {
            if(answer) {
                $state.go('reset', {
                    askConfirm: false
                });
            }
        });
    };

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
        Monospace: {
            normal: 'Monospace-Regular.ttf',
            bold: 'Monospace-Bold.ttf',
            italics: 'Monospace-Regular.ttf',
            bolditalics: 'Monospace-Bold.ttf'
        }
    };

    $rootScope.stopWatchdog = function() {
        $timeout.cancel($rootScope.startupWatchdog);
    };

    $rootScope.resetWatchdog = function() {
        $rootScope.stopWatchdog();
        $rootScope.startupWatchdog = $timeout($rootScope.startupWatchdogFunction, 300000);
    };

    $rootScope.cleanupWatchdog = function() {
        $timeout.cancel($rootScope.startupWatchdog);
        $mdDialog.cancel();
        delete $rootScope.startupWatchdog;
    };

    $rootScope.resetWatchdog();

    errorsLogger.debug('app.js run');

    $rootScope.restartApplication = function() {
        return coreState.restartApplication();
    };

    $rootScope.showAppLoader = function() {
        $rootScope.$broadcast("loader:changeStatus", "backLoader", { enabled: true });
    };

    $rootScope.hideAppLoader = function() {
        $rootScope.$broadcast("loader:changeStatus", "backLoader", { enabled: false });
    };

    if(environmentInfo.isMobileApp()) { //force InAppBrowser usage if running in cordova
        $window.open = $window.cordova.InAppBrowser.open;
    }

    if(environmentInfo.isElectronApp()) { //Destroy for second display if exists (used then restarting the application)
        $window.require('electron').ipcRenderer.invoke('close-customer-display');
    }

    $transitions.onStart({ }, function(transition) {
        errorsLogger.debug('Switching to state ' + transition.to().name);

        var fromModule = transition.$from().name.split('.')[1];
        var toModule = transition.$to().name.split('.')[1];

        if (fromModule !== toModule) {
            $rootScope.showAppLoader();
        }
    });

    $transitions.onEnter({ }, function(transition, state) {
        errorsLogger.debug('Entering state ' + state.name);
    });

    $transitions.onSuccess({ }, function(transition) {
        errorsLogger.debug('Reached state ' + transition.to().name);
        $rootScope.hideAppLoader();
    });

    $transitions.onError({ }, function(transition) {
        errorsLogger.debug('Error loading state ' + transition.to().name + ": " + transition.error());
        $rootScope.hideAppLoader();
        var errDetail = _.get(transition.error(), 'detail');

        if(_.startsWith(errDetail, 'SCL_')) {
            switch(errDetail) {
                case 'SCL_UPDATE_REQUIRED':
                    $state.go('update-required');
                break;
                default:
                    $state.go('error', {
                        errorCode: errDetail
                    });
                break;
            }
        }
    });

    appToApp.init();
}
