<tilby-dialog-toolbar
    [title]="'ITEMS.DETAILS.AUTOMATE_ITEMS.TITLE'"
    [hideConfirm]="true"
    [customActions]="[]"
/>
<tilby-dialog-content>
    <ul class="tw-list-none tw-p-0">
        @if(item){
            @if (item.name) {
                <li class="tw-py-1">{{ "ITEMS.DETAILS.AUTOMATE_ITEMS.NAME" | translate }}: {{item.name}}</li>
            }
            @if (item.category_name) {
                <li class="tw-py-1">{{ "ITEMS.DETAILS.AUTOMATE_ITEMS.CATEGORY_NAME" | translate }}: {{item.category_name}}</li>
            }
            @if (item.price) {
                <li class="tw-py-1">{{ "ITEMS.DETAILS.AUTOMATE_ITEMS.PRICE" | translate }}: {{item.price}}</li>
            }
            @if (item.description) {
                <li class="tw-py-1 tw-truncate">{{ "ITEMS.DETAILS.AUTOMATE_ITEMS.DESCRIPTION" | translate }}: {{item.description}}</li>
            }
            @if (item.sku) {
                <li class="tw-py-1">{{ "ITEMS.DETAILS.AUTOMATE_ITEMS.SKU" | translate }}: {{item.sku}}</li>
            }
            @if (item.barcodes) {
                <li class="tw-py-1">{{ "ITEMS.DETAILS.AUTOMATE_ITEMS.BARCODE" | translate }}: {{item.barcodes}}</li>
            }
        }
    </ul>
</tilby-dialog-content>