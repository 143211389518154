import {
    Component,
    inject
} from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConfirmDialogService } from 'src/app/dialogs';
import { GridComponent } from '../grid.component';
import { GiftcardsFields } from 'src/app/features';
import { EnvironmentInfoService } from 'src/app/core';
export interface CliccableActionGiftcard {
    action: 'deactivate' | 'download-pdf' | 'share-image';
    data: GiftcardsFields;
}

@Component({
    selector: 'app-grid-clickable-giftcard-button',
    templateUrl: './grid-clickable-giftcard-button.component.html',
    styleUrls: ['./grid-clickable-giftcard-button.component.scss'],
})
export class GridClickableGiftcardButtonComponent implements ICellRendererAngularComp {
    private confirmDialogService = inject(ConfirmDialogService);
    private environmentInfoService = inject(EnvironmentInfoService);

    params!: ICellRendererParams<GiftcardsFields>;

    agInit(params: ICellRendererParams<GiftcardsFields>): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    async openShareImageDialog($event: any) {
        $event.stopPropagation();

        const res = await this.confirmDialogService.openDialog({data: {
            messageLabel: 'DIALOG.GIFTCARD_SHARE_IMAGE.TITLE',
            confirmLabel: 'DIALOG.GIFTCARD_SHARE_IMAGE.CONFIRM',
            cancelLabel: 'DIALOG.GIFTCARD_SHARE_IMAGE.CANCEL'
        }});

        if(res) {
            this.onShareImageSelected();
        }
    }

    onShareImageSelected() {
        (<GridComponent>this.params.context.componentParent).clickableAction.emit({
            action: 'share-image',
            data: this.params.data
        });
    }

    async openDownloadPdfDialog($event: any) {
        $event.stopPropagation();
        this.onDownloadPdfSelected();
    }

    onDownloadPdfSelected() {
        (<GridComponent>this.params.context.componentParent).clickableAction.emit({
            action: 'download-pdf',
            data: this.params.data
        });
    }

    async openDeactivateDialog($event: any) {
        $event.stopPropagation();

        const res = await this.confirmDialogService.openDialog({data: {
            messageLabel: 'DIALOG.GIFTCARD_DEACTIVATE.TITLE',
            confirmLabel: 'DIALOG.GIFTCARD_DEACTIVATE.CONFIRM',
            cancelLabel: 'DIALOG.GIFTCARD_DEACTIVATE.CANCEL'
        }});

        if(res){
            this.onDeactivateSelected();
        }
    }

    onDeactivateSelected() {
        (<GridComponent>this.params.context.componentParent).clickableAction.emit({
            action: 'deactivate',
            data: this.params.data
        });
    }

    preventSelection($event: MouseEvent) {
        $event.stopPropagation();
    }

    isGiftcardActive() {
        return this.params.data?.active;
    }

    isGiftcardDeleted() {
        return this.params.data?.deleted_at ? true : false;
    }

    canShare() {
        return this.environmentInfoService.canShare();
    }
}
