@if({
    height:height,
    saleUpdates: saleUpdates$|async
}; as data){
    <div class="splitview-boundary" #orderView>
        <as-split direction="vertical" #splitComponent
                  [gutterSize]="30"
                  [restrictMove]="true"
                  [gutterDblClickDuration]="400"
                  [unit]="'percent'"
                  [direction]="'vertical'"
        >
            <as-split-area [size]="data.height" class="tw-overflow-y-hidden">
                <app-payments-list-wrapper [sale]="data.saleUpdates?.currentSale">
                    <tilby-active-sale-header-info Header class="*:tw-pointer-events-none *:tw-cursor-default"
                           [isPaymentsState]="isPaymentsState||false"
                           [showSaleFinalAmount]="true"
                           [showPreBill]="false"
                           [sale]="data.saleUpdates?.currentSale||undefined"
                    />
                </app-payments-list-wrapper>
            </as-split-area>
            <as-split-area [size]="100-(data.height||0)" [minSize]="actionBarHeight()" class="tw-py-0 tw-px-2">
                <div class="tw-h-full tw-overflow-y-hidden tw-flex tw-flex-col" [class.tw-flex-col-reverse]="isTablet">
                    <app-payments-action-buttons-wrapper [sale]="data.saleUpdates?.currentSale" class="tw-py-2 tw-h-[106px]"/>
                    <div [ngStyle]="{height: 'calc(100% - 106px)'}">
                        <div class="tw-w-full tw-h-full" appUpgradeUiViewOne></div> <!--Payments-->
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </div>
}

