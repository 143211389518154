
import { Injectable } from "@angular/core";

import {
    GiftcardDefault,
    GiftcardsFields,
    GiftcardsForm
} from "../components";

import { TranslateService } from "@ngx-translate/core";
import {
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

@Injectable({
    providedIn: 'root'
})
export class GiftcardFormService {
    giftcardsForm!:CustomFormGroup<GiftcardsForm>;

    constructor(
        private translate: TranslateService,
    ) {}

    createForm(giftcard: GiftcardsFields = new GiftcardDefault(), hiddenFields?:Array<keyof GiftcardsFields>) {
        this.giftcardsForm = new CustomFormGroup<GiftcardsForm>({
            valid_until: new CustomFormControl(null, {}, {
                ...new CustomFormControlProps(),
                hint:()=>`dd/mm/yyyy`,
                label: this.translate.instant('GIFTCARD.MODIFY_GIFTCARD_DIALOG.VALID_UNTIL'),
                inputType:'date'
            }),
            is_divisible: new CustomFormControl(null, {}, {
                ...new CustomFormControlProps(),
                label: this.translate.instant('GIFTCARD.MODIFY_GIFTCARD_DIALOG.IS_DIVISIBLE'),
                inputType:'checkbox'
            })
        });

        return this.giftcardsForm;
    }
}
