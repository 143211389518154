import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as pdfMake from 'pdfmake';
import { paymentMethodTypes } from 'src/app/core/constants';

angular.module('cashregister').controller('ActiveSaleCtrl', ActiveSaleCtrl);

ActiveSaleCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$animate", "$timeout", "$filter", "$translate", "ActiveSale", "addSelectCustomer", "departments", "promptDialog", "confirmDialog", "documentPrintersManager", "itemListSelector", "refundOrRemoveItem", "saleItemManager", "savedSales", "checkManager", "util", "fiscalUtils", "entityManager", "documentPrinter", "alertDialog", "toast", "printerErrorFiscal", "lotteryCodeDialog", "$mdMedia", "environmentInfo", "DigitalPaymentsManager", "connection", "NoFiscalPrinters", "printerErrorOrders", "peopleSplit", "ExternalSalesManager", "radioListSelector", "stockDictionary", "eInvoiceDialog", "eInvoicePlugin", "deliveryChannels", "orderPrinterSelect", "CustomerDisplayManager", "requestOnlinePaymentDialog","receiptOptionsDialogService", "roundTotalDialog", "newSaleUtils", "currencyConverterDialog"];

function ActiveSaleCtrl($scope, $rootScope, $state, $stateParams, $animate, $timeout, $filter, $translate, ActiveSale, addSelectCustomer, departments, promptDialog, confirmDialog, documentPrintersManager, itemListSelector, refundOrRemoveItem, saleItemManager, savedSales, checkManager, util, fiscalUtils, entityManager, documentPrinter, alertDialog, toast, printerErrorFiscal, lotteryCodeDialog, $mdMedia, environmentInfo, DigitalPaymentsManager, connection, NoFiscalPrinters, printerErrorOrders, peopleSplit, ExternalSalesManager, radioListSelector, stockDictionary, eInvoiceDialog, eInvoicePlugin, deliveryChannels, orderPrinterSelect, CustomerDisplayManager, requestOnlinePaymentDialog, receiptOptionsDialogService, roundTotalDialog, newSaleUtils, currencyConverterDialog) {
    $scope.country = checkManager.getShopCountry();

    Object.assign($scope, {
        sale: ActiveSale,
        sendSaleToOrderPrintersInProgress: false,
        extSalesMan: ExternalSalesManager,
        fastPaymentButtons: [],
        isSendSaleToOrderPrintersManualEnabled: !!checkManager.getPreference('cashregister.enable_print_order'),
        canAddNewItem: checkManager.isUserPermitted("cashregister.add_new_item"),
        canChangeSaleItemQuantity: checkManager.isUserPermitted("cashregister.change_item_quantity"),
        canSendPreorder: checkManager.isUserPermitted('cashregister.preorder'),
        canShowPeopleSplit: checkManager.isFunctionEnabledOptin('cashregister.people_split'),
        canStoreSale: ['IT', 'MT'].includes($scope.country) && checkManager.isUserPermitted("cashregister.store_sale"),
        canDeleteSale: checkManager.isUserPermitted("cashregister.delete_sale"),
        isDutchTreatEnabled: !!checkManager.getPreference('cashregister.enable_split_sale'),
        deliveryChannels: _.keyBy(deliveryChannels, 'id'),
        cSitoCheckout: checkManager.isModuleEnabled('c_sito_checkout')
    });

    const halfPortionValue = newSaleUtils.getHalfPortionValue();

    for(let i = 1; i <= 3; i++) {
        let fastPaymentClass = checkManager.getPreference(`cashregister.fast_payment_${i}_type`);

        if(fastPaymentClass) {
            $scope.fastPaymentButtons.push({
                class: fastPaymentClass,
                index: i,
                label: checkManager.getPreference(`cashregister.fast_payment_${i}_label`) || $translate.instant(`PAYMENT_CLASSES.${_.toUpper(fastPaymentClass)}`)
            });
        }
    }

    $scope.requestOnlinePayment = async () => {
        if(ActiveSale.isActiveSale()) {
            let savedSale;
            let saleToSave = _.cloneDeep(ActiveSale.currentSale);

            if(ActiveSale.currentSale.id) {
                savedSale = await entityManager.sales.putOneOnline(saleToSave);
            } else {
                savedSale = await entityManager.sales.postOneOnline(saleToSave);
            }

            await ActiveSale.loadSale(savedSale.id, { skipStore: true, skipResetDocumentData: true });

            requestOnlinePaymentDialog.show($scope.sale);
        }
    };

    let departmentsById;

    const updateDeparmtentsMap = () => {
        departmentsById = _.keyBy(departments, 'id');
    };

    updateDeparmtentsMap();

    $scope.$on('cashregister-showcase:update-departments', () => {
        updateDeparmtentsMap();
    });

    var getReasonsArray = function(sourcePreference) {
        var reasonsString = _.toString(sourcePreference);
        var reasonsArr = [];

        if(!_.isEmpty(reasonsString)) {
            _.forEach(_.split(reasonsString, '\n'), function(reason, idx) {
                reasonsArr.push({ id: idx + 1, name: reason });
            });
        }

        return reasonsArr;
    };

    var updateSaleStock = function() {
        var stockRequirements = {};
        _.forEach(ActiveSale.currentSale.sale_items, function(saleItem) {
            if(saleItem.item_id) {
                var target = saleItem.combination_id ? 'combination_id_' + saleItem.combination_id : 'item_id_' + saleItem.item_id;
                if(!stockRequirements[target]) {
                    stockRequirements[target] = 0;
                }
                stockRequirements[target] += saleItem.quantity;
            }
        });
        _.forEach(ActiveSale.currentSale.sale_items, function(saleItem) {
            delete saleItem.$unavailable;
            if(saleItem.item_id) {
                var target = saleItem.combination_id ? 'combination_id_' + saleItem.combination_id : 'item_id_' + saleItem.item_id;
                var currentStock = stockDictionary.get(target);
                if(currentStock) {
                    if(!_.isNil(currentStock.stock_quantity)) { //Simple item
                        if(currentStock.stock_quantity < stockRequirements[target]) {
                            saleItem.$unavailable = true;
                        }
                    } else { //BOM
                        if(currentStock.available === 'unavailable') {
                            saleItem.$unavailable = true;
                        }
                    }
                }
            }
        });
    };

    var autosetPrinterDocumentData = function(documentType) {
        documentPrintersManager.getPrinterDocumentData('default', documentType).then(function(docData) {
            ActiveSale.setPrinterDocumentData(docData);
        }, function(error) {
            var errorMessage;
            switch (error) {
                case "UNKNOWN_DOCUMENT_TYPE":
                    errorMessage = $translate.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_DOCUMENT_TYPE');
                    break;
                case "PRINTER_NOT_CAPABLE":
                    errorMessage = $translate.instant('CASHREGISTER.ACTIVE_SALE.PRINTER_NOT_CAPABLE');
                    break;
                case "PRINTER_NOT_FOUND":
                    errorMessage = $translate.instant('CASHREGISTER.ACTIVE_SALE.PRINTER_NOT_FOUND');
                    break;
                case "UNKNOWN_ERROR":
                    errorMessage = $translate.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR');
                    break;
                case "ID_NOT_SET":
                    errorMessage = $translate.instant('CASHREGISTER.ACTIVE_SALE.ID_NOT_SET');
                    break;
                default:
                    errorMessage = $translate.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR');
                    break;
            }
            alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.ATTENTION') + errorMessage);
        });
    };

    $scope.newSale = function() {
        ActiveSale.newSale();
    };

    $scope.createNewSale = function(options) {
        ActiveSale.createNewSale(options);
    };

    $scope.toggleSaleView = function() {
        $scope.viewOptions.hideSale = !$scope.viewOptions.hideSale;
    };

    $scope.showSaveSale = function() {
        promptDialog.show({ title: $translate.instant('CASHREGISTER.ACTIVE_SALE.SAVE_SALE'), defaultValue: ActiveSale.currentSale.name, minLength: 3, maxLength: 25 }).then(function(newName) {
            ActiveSale.saveSale(newName, true);
        });
    };

    $scope.storeSale = async () => {
        let proceedToArchive = checkManager.getShopPreference('cashregister.skip_archive_confirmation');
        let archiveReason;

        if(!proceedToArchive) {
            proceedToArchive = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.WANT_TO_ARCHIVE'));
        }

        if(proceedToArchive) {
            if(checkManager.getSetting('cashregister.store_sale_needs_reason')) {
                let reasonsArr = getReasonsArray(checkManager.getSetting('cashregister.store_sale_reasons'));

                if(!_.isEmpty(reasonsArr)) {
                    archiveReason = await radioListSelector.show(reasonsArr, { label: $translate.instant('CASHREGISTER.ACTIVE_SALE.REASON') });
                } else {
                    alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.ARCHIVE_REASONS_NOT_CONFIGURED'));
                    return;
                }
            }

            try {
                await ActiveSale.storeSale({ reason: archiveReason ? archiveReason.name : null });
            } catch(error) {
                printerErrorFiscal.show(error);
            }
        }
    };

    $scope.deleteSale = function() {
        var performDeleteSale = function(options) {
            ActiveSale.deleteSale(options).catch(function(error) {
                alertDialog.show(error);
            });
        };

        if (ActiveSale.isActiveSale()) {
            confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.WANT_TO_DELETE_SALE')).then(function(answer) {
                if (answer) {
                    if(checkManager.getSetting('cashregister.delete_sale_needs_reason')) {
                        var reasonsArr = getReasonsArray(checkManager.getSetting('cashregister.delete_sale_reasons'));

                        if(!_.isEmpty(reasonsArr)) {
                            radioListSelector.show(reasonsArr, { label: $translate.instant('CASHREGISTER.ACTIVE_SALE.REASON') }).then(function(answer) {
                                if(answer) {
                                    performDeleteSale({ reason: answer.name });
                                }
                            });
                        } else {
                            alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.DELETE_REASONS_NOT_CONFIGURED'));
                        }
                    } else {
                        performDeleteSale();
                    }
                }
            });
        }
    };

    $scope.isActiveSale = function() {
        return ActiveSale.isActiveSale();
    };

    $scope.isSummarySale = function() {
        return ActiveSale.currentSale.is_summary;
    };

    $scope.hasCustomerOrTaxCode = function() {
        return ActiveSale.currentSale.sale_customer || ActiveSale.currentSale.customer_tax_code;
    };

    $scope.hasLotteryCode = function() {
        return ActiveSale.currentSale.lottery_code;
    };

    $scope.showAddCustomer = async () => {
        if(!$scope.isActiveSale() || !$scope.isShowcase()) {
            return;
        }

        ActiveSale.lockPaymentButtons = true;

        try {
            const result = await addSelectCustomer.show(ActiveSale.currentSale.sale_customer);
            $scope.addCustomer(result);
        } catch(err) {
        } finally {
            ActiveSale.lockPaymentButtons = false;
        }
    };

    $scope.addCustomer = async(customer) => {
        let saleCustomer = await ActiveSale.addCustomer(customer);

        //Show customer notes if present
        if(customer.notes) {
            try {
                await alertDialog.show(customer.notes);
            } catch(err) {}
        }

        //Ask to enable invoices if the customer has a valid billing address and the current printer supports them
        if(!checkManager.getPreference('cashregister.disable_customer_invoice_prompt') && eInvoicePlugin.isCustomerValid(saleCustomer)) {
            let currentDocData = ActiveSale.printerDocumentData;

            if((typeof currentDocData == 'object') && !['e_invoice', 'summary_e_rc', 'summary_e_nrc', 'generic_invoice'].includes(currentDocData.document_type?.id)) {
                try {
                    let docData = await documentPrintersManager.getPrinterDocumentData(currentDocData.printer?.id || 'default', ['e_invoice', 'generic_invoice'], currentDocData.options);
                    let answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.WANT_TO_ENABLE_INVOICE'));

                    if(answer) {
                        ActiveSale.setPrinterDocumentData(docData);
                    }
                } catch(err) {}
            }
        }

        //Check if the customer has multiple shipping addresses and, if that's the case, ask which one is to use
        if(checkManager.getPreference('cashregister.ask_shipping_address')) {
            let shippingAddressesCount = 0;
            let shippingAddresses = [{
                address: {
                    shipping_prov: null,
                    shipping_street: null,
                    shipping_number: null,
                    shipping_zip: null,
                    shipping_city: null,
                    shipping_country: null,
                    shipping_address_id: null
                },
                name: $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_DELIVERY'),
                idx: -1
            }];

            for(let idx = 0; idx < 10; idx++) {
                let slot = idx === 0 ? "" : "_" + (idx);

                if(_.get(saleCustomer, 'shipping_street' + slot)) {
                    shippingAddressesCount++;

                    if(idx === 0) { //Set shipping_address_id to 0 in case the user cancels the selection afterwards
                        saleCustomer.shipping_address_id = 0;
                    }

                    let addressObj = {
                        shipping_prov: _.get(saleCustomer, 'shipping_prov' + slot),
                        shipping_street: _.get(saleCustomer, 'shipping_street' + slot),
                        shipping_number: _.get(saleCustomer, 'shipping_number' + slot),
                        shipping_zip: _.get(saleCustomer, 'shipping_zip' + slot),
                        shipping_city: _.get(saleCustomer, 'shipping_city' + slot),
                        shipping_country: _.get(saleCustomer, 'shipping_country' + slot),
                        shipping_address_id: idx
                    };

                    if(idx) {
                        delete saleCustomer['shipping_prov' + slot];
                        delete saleCustomer['shipping_street' + slot];
                        delete saleCustomer['shipping_number' + slot];
                        delete saleCustomer['shipping_zip' + slot];
                        delete saleCustomer['shipping_city' + slot];
                        delete saleCustomer['shipping_country' + slot];
                    }

                    let addressStr = addressObj.shipping_street + " " + (addressObj.shipping_number || "") + " " + (addressObj.shipping_zip || "") + " " + (addressObj.shipping_city || "") + " " + (addressObj.shipping_prov ? "(" +  addressObj.shipping_prov + ")" : "") + " ";
                    let isDisabled = checkManager.getPreference('orders.allow_street_only_shipping_addresses') ? !addressObj.shipping_street : !addressObj.shipping_street || !addressObj.shipping_city;

                    if(isDisabled) {
                        addressStr += " (Incompleto)";
                    }

                    shippingAddresses.push({
                        address: addressObj,
                        name: addressStr,
                        $disabled: isDisabled
                    });
                }
            }

            if(shippingAddressesCount >= 1) {
                let addAction = {
                    callback: function() {
                        addSelectCustomer.show(ActiveSale.currentSale.sale_customer, { editMode: true }).then(function(result) {
                            $scope.addCustomer(result);
                        });
                    }
                };

                let sAddr = await radioListSelector.show(shippingAddresses, { label: $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SELECT_SHIPPING_ADDRESS'), default: 0, addAction: addAction, hideCancel: true });
                Object.assign(saleCustomer, sAddr.address);
            }
        }

        if(saleCustomer.fidelity) {
            let campaigns = await entityManager.campaigns.fetchCollectionOffline({ isValid: true });

            if(!_.isEmpty(campaigns)) {
                let activeCampaign = _.head(campaigns);

                let customerPoints = await entityManager.fidelitiesPoints.fetchCollectionOffline({ fidelity: saleCustomer.fidelity, campaign_id: activeCampaign.id });

                if(!_.isEmpty(customerPoints)) {
                    customerPoints = _.head(customerPoints);

                    let prizes = await entityManager.prizes.fetchCollectionOffline({ campaign_id: activeCampaign.id });
                    let now = moment();

                    let giftPrizes = _.filter(prizes, { type: 'gift' });
                    let giftsMap = {};

                    //Load prize items
                    for(let giftPrize of giftPrizes) {
                        let results = await entityManager.items.fetchCollectionOffline({ sku: giftPrize.item_sku });

                        if(!_.isEmpty(results)) {
                            giftsMap[giftPrize.item_sku] = _.head(results);
                        }
                    }

                    let eligiblePrizes = _.filter(prizes, (prize) => ((customerPoints.points >= prize.points) && (_.isNil(prize.valid_from) || moment(prize.valid_from) <= now) && (_.isNil(prize.valid_to) || moment(prize.valid_to) >= now)));

                    if(!_.isEmpty(eligiblePrizes)) {
                        let radioListPrizes = _.map(eligiblePrizes, (prize) => {
                            let prizeName = [
                                $translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.PRIZE_POINTS', { points: prize.points }),
                                prize.name
                            ];

                            switch(prize.type) {
                                case 'discount_fix':
                                    prizeName.push($translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: $filter('sclCurrency')(prize.discount_amount) }));
                                break;
                                case 'discount_perc':
                                    prizeName.push($translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: util.round(prize.discount_amount) + '%' }));
                                break;
                                case 'gift':
                                    prizeName.push($translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.GIFT', { value: _.get(giftsMap, [prize.item_sku, 'name'], '') }));
                                break;
                                default: break;
                            }

                            return {
                                name: prizeName.join(' - '),
                                prize: prize
                            };
                        });

                        try {
                            let selectedOption = await radioListSelector.show(radioListPrizes, { label: $translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.TITLE', { points: customerPoints.points }) });
                            await ActiveSale.addPrize(selectedOption.prize);
                        } catch(err) {}
                    }
                }
            }
        }

        if (saleCustomer.default_pricelist) {
            $rootScope.$broadcast("activeSale:use-pricelist", { priceList: saleCustomer.default_pricelist });
        }
    };

    $scope.showRemoveCustomer = async function() {
        if($scope.isShowcase()) {
            let answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.WANT_TO_REMOVE_CLIENT'));

            if (answer) {
                ActiveSale.removeCustomer();
            }
        }
    };

    $scope.showAddLotteryCode = async () => {
        const oldLotteryCode = ActiveSale.currentSale.lottery_code;

        const res = await lotteryCodeDialog.openDialog({
            data: {
                currentLotteryCode: oldLotteryCode
            }
        });

        if (!res) {
            return;
        }

        const lotteryCode = res.lotteryCode.toUpperCase();

        ActiveSale.updateSaleDetails({ lottery_code: lotteryCode });

        const customerId  = ActiveSale.currentSale?.sale_customer?.customer_id;

        if(customerId && oldLotteryCode !== lotteryCode) {
            let answer = await confirmDialog.show($translate.instant('APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE'), {
                yesLabel: $translate.instant('APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE_YES'),
                noLabel: $translate.instant('APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE_NO')
            });

            if(answer) {
                let customer = await entityManager.customers.fetchOneOfflineFirst(customerId);
                customer.lottery_code = lotteryCode;
                entityManager.customers.putOneOfflineFirst(customer);
            }
        }
    };

    $scope.showRemoveLotteryCode = async () => {
        if(!ActiveSale.currentSale.lottery_code) {
            return;
        }

        const answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.CONFIRM_LOTTERY_CODE_REMOVE'));

        if(answer) {
            ActiveSale.updateSaleDetails({ lottery_code: null });
        }
    };

    $scope.showCurrencyConverter = async function() {
        if(!$scope.isActiveSale()) {
            return;
        }

        try {
            const result = await currencyConverterDialog.openDialog({
                data: {
                    currency: ActiveSale.currentSale.currency,
                    secondary_currency: ActiveSale.currentSale.secondary_currency,
                    secondary_exchange_rate: ActiveSale.currentSale.secondary_exchange_rate,
                    final_amount: ActiveSale.currentSale.final_amount
                }
            });

            $scope.sale.updateSaleDetails(result);
        } catch(error) {
            // Do nothing
        }
    };

    $scope.showEInvoiceConfigMenu = function() {
        return $scope.isActiveSale() && ['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(ActiveSale.printerDocumentData?.document_type?.id);
    };

    $scope.configureEInvoice = async () => {
        if(!$scope.isActiveSale()) {
            return;
        }

        const result = await eInvoiceDialog.openDialog({ data: { e_invoice: ActiveSale.currentSale.e_invoice } });

        if(result) {
            ActiveSale.setEInvoiceData(result);
        }
    };

    $scope.showSavedSales = async () => {
        if (!$scope.isShowcase()) {
            return;
        }

        const saleId = await savedSales.show();
        return ActiveSale.loadSale(saleId);
    };


    $scope.incrementQty = (saleItem) => {
        if ($scope.canChangeSaleItemQuantity && $scope.sale.canChangeSaleItemQuantity(saleItem)) {
            if (saleItem.quantity % 1 === 0) {
                if (saleItem.quantity === -1) {
                    ActiveSale.cancelRefundOnSaleItem(saleItem);
                } else {
                    ActiveSale.editSaleItem(saleItem, { quantity: saleItem.quantity + 1 });
                }
            } else {
                if (saleItem.quantity < 0) {
                    ActiveSale.cancelRefundOnSaleItem(saleItem);
                }
            }
        }
    };

    const getItemDeletionReason = async (saleItem) => {
        if(checkManager.getSetting('cashregister.delete_item_needs_reason') && saleItem.id && !_.get($scope, ['sale', 'currentSale', 'deleted_items', saleItem.uuid])) {
            let reasonsArr = getReasonsArray(checkManager.getSetting('cashregister.delete_sale_reasons')); //Same reasons as sale

            if(!_.isEmpty(reasonsArr)) {
                let answer = await radioListSelector.show(reasonsArr, { label: $translate.instant('CASHREGISTER.ACTIVE_SALE.REASON') });

                _.set($scope, ['sale', 'currentSale', 'deleted_items', saleItem.uuid], {
                    reason: answer.name
                });
            } else {
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.DELETE_REASONS_NOT_CONFIGURED'));
                throw 'DELETE_REASONS_NOT_CONFIGURED';
            }
        }
    };

    $scope.decrementQty = async (saleItem) => {
        //Disable decrement on sale items that are part of a menu that is not being edited
        if(saleItem.sale_item_parent_uuid && saleItem.sale_item_parent_uuid !== ActiveSale.groupItemUuid) {
            return;
        }

        if ($scope.canChangeSaleItemQuantity && $scope.sale.canChangeSaleItemQuantity(saleItem)) {
            if (saleItem.quantity === 1 || saleItem.quantity % 1) {
                let enableRefund = (saleItem.price !== 0);
                let answer = await refundOrRemoveItem.openDialog({ data: { enableRefund: enableRefund } });

                switch(answer.action) {
                    case 'remove':
                        await getItemDeletionReason(saleItem);
                        ActiveSale.removeSaleItem(saleItem);
                    break;
                    case 'refund':
                        ActiveSale.refundSaleItem(saleItem, answer.refundCause);
                    break;
                    default:
                    break;
                }
            } else {
                await getItemDeletionReason(saleItem);
                ActiveSale.editSaleItem(saleItem, { quantity: saleItem.quantity - 1 });
            }
        }
    };

    $scope.selectSaleItem = function(saleItem) {
        let currentSaleItem = ActiveSale.selectActiveSaleItem(saleItem);

        if(currentSaleItem) {
            $rootScope.$broadcast('cashregister-showcase:show-keyboard', true);
        }
    };

    $scope.isActiveSaleItem = function(saleItem) {
        return ActiveSale.isActiveSaleItem(saleItem);
    };

    const removeSaleItem = async(saleItem) => {
        let answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.REMOVE_ITEM_CONFIRM', { itemName: saleItem.name }));

        if (answer) {
            if(!['deposit_cancellation', 'coupon'].includes(saleItem.type)) {
                await getItemDeletionReason(saleItem);
            }

            ActiveSale.removeSaleItem(saleItem);
        }
    };

    $scope.onSaleItemPress = async (saleItem) => {
        if(saleItem.is_group_item) {
            if(saleItem.item_id) {
                let relatedItem = await entityManager.items.fetchOneOffline(saleItem.item_id);

                if(relatedItem?.split_group_components) {
                    return removeSaleItem(saleItem);
                }
            }

            ActiveSale.groupItemUuid = saleItem.uuid;
        } else if(['deposit_cancellation', 'coupon'].includes(saleItem.type)) {
            return removeSaleItem(saleItem);
        } else {
            $scope.showSaleItemManager(saleItem);
        }
    };

    $scope.showSaleItemManager = async (saleItem) => {
        ActiveSale.lockPaymentButtons = true;

        try {
            let answer = await saleItemManager.show(saleItem, { disableUnbundle: true });

            //If the quantity is 0 and there is not an unbundled item, consider this an item deletion
            if(!answer.rowItem.quantity && !answer.unbundledRowItem) {
                return removeSaleItem(saleItem);
            }

            if(answer.rowItem.quantity < saleItem.quantity) {
                await getItemDeletionReason(saleItem);
            }

            $scope.sale.editSaleItem(saleItem, answer.rowItem, { replace: true });
        } catch(err) {
        } finally {
            ActiveSale.lockPaymentButtons = false;
        }
    };

    $scope.showDeletedOrderItems = async () => {
        if(!ActiveSale.deletedOrderItems?.length) {
            return;
        }

        //Prepare item list selector
        const orderItems = ActiveSale.deletedOrderItems.map(item => ({ name: `${item.quantity}x ${item.name} (${$filter('sclDate')(item.added_at, 'LT')})`, id: item.uuid}));
        const answer = await itemListSelector.show($translate.instant('CASHREGISTER.ACTIVE_SALE.DELETED_ORDER_ITEMS_DIALOG'), orderItems, { multiple: true });

        //Add the selected items to the active sale
        for(let item of answer.elements) {
            const orderitem = ActiveSale.deletedOrderItems?.find(i => i.uuid === item.id);

            if(orderitem) {
                ActiveSale.cloneSaleItem(orderitem);
            }
        }
    };

    $scope.removePriceChangeFromSale = function(price_change) {
        ActiveSale.removePriceChangeFromSale(price_change);
    };

    $scope.removePriceChangeFromSaleItem = function(saleItem, price_change) {
        ActiveSale.removePriceChangeFromSaleItem(saleItem, price_change);
    };

    $scope.showRoundTotal = async () => {
        if (!$scope.isActiveSale()) {
            return;
        }

        ActiveSale.lockPaymentButtons = true;

        try {
            const res = await roundTotalDialog.openDialog({data: {
                    finalAmount : ActiveSale.currentSale.final_amount
                }
            });

            if (!res) {
                return;
            }

            if(res.amount > 0) {
                ActiveSale.addSaleSurchargeFix(Math.abs(res.amount), res.description);
            }
            else if(res.amount < 0) {
                ActiveSale.addSaleDiscountFix(Math.abs(res.amount), res.description);
            }

        } catch(err) {
        } finally {
            ActiveSale.lockPaymentButtons = false;
        }
    };

    $scope.showPeopleSplit = function() {
        if ($scope.isActiveSale() && $scope.canShowPeopleSplit) {
            peopleSplit.show();
        }
    };

    $scope.showSelectPrinterDocument = async () => {
        let documentData = await documentPrintersManager.openDialog({ data: { documentSettings: ActiveSale.printerDocumentData } });

        if(documentData) {
            ActiveSale.setPrinterDocumentData(documentData);
        }
    };

    $scope.priceChangeDescription = function(price_change) {
        if (!price_change.description) {
            switch(price_change.type) {
                case 'discount_fix':
                    return $translate.instant('CASHREGISTER.ACTIVE_SALE.DISCOUNT');
                case 'gift':
                    return $translate.instant('CASHREGISTER.ACTIVE_SALE.GIFT');
                case 'surcharge_fix':
                    return $translate.instant('CASHREGISTER.ACTIVE_SALE.SURCHARGE');
                case 'discount_perc':
                    return $translate.instant('CASHREGISTER.ACTIVE_SALE.DISCOUNT') + " " + price_change.value + "%";
                case 'surcharge_perc':
                    return $translate.instant('CASHREGISTER.ACTIVE_SALE.SURCHARGE') + " " + price_change.value + "%";
                default:
                    break;
            }
        } else {
            if (['discount_perc', 'surcharge_perc'].includes(price_change.type)) {
                return price_change.description + " (" + price_change.value + "%)";
            } else {
                return price_change.description;
            }
        }
    };

    $scope.getCustomerCaption = function() {
        if (ActiveSale.currentSale.customer_tax_code) {
            return ActiveSale.currentSale.customer_tax_code;
        } else {
            return util.getCustomerCaption(ActiveSale.currentSale.sale_customer);
        }
    };

    $scope.getRowPrice = (saleItem) => $filter('sclCurrency')(saleItem.price * saleItem.quantity * (saleItem.half_portion ? (1 - halfPortionValue) : 1));

    $scope.getRowVAT = (saleItem) => departmentsById[saleItem.department_id]?.vat.code || `${departmentsById[saleItem.department_id]?.vat.value}%`;

    $scope.getSaleChannelInfo = function() {
        if(ActiveSale.currentSale.channel || ActiveSale.currentSale.external_id) {
            var channelName;

            if(ActiveSale.currentSale.channel && ActiveSale.currentSale.channel !== 'pos') {
                channelName = _.get($scope.deliveryChannels, [ActiveSale.currentSale.channel, 'name'], ActiveSale.currentSale.channel);
            }

            return (channelName ? channelName + ' ' : '') + (ActiveSale.currentSale.external_id ? '#' + ActiveSale.currentSale.external_id : '');
        } else {
            return '';
        }
    };

    $scope.getDocumentTypeName = function() {
        return documentPrintersManager.getDocumentTypeName(ActiveSale.printerDocumentData, 'CASHREGISTER.ACTIVE_SALE');
    };

    $scope.goToPayments = async() => {
        try {
            await beforePayment();
            $state.go('app.cashregister.content.payments', {});
            $scope.$broadcast('activeSale:go-to-payments');
        } catch(error) {
            printerErrorFiscal.show(error, { printerId: ActiveSale.printerDocumentData?.printer.id });
        }
    };

    $scope.isShowcase = function() {
        return $state.current.name === 'app.cashregister.content.showcase';
    };

    const checkSale = async (skipWarnings) => {
        let result = ActiveSale.checkSale();

        if(!result.isValid) {
            let error = _.head(result.errors);
            let message;

            switch(error) {
                case "SALE_EMPTY":
                case "NEG_AMOUNT_WITH_SALES":
                case "NEG_SALE_ROWS":
                    message = $translate.instant(`CASHREGISTER.ACTIVE_SALE.${error}`);
                break;
                default:
                    message = $translate.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR_CAPITAL');
            }

            await alertDialog.show(message);

            throw null;
        }

        if(result.hasWarnings && !skipWarnings) {
            for(let warning of result.warnings) {
                let message;

                switch(warning) {
                    case "ZERO_PRICE_ITEMS":
                        message = $translate.instant('CASHREGISTER.ACTIVE_SALE.ITEMS_WITH_PRICE_ZERO_AJS');
                        break;
                    default:
                        message = $translate.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR_MANAGE_CHECK');
                }

                message += $translate.instant('CASHREGISTER.ACTIVE_SALE.WANT_TO_PROCEED');

                let result = await confirmDialog.show(message);

                if(!result) {
                    throw null;
                }
            }
        }
    };

    $scope.splitSale = function() {
        if ($scope.isActiveSale()) {
            ActiveSale.splitSale();
        }
    };

    $scope.canSplitSale = function() {
        if ($scope.isActiveSale()) {
            return !(ActiveSale.currentSale.sale_parent_uuid || ActiveSale.currentSale.sale_parent_id);
        } else {
            return false;
        }
    };

    $scope.sendSaleToOrderPrinters = function() {
        if (!$scope.sendSaleToOrderPrintersInProgress) {
            $scope.sendSaleToOrderPrintersInProgress = true;

            NoFiscalPrinters.printSale(ActiveSale.currentSale, { reprint: ActiveSale.reprintSaleToOrderPrinters }).then(function(results) {
                ActiveSale.reprintSaleToOrderPrinters = true;
                $scope.sendSaleToOrderPrintersInProgress = false;
            });
        }
    };

    $scope.emitCreditNote = async () => {
        if(ActiveSale.paymentInProgress) {
            return;
        }

        try {
            ActiveSale.paymentInProgress = true;
            //Handle payments reversal
            await beforePayment();
            await ActiveSale.processPayments();

            await emitDocument();
        } catch(error) {
            printerErrorFiscal.show(error, { printerId: ActiveSale.printerDocumentData?.printer.id });
        } finally {
            ActiveSale.paymentInProgress = false;
        }
    };

    $scope.paySummary = async () => {
        if(ActiveSale.paymentInProgress) {
            return;
        }

        try {
            ActiveSale.paymentInProgress = true;

            await beforePayment();
            await emitDocument();
        } catch(error) {
            printerErrorFiscal.show(error, { printerId: ActiveSale.printerDocumentData?.printer.id });
        } finally {
            ActiveSale.paymentInProgress = false;
        }
    };

    $scope.fastPayment = async (fastPaymentMethod, options) => {
        if(ActiveSale.paymentInProgress) {
            return;
        }

        if(!_.isObject(options)) {
            options = {};
        }

        try {
            ActiveSale.paymentInProgress = true;

            await beforePayment();

            //Find payment method types for the button class
            let paymentMethodTypeIds  = paymentMethodTypes.filter((methodType) => methodType.class === fastPaymentMethod.class).map((methodType) => methodType.id);

            if(!paymentMethodTypeIds.length) {
                return;
            }

            //Find available payment methods for the button class
            let paymentMethods = await entityManager.paymentMethods.fetchCollectionOffline({ payment_method_type_id_in: paymentMethodTypeIds, hidden: false });

            //Get user default payment method id for this button if exists
            const defaultPaymentMethodPreference = `cashregister.fastpayment_${fastPaymentMethod.index}`;
            let defaultPaymentMethodId = checkManager.getPreference(defaultPaymentMethodPreference);

            let targetPaymentMethod;

            //If there is a default id, check if the related payment method exists for this class
            if(defaultPaymentMethodId) {
                defaultPaymentMethodId = _.toInteger(defaultPaymentMethodId);

                targetPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === defaultPaymentMethodId);
            }

            //Show a selection dialog if we cannot find the default payment method or the user is forcing the manual selection
            if(!targetPaymentMethod || options.forceSelect) {
                if(paymentMethods.length > 1) {
                    let selectResult = await itemListSelector.show($translate.instant("CASHREGISTER.ACTIVE_SALE.SELECT_PAYMENT"), paymentMethods, { defaultElement: targetPaymentMethod });

                    //Save the selection if the user wants so
                    if(selectResult.setAsDefault) {
                        checkManager.setUserPreference(defaultPaymentMethodPreference, selectResult.element.id);
                    }

                    targetPaymentMethod = selectResult.element;
                } else {
                    targetPaymentMethod = paymentMethods[0];
                }
            }

            fastPaymentMethod.paying = true;

            //Check if the printer is reachable before attemping a digital payment
            if(DigitalPaymentsManager.isPaymentDigital(targetPaymentMethod.payment_method_type_id)) {
                await documentPrinter.isPrinterReachable(ActiveSale.printerDocumentData?.printer);
            }

            fastPaymentMethod.paying = false;

            await ActiveSale.fastPayment(targetPaymentMethod);

            await ActiveSale.processSaleChange();
            await emitDocument();
        } catch(error) {
            printerErrorFiscal.show(error, { printerId: ActiveSale.printerDocumentData?.printer.id });
        } finally {
            delete fastPaymentMethod.paying;
            ActiveSale.paymentInProgress = false;
        }
    };

    $scope.getSaleType = () => {
        if(ActiveSale.isCreditNote()) {
            return 'credit-note';
        } else if((ActiveSale.currentSale?.is_summary && ['summary_e_nrc', 'generic_invoice'].includes(ActiveSale.printerDocumentData?.document_type?.id))) {
            return 'summary-unclaimed';
        } else {
            return 'sale';
        }
    };

    $scope.checkCreditNote = () => ActiveSale.checkCreditNote();

    $scope.printNonFiscalSale = async (forceSelect) => {
        const PREFERENCE_DEFAULT_PRINTER_ID = 'cashregister.nonfiscalsale_default_printer';
        let targetPrinter;

        //Check the sale
        await checkSale(true);

        //Find the printer to use
        const defaultPrinterId = _.toInteger(checkManager.getPreference(PREFERENCE_DEFAULT_PRINTER_ID));

        if (defaultPrinterId && !forceSelect) {
            let printer = await entityManager.printers.fetchOneOffline(defaultPrinterId);

            if (documentPrinter.isPrinterUsable(printer)) {
                targetPrinter = printer;
            }
        }

        if(!targetPrinter) {
            const printers = await entityManager.printers.fetchCollectionOffline().then((printers) => printers.filter(printer => documentPrinter.isPrinterUsable(printer)));
            const { setAsDefault } = ({ selectedPrinter: targetPrinter } = await orderPrinterSelect.openDialog({ data: { printers, defaultPrinterId } }));

            if(!targetPrinter) {
                return;
            }

            if (setAsDefault) {
                checkManager.setUserPreference(PREFERENCE_DEFAULT_PRINTER_ID, targetPrinter.id);
            }
        }

        //Send the sale to the printer
        try {
            await ActiveSale.printNonFiscalSale(targetPrinter);
            toast.show({ message: $translate.instant('CASHREGISTER.ACTIVE_SALE.NON_FISCAL_SALE_PRINTED') });
        } catch(error) {
            printerErrorFiscal.show(error, { printerId: targetPrinter.id });
        }
    };

    //**** PAYMENT functions *****
    const checkPayments = async () => {
        const sale = ActiveSale.currentSale;

        //Positive amount (sales)
        if (sale.final_amount >= 0) {
            ActiveSale.cleanPaymentsFromSale();
            return;
        }

        //Negative amount (refunds/voids/credit notes/etc)
        if(_.size(sale.payments) > 1 && sale.sale_parent_id) { //Multiple payments
            const parentSale = await entityManager.sales.fetchOneOfflineFirst(sale.sale_parent_id);

            if(util.round(parentSale.final_amount) !== -util.round(sale.final_amount)) {
                await alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.REFUND_WITH_MULTIPLE_PAYMENTS_NOT_ALLOWED'));
                throw 'REFUND_WITH_MULTIPLE_PAYMENTS_NOT_ALLOWED';
            }

            if(parentSale.change) {
                let targetPayment;

                switch(parentSale.change_type) {
                    case 'cash':
                        targetPayment = _.find(sale.payments, (payment) => fiscalUtils.isCashPayment(payment.payment_method_type_id) && (Math.abs(payment.amount) > parentSale.change));
                    break;
                    case 'ticket':
                        targetPayment = _.find(sale.payments, (payment) => (payment.payment_method_type_id === 6) && (Math.abs(payment.amount) > parentSale.change));
                    break;
                }

                if(targetPayment) {
                    targetPayment.amount = util.round(targetPayment.amount + parentSale.change);
                }
            }
        } else { //Single/no payments
            let currentPayment = _.head(sale.payments);

            if(currentPayment) {
                currentPayment.amount = util.round(sale.final_amount);
            }
        }

        let digitalPayment = _.find(sale.payments, (payment) => DigitalPaymentsManager.isPaymentDigital(payment.payment_method_type_id) && DigitalPaymentsManager.paymentAllowsRefund(payment.payment_method_type_id));

        if(digitalPayment) {
            let answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.REFUND_DIGITAL_PAYMENT_PROMPT', { amount: $filter('sclCurrency')(Math.abs(digitalPayment.amount)), paymentMethod: digitalPayment.payment_method_name }), {
                yesLabel: digitalPayment.payment_method_name,
                noLabel: $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.CASH')
            });

            if(!answer) {
                return ActiveSale.cashPayment();
            }
        }
    };

    const beforePayment = async () => {
        await ActiveSale.verifyPrinterDocumentDataAndSelect(ActiveSale.currentSale, ActiveSale.printerDocumentData);
        await checkSale();
        await checkPayments();
        await documentPrinter.checkSale(ActiveSale.currentSale, ActiveSale.printerDocumentData);
    };

    const emitDocument = async() => {
        try {
            let cashSum = _(ActiveSale.currentSale.payments).filter((payment) => fiscalUtils.isCashPayment(payment.payment_method_type_id)).sumBy('amount');

            if(cashSum >= 5000 && $scope.country === 'IT' && !checkManager.getPreference('cashregister.disable_cash_limit_exceeded_confirm')) {
                let result = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.CASH_LIMIT_EXCEEDED_CONFIRM'));

                if(!result) {
                    throw { printError: 'CANCELED' };
                }
            }

            if(checkManager.getPreference('cashregister.enable_receipt_options')) {
                try {
                    const res = await receiptOptionsDialogService.openDialog({
                        data: {
                            printerDocumentData: ActiveSale.printerDocumentData,
                            saleUuid: ActiveSale.currentSale.uuid,
                            saleCustomer: ActiveSale.currentSale.sale_customer
                        }
                    });

                    if (!res) {
                        return;
                    }

                    /**
                     * @typedef {Object} CustomerData
                     * @property {string} email
                     */

                    /** @type {CustomerData} */
                    const customerData = {};

                    ActiveSale.printerDocumentData.options.courtesyReceipt = res.courtesyReceipt || false;
                    ActiveSale.printerDocumentData.options.eReceipt = ['email', 'sms', 'save'].includes(res.mode);

                    if (res.mode === 'email') {
                        customerData.email = res.email;
                    }

                    if (Object.keys(customerData).length) {
                        ActiveSale.updateSaleDetails({
                            sale_customer: ActiveSale.currentSale.sale_customer ? {
                                    ...ActiveSale.currentSale.sale_customer,
                                    ...customerData
                                } : {
                                    ...customerData,
                                    first_name: ' ',
                                    last_name: ' '
                                }
                        });
                    }
                } catch(e) {
                    throw { printError: 'CANCELED' };
                }
            }

            let result = await ActiveSale.emitDocument();

            if(result.printError) {
                throw result;
            } else {
                if(!_.isEmpty(result.notes)) { //TODO: Support multiple warning messages
                    if(_.head(result.notes) === "CHECK_PRINTER_CLOCK") {
                        alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.TIME_NOT_ALIGNED'));
                    }
                }
            }
        } catch(error) {
            if(error?.printError) {
                throw error.printError;
            }
        }
    };

    $scope.removePaymentFromSale = function(payment) {
        if(payment.paid) {
            confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.REMOVE_PAID_PAYMENT_CONFIRM')).then(function(answer) {
                if(answer) {
                    ActiveSale.removePaymentFromSale(payment);
                }
            });
        } else {
            ActiveSale.removePaymentFromSale(payment);
        }
    };

    $scope.getPaymentDetail = function(payment) {
        switch(payment.payment_method_type_id) {
            case 17:
                return (payment.payment_data) ? _.get($scope.deliveryChannels, [payment.payment_data, 'name'], payment.payment_data) : '';
            case 33:
                return payment.code;
            default:
                if (payment.card_circuit_id) {
                    return payment.card_circuit_name;
                }
                if (payment.ticket_id) {
                    return payment.ticket_name;
                }
                return '';
        }
    };

    $scope.showPayAllOnPayments = function() {
        return !_.isEmpty(ActiveSale.currentSale.payments) && (ActiveSale.getToPay() <= 0 || (fiscalUtils.isPaymentRoundingEnabled() && _.every(ActiveSale.currentSale.payments, function(payment) { return fiscalUtils.isCashPayment(payment.payment_method_type_id); })));
    };

    $scope.payAllOnPayments = async () => {
        if(ActiveSale.paymentInProgress) {
            return;
        }

        if(ActiveSale.isPaymentsOnSaleEmpty()) {
            return alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.IMPOSSIBLE_TO_PRINT_WITHOUT_PAYMENT_TYPE'));
        }

        if (!connection.isOnline() && ActiveSale.hasOnlineDigitalPayment()) {
            return alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.DIGITAL_PAYMENT_NOT_AVAILABLE_OFFLINE'));
        }

        try {
            ActiveSale.paymentInProgress = true;

            await ActiveSale.processPayments();
            await ActiveSale.processSaleChange();
            await emitDocument();

            $state.go('app.cashregister.content.showcase', {});
        } catch(error) {
            printerErrorFiscal.show(error, { printerId: ActiveSale.printerDocumentData?.printer.id });
        } finally {
            ActiveSale.paymentInProgress = false;
        }
    };

    $scope.$on('active-sale:saleitem-quantity', async(event, data) => {
        const saleItem = ActiveSale.getActiveSaleItem();

        if(saleItem) {
            if(saleItem.quantity > data.quantity) {
                await getItemDeletionReason(saleItem);
            }

            ActiveSale.changeSaleItemQuantity(saleItem, data.quantity);
        }
    });

    //**** PAYMENT functions *****

    $scope.$on("activeSale:updateSaleStock", function(event) {
        updateSaleStock();
    });

    const highlightItem = (saleItem) => {
        $timeout(async () => {
            const element = angular.element(`#${saleItem.uuid}`);

            if(!element.length) {
                return;
            }

            element[0].scrollIntoView({ behavior: 'smooth', block: 'start' });

            if(element.hasClass('fade-out-start') || (saleItem.is_group_item && saleItem.uuid === ActiveSale.groupItemUuid)) {
                return;
            }

            await $animate.addClass(element, 'fade-out-start');
            await $animate.addClass(element, 'fade-out-end');
            await $animate.removeClass(element, 'fade-out-start fade-out-end');
        });
    };

    $scope.$on("activeSale:item-added", (event, item) => highlightItem(item));
    $scope.$on('activeSale:item-changed', (event, data) => highlightItem(data.currentSaleItem));

    $scope.$on("barcodeManager:Customer", function(event, data) {
        if (data.customer) {
            $scope.addCustomer(data.customer);
        } else if(data.fidelity) {
            alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.NO_CLIENT_WITH_THIS_FIDELITY'));
        } else if(data.taxCode) {
            if (checkManager.getPreference("cashregister.on_taxcode_not_found_show_add_customer") !== false) {
                addSelectCustomer.show(undefined, { newCustomerData : { tax_code: data.taxCode } }).then(function(customer) {
                    $scope.addCustomer(customer);
                });
            } else {
                ActiveSale.addTaxCode(data.taxCode);
            }
        }
    });

    $scope.$on("barcodeManager:Sale", function(event, data) {
        const sale = data.sale;

        if(sale) {
            if(sale.status === 'open') {
                ActiveSale.loadSale(sale.id || sale.uuid);
            } else {
                //TODO: open in history
            }
        }
    });

    $scope.$on("activeSale:changed", function(event, action) {
        switch (action) {
            case 'updated':
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.ANOTHER_OPERATOR_HAS_UPDATED_SALE'));
                break;
            case 'closed':
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.ANOTHER_OPERATOR_HAS_CLOSED_SALE'));
                break;
            case 'deleted':
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.ANOTHER_OPERATOR_HAS_DELETED_SALE'));
                break;
            default:
                break;
        }
    });

    $scope.$on("documentPrinter:document-printed", function(event, data) {
        if(_.isObject(data)) {
            var message;
            var documentToShare;
            var actions = [];

            switch(data.type) {
                case 'e_invoice':
                    message = (data.success) ? $translate.instant('CASHREGISTER.ACTIVE_SALE.E_INVOICE_RECEIPT_PRINTED') : $translate.instant('CASHREGISTER.ACTIVE_SALE.ERROR_IN_PRINTING_RECEIPT');
                break;
                default:
                    message = (data.success) ? $translate.instant('CASHREGISTER.ACTIVE_SALE.RECEIPT_PRINTED') : $translate.instant('CASHREGISTER.ACTIVE_SALE.ERROR_IN_PRINTING_RECEIPT');
                break;
            }

            if(environmentInfo.canShare() && !checkManager.getPreference('cashregister.disable_share_receipt')) {
                if(_.isObject(data.saleData)) {
                    documentToShare = _.find(data.saleData.sale_documents, (document) => !['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(document.document_type));
                }
            }


            if(documentToShare) {
                actions.push({ text: $translate.instant('CASHREGISTER.ACTIVE_SALE.SHARE_RECEIPT'), action: 'share', highlight: true });
            }

            actions.push({ text: $translate.instant('CASHREGISTER.ACTIVE_SALE.OK'), action: 'dismiss', highlight: documentToShare ? false : true });

            toast.show({ message: message, actions: actions }).then(function(action) {
                switch(action) {
                    case 'share':
                        pdfMake.createPdf({
                            content: [documentToShare.document_content],
                            defaultStyle: {
                                font: 'Monospace',
                                fontSize: 10
                                }
                        }).getBase64(function(dataUri) {
                            window.plugins.socialsharing.shareWithOptions({
                                message: data.saleData.name, // not supported on some apps (Facebook, Instagram)
                                subject: data.saleData.name, // fi. for email
                                files: ["data:application/pdf;base64," + dataUri], // an array of filenames either locally or remotely
                                }, function() {

                            }, function(error) {

                            });
                        });
                    break;
                    default: break;
                }
            });
        }
    });

    $scope.$on('order:printed', function(event, data) {
        if(data.printType === 'sale') {
            if (printerErrorOrders.hasError(data.results)) {
                printerErrorOrders.show(data.results, _.chain(data.order.sale_items).map('category_id').union().value());
            }
        }
    });

    $scope.isPortrait = function() {
        return $mdMedia('xs');
    };

    $scope.addNewItem = function() {
        $rootScope.$broadcast('cashregister:addNewItem');
    };

    if(checkManager.getPreference('customer_display.enable_two_rows_display')) {
        $scope.$on('activeSale:customer-added', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'customer-added', data }));
        $scope.$on('activeSale:item-added', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'item-added', data }));
        $scope.$on('activeSale:item-changed', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'item-changed', data }));
        $scope.$on('activeSale:item-removed', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'item-removed', data }));
        $scope.$on('activeSale:priceChange-added', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'priceChange-added', data }));
        $scope.$on('activeSale:priceChange-removed', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'priceChange-removed', data }));
        $scope.$on('activeSale:sale-opened', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'sale-opened', data }));
        $scope.$on('activeSale:go-to-payments', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'go-to-payments', data }));
        $scope.$on('activeSale:payment-in-progress', (event, data) => CustomerDisplayManager.updateTwoRowsDisplay({ event: 'payment-in-progress', data }));
    }

    if((environmentInfo.isElectronApp() && checkManager.getPreference('customer_display.enable_color_display')) || checkManager.getPreference('customer_display.enable_network_display')) {
        $scope.$on('activeSale:sale-updated', (event, data) => CustomerDisplayManager.updateColorDisplay(data));
    }

    const handleStateChange = async () => {
        switch ($stateParams.action) {
            case 'create-new-sale':
                $scope.createNewSale($stateParams);
                break;
            case 'open-saved-sale':
                $scope.showSavedSales();
                break;
            case 'open-sale-id':
                if ($stateParams.id) {
                    await ActiveSale.loadSale($stateParams.id);

                    if ($stateParams.advancedpayments) {
                        $scope.goToPayments();
                    } else if ($stateParams.saleType) {
                        let docType;

                        switch ($stateParams.saleType) {
                            case 'summary':
                                docType = 'summary_invoice';
                                break;
                            case 'summary_e_rc':
                            case 'summary_e_nrc':
                            case 'generic_receipt': case 'generic_invoice': case 'generic_document':
                                docType = $stateParams.saleType;
                                break;
                            case 'void_doc': case 'refund_doc': case 'credit_note':
                                docType = 'fiscal_receipt';
                                break;
                            case 'e_credit_note':
                                docType = 'e_invoice';
                                break;
                            default:
                                docType = 'fiscal_receipt';
                        }

                        autosetPrinterDocumentData(docType);
                    }
                }

                break;
            default:
                break;
        }

        _.assign($stateParams, {
            advancedpayments: null,
            id: null,
            saleType: null
        });
    };

    handleStateChange();
}
