<tilby-dialog-toolbar mat-dialog-title
    title='CASHREGISTER.MAGO_ORDERS.TITLE'
    [hideConfirm]="!selectedOrder"
    (confirm)="confirm()" />
@if(message) {
    <div class="tw-flex tw-justify-center tw-py-4" (click)="clearMessage()">
        <span>{{message | translate}}</span>
    </div>
}
@if(operationInProgress()) {
    <tilby-dialog-progress-bar />
}
<tilby-dialog-content>
    @if(loginForm) {
        <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="tw-flex tw-flex-col">
            <mat-form-field appearance="fill" class="tw-pb-4">
                <mat-label>{{'CASHREGISTER.MAGO_ORDERS.TYPE' | translate}}</mat-label>
                <mat-select formControlName="appId" required>
                    <mat-option value="magoCloud">{{'CASHREGISTER.MAGO_ORDERS.TYPE_MAGO_CLOUD' | translate}}</mat-option>
                    <mat-option value="magoWeb">{{'CASHREGISTER.MAGO_ORDERS.TYPE_MAGO_WEB' | translate}}</mat-option>
                    <mat-option value="mago4">{{'CASHREGISTER.MAGO_ORDERS.TYPE_MAGO_4' | translate}}</mat-option>
                </mat-select>
                @if(loginForm.get('type')?.hasError('required')) {
                    <mat-error class="tw-pt-2">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="fill" class="tw-pb-4">
                <mat-label>{{'CASHREGISTER.MAGO_ORDERS.ACCOUNT_NAME' | translate}}</mat-label>
                <input matInput type="text" formControlName="accountName" required>
                @if(loginForm.get('accountName')?.hasError('required')) {
                    <mat-error class="tw-pt-2">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="fill" class="tw-pb-4">
                <mat-label>{{'CASHREGISTER.MAGO_ORDERS.PASSWORD' | translate}}</mat-label>
                <input matInput type="password" formControlName="password" required>
                @if(loginForm.get('password')?.hasError('required')) {
                    <mat-error class="tw-pt-2">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                }
            </mat-form-field>
            @if(loginForm.get('appId')?.value === 'mago4') {
                <mat-form-field appearance="fill" class="tw-pb-4">
                    <mat-label>{{'CASHREGISTER.MAGO_ORDERS.COMPANY' | translate}}</mat-label>
                    <input matInput type="text" formControlName="company" required>
                    @if(loginForm.get('company')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                    }
                </mat-form-field>
            } @else {
                <mat-form-field appearance="fill" class="tw-pb-4">
                    <mat-label>{{'CASHREGISTER.MAGO_ORDERS.SUBSCRIPTION_KEY' | translate}}</mat-label>
                    <input matInput type="text" formControlName="subscriptionKey" required>
                    @if(loginForm.hasError('required', ['subscriptionKey'])) {
                        <mat-error class="tw-pt-2">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                    }
                </mat-form-field>
            }
            @if(loginForm.get('appId')?.value === 'magoWeb' || loginForm.get('appId')?.value === 'mago4') {
                <mat-form-field appearance="fill" class="tw-pb-4">
                    <mat-label>{{'CASHREGISTER.MAGO_ORDERS.ESPBACKEND' | translate}}</mat-label>
                    <input matInput type="text" formControlName="espBackend" required>
                    @if(loginForm.get('espBackend')?.hasError('required')) {
                        <mat-error class="tw-pt-2">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                    }
                </mat-form-field>
            }
            <div class="tw-flex tw-justify-center">
                <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">
                    {{'CASHREGISTER.MAGO_ORDERS.SAVE_CREDENTIALS_ACTION' | translate}}
                </button>
            </div>
        </form>
    } @else if(ordersForm) {
        <form [formGroup]="ordersForm" class="tw-flex tw-flex-col tw-p-4">
            <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-1">
                <!-- Customer Order Search -->
                <mat-form-field appearance="fill" class="tw-flex-grow">
                    <mat-label>{{'CASHREGISTER.MAGO_ORDERS.CUSTOMER' | translate}}</mat-label>
                    <input matInput formControlName="customer" [matAutocomplete]="customerAutocomplete">
                    <mat-autocomplete #customerAutocomplete="matAutocomplete" [displayWith]="displayCustomerName" (optionSelected)="customerSelected($event)">
                        @for(customer of filteredCustomers | async; track customer.uuid) {
                            <mat-option [value]="customer">{{ displayCustomerName(customer) }}</mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <button mat-raised-button color="primary"
                (click)="searchOrders()"
                [disabled]="!selectedCustomer">
                {{'CASHREGISTER.MAGO_ORDERS.SEARCH_ACTION' | translate}}
            </button>

            <!-- Orders List -->
            @if(ordersList) {
                @if(ordersList.length) {
                    <mat-selection-list class="tw-mt-4 tw-max-h-[400px] tw-overflow-y-auto" [multiple]="false" (selectionChange)="onOrderSelect($event)">
                        @for(order of ordersList; track order) {
                            <mat-list-option [value]="order">
                                <div class="tw-flex tw-flex-col tw-w-full">
                                    <div class="tw-flex tw-justify-between tw-items-center">
                                        <span class="tw-font-medium">{{ 'CASHREGISTER.MAGO_ORDERS.ORDER' | translate}} {{order.InternalOrdNo}}</span>
                                        <span>{{order.OrderDate | tilbyDate : 'dd/MM/yyyy'}}</span>
                                    </div>
                                    <div class="tw-flex tw-justify-between tw-items-center tw-text-sm">
                                        <span>{{order.TotalAmount | tilbyCurrency}}</span>
                                    </div>
                                </div>
                            </mat-list-option>
                            <mat-divider />
                        }
                    </mat-selection-list>
                } @else {
                    <div class="tw-flex tw-justify-center tw-items-center tw-p-4 tw-text-gray-500">
                        {{'CASHREGISTER.MAGO_ORDERS.NO_ORDERS' | translate}}
                    </div>
                }
            }
        </form>
    }
</tilby-dialog-content>