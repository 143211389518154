import {
    Component,
    Injectable,
    inject
} from "@angular/core";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { TranslateModule } from "@ngx-translate/core";

type AutomateItemsDialogData = {
    item: {
        name: string
        category_name: string
        price: string
        description: string
        sku: string
        barcodes: string
    },
};

@Component({
    selector: "app-qrcode-dialog",
    standalone: true,
    imports: [
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        TranslateModule
    ],
    templateUrl: "./automate-items-dialog.component.html",
    styleUrls: ["./automate-items-dialog.component.scss"],
})
export class TilbyDialogComponent {
    protected readonly data: AutomateItemsDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    protected item = this.data.item;

    protected confirm() {
        this.matDialogRef.close();
    }
}

@Injectable({
    providedIn: "root",
})
export class AutomateItemsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<AutomateItemsDialogData>) {
        const data: AutomateItemsDialogData = config?.data;

        const configEdited: NonNullableConfigData<AutomateItemsDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({ width: "800px" }),
            disableClose: true,
            data,
        };

        return lastValueFrom(
            this.dialogRef.open(TilbyDialogComponent, configEdited).afterClosed()
        );
    }
}
