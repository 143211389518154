import {
    Component,
    inject
} from '@angular/core';

import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {
    ConfirmDialogService,
} from 'src/app/dialogs';

import {GridComponent} from '../grid.component';
import {GridService} from '../grid.service';

@Component({
    selector: 'app-grid-clickable-button',
    templateUrl: './grid-clickable-button.component.html',
    styleUrls: ['./grid-clickable-button.component.scss'],
})
export class GridClickableButtonComponent implements ICellRendererAngularComp {
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly gridService = inject(GridService);

    _params!: ICellRendererParams;
    hide = false;

    get params() {
        return this._params;
    }

    set params(v) {
        this._params = v;
        if (this.gridService.tableProperties.idsDeleting.indexOf(Number(this.params.data?.id)) > -1) this.hide = true;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    async openDeleteDialog($event: any){
        $event.stopPropagation();

        const res = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.CUSTOMER_DELETE.TITLE',
                confirmLabel: 'DIALOG.CUSTOMER_DELETE.CONFIRM',
                cancelLabel: 'DIALOG.CUSTOMER_DELETE.CANCEL'
            }
        });

        if(res) {
            this.onRemoveSelected()
        }
    }


    onRemoveSelected() {
        (<GridComponent>this.params.context.componentParent).clickableButton.emit({
            method: 'delete',
            id: this.params.data.id
        });
    }

    preventSelection($event: MouseEvent) {
        // if(!!this.params.context.componentParent.gridApi.getSelectedRows().length)
        $event.stopPropagation();
    }


}
