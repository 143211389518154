import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";

import {
    Component,
    Injectable,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
    inject 
} from "@angular/core";

import { FormsModule, NgForm, NgModel, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import _ from "lodash";
import { lastValueFrom } from "rxjs";
import { ConfigurationManagerService, EntityManagerService, ScreenOrientationService } from "src/app/core";
import { ActiveSaleService } from "src/app/features";
import { Categories, Departments, ItemsVariations } from "tilby-models";

@Component({
    selector: 'app-sale-item-manager-dialog',
    standalone: true,
    imports: [CommonModule, TilbyDialogContentComponent, TilbyDialogToolbarComponent, TranslateModule, MatSelectModule, FormsModule,
        MatFormFieldModule, MatOptionModule, MatInputModule, MatDialogModule, MatDividerModule,
        MatSlideToggleModule, ScrollingModule, TilbyGesturesDirective, ReactiveFormsModule, MatRadioModule, TilbyCurrencyPipe,
        MatTabsModule, TilbyDialogActionButtonsComponent, MatButtonModule, MatIconModule],
    templateUrl: './sale-item-manager-dialog.component.html',
    styleUrls: ['./sale-item-manager-dialog.component.scss']
  })
  export class SaleItemManagerDialogComponent implements OnInit {

    private readonly data: {rowItem: any} = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef= inject(MatDialogRef);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly screenOrientationService= inject(ScreenOrientationService);
    private readonly translateService = inject(TranslateService);
    private readonly tilbyCurrencyPipe=inject(TilbyCurrencyPipe);
    private readonly activeSaleService=inject(ActiveSaleService);

    public isMobilePortrait = this.screenOrientationService.isMobilePortrait;

    @ViewChild('optionsOne', { read: TemplateRef }) optionsOne!: TemplateRef<any>;
    @ViewChild('optionsTwo', { read: TemplateRef }) optionsTwo!: TemplateRef<any>;
    @ViewChild('rowItemManagerForm') rowItemManagerForm!: NgForm;
    @ViewChild('formContainerOptionsOne', { read: ViewContainerRef }) formContainerOptionsOne!: ViewContainerRef;
    @ViewChild('formContainerOptionsTwo', { read: ViewContainerRef }) formContainerOptionsTwo!: ViewContainerRef;
    @ViewChildren(NgModel) ngModels!: QueryList<NgModel>;

    protected screenHeight=screen.height;

    components : any;
    item : any;
    category : any;
    ingredients : any = [];
    variations : any = [];
    departments : Departments[] = [];
    applyAll: boolean = true;
    saleHasTable = this.activeSaleService.currentSale.room_id ? true : false;
    halfPortionEnabled = this.configurationManagerService.getPreference("orders.half_portion_enable");
    ingredientsRemovalPrice = this.configurationManagerService.getPreference("orders.ingredients_removal_affects_price");
    disableChangePrice = !this.configurationManagerService.isUserPermitted("cashregister.use_price_changes");
    disableDeleteItem = !this.configurationManagerService.isUserPermitted("cashregister.delete_item");
    disableRequiredVariations = this.configurationManagerService.getPreference("cashregister.disable_required_variations");
    ordersIngredientsRemovalDisabled = this.configurationManagerService.getPreference("orders.ingredients_removal_disabled");

    rowItem = { ...this.data.rowItem };
    rowItemBck = { ...this.data.rowItem };
    newQuantity : number = 1;
    isShowing = false;
    exits : { name: string; value: string | number | null }[] = [];
    isSubmitted = false;
    filterText : string = '';

    constructor() {}

    async ngOnInit() {
        await this.loadInfo();

        if (this.item) {
            //Initialize variations (if the item does not use combinations)
            if(!this.rowItem.combination_id) {
                this.variations = structuredClone([...(this.item.variations || []), ...(this.category?.variations || [])]);

                this.variations.forEach((variation : any) => {
                    variation.variation_values.sort((a: any, b: any) => {
                        if (a.index !== undefined && b.index !== undefined) {
                            return a.index - b.index;
                        } else if (a.index !== undefined) {
                            return -1;
                        } else if (b.index !== undefined) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                });

                for(let variation of this.variations) {
                    if(this.disableRequiredVariations) {
                        variation.required = false;
                    }

                    let found = variation.variation_values.find((value : any) => value.default_value === true);

                    if (found) {
                        variation.variation_value_id = found.id;
                    }
                }
            }

            let itemComponentsMap = _.keyBy(this.item.components, 'id');

            //Initialize Ingredients
            const itemIngredients = _.chain(this.item.components).sortBy((component) => component.name.toLowerCase()).cloneDeep().value();

            for(let ingredient of itemIngredients) {
                Object.assign(ingredient, {
                    $type: 'ingredient',
                    quantity: 1
                });
            }

            //Initialize components
            const availableComponents = _.chain(this.components)
                .filter((component) => itemComponentsMap[component.id] == null && (!component.categories?.length || component.categories.find((category : Categories) => category.id === this.item.category_id)))
                .sortBy((component) => component.name.toLowerCase())
                .cloneDeep()
                .value();



            for(let component of availableComponents) {
                Object.assign(component, {
                    $type: 'component',
                    quantity: 0
                });
            }

            this.ingredients = [...itemIngredients, ...availableComponents];
        }

        if (this.rowItem.quantity) {
            this.newQuantity = this.rowItem.quantity;
            let ingredientsMap = _.keyBy(this.ingredients, 'id');
            let variationsMap = _.keyBy(this.variations, 'id');

            //Map existing variations
            if(Array.isArray(this.rowItem.variations)) {
                for(let variation of this.rowItem.variations) {
                    let found = variationsMap[variation.variation_id];

                    if (found) {
                        found.variation_value_id = variation.variation_value_id;
                    }
                }
            }

            //Map existing ingredient variations
            if(Array.isArray(this.rowItem.ingredients)) {
                for(let ingredient of this.rowItem.ingredients) {
                    let found = ingredientsMap[ingredient.ingredient_id];

                    if (found) {
                        switch(ingredient.type) {
                            case 'removed':
                                found.quantity = -1;
                            break;
                            case 'added':
                                found.quantity = (found.$type === 'ingredient') ? (ingredient.quantity + 1) : ingredient.quantity;
                            break;
                        }
                    }
                }
            }
        } else {
            this.newQuantity = 1;
        }

        const exits = this.configurationManagerService.getPreference('orders.exits') !== null ?
            parseInt(this.configurationManagerService.getPreference('orders.exits') as string, 10) : 5;

        this.exits = [{
            name: this.translateService.instant("ORDERS.ORDER_ITEM_MANAGER.NO_EXIT"),
            value: null
        }];

        for(let exit = 1; exit <= exits; exit++) {
            this.exits.push({
                name: `${this.translateService.instant("ORDERS.ORDER_ITEM_MANAGER.EXIT_LABEL")} ${exit}`,
                value: exit
            });
        }
    }

    ngAfterViewInit() {
        this.registerControls();
    }

    registerControls() {
        this.formContainerOptionsOne.createEmbeddedView(this.optionsOne);
        this.formContainerOptionsTwo.createEmbeddedView(this.optionsTwo);

        setTimeout(() => {
            this.ngModels.forEach(control => {
                if (control.name && !this.rowItemManagerForm.controls[control.name]) {
                    this.rowItemManagerForm.addControl(control);
                }
            });
        });
    }

    async loadInfo() {
        this.components = await this.entityManagerService.components.fetchCollectionOffline();
        this.departments = await this.entityManagerService.departments.fetchCollectionOffline();
        this.departments = this.departments.sort((a : Departments, b: Departments) => a.name.localeCompare(b.name));
        this.item = (this.data.rowItem.item_id ? await this.entityManagerService.items.fetchOneOffline(this.data.rowItem.item_id) : Promise.resolve(null));
        this.category = (this.data.rowItem.category_id ? await this.entityManagerService.categories.fetchOneOffline(this.data.rowItem.category_id) : Promise.resolve(null));
    }

    getTitle() {
        if (this.item) {
            return this.translateService.instant("DIALOG.SALE_ITEM_MANAGER.TITLE") + " " + this.item.name;
        }

        return '';
    }

    parseNewComponents = () => {
        const newComponents = [];

        for(let ingredient of this.ingredients) {
            if((ingredient.$type === 'component' && ingredient.quantity !== 0) || (ingredient.$type === 'ingredient' && ingredient.quantity !== 1)) {
                let newComponent = {
                    type: ingredient.quantity === -1 ? 'removed' : 'added',
                    name: ingredient.name,
                    ingredient_id: ingredient.id,
                    price_difference: ingredient.price_difference,
                    quantity : ingredient.quantity
                };

                if(ingredient.$type === 'ingredient' && ingredient.quantity > 1) {
                    newComponent.quantity = (ingredient.quantity - 1);
                } else {
                    newComponent.quantity = Math.abs(ingredient.quantity);
                }

                newComponents.push(newComponent);
            }
        }

        return newComponents;
    };

    parseNewVariations = () => {
        const newVariations = [];

        if(!this.rowItem.combination_id) {
            for(let variation of this.variations) {
                if (variation.variation_value_id) {
                    let found = variation.variation_values.find((element : ItemsVariations) => element.id === variation.variation_value_id);

                    newVariations.push({
                        name: variation.name,
                        value: found.value,
                        price_difference: found.price_difference,
                        linked_item_uuid: found.linked_item_uuid,
                        linked_item_sku: found.linked_item_sku,
                        variation_id: variation.id,
                        variation_value_id: variation.variation_value_id
                    });
                }
            }
        }

        return newVariations;
    };

    getPriceDifference(ingredient : any) {
        if(!ingredient.price_difference || (!this.ingredientsRemovalPrice && ingredient.$type === 'ingredient')) {
            return '';
        } else {
            return (ingredient.price_difference >= 0 ? '+' : '') + this.tilbyCurrencyPipe.transform((ingredient.quantity > 1) ? ingredient.price_difference * ingredient.quantity : ingredient.price_difference);
        }
    };

    incrementIngredientQuantity(ingredient : any) {
        if(ingredient.quantity <= -1) {
            switch(ingredient.$type) {
                case 'ingredient':
                    ingredient.quantity = 1;
                break;
                case 'component':
                    ingredient.quantity = 0;
                break;
            }
        } else {
            ingredient.quantity++;
        }
    };

    decrementIngredientQuantity(ingredient : any) {
        switch(ingredient.$type) {
            case 'ingredient':
                if(ingredient.quantity <= 1) {
                    ingredient.quantity = -1;
                } else {
                    ingredient.quantity--;
                }
            break;
            case 'component':
                if(this.ordersIngredientsRemovalDisabled) {
                    if(ingredient.quantity > 0) {
                        ingredient.quantity--;
                    }
                } else {
                    if(ingredient.quantity <= 0) {
                        ingredient.quantity = -1;
                    } else {
                        ingredient.quantity--;
                    }
                }
            break;
        }
    };

    isDisabled() {
        return (this.rowItemManagerForm && this.rowItemManagerForm.invalid) || false;
    }

    answer() {
        this.isSubmitted = true;

        const selectedDepartment = this.departments.find((department: Departments) => department.id === this.rowItem.department_id);
        const rowItem = {
            ...this.rowItem,
            department: selectedDepartment,
            department_id: selectedDepartment?.id,
            department_name: selectedDepartment?.name,
            half_portion: !!this.rowItem.half_portion,
            ingredients: this.parseNewComponents(),
            request_weighing: false,
            vat_perc: selectedDepartment?.vat?.value,
            variations: this.parseNewVariations()
          };

          if (rowItem.unbundledQuantity && rowItem.unbundledQuantity < this.newQuantity) {
            const updatedRowItem = Object.assign({}, rowItem);

            updatedRowItem.quantity = rowItem.unbundledQuantity;
            this.rowItemBck.quantity = this.newQuantity - rowItem.unbundledQuantity;

            updatedRowItem.unbundledQuantity = undefined;
            this.rowItemBck.unbundledQuantity = undefined;

            this.matDialogRef?.close({ rowItem: this.rowItemBck , unbundledRowItem: updatedRowItem });
        } else {
            rowItem.quantity = this.newQuantity;
            this.matDialogRef?.close({ rowItem });
        }
    };

    filteredIngredients() {
        return this.ingredients.filter((ingredient : any) => {
            return ingredient.name.toLowerCase().includes(this.filterText.toLowerCase());
        });
    }

    async deleteItem() {
        this.applyAll = true;
        this.newQuantity = 0;
        this.answer();
    }
}

@Injectable({
    providedIn: 'root'
})
export class SaleItemManagerDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: {rowItem: any}) {
        if(!data) {
            data={rowItem: null};
        }
        const config:NonNullableConfigData<any>={...this.switchMobileDesktopDimensions({ height: '90vh', width: '90%' }), disableClose:true, data };
        return lastValueFrom(this.dialogRef.open(SaleItemManagerDialogComponent,config).afterClosed()).then((res : any) => {
            return res;
        });
    }
}
