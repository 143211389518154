<div class="tw-flex tw-h-full">
    @switch (data.type) {
        @case (0) {
            <app-booking-week [rooms]="rooms"></app-booking-week>
        }
        @case (1) {
            <app-booking-list [rooms]="rooms" class="tw-w-full"></app-booking-list>
        }
        @case (2) {
            <app-booking-timeline [rooms]="rooms"></app-booking-timeline>
        }
    }
</div>
