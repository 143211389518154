
import { inject, Injectable } from "@angular/core";
import {
    TicketsDefault,
    TicketsFields,
    TicketsFormGroup
} from "../settings-tickets-form";
import { Validators } from "@angular/forms";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class TicketsFormService {
    private readonly translateService = inject(TranslateService);

    ticketsForm!: CustomFormGroup<TicketsFormGroup>;

    colors = [{
        value: 'null',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_NONE')
    }, {
        value: 'D1C4E9',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_PURPLE')
    }, {
        value: 'C5CAE9',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_INDIGO')
    }, {
        value: 'B3E5FC',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_LIGHT_BLUE')
    }, {
        value: 'B2DFDB',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_WATER_GREEN')
    }, {
        value: 'C8E6C9',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_GREEN')
    }, {
        value: 'FFECB3',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_AMBER')
    }, {
        value: 'FFE0B2',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_ORANGE')
    }, {
        value: 'FFCDD2',
        name: this.translateService.instant('ITEMS.DETAILS.COLOR_RED')
    }];

    createForm(ticket: TicketsFields): CustomFormGroup<TicketsFormGroup> {
        if(!ticket) {
            ticket = new TicketsDefault();
        }

        const isNewEntryInProgress = ticket?.isNew ? true : false;

        const colorChoices = this.colors.map((c) => ({ key: c.name, value: c.value }));

        this.ticketsForm = new CustomFormGroup<TicketsFormGroup>({
            generalForm: new CustomFormGroup({
                circuit: new CustomFormControl(
                    { value: ticket.circuit || '', disabled: !isNewEntryInProgress },
                    { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.TICKETS.CIRCUIT',
                    inputType: 'text',
                    class: 'tw-w-full tw-mb-2'
                }),
                name: new CustomFormControl(ticket.name || '', { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.TICKETS.NAME',
                    inputType: 'text',
                    class: 'tw-w-full tw-mb-2'
                }),
                image_url: new CustomFormControl(ticket.image_url || '', {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.TICKETS.IMAGE_URL',
                    inputType: 'text',
                    class: 'tw-w-full tw-mb-2'
                }),
                color: new CustomFormControl(ticket.color || '', {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.TICKETS.COLOR',
                    inputType: 'select',
                    inputChoices: colorChoices,
                    class: 'tw-w-full tw-mb-2'
                }),
            }, {}, {...new CustomFormGroupProps()}),
        });


        if(isNewEntryInProgress) {
            this.circuit.enable();
        }

        return this.ticketsForm;
    }

    updateForm(ticket: TicketsFields): void {
        this.ticketsForm?.controls.generalForm.reset(ticket);

        if(ticket?.isNew) {
            this.circuit.enable();
        }
    }

    disableCircuit() {
        this.circuit.disable();
    }

    get generalForm() {
        return this.ticketsForm?.controls.generalForm;
    }
    get id() {
        return this.generalForm?.controls?.id;
    }
    get name() {
        return this.generalForm?.controls?.name;
    }
    get image_url() {
        return this.generalForm?.controls?.image_url;
    }
    get circuit() {
        return this.generalForm?.controls?.circuit;
    }
    get color() {
        return this.generalForm?.controls?.color;
    }
}
