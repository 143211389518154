import {
    Component,
    inject,
    Input,
} from '@angular/core';

import {
    Sales,
    SalesItemsPriceChanges,
    SalesPriceChanges
} from 'tilby-models';

import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-history-sale-detail-toolbar-content',
    templateUrl: './history-sale-detail-toolbar-content.component.html',
    styleUrls: ['./history-sale-detail-toolbar-content.component.scss']
})
export class HistorySaleDetailToolbarContentComponent {
    private readonly translate = inject(TranslateService);

    @Input() sale?: Sales;

    fieldByIdx(i:number, f:any) {
        return f.idx;
    }

    priceChangeDescription(price_change: SalesPriceChanges|SalesItemsPriceChanges){
        if (!price_change.description) {
            switch(price_change.type) {
                case 'discount_fix':
                    return this.translate.instant('HISTORY.SALE.DISCOUNT');
                case 'surcharge_fix':
                    return this.translate.instant('HISTORY.SALE.SURCHARGE');
                case 'discount_perc':
                    return this.translate.instant('HISTORY.SALE.DISCOUNT_PERCENTAGE', { value: price_change.value });
                case 'surcharge_perc':
                    return this.translate.instant('HISTORY.SALE.SURCHARGE_PERCENTAGE', { value: price_change.value });
            // @ts-ignore
                case 'gift':
                    return this.translate.instant('HISTORY.SALE.GIFT');
                default:
                    break;
            }
        } else {
            return price_change.type.endsWith('_perc') ? `${price_change.description} (${price_change.value}%)` : price_change.description;
        }
    };

    hasPayments(sale: Sales & { type_document?: string } | undefined) {
        if (sale && sale.type_document) {
            return sale.type_document !== 'summary_e_nrc';
        }
        return true;
    }

}
