import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import {
    EpsonAlign,
    EpsonBarcode,
    EpsonCut,
    EpsonFont,
    EpsonHRI,
    EpsonSymbol,
    NFThermalPrinter
} from 'src/app/shared/model/nf-thermal-printer.model';

import {
    Sales,
    SalesItems,
    SalesItemsPriceChanges,
    SalesPriceChanges
} from "tilby-models";

import { stringToLines } from 'src/app/shared/string-utils';

import {
    SalePaymentService,
    SaleUtilsService
} from 'src/app/features';

type documentRule = {
    id: number,
    index: number,
    type: "barcode_fixed" | "barcode_sale_field" | "cashdrawer" | "cut" | "date_time" | "document_tail" | "document_title" | "from_url" | "logo" | "printer_tail" | "progressive" | "sale_content" | "sale_field" | "sale_payments" | "sale_price_changes" | "sale_reference" | "sale_totals" | "sale_vats" | "separator" | "shop_preference" | "shop_setting" | "text",
    align: "left" | "center" | "right",
    font_size: number,
    bold: boolean
    margin_top: number
    margin_bottom: number
    options?: object,
}

export class DocumentBuilderService {
    private defaultColumns = 46;

    constructor(
        private $filter: any,
        private $translate: any,
        private checkManager: any,
        private util: any,
        private salePaymentService: SalePaymentService,
        private saleUtilsService: SaleUtilsService,
        private fiscalUtils: any
    ) {
    }

    //Common Block Functions START
    private setBlockAlignment(driver: NFThermalPrinter, block: documentRule, options: any) {
        let align;

        switch (block.align) {
            case 'left':
                align = EpsonAlign.LEFT;
                break;
            case 'center':
                align = EpsonAlign.CENTER;
                break;
            case 'right':
                align = EpsonAlign.RIGHT;
                break;
            default:
                align = EpsonAlign.LEFT;
                break;
        }

        driver.addTextAlign(align);
    };

    private setBlockStyle(driver: NFThermalPrinter, block: documentRule, options: any) {
        let bold = _.isBoolean(block.bold) ? block.bold : false;
        let size = _.inRange(block.font_size, 1, 9) ? block.font_size : 1;

        driver.addTextStyle(undefined, undefined, bold, undefined);
        driver.addTextSize(size, size);
    };

    private addMarginTop(driver: NFThermalPrinter, block: documentRule, options: any) {
        driver.addText(_.repeat('\n', _.toInteger(block.margin_top)));
    };

    private getVatCode(vat: {code:string, id:number}) {
        return vat.code || String.fromCharCode(vat.id + 0x41);
    }

    private getAlignFunction(align: string): Function {
        switch (align) {
            case 'left':
                return _.padEnd;
            case 'center':
                return _.pad;
            case 'right':
                return _.padStart;
            default:
                return _.padEnd;
        }
    }

    private addGenericText(driver: NFThermalPrinter, text: string|null, options: any): string[] {
        let printerColumns = options.columns || this.defaultColumns;
        let contentLines: string[]  = [];

        if (_.isString(text)) {
            contentLines = stringToLines(text, printerColumns);

            for (let line of contentLines) {
                driver.addText(line + '\n');
            }
        }

        return contentLines;
    };

    private computeBlockLengths(blocks: any[], options: any) {
        let blocksWithLength: any[] = [];
        let blocksWithoutLength: any[] = [];

        for (let block of blocks) {
            if (_.isInteger(block.length)) {
                blocksWithLength.push(block);
            } else {
                blocksWithoutLength.push(block);
            }
        }

        if (blocksWithoutLength.length) {
            let rowColumns = options.columns || this.defaultColumns;
            let allocatedChars = _.sumBy(blocksWithLength, 'length');
            let freeChars = rowColumns - (allocatedChars % rowColumns);
            let charsPerColumn = _.floor(freeChars / blocksWithoutLength.length);
            let firstColumnLength = freeChars - (charsPerColumn * (blocksWithoutLength.length - 1));

            for (let block of blocksWithoutLength) {
                block.length = (block === blocksWithoutLength[0]) ? firstColumnLength : charsPerColumn;
            }
        }
    };

    //Common Block Functions END

    //Block Functions START
    private handleTextBlock(driver: NFThermalPrinter, sale:Sales, block: any&documentRule, options: any): string[] {
        return this.addGenericText(driver, block.content, options);
    };

    private handleShopPreference(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let preferenceValue = this.checkManager.getShopPreference(block.preference_id);
        let finalText = _.toString(block.prefix) + _.toString(preferenceValue) + _.toString(block.suffix);

        return this.addGenericText(driver, finalText, options);
    };

    private handleShopSetting(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let settingValue = this.checkManager.getSetting(block.preference_id);
        let finalText = _.toString(block.prefix) + _.toString(settingValue) + _.toString(block.suffix);

        return this.addGenericText(driver, finalText, options);
    };

    private handleSaleField(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let fields = _.toString(block.field_id).split(' ');
        let fieldsValues: String[] = [];
        let finalText: String[] = [];

        for (let fieldId of fields) {
            fieldsValues.push(_.toString(_.get(sale, fieldId)));
        }

        if (!block.skip_empty || _.some(fieldsValues, _.identity)) { //Don't print line if skip_empty is set if there is no data to print
            finalText.push(_.toString(block.prefix), ...fieldsValues, _.toString(block.suffix));
        }

        return this.addGenericText(driver, _.chain(finalText).compact().join(' ').value(), options);
    };

    private handleDateTime(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let finalText = _.toString(block.prefix) + moment().format(block.format || 'l LT') + _.toString(block.suffix);

        return this.addGenericText(driver, finalText, options);
    };

    private parseProgressivePrefix(block: any&documentRule, options: any): string {
        let progressivePrefix = "";

        if (block.progressive_prefix) {
            //%p: printer prefix    %d: document prefix
            progressivePrefix = _.toString(block.progressive_prefix).replace("%d", _.toString(options.progressive_prefix)).replace("%p", _.toString(options.printer_prefix))
            options.progressivePrefix = progressivePrefix;
        }

        return progressivePrefix;
    };

    private handleSaleReference(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let finalText:string|null = null;

        const referenceSaleItem = sale.sale_items?.find((saleItem) => saleItem.reference_sequential_number && saleItem.reference_date);

        if (referenceSaleItem) {
            const reference = [referenceSaleItem.reference_sequential_number, moment(referenceSaleItem.reference_date).format(block.format || 'l')].join(' - ');
            finalText = _.chain([block.prefix, reference, block.suffix]).map(_.toString).join(' ').value();
        }

        return this.addGenericText(driver, finalText, options);
    };

    private handleProgressive(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let progressivePrefix = this.parseProgressivePrefix(block, options);
        let finalText:string|null = null;

        if (options.progressive) {
            finalText = _.chain([block.prefix, progressivePrefix, options.progressive, block.suffix]).map(_.toString).join('').value();
        }

        return this.addGenericText(driver, finalText, options);
    };

    private handleSaleContent(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let subBlocks = _(block.fields).sortBy('index').cloneDeep();
        let finalText: string[] = [];
        let saleItems: any[] = [];

        this.computeBlockLengths(subBlocks, options);

        for (let saleItem of (sale.sale_items || [])) {
            saleItems.push(saleItem);
            let priceChanges = _.orderBy(saleItem.price_changes, 'index', ['asc']) as (SalesItemsPriceChanges)[];

            for (let priceChange of priceChanges) {
                if (priceChange.amount != null) {
                    saleItems.push({
                        name: ' ' + priceChange.description,
                        type: priceChange.type,
                        price: priceChange.amount,
                        vat_perc: saleItem.vat_perc,
                        quantity: 1
                    });
                }
            }
        }

        const getSaleItemNetPrice = (saleItem: SalesItems, quantity:number) => ((saleItem.price * quantity) / ((100 + saleItem.vat_perc) / 100));

        for (let saleItem of saleItems) {
            let itemText = '';

            for (let subBlock of subBlocks) {
                let alignFunction = this.getAlignFunction(subBlock.align);
                let blockText;

                let currency = subBlock.currency ? undefined : '';

                if (['sale', 'gift', 'refund'].includes(saleItem.type) || ['name', 'price'].includes(subBlock.field)) {
                    switch (subBlock.field) {
                        case 'name':
                            blockText = saleItem.name;
                            break;
                        case 'quantity':
                            blockText = _.toString(saleItem.quantity);
                            break;
                        case 'net_price':
                            blockText = this.$filter('sclCurrency')(getSaleItemNetPrice(saleItem, saleItem.quantity), currency);
                            break;
                        case 'price':
                            blockText = this.$filter('sclCurrency')((saleItem.price * saleItem.quantity), currency);
                            break;
                        case 'vat':
                            blockText = this.$filter('sclCurrency')((saleItem.price * saleItem.quantity) - getSaleItemNetPrice(saleItem, saleItem.quantity), currency);
                            break;
                        case 'unit_net_price':
                            blockText = this.$filter('sclCurrency')(getSaleItemNetPrice(saleItem, 1), currency);
                            break;
                        case 'unit_price':
                            blockText = this.$filter('sclCurrency')(saleItem.price, currency);
                            break;
                        case 'unit_vat':
                            blockText = this.$filter('sclCurrency')((saleItem.price) - getSaleItemNetPrice(saleItem, 1), currency);
                            break;
                        case 'sku':
                            blockText = saleItem.sku;
                            break;
                        case 'code':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.code);
                            break;
                        case 'barcode':
                            blockText = saleItem.barcode;
                            break;
                        case 'notes':
                            blockText = saleItem.notes;
                            break;
                        case 'reference_text':
                            blockText = saleItem.reference_text;
                            break;
                        case 'option_1':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.option1_value);
                            break;
                        case 'option_2':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.option2_value);
                            break;
                        case 'option_3':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.option3_value);
                            break;
                        case 'option_4':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.option4_value);
                            break;
                        case 'description':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.description);
                            break;
                        case 'weight':
                            blockText = !_.chain(options.itemsMap).get([saleItem.item_id, 'weight']).isNil().value() ? _.toString(options.itemsMap[saleItem.item_id].weight * saleItem.quantity) : "";
                            break;
                        case 'unit_weight':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.weight);
                            break;
                        case 'unit':
                            blockText = _.toString(options.itemsMap[saleItem.item_id]?.unit);
                            break;
                        case 'vat_code':
                            let targetDepartment = options.departments[saleItem.department_id];
                            blockText = targetDepartment ? this.getVatCode(targetDepartment.vat) : "";
                            break;
                        case 'vat_perc':
                            blockText = _.toNumber(saleItem.vat_perc).toFixed(options.vatDecimals);
                            break;
                        default:
                            break;
                    }
                }

                if (blockText) {
                    blockText = subBlock.pattern ? _.replace(subBlock.pattern, '%s', blockText) : blockText;
                    itemText += alignFunction(_.truncate(blockText, { length: subBlock.length }), subBlock.length, ' ');
                } else if (!subBlock.skip_empty) {
                    itemText += _.repeat(' ', subBlock.length);
                }
            }

            finalText.push(itemText);
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    private handleSaleTotals(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let subBlocks = _(block.fields).sortBy('index').cloneDeep();
        let rowLength = options.columns || this.defaultColumns;
        let finalText: string[] = [];

        for (let subBlock of subBlocks) {
            let alignFunction = this.getAlignFunction(subBlock.align);
            let blockText;

            let currency = subBlock.currency ? undefined : '';

            switch (subBlock.field) {
                case 'amount':
                    blockText = this.$filter('sclCurrency')(sale.amount, currency);
                    break;
                case 'final_amount':
                    blockText = this.$filter('sclCurrency')(sale.final_amount, currency);
                    break;
                case 'secondary_final_amount':
                    if(sale.secondary_final_amount != null) {
                        blockText = this.$filter('sclCurrency')(sale.secondary_final_amount, subBlock.currency ? sale.secondary_currency : '');
                    }
                    break;
                case 'final_net_amount':
                    blockText = this.$filter('sclCurrency')(sale.final_net_amount, currency);
                    break;
                case 'vat_amount':
                    blockText = this.$filter('sclCurrency')(((sale.final_amount||0) - (sale.final_net_amount||0)), currency);
                    break;
                case 'change':
                    blockText = this.$filter('sclCurrency')(sale.change, currency);
                    break;
                case 'price_changes':
                    if(!sale.sale_items || !sale.sale_items.length) {
                        return [];
                    }
                    let priceChangesSum = _.chain(sale.sale_items).map<any>('price_changes').flatten().concat(sale.price_changes).compact().map('amount').sum().value();

                    blockText = this.$filter('sclCurrency')(priceChangesSum, currency);
                    break;
                case 'weight':
                    let weight = _.sumBy(sale.sale_items, (saleItem: any) => ((options.itemsMap[saleItem.item_id]?.weight || 0) * saleItem.quantity))

                    blockText = _.toString(weight);
                    break;
                default:
                    break;
            }

            if (blockText) {
                if (subBlock.pattern) {
                    blockText = subBlock.pattern ? _.replace(subBlock.pattern, '%s', blockText) : blockText;
                    finalText.push(alignFunction(_.truncate(blockText, { length: rowLength }), rowLength, ' '));
                } else {
                    let prefix = _.toString(subBlock.prefix);
                    blockText = prefix + _.padStart(blockText, rowLength - prefix.length, ' ');
                    finalText.push(alignFunction(blockText, rowLength, ' '));
                }
            } else {
                finalText.push(_.repeat(' ', rowLength));
            }
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    private handlePriceChanges(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let finalText: string[] = [];
        let printerColumns = options.columns || this.defaultColumns;
        let currency = block.print_currency ? undefined : ''; //setting currency to null makes the filter print the current currency
        let salePriceChanges = _.orderBy(sale.price_changes, 'index', ['asc']) as (SalesPriceChanges&{ amount: number })[];

        for (let priceChange of salePriceChanges) {
            let description = priceChange.description;
            let amount = this.$filter('sclCurrency')(priceChange.amount, currency);

            finalText.push(priceChange.description + _.padStart(amount, printerColumns - description.length, ' '));
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    private handleSalePayments(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let finalText: string[] = [];
        let printerColumns = options.columns || this.defaultColumns;
        let currency = block.print_currency ? undefined : ''; //setting currency to null makes the filter print the current currency
        let payments = _.sortBy(this.fiscalUtils.extractPayments(sale), 'amount');

        for (let payment of payments) {
            let description = payment.method_name;
            let amount = this.$filter('sclCurrency')(payment.amount, currency);
            finalText.push(description + _.padStart(amount, printerColumns - description.length, ' '));
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    private handleSaleVats(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let finalText: string[] = [];
        let aggregateTaxes = this.fiscalUtils.extractTax(sale, options.departments);
        let printerColumns = options.columns || this.defaultColumns;
        let columnsToPrint = _.chain([block.print_code, block.print_value, block.print_taxable, block.print_tax, block.print_total]).map(_.toInteger).sum().value();
        let otherColumns = _.floor(printerColumns / columnsToPrint);
        let firstColumn = this.util.round(printerColumns - (otherColumns * (columnsToPrint - 1)), 0);
        let currency = block.print_currency ? undefined : ''; //setting currency to null makes the filter print the current currency

        for (let vatId in aggregateTaxes) {
            let aggrTax = aggregateTaxes[vatId];
            let taxLine = "";

            if (aggrTax.taxable !== 0) {
                let columnSize = firstColumn;

                for (let columnToPrint of ['print_code', 'print_value', 'print_taxable', 'print_tax', 'print_total']) {
                    if (block[columnToPrint]) {
                        switch (columnToPrint) {
                            case 'print_code':
                                taxLine += _.padEnd(this.getVatCode(options.vats[vatId]), columnSize, ' ');
                                break;
                            case 'print_value':
                                taxLine += _.padEnd(_.toNumber(options.vats[vatId].value).toFixed(options.vatDecimals) + "%", columnSize, ' ');
                                break;
                            case 'print_taxable':
                                taxLine += _.padStart(this.$filter('sclCurrency')(aggrTax.taxable, currency), columnSize, ' ');
                                break;
                            case 'print_tax':
                                taxLine += _.padStart(this.$filter('sclCurrency')(aggrTax.tax, currency), columnSize, ' ');
                                break;
                            case 'print_total':
                                taxLine += _.padStart(this.$filter('sclCurrency')(aggrTax.total, currency), columnSize, ' ');
                                break;
                        }

                        if (columnSize === firstColumn) {
                            columnSize = otherColumns;
                        }
                    }
                }
            }

            finalText.push(taxLine);
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    private handleDocumentTitle(driver: NFThermalPrinter, sale: Sales, block: documentRule, options: any): string[] {
        let mainDocumentType: string = options.documentType.replace('generic_', '');
        let subDocumentType: string = '';

        if(!['preliminary_receipt'].includes(mainDocumentType)) {
            if (!['receipt', 'invoice'].includes(mainDocumentType)) {
                mainDocumentType = 'receipt';
            }

            if (sale.final_amount && sale.final_amount < 0) {
                if (_.every(sale.sale_items, { refund_cause_id: 6 })) {
                    subDocumentType = '_void';
                } else {
                    subDocumentType = '_refund';
                }
            }
        }

        let textLines: String[] = [this.$translate.instant(`PRINTERS.DOCUMENT_BUILDER.SALE_TYPES.${mainDocumentType.toUpperCase()}${subDocumentType.toUpperCase()}`)];

        if (options.isReprint) {
            textLines.push(this.$translate.instant(`PRINTERS.DOCUMENT_BUILDER.SALE_TYPES.REPRINT`));
        }

        return this.addGenericText(driver, textLines.join('\n'), options);
    };

    private handleDocumentTail(driver: NFThermalPrinter, sale: Sales, block: documentRule, options: any): string[] {
        let tail = options.tail;
        let tailText = this.addGenericText(driver, tail, options);

        if (options.tail_qr_code && options.tail_qr_code_size !== 0) {
            driver.addTextAlign(EpsonAlign.CENTER);
            driver.addText('\n');
            driver.addSymbol(options.tail_qr_code, EpsonSymbol.QRCODE_MODEL_2, undefined, options.tail_qr_code_size || 6);
        }

        return tailText;
    };

    private handlePrinterTail(driver: NFThermalPrinter, sale: Sales, block: documentRule, options: any): string[] {
        let tail = this.checkManager.getPreference('fiscalprinter.tail');
        return this.addGenericText(driver, tail, options);
    };

    private handleSeparator(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any): string[] {
        let printerColumns = options.columns || this.defaultColumns;
        let finalText: string[] = [];

        for (let i = 0; i < (block.rows || 1); i++) {
            finalText.push(_.repeat(block.pattern || '-', _.floor(printerColumns / _.get(block, ['pattern', 'length'], 1))));
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    private handleCut(driver: NFThermalPrinter, sale: Sales, block: documentRule, options: any) {
        driver.addCut(EpsonCut.FEED);
    };

    private handleLogo(driver: NFThermalPrinter, sale: Sales, block: documentRule, options: any) {
        driver.addLogo();
    };

    private handleUrl(driver: NFThermalPrinter, sale: Sales, block: documentRule, options: any) {
    };

    private handleCashdrawer(driver: NFThermalPrinter, sale: Sales, block: documentRule, options: any) {
        if(options.can_open_cash_drawer) {
            driver.addPulse();
        }
    };

    private handleBarcode(driver: NFThermalPrinter, barcodeValue: string, block: any&documentRule) {
        let barcodeType: EpsonBarcode | null = null;
        let symbolType: EpsonSymbol | null = null;
        let barcodeHri: EpsonHRI;
        let barcodeFont: EpsonFont;

        switch(_.toUpper(block.barcode_type)) {
            case 'CODE39':
            default:
                barcodeType = EpsonBarcode.CODE39;
            break;
            case 'EAN13':
                barcodeType = EpsonBarcode.EAN13;
            break;
	        case 'EAN8':
                barcodeType = EpsonBarcode.EAN8;
            break;
	        case 'EAN128':
                barcodeType = EpsonBarcode.CODE128;
            break;
	        case 'UPC':
                barcodeType = EpsonBarcode.UPC_A;
            break;
            case 'QRCODE':
                symbolType = EpsonSymbol.QRCODE_MODEL_2;
            break;
        }

        switch(_.toUpper(block.barcode_hri)) {
            case 'NONE':
                barcodeHri = EpsonHRI.NONE;
            break;
            case 'ABOVE':
                barcodeHri = EpsonHRI.ABOVE;
            break;
            case 'BELOW':
            default:
                barcodeHri = EpsonHRI.BELOW;
            break;
            case 'BOTH':
                barcodeHri = EpsonHRI.BOTH;
            break;
        };

        switch(_.toUpper(block.barcode_font)) {
            case 'FONT_A':
            default:
                barcodeFont = EpsonFont.FONT_A;
            break;
            case 'FONT_B':
                barcodeFont = EpsonFont.FONT_B;
            break;
            case 'FONT_C':
                barcodeFont = EpsonFont.FONT_C;
            break;
            case 'FONT_SPECIAL_A':
                barcodeFont = EpsonFont.FONT_SPECIAL_A;
            break;
            case 'FONT_SPECIAL_B':
                barcodeFont = EpsonFont.FONT_SPECIAL_B;
            break;
        }

        if(symbolType) {
            driver.addSymbol(barcodeValue, EpsonSymbol.QRCODE_MODEL_2, undefined, (block.width * 2) || 6);
        }

        if(barcodeType) {
            driver.addBarcode(barcodeValue, barcodeType, barcodeHri, barcodeFont, block.width || 1, block.height || 66);
        }
    }

    private handleBarcodeFixed(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any) {
        const barcodeValue = _.toString(block.barcode_value);

        if(barcodeValue) {
            this.handleBarcode(driver, barcodeValue, block);
        }
    }

    private handleBarcodeSaleField(driver: NFThermalPrinter, sale: Sales, block: any&documentRule, options: any) {
        const barcodeValue = _.get(sale, block.field_id);

        if(typeof barcodeValue === 'number' || typeof barcodeValue === 'string') {
            this.handleBarcode(driver, barcodeValue.toString(), block);
        }
    }

    //Block Functions END

    public async buildDocument(driver: NFThermalPrinter, origSale:Sales, printerDocumentData:any, options: any) {
        let resultText: string[] = [];
        let localOptions: any = _.isObject(options) ? _.cloneDeep(options) : {};
        let sale = _.cloneDeep(origSale);
        let documentModel = printerDocumentData.document_template;

        let fiscalResources = await this.fiscalUtils.getPrinterConfigurationResources();

        Object.assign(localOptions, {
            itemsMap: printerDocumentData.itemsMap,
            progressive: documentModel.progressive,
            documentType: documentModel.type,
            departments: _.keyBy(fiscalResources.departments, 'id'),
            vats: _.keyBy(fiscalResources.vats, 'id'),
            vatDecimals: _.max([_.chain(fiscalResources.vats).mapValues('value').map(_.toString).map((val) => _.size(val.split('.')[1])).max().value(), 2])
        });

        sale.sale_items = this.fiscalUtils.extractSaleItems(sale);
        this.saleUtilsService.calculateSalePrices(sale);
        sale.change = this.salePaymentService.getSaleChange(sale) ?? undefined;

        if(sale.secondary_exchange_rate) {
            sale.secondary_final_amount = this.util.round((sale.final_amount || 0) * sale.secondary_exchange_rate);
        }

        let blocksByIndex: documentRule[] = _.sortBy(documentModel.rules, 'index');

        for (let block of blocksByIndex) {
            if (_.isObject(block.options)) {
                Object.assign(block, block.options);
                delete block.options;
            }

            this.setBlockAlignment(driver, block, localOptions);
            this.setBlockStyle(driver, block, localOptions);
            this.addMarginTop(driver, block, localOptions);

            for (let i = 0; i < (block.margin_top || 0); i++) {
                resultText.push('');
            }

            let result: string[] = [];

            try {
                switch (block.type) {
                    case "barcode_fixed":
                        this.handleBarcodeFixed(driver, sale, block, localOptions);
                    break;
                    case "barcode_sale_field":
                        this.handleBarcodeSaleField(driver, sale, block, localOptions);
                    break;
                    case "text":
                        result = this.handleTextBlock(driver, sale, block, localOptions);
                        break;
                    case "shop_preference":
                        result = this.handleShopPreference(driver, sale, block, localOptions);
                        break;
                    case "shop_setting":
                        result = this.handleShopSetting(driver, sale, block, localOptions);
                        break;
                    case "sale_field":
                        result = this.handleSaleField(driver, sale, block, localOptions);
                        break;
                    case 'sale_reference':
                        result = this.handleSaleReference(driver, sale, block, localOptions);
                        break;
                    case "date_time":
                        result = this.handleDateTime(driver, sale, block, localOptions);
                        break;
                    case "progressive":
                        result = this.handleProgressive(driver, sale, block, localOptions);
                        break;
                    case "sale_content":
                        result = this.handleSaleContent(driver, sale, block, localOptions);
                        break;
                    case "sale_totals":
                        result = this.handleSaleTotals(driver, sale, block, localOptions);
                        break;
                    case "sale_payments":
                        result = this.handleSalePayments(driver, sale, block, localOptions);
                        break;
                    case "sale_vats":
                        result = this.handleSaleVats(driver, sale, block, localOptions);
                        break;
                    case "sale_price_changes":
                        result = this.handlePriceChanges(driver, sale, block, localOptions);
                        break;
                    case "document_title":
                        result = this.handleDocumentTitle(driver, sale, block, localOptions);
                        break;
                    case "document_tail":
                        result = this.handleDocumentTail(driver, sale, block, localOptions);
                        break;
                    case "printer_tail":
                        result = this.handlePrinterTail(driver, sale, block, localOptions);
                        break;
                    case "separator":
                        result = this.handleSeparator(driver, sale, block, localOptions);
                        break;
                    case "cut":
                        this.handleCut(driver, sale, block, localOptions);
                        break;
                    case "logo":
                        this.handleLogo(driver, sale, block, localOptions);
                        break;
                    case "from_url":
                        this.handleUrl(driver, sale, block, localOptions);
                        break;
                    case "cashdrawer":
                        this.handleCashdrawer(driver, sale, block, localOptions);
                        break;
                }

                resultText = _.concat(resultText, result);

                for (let i = 0; i < (block.margin_bottom || 0); i++) {
                    resultText.push('');
                }

                driver.addText(_.repeat('\n', _.toInteger(block.margin_bottom)));
            } catch (err) {
                //Nothing to do
            }
        }

        if(blocksByIndex.length) {
            driver.addCut();
        }

        return {
            document_content: resultText.join('\n'),
            sequential_number: localOptions.progressive,
            sequential_number_prefix: localOptions.progressivePrefix,
            document_type: localOptions.documentType
        }
    }
}

angular.module('printers').service('DocumentBuilder', DocumentBuilderService);

DocumentBuilderService.$inject = ["$filter", "$translate", "checkManager", "util", "salePayment", "newSaleUtils", "fiscalUtils"];
