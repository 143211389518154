import { inject, Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DevLogger } from "src/app/shared/dev-logger";
import { lastValueFrom } from "rxjs";
import { MagicPortalDialogComponent } from "../magic-portal-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { CashMovements } from 'tilby-models';
import { CashMovementDialog } from "./cash-movement-dialog.model";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { CashMovementDialogComponent } from "./cash-movement.dialog.component";

//type CashMovementFormValue = {};
//export type CashMovementForm = CustomFormGroup<CustomForm<CashMovementFormValue>>;
export type CashMovementParams = CashMovementDialog;
export type CashMovementDialogOutput = {};
export type CashMovementDialogData = { cash_movement: CashMovements | undefined};

/**
 * NEVER SUBSCRIBE IN THIS SERVICE, SUBSCRIBE IN CONTENT COMPONENT
 */

 @Injectable({
     providedIn: 'root'
 })

export class CashMovementDialogStateService {
    private readonly openDialogsService = inject(OpenDialogsService);

    private dialogRef?: MatDialogRef<
        MagicPortalDialogComponent<CashMovementDialogComponent>,
        CashMovementDialogOutput
    >;
    //private _form?: CashMovementForm;

    private log(...args: any[]) {
        DevLogger.log("ITEM_LABELS_DIALOG_STATE_SERVICE", ...args);
    }

    // OPEN DIALOG
    public async openDialog(config: NonNullableConfigData<CashMovementParams>) {
        const {title, cash_movement} = config.data;
        this.log("OPEN_DIALOG", config.data);
        // await this.resolveEntities();
        // this.resetInitialValues();
        // this.init();
        // this.createForm();

        const configPortal: NonNullablePortalConfigData<CashMovementDialogData,CashMovementDialogComponent> = {
            ...config,
            data: {
                component: CashMovementDialogComponent,
                titleLabel: title,
                cash_movement: cash_movement,
                hideConfirm: true
            },
        };
        this.dialogRef = this.openDialogsService.openPortalDialogRef<
            CashMovementDialogComponent,
            PortalConfigData<CashMovementDialogData, CashMovementDialogComponent>,
            CashMovementDialogOutput
        >({
            ...this.openDialogsService.switchMobileDesktopDimensions({
                width: "800px",
            }),
            ...configPortal,
        });
        return lastValueFrom(this.dialogRef.afterClosed());
    }

    // FUNTIONS IN CREATE FORM
}
