import { CommonModule, KeyValue } from "@angular/common";
import { Component, Injectable, inject } from "@angular/core";
import { Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { lastValueFrom } from "rxjs";
import { PaymentMethods } from "tilby-models";

type CashmaticPaymentMethods = { paymentMethods: PaymentMethods[] };
interface CashMaticPaymentFields {
    cashmatic_payment: string;    
}

@Component({
    selector: 'app-choose-cashmatic-payment-method-dialog',
    templateUrl: './choose-cashmatic-payment-method-dialog.component.html',
    styleUrls: ['./choose-cashmatic-payment-method-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        TilbyMagicFormComponent
    ]
})
export class ChooseCashmaticPaymentMethodComponent {
    protected readonly data: CashmaticPaymentMethods = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    cashmaticPaymentForm!:CustomFormGroup<CustomForm<any>>;

    ngOnInit() {
        const cashmatic_payments: KeyValue<string, string>[] = this.data.paymentMethods.map((payment, i) => ({ key: payment.name, value: payment.id.toString() }));

        this.cashmaticPaymentForm = new CustomFormGroup<CustomForm<CashMaticPaymentFields>>({
            cashmatic_payment: new CustomFormControl(
                null, 
                { validators: [Validators.required] }, 
                { 
                    ...new CustomFormControlProps(), 
                    inputType: 'select', 
                    inputChoices: cashmatic_payments, 
                    label: 'Metodo di pagamento', 
                    id: 'cashmatic-payment', 
                    class: 'tw-w-1/3' 
                }
            ),
        });
    }

    protected confirm() {
        if(this.cashmaticPaymentForm.valid) {   
            const payment = this.data.paymentMethods.filter(payment => payment.id === +this.cashmaticPaymentForm.controls.cashmatic_payment.value)[0];
            this.matDialogRef.close(payment);
        } else {
            this.cashmaticPaymentForm.markAllAsTouched();
        }
    }

}

@Injectable({
    providedIn: "root",
})
export class ChooseCashmaticPaymentMethodDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<CashmaticPaymentMethods>) {
        const data: CashmaticPaymentMethods = config?.data || { paymentMethods: [] };
        const configEdited: NonNullableConfigData<CashmaticPaymentMethods> = {
            ...config,
            ...this.switchMobileDesktopDimensions({ width: '500px' }),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(ChooseCashmaticPaymentMethodComponent, configEdited).afterClosed()
        );
    }
}
