import { CommonModule } from '@angular/common';
import {
	Component,
	inject,
	Input
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { BaseInputButton } from '@tilby/tilby-ui-lib/models';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {
	ScreenOrientationService
} from 'src/app/core';


@Component({
	selector: 'app-payments-action-buttons',
	templateUrl: './payments-action-buttons.component.html',
	styleUrls: ['./payments-action-buttons.component.scss'],
	standalone: true,
	imports: [CommonModule, MatButtonModule, TilbyCurrencyPipe, TilbyGesturesDirective, MatButtonToggleModule, TranslateModule, MatIconModule, MatProgressSpinnerModule]
})
export class PaymentsActionButtonsComponent {
	private readonly screenOrientationService = inject(ScreenOrientationService);

	@Input() saleToPay: number | undefined;
	@Input() salePaid: number | undefined;
	@Input() saleChange: number | null | undefined;
	@Input() backActionButton: BaseInputButton | undefined;
	@Input() payAndPrintActionButton: BaseInputButton | undefined;
	@Input() isLoading: boolean = false;

	protected isMobilePortrait = this.screenOrientationService.isMobilePortrait;
}
