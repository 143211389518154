
<tilby-dialog-toolbar title={{data.title}} (confirm)="confirm()" [hideConfirm]="true"/>
<tilby-dialog-content>
    <mat-list>
        <ng-container>
            @if(data.transactions && data.transactions[0]; as trans0){
                <div class="tw-container tw-flex">
                    <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5">
                        @if(trans0.sale_details?.name) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.NAME"></span>:
                                {{ trans0.sale_details?.name }}
                            </div>
                        }
                        @if(data.transactions[0].sale_details && data.transactions[0].sale_details.status){
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.STATUS"></span>:
                                {{data.transactions[0].sale_details.status }}
                            </div>
                        }
                        @if(trans0.sale_details?.channel) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.CHANNEL"></span>:
                                {{ trans0.sale_details?.channel }}
                            </div>
                        }
                        @if(trans0.sale_details?.table_name) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.TABLE"></span>:
                                {{ trans0.sale_details?.table_name }}
                            </div>
                        }
                        @if(trans0.sale_details?.room_name) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.ROOM_NAME"></span>:
                                {{ trans0.sale_details?.room_name }}
                            </div>
                        }
                        @if(trans0.sale_details?.external_id) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.EXTERNAL_ID"></span>:
                                {{ trans0.sale_details?.external_id }}
                            </div>
                        }
                        @if(trans0.sale_details?.sale_customer) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.SALE_CUSTOMER"></span>:
                                {{ trans0.sale_details?.sale_customer?.first_name}}
                                {{ trans0.sale_details?.sale_customer?.last_name }}
                                {{ trans0.sale_details?.sale_customer?.company_name }}
                            </div>
                        }
                        @if (data.transactions[1] && data.transactions[1].sale_items_transactions) {
                            @if(((data.transactions[1].sale_items_transactions && data.transactions[1].sale_items_transactions.length > 0) ? data.transactions[1].sale_details?.bill_lock : (data.transactions[2] ? data.transactions[2].sale_details?.bill_lock : false))) {
                                <div matListItemTitle class="tw-flex">
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.BILL_LOCK"></span>
                                    <mat-icon class="material-symbols-outlined">lock</mat-icon>
                                </div>
                            }                        
                            @if(data.transactions[1].sale_details?.printed_prebills === 1){
                                <div matListItemTitle>
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.PRINTED_PREBILLS"></span>
                                </div>
                            }
                            @if(data.transactions[1].sale_details?.sent_exits; as trans1){
                                <div matListItemTitle>
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.SENT_EXITS"></span>: 1
                                </div>
                            }
                        }
                        <div matListItemTitle>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.ITEMS"></span>:
                            <div *ngFor="let item of (data.transactions[1]?.sale_items_transactions!.length > 0 ? data.transactions[1]?.sale_items_transactions : (data.transactions[2]?.sale_items_transactions && data.transactions[2]?.sale_items_transactions!.length > 0) ? data.transactions[2]?.sale_items_transactions : data.transactions[0]?.sale_items_transactions)">
                                <div matListItemLine [ngStyle]="{ color: 'green' }" class="tw-font-normal">
                                    {{ item.quantity_difference > 0 ? "+" + item.quantity_difference + " x " : item.quantity_difference < 0 ? item.quantity_difference + " x " : "" }} {{item.sale_item_details.half_portion ? hasHalfPortion(item) : ""}} {{item.sale_item_details.name || items[item.sale_item_uuid]}}

                                    @for(variation of item.sale_item_details.variations; track $index;) {
                                        <div [ngStyle]="{'padding-left': '1.8em'}">
                                            @if(variation.name && variation.value) {
                                                <span matListItemLine [ngStyle]="{color: 'green'}">
                                                    {{variation.name}}: {{variation.value}}
                                                </span>
                                            }
                                        </div>
                                    }
                                    @if(item.sale_item_details.notes) {
                                        <div [ngStyle]="item.quantity_difference > 0 ? {color:'green'} : item.quantity_difference < 0 ? {color:'red'} : {color:'grey'}" style="padding-left: 2.1em;">
                                            {{item.sale_item_details.notes}}
                                        </div>
                                    }
                                    @for(ingredient of item.sale_item_details.ingredients; track $index;) {
                                        <div [ngStyle]="{'padding-left': '1.8em'}">
                                            @if(ingredient.name && ingredient.quantity) {
                                                <span matListItemLine [ngStyle]="{color: 'green'}">
                                                    {{ingredient.quantity >= 2 ? "+" + ingredient.quantity : "-"}} {{ingredient.name}}
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5 tw-text-right">
                        <div matListItemLine>
                            {{trans0.created_at | tilbyDate : "HH:mm:ss"}}
                        </div>
                        <div matListItemLine>
                            {{trans0.created_at | tilbyDate : "dd/MM/yyyy"}}
                        </div>
                        <div matListItemLine class="tw-flex tw-items-center tw-justify-end">
                            <mat-icon>person</mat-icon>
                            {{ trans0.operator_name }}
                        </div>
                        @if(trans0.printed) {
                            <mat-icon class="material-symbols-outlined">print_connect</mat-icon>
                        }
                    </mat-list-item>
                </div>
            }
            @else {
                <div class="tw-container tw-flex tw-justify-center tw-items-center">
                    <label translate="HISTORY.TRANSACTIONS_VIEWER.NO_TRANSACTIONS"></label>
                </div>
            }
        </ng-container>
    </mat-list>
    <mat-list>
        <ng-container *ngFor="let transaction of data.transactions; index as i; let last = last;">
            @if(i >= fromTransactionStart) {
                <div class="tw-container tw-flex">
                    <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5 tw-min-h-[100px]">
                        @if(transaction!.sale_details?.name) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.NAME"></span>:
                                {{ transaction!.sale_details?.name }}
                            </div>
                        }
                        @if(transaction!.sale_details?.status) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.STATUS"></span>:
                                {{ transaction!.sale_details?.status }}
                            </div>
                        }
                        @if(transaction!.sale_details?.channel && !last) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.CHANNEL"></span>:
                                {{ transaction!.sale_details?.channel }}
                            </div>
                        }
                        @if(transaction!.sale_details?.table_name && !last) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.TABLE"></span>:
                                {{ transaction!.sale_details?.table_name }}
                            </div>
                        }
                        @if(transaction!.sale_details?.room_name && !last) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.ROOM_NAME"></span>:
                                {{ transaction!.sale_details?.room_name }}
                            </div>
                        }
                        @if(transaction!.sale_details?.external_id && !last){
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.EXTERNAL_ID"></span>:
                                {{ transaction!.sale_details?.external_id }}
                            </div>
                        }
                        @if(transaction!.sale_details?.sale_customer && !last) {
                            <div matListItemTitle>
                                <span translate="HISTORY.TRANSACTIONS_VIEWER.SALE_CUSTOMER"></span>:
                                {{ transaction!.sale_details?.sale_customer?.first_name}}
                                {{ transaction!.sale_details?.sale_customer?.last_name }}
                                {{ transaction!.sale_details?.sale_customer?.company_name }}
                            </div>
                        }
                        @if((transaction!.sale_items_transactions!.length > 0 || transaction.sale_details?.sent_exits || transaction.sale_details?.bill_lock || transaction.sale_details?.printed_prebills === 1) && transaction.sale_details?.status !== 'closed') {
                            @if(transaction.sale_details?.printed_prebills && !last) {
                                <div class="tw-flex">
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.PRINTED_PREBILLS"></span>
                                </div>
                            }
                            @if(transaction.sale_details?.bill_lock && !last) {
                                <div class="tw-flex">
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.BILL_LOCK"></span>
                                    <mat-icon class="material-symbols-outlined">lock</mat-icon>
                                </div>
                            }
                            @if(data.transactions[i-1].sale_details?.bill_lock && (!data.transactions[i].sale_details?.bill_lock && !last)) {
                                <div class="tw-flex">
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.BILL_UNLOCK"></span>
                                    <mat-icon class="material-symbols-outlined">lock_open_right</mat-icon>
                                </div>
                            }
                            @if (!transaction.sale_details?.sent_exits && !transaction.sale_details?.bill_lock && !last && !transaction.sale_details?.printed_prebills) {
                                <div class="tw-flex tw-items-center">
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.ITEMS"></span>:
                                </div>
                            }
                            @for(item of transaction.sale_items_transactions; track $index;) {
                                <div [ngStyle]="item.quantity_difference > 0 ? { color: 'green' } : item.quantity_difference < 0 ? { color: 'red' } : { color: 'grey' }" class="tw-font-normal">
                                    @if(item.quantity_difference > 0 || item.quantity_difference < 0) {
                                        <div class="tw-flex" [ngClass]="removeQuantity(item.sale_item_uuid, item.quantity_difference)">
                                            {{ item.quantity_difference > 0 ? "+" + item.quantity_difference : item.quantity_difference < 0 ? item.quantity_difference : "" }} x {{item_half_portion[item.sale_item_uuid] ? "1/2" : ""}} {{ item.sale_item_details.name || items[item.sale_item_uuid] }}
                                        </div>
                                    }
                                    @if(item.quantity_difference == 0 && !last) {
                                        <div class="tw-flex tw-items-center">
                                            <mat-icon class="tw-scale-[0.8]">edit</mat-icon>
                                            <div>{{item.sale_item_details.half_portion ? hasHalfPortion(item) : item_half_portion[item.sale_item_uuid] ? "1/2" : ""}} {{item.sale_item_details.name || items[item.sale_item_uuid]}}</div>
                                        </div>
                                    }
                                    @if(item.sale_item_details.variations) {
                                        @for(variation of item.sale_item_details.variations; track $index;) {
                                            <div [ngStyle]="{'padding-left': '1.8em'}">
                                                <div [ngStyle]="variation.deleted_at && {'text-decoration': 'line-through'}">
                                                    {{variation.name}}: {{variation.value}}
                                                </div>
                                            </div>
                                        }
                                    }
                                    @if(item.sale_item_details.notes) {
                                        <div [ngStyle]="item.quantity_difference > 0 ? {color:'green'} : item.quantity_difference < 0 ? {color:'red'} : {color:'grey'}" 
                                            style="padding-left: 1.8em;">
                                            {{item.sale_item_details.notes}}
                                        </div>
                                    }
                                    @if(item.quantity_difference > 0 || item.quantity_difference < 0) {
                                        <div [ngStyle]="item.quantity_difference > 0 ? {color:'green'} : {color:'red'}">
                                            @if(variations[item.sale_item_uuid] && !item.sale_item_details.variations) {
                                                @for(variation of variations[item.sale_item_uuid]; track $index;) {    
                                                    <div [ngStyle]="{'padding-left': '1.8em'}">
                                                        <div [ngStyle]="variation.deleted_at && {'text-decoration': 'line-through'}" 
                                                            [ngClass]="items_removed[item.sale_item_uuid] ? 'tw-line-through' : ''">
                                                            {{variation.name}}: {{variation.value}}
                                                        </div>
                                                    </div>
                                                }
                                            }
                                            @if(notes[item.sale_item_uuid] && !item.sale_item_details.notes) {
                                                <div [ngStyle]="item.quantity_difference > 0 ? {color:'green'} : item.quantity_difference < 0 ? {'color':'red'} : {color:'grey'}" 
                                                    [ngClass]="items_removed[item.sale_item_uuid] ? 'tw-line-through' : ''"
                                                    style="padding-left: 1.8em;">
                                                    {{notes[item.sale_item_uuid]}}
                                                </div>
                                            }
                                            @for(ingredient of item.sale_item_details.ingredients || ingredients_items[item.sale_item_uuid]; track $index;) {
                                                <div [ngClass]="items_removed[item.sale_item_uuid] ? 'tw-line-through' : ''" style="padding-left: 1.8em;">
                                                    {{manageIngredients(ingredient, item.sale_item_uuid)}}
                                                    {{ingredient.type === 'removed' ? '-' : '+'}}{{ingredient.quantity > 1 ? ingredient.quantity : ''}} {{ingredient.name}}
                                                </div>
                                            }
                                        </div>
                                    }
                                    @if(item.quantity_difference === 0) {
                                        @for(ingredient of (item.sale_item_details.ingredients !== undefined && item.sale_item_details.ingredients.length > 0) ? item.sale_item_details.ingredients : ingredients_items[item.sale_item_uuid]; track $index;) { 
                                            <div>
                                                <span [ngStyle]="{color:'grey'}" [ngClass]="ingredient.quantity === 0 ? 'tw-line-through' : ''" style="padding-left: 1.8em;">
                                                    {{manageIngredients(ingredient, item.sale_item_uuid)}}
                                                    {{ingredient.type === 'removed' ? '-' : '+'}}{{ingredient.quantity > 1 ? ingredient.quantity : ''}} {{ingredient.name}}
                                                </span>
                                            </div>
                                        }
                                    }
                                </div>
                            }
                            @if(transaction.sale_details?.sent_exits && !last) {
                                <div>
                                    <span translate="HISTORY.TRANSACTIONS_VIEWER.SENT_EXITS"></span>:
                                    @for(exit of transaction.sale_details?.sent_exits | keyvalue; track $index; let last = $last) {
                                        {{exit.key}}{{!last ? ", " : ""}}
                                    }
                                </div>
                            }
                        }
                    </mat-list-item>
                    <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5 tw-text-right">
                        <div matListItemLine>
                            {{ transaction.created_at | tilbyDate : "HH:mm:ss" }}
                        </div>
                        <div matListItemLine>
                            {{ transaction.created_at | tilbyDate : "dd/MM/yyyy" }}
                        </div>
                        <div matListItemLine class="tw-flex tw-items-center tw-justify-end">
                            <mat-icon>person</mat-icon>
                            {{ transaction.operator_name }}
                        </div>

                        @if(transaction.printed) {
                            <mat-icon class="material-symbols-outlined">print_connect</mat-icon>
                        }
                        @if(transaction.skip_printing) {
                            <mat-icon class="material-symbols-outlined">step_over</mat-icon>
                        }
                    </mat-list-item>
                </div>
            }
            @if(transaction!.sale_items_transactions!.length > 0 || transaction.sale_details?.sent_exits || transaction.sale_details?.bill_lock || transaction.sale_details?.printed_prebills === 1 || i>= 2 && (data.transactions[i].sale_details?.table_name !== data.transactions[i-1].sale_details?.table_name)){
                <mat-divider></mat-divider>
            }
        </ng-container>
        @if(data.transactions.length === 2){
            <div class="tw-container tw-flex">
                <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5 tw-min-h-[100px]">
                    <div matListItemTitle>
                        <span translate="HISTORY.TRANSACTIONS_VIEWER.STATUS"></span>:
                        {{data.transactions[data.transactions.length-1]!.sale_details?.status}}
                    </div>
                </mat-list-item>
                <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5 tw-text-right">
                    <div matListItemLine>
                        {{ data.transactions[data.transactions.length-1].created_at | tilbyDate : "HH:mm:ss" }}
                    </div>
                    <div matListItemLine>
                        {{ data.transactions[data.transactions.length-1].created_at | tilbyDate : "dd/MM/yyyy" }}
                    </div>
                    <div matListItemLine class="tw-flex tw-items-center tw-justify-end">
                        <mat-icon>person</mat-icon>
                        {{ data.transactions[data.transactions.length-1].operator_name }}
                    </div>

                    @if(data.transactions[data.transactions.length-1].printed) {
                        <mat-icon class="material-symbols-outlined">print_connect</mat-icon>
                    }
                    @if(data.transactions[data.transactions.length-1].skip_printing) {
                        <mat-icon class="material-symbols-outlined">step_over</mat-icon>
                    }
                </mat-list-item>
            </div>
        }
    </mat-list>
</tilby-dialog-content>