import * as angular from 'angular';
import * as _ from 'lodash';
import { FiscalPrintersService } from './service/fiscal-printers/fiscal-printers';

angular.module('printers', ['application']);

angular.module('printers').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {

    $translatePartialLoaderProvider.addPart('printers');

    $stateProvider.state('app.printers', {
        url: '/printers',
        views: {
            "appContent@app": {
                template: require('./partial/printers/printers.html'),
                controller: 'PrintersCtrl'
            }
        },
        resolve: {
            printers: ["entityManager", function(entityManager) {
                var query = {
                    pagination: false
                };
                return entityManager.printers.fetchCollectionOnline(query).then(function(result) {
                    return result || [];
                });
            }],
            billingData: ["restManager", function(restManager) {
                return restManager.getOne('billing_data').then(function(bData) {
                    return bData;
                }, function(error) {
                    return _.get(error, ['data', 'error', 'message', 'owner_data']) || null;
                });
            }],
        }
    });

    $stateProvider.state('app.printers.new', {
        url: '/new',
        params: {
            // fiscal / nonfiscal
            type: null,
            // epson / rch required only for fiscal
            manifacturer: null,
        },
        controller: 'PrintersNewCtrl',
        template: require('./partial/printers-new/printers-new.html')
    });

    $stateProvider.state('app.printers.detail', {
        url: '/detail/:id',
        controller: 'PrintersDetailCtrl',
        template: require('./partial/printers-detail/printers-detail.html'),
        resolve: {
            printer: ["entityManager", "$stateParams", function(entityManager, $stateParams) {
                return entityManager.printers.fetchOneOnline($stateParams.id);
            }],
            categories: ["entityManager", "printer", function(entityManager, printer) {
                return (printer.type === 'nonfiscal') ? entityManager.categories.fetchCollectionOffline() : [];
            }],
            rooms: ["entityManager", "printer", function(entityManager, printer) {
                return (printer.type === 'nonfiscal') ? entityManager.rooms.fetchCollectionOffline() : [];
            }],
            items: ["entityManager", "printer", function(entityManager, printer) {
                if (printer.type === 'nonfiscal') {
                    return entityManager.items.fetchCollectionOffline().then(function(items) {
                        return _(items)
                            .filter(function(item) { return _.some(item.printers, { id: printer.id }); })
                            .map(function(item) { return { id: item.id, name: item.name, _enabled: true }; }).value();
                    });
                } else {
                    return {};
                }
            }],
            options: ["checkManager", "printer", function(checkManager, printer) {
                let options = {};
                
                if (printer.type !== 'nonfiscal') {
                    options.display_message = checkManager.getPreference('fiscalprinter.display_message') || "";

                    // each option has to be defined, or won't be replaced in printer config
                    _.times(13, function(idx) {
                        options['header' + (idx + 1)] = checkManager.getPreference('fiscalprinter.printer_header_' + (idx + 1)) || "";
                    });
    
                    _.times(6, function(idx)  {
                        options['invoice_footer' + (idx + 1)] = checkManager.getPreference('fiscalprinter.printer_invoice_footer_' + (idx + 1)) || "";
                    });
                }

                return options;
            }],
            departments: ["entityManager", "printer", function(entityManager, printer) {
                return (printer.type === 'nonfiscal') ? [] : entityManager.departments.fetchCollectionOffline();
            }],
            vat: ["entityManager", "printer", function(entityManager, printer) {
                return (printer.type === 'nonfiscal') ? [] : entityManager.vat.fetchCollectionOffline();
            }],
            paymentMethods: ["entityManager", "printer", function(entityManager, printer) {
                return (printer.type === 'nonfiscal') ? [] : entityManager.paymentMethods.fetchCollectionOffline();
            }]
        }
    });

    $stateProvider.state('app.printers.general', {
        url: '/general',
        controller: 'PrintersGeneralCtrl',
        template: require('./partial/printers-general/printers-general.html'),
        resolve: {
            preferences: ["checkManager", function(checkManager) {
                var fiscalprinter = {};
                var nofiscalprinter = {};

                var fiscalPreferences = [
                    'ticket_change',
                    'print_notes',
                    'print_description',
                    'print_name',
                    'print_barcode_id',
                    'print_sale_qr_code',
                    'print_customer_details',
                    'print_seller',
                    'print_daily_read_on_closing',
                    'display_message',
                    'seller_prefix',
                    'tail',
                    'qr_code_message',
                    'qr_code_url'
                ];

                for (var i = 1; i <= 13; i++) {
                    fiscalPreferences.push('printer_header_' + i);
                }

                for (i = 1; i <= 6; i++) {
                    fiscalPreferences.push('printer_invoice_footer_' + i);
                }

                var nofiscalPreferences = [
                    'goExit_skip_content'
                ];

                _.forEach(fiscalPreferences, function(preference) {
                    fiscalprinter[preference] = checkManager.getPreference('fiscalprinter.' + preference);
                });

                _.forEach(nofiscalPreferences, function(preference) {
                    nofiscalprinter[preference] = checkManager.getPreference('nofiscalprinter.' + preference);
                });

                return {
                    'fiscalprinter': fiscalprinter,
                    'nofiscalprinter': nofiscalprinter
                };
            }]
        }
    });
}])
.service('FiscalPrinters', FiscalPrintersService);