import {Injectable, Injector, inject} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import { SalesItems} from 'tilby-models';
import {Printers} from "tilby-models";
import {AlertDialogComponent} from '../alert-dialog';
import {
    DataConfirmDialog,
} from 'src/app/dialogs';
import {
    InputItemDataMagicForm,
} from '../../shared/model/model';
import {
    DataEditBadgeId,
    DataFidelityMovementDetailsDialog,
    DataPrepaidMovementDetailsDialog,
    DataRadioListSelectorDialog,
    DataRefundItemsSelectorDialog
} from '../dialog.model';

import {GridDownloadDialogComponent} from '../grid-download-dialog';
import {Fidelity_pointsMovementDetailsDialogComponent} from '../fidelity_points-movement-details-dialog';
import {Prepaid_creditMovementDetailsDialogComponent} from '../prepaid_credit-movement-details-dialog';
import {GridService} from '../../shared/components/grid/grid.service';
import {EmailExportDialogComponent} from '../email-export-dialog';
import {RadioListSelectorDialogComponent} from '../radio-list-selector-dialog';
import {ObjDataData} from 'src/app/features/dashboard/models/dashboard.model';
import {FiscalPrinterDashboardDialogComponent} from "../fiscal-printer-dashboard-dialog";
import {GeneralDocumentPrinterSelectorDialogComponent} from "../general-document-printer-selector-dialog";
import {DailyClosingDialogComponent, DailyClosingDialogData} from "../daily-closing-dialog";
import {RefundItemsSelectorDialogComponent} from '../refund-items-selector-dialog';
import { NonNullableObjectField} from 'src/app/models';
import {EditRowsDialogWithMagicFormComponent} from "../edit-rows-dialog-with-magic-form";
import {POST} from "../../features";
import { InsertNewDeviceWaiterDialogComponent } from '../insert-new-device-waiter-dialog';
import { GenericJsonDetailDialogComponent, GenericJsonDialogData } from '../generic-json-detail-dialog';
import {MagicFormData, MagicFormDialogComponent} from "../magic-form-dialog";
import {MatBottomSheet, MatBottomSheetConfig} from '@angular/material/bottom-sheet';
import {BottomSheetData, BottomSheetItem, GenericBottomSheetComponent} from "../generic-bottom-sheet";
import { GenericListDialogComponent } from '../generic-list-dialog';
import { GenericListData, GenericListDialogMessage, GenericListItem } from '../generic-list-dialog/generic-list-dialog.model';
import {CustomFormGroup} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {Dimensions, NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import {
    AddNewStaticTokenDialogComponent,
    AddNewUserDialogComponent,
    CopyNewStaticTokenDialogComponent,
    EditIdBadgeDialogComponent,
    EditEmailDialogComponent,
    EditPhoneDialogComponent,
    EditUiLanguageDialogComponent,
    EditPinDialogComponent,
    LinkToDeliverectDialogComponent,
    MagicPortalDialogComponent,
    MagicPortalDialogData,
    SaleDocumentsViewerDialogComponent,
    SupportDialogComponent,
    OfflineMessageComponent,
    ReceiptOptionsDialogComponent,
    EInvoiceDialogComponent,
    LotteryCodeDialogComponent,
    RoundTotalDialogComponent,
    NexiDialogComponent,
    SatispayDialogComponent,
    PayPalDialogComponent,
    CurrencyConverterDialogComponent,
    AddTimeSlotDialogComponent,
    ItemLabelsDialogComponent
} from 'src/app/dialogs';

import { CashregisterStockDialogComponent, CashregisterStockDialogData } from '../cashregister/cashregister-stock-dialog';
import { RoomElementShapeGridComponent } from 'src/app/features/tables/components/room-element-shape-grid';
import { DevLogger } from 'src/app/shared/dev-logger';
import { DocumentPrinterManagerDialogComponent } from '../document-printers-manager-dialog';
import { OrderPrinterSelectDialogComponent } from '../order-printer-select-dialog';
import { HistoricalChangesDialogComponent } from '../historical-changes-dialog/historical-changes-dialog.component';
import { RefundOrRemoveItemDialogComponent } from '../refund-or-remove-item-dialog';
import { CashMovementDialogComponent } from '../cash-movement/cash-movement.dialog.component';
import { documentPrinter } from 'app/ajs-upgraded-providers';
import { AddBookingsTimeDialogComponent } from '../add-bookings-time-dialog';

export type PortalConfigData<BaseData,BaseComponent extends DialogsComponents> = BaseData&MagicPortalDialogData<BaseComponent>;
export type NonNullablePortalConfigData<BaseData,BaseComponent extends DialogsComponents> = NonNullableConfigData<PortalConfigData<BaseData,BaseComponent>>;

type NonNullableConfigBottomSheetData<T> = NonNullableObjectField<MatBottomSheetConfig<T>, 'data'>;

export type DialogsComponents =
    AlertDialogComponent |
    CashMovementDialogComponent |
    CurrencyConverterDialogComponent |
    DocumentPrinterManagerDialogComponent |
    EditRowsDialogWithMagicFormComponent |
    EInvoiceDialogComponent |
    Fidelity_pointsMovementDetailsDialogComponent |
    GridDownloadDialogComponent |
    HistoricalChangesDialogComponent |
    ItemLabelsDialogComponent |
    LotteryCodeDialogComponent |
    MagicFormDialogComponent |
    NexiDialogComponent |
    OfflineMessageComponent |
    OrderPrinterSelectDialogComponent |
    PayPalDialogComponent |
    Prepaid_creditMovementDetailsDialogComponent |
    RadioListSelectorDialogComponent |
    ReceiptOptionsDialogComponent |
    RefundOrRemoveItemDialogComponent |
    RoomElementShapeGridComponent |
    RoundTotalDialogComponent |
    SaleDocumentsViewerDialogComponent |
    SatispayDialogComponent |
    SupportDialogComponent |
    AddTimeSlotDialogComponent |
    AddBookingsTimeDialogComponent

@Injectable({
    providedIn: 'root'
})
//IMPORTANT!!! Callback functions need @autobind or .bind(this) to bring the right context inside the service
export class OpenDialogsService {
    width?:string ;
    height?:string;

    private _snackBar = inject(MatSnackBar);
    private breakpointObserver = inject(BreakpointObserver);
    private dialog = inject(MatDialog);
    private _bottomSheet = inject(MatBottomSheet);
    private translate = inject(TranslateService);
    private gridService = inject(GridService);
    private injector = inject(Injector);

    constructor(
    ) {
        this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(size=>{
            this.width = size.matches? '100vw':undefined;
            this.height = size.matches? '100vh':undefined;
            this.log('XSMALL',this.width,this.height)
        });
    }

    private log(...args:any[]) {
        DevLogger.log('OPEN_DIALOGS_SERVICE',...args);
    }

    /**
     * Switches between mobile and desktop dimensions.
     *
     * @param {Dimensions} [dim] - The dimensions object containing width, height, minWidth, maxWidth, and maxHeight properties.
     * @param fullScreenForMobile - Set Full Screen Dialog In Mobile View
     * @returns {Object} - The updated dimensions object with width, height, minWidth, maxWidth, and maxHeight properties.
     */
    public switchMobileDesktopDimensions(dim?:Dimensions, options?:{fullScreenForMobile?: boolean}){
        const {width=dim?.width,height=dim?.height}=(options?.fullScreenForMobile?this:{});
        return {
            ...(!!width&&{width}),
            ...(dim?.minWidth&&{minWidth:dim.minWidth}),
            ...(!!height&&{height}),
            maxWidth:dim?.maxWidth||'100vw',
            maxHeight:dim?.maxHeight||'100vh'
        };
    }

    openPortalDialogRef<Component extends DialogsComponents,Input,Output=Input>(config:NonNullableConfigData<Input>) {
        return this.dialog.open<MagicPortalDialogComponent<Component>,Input,Output>(MagicPortalDialogComponent, {disableClose:true, ...config});
    }
    openPortalDialog<Component extends DialogsComponents,Input,Output=Input>(config:NonNullableConfigData<Input>) {
        return lastValueFrom(this.openPortalDialogRef<Component,Input,Output>(config).afterClosed());
    }

    openCreateTable(config?:NonNullableConfigData<{}>){
        const configPortal:NonNullablePortalConfigData<{},RoomElementShapeGridComponent> = {...config,data:{titleLabel: 'Scegli Formato', component: RoomElementShapeGridComponent, hideConfirm: true}};
        return this.openPortalDialog<RoomElementShapeGridComponent, {}, BottomSheetItem>({...configPortal});
    }
    openOfflineDialog(config?:NonNullableConfigData<{}>){
        const configPortal:NonNullablePortalConfigData<{},OfflineMessageComponent>={...config,data:{   titleLabel:'DIALOG.OFFLINE.TITLE', component:OfflineMessageComponent, hideConfirm:true}};
        return this.openPortalDialog<OfflineMessageComponent,{},never>({...configPortal});
    }
    openEditRowsDialog<T extends Partial<Record<keyof T, any>>,Multi=never>(config:NonNullableConfigData<InputItemDataMagicForm<T,Multi>>) {
        const setNewValue = (data:InputItemDataMagicForm<T,Multi>)=> {
                data.form.markAllAsTouched();
                if (data.form.valid) {
                   return data.form.value as T;
                }
        }
        const configPortal:NonNullablePortalConfigData<InputItemDataMagicForm<T,Multi>,EditRowsDialogWithMagicFormComponent>={...config,data: {...config.data,component:EditRowsDialogWithMagicFormComponent,titleLabel:'DIALOG.EDIT_ROWS.TITLE',confirmDisabled:()=>config.data.form.invalid}};
        return this.openPortalDialog<EditRowsDialogWithMagicFormComponent,InputItemDataMagicForm<T,Multi>>({
            ...this.switchMobileDesktopDimensions({width:'800px'}),
            ...configPortal
        }).then(res=>res?setNewValue(res):res);
    }
    openPrepaidMovementDetailsDialog(config:NonNullableConfigData<DataPrepaidMovementDetailsDialog>) {
        const configPortal:NonNullablePortalConfigData<DataPrepaidMovementDetailsDialog,Prepaid_creditMovementDetailsDialogComponent>={...config,data: {...config.data,component:Prepaid_creditMovementDetailsDialogComponent,titleLabel:config.data.movement.id ? 'PREPAID.ADD_SHOW_MOVEMENT.MOVEMENT_DETAILS' : 'PREPAID.ADD_SHOW_MOVEMENT.NEW_MOVEMENT',dontCloseDialogOnConfirm$:new BehaviorSubject<boolean>(true),hideConfirm:!!config.data.movement.id}};
        return this.openPortalDialog<Prepaid_creditMovementDetailsDialogComponent,DataPrepaidMovementDetailsDialog,POST.PrepaidMovement | 'Cancel' | 'Close'>(
            {...this.switchMobileDesktopDimensions(), ...configPortal}
        ).then(result=>{
                if (typeof result == 'object') return result;
                // Flash on close
                else if (result === 'Close') setTimeout(() => this.gridService.prepaid$.flash.next(null), 0);
            });
    }
    openFidelityMovementDetailsDialog(config:NonNullableConfigData<DataFidelityMovementDetailsDialog>) {
        const configPortal:NonNullablePortalConfigData<DataFidelityMovementDetailsDialog,Fidelity_pointsMovementDetailsDialogComponent>={...config,data: {...config.data,component:Fidelity_pointsMovementDetailsDialogComponent,titleLabel:config.data.movement.id ? 'PREPAID.ADD_SHOW_MOVEMENT.MOVEMENT_DETAILS' : 'PREPAID.ADD_SHOW_MOVEMENT.NEW_MOVEMENT',dontCloseDialogOnConfirm$:new BehaviorSubject<boolean>(true),hideConfirm:!!config.data.movement.id}};
        return this.openPortalDialog<Fidelity_pointsMovementDetailsDialogComponent, DataFidelityMovementDetailsDialog,POST.FidelityMovement | 'Cancel' | 'Close'>({...this.switchMobileDesktopDimensions(),...configPortal})
            .then(result=>{
                    if (typeof result == 'object') return result;
                    // Flash on close
                    else if (result === 'Close') setTimeout(() => this.gridService.fidelity$.flash.next(null), 0);
            });

    }
    openEmailExportDialog(config:NonNullableConfigData<DataConfirmDialog>) {
        const configPortal:NonNullablePortalConfigData<DataConfirmDialog,EmailExportDialogComponent>=
            {...config,
                data: {...config.data,
                    titleLabel:config.data.messageLabel,
                    component:EmailExportDialogComponent,
                    dontCloseDialogOnConfirm$:new BehaviorSubject<boolean>(true)
            }};
        return this.openPortalDialog<EmailExportDialogComponent, DataConfirmDialog,{email:string}>(
            {...this.switchMobileDesktopDimensions({minWidth: '30vw'}),...configPortal}
        );
    }

    openMagicFormDialog<Title extends string,Form extends CustomFormGroup<any>,Output>(config:NonNullableConfigData<MagicFormData<Title,Form>>) {
        const configPortal:NonNullablePortalConfigData<MagicFormData<Title,Form>,MagicFormDialogComponent>={...config,data: {...config.data,component:MagicFormDialogComponent,titleLabel:config.data.title,hideConfirm:!config.data.buttonConfirmLabel,hideCancel:!config.data.buttonCancelLabel, confirmDisabled:()=>config.data.form.invalid}};
        return this.openPortalDialog<MagicFormDialogComponent,MagicFormData<Title,Form>>({...this.switchMobileDesktopDimensions(),...configPortal}).then(res=> {
            console.log('RES', res);
            return res?.form?.value as Output;
        });
    }
    ///TODO WITH PORTAL
    openRadioListSelectorDialog(config:NonNullableConfigData<DataRadioListSelectorDialog>) {
        return lastValueFrom(this.dialog.open<RadioListSelectorDialogComponent, DataRadioListSelectorDialog, any>(RadioListSelectorDialogComponent, {
                ...this.switchMobileDesktopDimensions(),
                ...config
            }).afterClosed());
    }
    ///TODO WITH PORTAL
    openGenericJsonDetailDialog(config:MatDialogConfig<GenericJsonDialogData>) {
        return lastValueFrom(this.dialog.open(GenericJsonDetailDialogComponent, {...this.switchMobileDesktopDimensions(), panelClass: 'no-padding-dialog-container',...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    openFiscalPrinterDashboardDialog(config:NonNullableConfigData<ObjDataData>) {
        return lastValueFrom(this.dialog.open(FiscalPrinterDashboardDialogComponent, {...this.switchMobileDesktopDimensions(), panelClass: 'no-padding-dialog-container', ...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    openGeneralDocumentPrinterSelectorDialog(config:NonNullableConfigData<{ titleLabel: string }>) {
        return lastValueFrom(this.dialog.open<GeneralDocumentPrinterSelectorDialogComponent,{ titleLabel: string },Printers>(GeneralDocumentPrinterSelectorDialogComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: 'no-padding-dialog-container',
            ...config
        }).afterClosed());
    }

    ///TODO WITH PORTAL
    async openDailyClosingDialog(config:NonNullableConfigData<Printers>) {
        const printer=config.data;
        const dailyClosingDialogData:DailyClosingDialogData = {printer};
        const dailyClosingConfig= await lastValueFrom(this.dialog.open<DailyClosingDialogComponent, DailyClosingDialogData,{ cashVerification: number }|'cancel'>(DailyClosingDialogComponent, {...this.switchMobileDesktopDimensions({minWidth: '30vw'}), ...config,disableClose:true,data:dailyClosingDialogData}).afterClosed());
        if(dailyClosingConfig && dailyClosingConfig!=='cancel') {
            const documentPrinterService = this.injector.get(documentPrinter);
            return documentPrinterService.dailyClosing(printer, dailyClosingConfig);
        }
    }
    ///TODO WITH PORTAL
    openRefundItemsSelectorDialog(config:NonNullableConfigData<DataRefundItemsSelectorDialog>) {
        return lastValueFrom(this.dialog.open<RefundItemsSelectorDialogComponent,DataRefundItemsSelectorDialog,SalesItems[]|'Cancel'>(RefundItemsSelectorDialogComponent, {...this.switchMobileDesktopDimensions(), ...config}).afterClosed())
            .then(res=>(typeof res === 'object') && res);
       }
    ///TODO WITH PORTAL
    openSnackBarTilby(message: string, action: string,{duration}: MatSnackBarConfig={duration:0},config?:MatSnackBarConfig) {
        this._snackBar.open(this.translate.instant(message), this.translate.instant(action),{horizontalPosition: 'start', verticalPosition: 'bottom', panelClass:['mat-snack-bar-tilby'],duration,...config});
    }
    ///TODO WITH PORTAL
    openInsertNewDeviceWaiterDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open<InsertNewDeviceWaiterDialogComponent>(InsertNewDeviceWaiterDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    addNewStaticTokenDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open(AddNewStaticTokenDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config }).afterClosed());
    }
    ///TODO WITH PORTAL
    copyNewStaticTokenDialog(config:NonNullableConfigData<{ token: string }>) {
        return lastValueFrom(this.dialog.open(CopyNewStaticTokenDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config }).afterClosed());
    }
    ///TODO WITH PORTAL
    linkToDeliverectDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open(LinkToDeliverectDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config }).afterClosed());
    }
    ///TODO WITH PORTAL
    addNewUserDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open(AddNewUserDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config }).afterClosed());
    }
    ///TODO WITH PORTAL
    editBadgeIdDialog(config:NonNullableConfigData<DataEditBadgeId>) {
        return lastValueFrom(this.dialog.open(EditIdBadgeDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config }).afterClosed());
    }
    ///TODO WITH PORTAL
    openGenericBottomSheet(config:NonNullableConfigBottomSheetData<BottomSheetData>) {
        return lastValueFrom(this._bottomSheet.open<GenericBottomSheetComponent,BottomSheetData,BottomSheetItem>(GenericBottomSheetComponent, {...config}).afterDismissed());
    }
    ///TODO WITH PORTAL
    openGenericListDialog<T extends GenericListDialogMessage,O extends GenericListItem>(config:NonNullableConfigData<GenericListData<T,O>>) {
        return lastValueFrom(this.dialog.open<GenericListDialogComponent,GenericListData<T,O>,O>(GenericListDialogComponent, { ...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    openEditEmailDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open(EditEmailDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    openEditPhoneDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open(EditPhoneDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    openEditUiLanguageDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open(EditUiLanguageDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    openEditPinDialog(config?:MatDialogConfig) {
        return lastValueFrom(this.dialog.open(EditPinDialogComponent, { ...this.switchMobileDesktopDimensions({ width: '800px' }), ...config}).afterClosed());
    }
    ///TODO WITH PORTAL
    openStockDialog<Title extends string,Form extends CustomFormGroup<any>,Output>(config:NonNullableConfigData<CashregisterStockDialogData>) {
        return lastValueFrom(this.dialog.open<CashregisterStockDialogComponent,CashregisterStockDialogData,Output>(CashregisterStockDialogComponent, {...this.switchMobileDesktopDimensions(),...config}).afterClosed());
    }

}
