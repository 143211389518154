import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { $state, restManager } from "app/ajs-upgraded-providers";
import { ToolbarEventsService, UserActiveSessionManagerService } from "src/app/core";
import { ToolbarEventsContextService } from "src/app/core/services/toolbar-events/toolbar-events-context.service";
import {
    AlertDialogService,
    OpenDialogsService
} from "src/app/dialogs";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import * as moment from 'moment-timezone';



@Component({
    selector: 'app-file-importer-schedule',
    templateUrl: './settings-file-importer-schedule.component.html',
    styleUrls: ['./settings-file-importer-schedule.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, MatProgressBarModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule],
})
export class FileImporterScheduleComponent { 
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly restManagerService = inject(restManager);
    private readonly state = inject($state);
    private readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);

    @Input() fileConfig!: any;
    fileImporterScheduleForm: FormGroup = new FormGroup({ import_name: new FormControl('', Validators.required) });
    isProcessing: boolean = false;
    scheduleType = 'now';
    scheduleDateTime = moment().milliseconds(0).seconds(0).toDate();

    ngOnInit(): void {
        if(!Object.keys(this.fileConfig).includes('typeInsert')) {
            this.state.go('app.new.file_importer');
            return;
        }

        const createToolbarButtons = () => {
            this.toolbarEventsService.moduleTitle.next("NEW_IMPORTER");
            this.toolbarEventsService.showButtonsBar$.next(true);
            this.toolbarEventsContextService.showToolbarContext$.next(true);
            this.toolbarEventsContextService.buttons$.next({
                barButtons: [
                    {
                        isIt: () => true,
                        isDisable: () => false,
                        name: 'saveFileImporterScheduleForm',
                        icon: () => 'check',
                        click: () =>  this.confirm()
                    }
                ],
                panelButtons: []
            })
            this.toolbarEventsContextService.backButton$.next({
                isIt: () => true,
                name: "arrow_back",
                icon: () => "arrow_back",
                click: () => this.back()
            });
        };

        createToolbarButtons();
    }

    confirm = () => {
        if(this.fileImporterScheduleForm.valid) {
            if(!this.isProcessing) {
                this.isProcessing = true;
                this.fileImporterScheduleForm.controls.import_name.disable();

                let dataToSend = structuredClone(this.fileConfig);

                dataToSend = {
                    ...dataToSend,
                    name: this.fileImporterScheduleForm.controls.import_name.value
                }
    
                switch(this.scheduleType) {
                    case 'now':
                        dataToSend = {
                            ...dataToSend,
                            executionTime: 'now',
                            executionTimePeriods: null
                        }
                    break;
                    case 'at_time':
                        dataToSend = {
                            ...dataToSend,
                            executionTime: moment(this.scheduleDateTime).toISOString(),
                            executionTimePeriods: null
                        }
                    break;
                    case 'repeatedly':
                        dataToSend = {
                            ...dataToSend,
                            executionTime: moment(this.scheduleDateTime).toISOString(),
                        }
                    break;
                }
    
                this.restManagerService.post('importing/token', { token: "Bearer " + this.userActiveSessionManagerService.getSession()?.oauthdata.access_token }).then(() => {
                    return this.restManagerService.put('importing/file', 'config', dataToSend);
                }).then(() => {
                    this.state.go('app.new.file_importer.showcase');
                    this.openDialogsService.openSnackBarTilby('FILE_IMPORTER.SCHEDULE.FILE_SAVE_SUCCESS', 'MISC.OK', { duration: 3000 });
                }).catch((error: any) => {
                    this.alertDialogService.openDialog({data:{messageLabel: 'FILE_IMPORTER.SCHEDULE.FILE_SAVE_ERROR'}});
                }).finally(() => {
                    this.isProcessing = false;
                    this.fileImporterScheduleForm.controls.import_name.enable();
                });
            }
        } else {
            this.alertDialogService.openDialog({data: {messageLabel: this.fileImporterScheduleForm.controls.import_name.hasError('required') ? 'FILE_IMPORTER.SCHEDULE.INSERT_REQUIRED_DATA' : 'FILE_IMPORTER.SCHEDULE.FILE_SAVE_ERROR'}});
        }
    };

    back() {
        this.state.go('app.new.file_importer.fields',{
            fileConfig: this.fileConfig,
            id: this.fileConfig.id
        })
        .then(() => {  
            this.toolbarEventsContextService.showToolbarContext$.next(false);
            this.toolbarEventsService.showButtonsBar$.next(false);
        })
    }
}