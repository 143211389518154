<div class="tw-text-center tw-flex tw-flex-col" [class.tw-mb-3]="timeoutTarget">
    <span class="tw-text-lg">{{data.message | translate}}</span>
    @if(data.secondaryMessage) {
        <span class="tw-text-xs">{{data.secondaryMessage}}</span>
    }
</div>
@if(timeoutTarget) {
    <mat-progress-bar class="tw-mt-1" mode="determinate" [value]="(timeoutProgress / timeoutTarget) * 100"></mat-progress-bar>
}
<!--<mat-dialog-actions class="tw-flex tw-items-end" *ngIf="data?.cancelAction||data?.customActions">-->
<!--    <button mat-button *ngIf="data?.cancelAction" [disabled]="!!data.cancelAction?.disabled" [mat-dialog-close]="{error:'CANCELED'}" cdkFocusInitial>{{data.cancelAction?.label||'cancel'}}</button>-->
<!--    <button mat-button *ngFor="let action of data?.customActions" (click)="action.callback()" [disabled]="!!action.disabled">{{action.label}}</button>-->
<!--</mat-dialog-actions>-->
