import {
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
    signal
} from '@angular/core';
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';

import {
    $state,
    sessionManager
} from 'app/ajs-upgraded-providers';

import { Subject, filter, takeUntil } from 'rxjs';

import {
    ConfigurationManagerService,
    ConfigurationManagerStoreService,
    ConfigurationPermissions,
    ConnectionService,
    EntityManagerService,
    StorageManagerService,
    ThemeModeService,
    UserActiveSessionManagerService
} from 'src/app/core';

import {
    AutomaticPrintSettingsDialogService,
    OpenDialogsService,
    PendingPrintsDialogService,
    QrcodeWaiterShortcutDialogService
} from 'src/app/dialogs';

import {
    SaleBatchPrintService
} from 'src/app/features';
import { AlvoloStatus } from 'src/app/features/settings/settings-alvolo/model/alvolo-status';
import { SettingsAlvoloStateService } from 'src/app/features/settings/settings-alvolo/service/settings-alvolo.state.service';


@Component({
    selector: 'app-fixed-nav-list',
    templateUrl: './fixed-nav-list.component.html',
    styleUrls: ['./fixed-nav-list.component.scss'],
})
export class FixedNavListComponent {
    //Injections
    private readonly automaticPrintSettingsDialogService = inject(AutomaticPrintSettingsDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly connectionService = inject(ConnectionService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly pendingPrintsDialogService = inject(PendingPrintsDialogService);
    private readonly qrcodeWaiterShortcutDialogService = inject(QrcodeWaiterShortcutDialogService);
    private readonly saleBatchPrintService = inject(SaleBatchPrintService);
    private readonly sessionManager = inject(sessionManager);
    private readonly storageManagerService = inject(StorageManagerService);
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);
    private readonly settingsAlvoloStateService = inject(SettingsAlvoloStateService);
    protected readonly state = inject($state);
    protected readonly themeModeService = inject(ThemeModeService);

    //Inputs
    @Input() currentRoute = '';

    //Outputs
    @Output() goTo = new EventEmitter<string>();
    @Output() menuClickOpenSettings = new EventEmitter<void>();
    @Output() automaticPrintOpenSettings = new EventEmitter<void>();

    private user = this.userActiveSessionManagerService.getSession()!;
    protected avatar = signal(`${this.user.first_name?.charAt(0).toUpperCase() || ''}${this.user.last_name?.charAt(0).toUpperCase() || ''}`);
    protected operatorName = this.user?.first_name && this.user?.last_name ? `${this.user.first_name} ${this.user.last_name}` : this.user.username;
    protected enableUserLogoutPin = this.configurationManagerService.getPreference('users.enable_user_logout_pin') || false;

    protected pendingPrints: number = 0;
    protected externalOrdersEnabled = this.saleBatchPrintService.isExternalOrdersPrintEnabled();

    private destroy$ = new Subject<void>();
    protected externalSalesEnabled = this.saleBatchPrintService.isExternalSalesPrintEnabled();
    protected firstDot: boolean = false;
    protected firstDotAlvolo: boolean = false;
    private modulesPermissions = this.configurationManagerService.getModulesStatus();
    protected enabledModules = this.modulesPermissions.enabledModules;
    protected secondDot: boolean = false;
    protected secondDotAlvolo: boolean = false;
    protected shopStatus: AlvoloStatus = {} as AlvoloStatus;
    
    public showShopStatusAlvolo = false;
    public waitFourSecondApiRateLimit = false;

    protected isTablesModuleEnabled: boolean = false;
    protected showButtons: boolean = false;
    protected shopName: string = '';
    protected showAutomaticPrinter = false;
    protected canExitUser = false;
    protected canSwitchUser = false;

    public isConnectionOffline = this.connectionService.isOffline;
    protected settingsEnabled = this.configurationManagerService.isFunctionUserEnabledOptin('settings.enabled');

    private async updatePendingPrints() {
        this.pendingPrints = (await this.storageManagerService.getCollectionSize('pending_prints'));
    }

    private initializeConfig() {
        this.isTablesModuleEnabled = this.configurationManagerService.isModuleEnabled('tables');
        this.showButtons = !!this.configurationManagerService.getPreference('migration.fix_navbar_buttons');
        this.shopName = this.configurationManagerService.getPreference('general.shopname') || this.user.shop!.name!;
        this.showAutomaticPrinter = !!this.configurationManagerService.getPreference('general.auto_print.enable');
        this.canExitUser = this.configurationManagerService.getPreference('users.enable_user_logout') !== false;
        this.canSwitchUser = this.configurationManagerService.getPreference('users.enable_user_switch') !== false;
    }

    private updateExternalPrintStatus() {
        this.externalOrdersEnabled = this.saleBatchPrintService.isExternalOrdersPrintEnabled();
        this.externalSalesEnabled = this.saleBatchPrintService.isExternalSalesPrintEnabled();

        if (this.externalOrdersEnabled && this.externalSalesEnabled) {
            this.firstDot = true;
            this.secondDot = true;
        } else if (!this.externalOrdersEnabled && !this.externalSalesEnabled) {
            this.firstDot = false;
            this.secondDot = false;
        } else if (this.externalOrdersEnabled || this.externalSalesEnabled) {
            this.firstDot = true;
            this.secondDot = false;
        }
    }

    private updateAlvoloStatus() {
        if (this.shopStatus.payload.sales_enabled && this.shopStatus.payload.bookings_enabled) {
            this.firstDotAlvolo = true;
            this.secondDotAlvolo = true;
        } else if (!this.shopStatus.payload.sales_enabled && !this.shopStatus.payload.bookings_enabled) {
            this.firstDotAlvolo = false;
            this.secondDotAlvolo = false;
        } else if (this.shopStatus.payload.sales_enabled || this.shopStatus.payload.bookings_enabled) {
            this.firstDotAlvolo = true;
            this.secondDotAlvolo = false;
        }

        setTimeout(() => {
            this.waitFourSecondApiRateLimit = false;
        }, 4000);
    }

    constructor() {
        this.initializeConfig();

        subscribeInComponent(ConfigurationManagerStoreService.shopPreferencesUpdate$, () => {
            this.initializeConfig();
        });

        //Init Alvòlo status
        const modulesPermissions = this.configurationManagerService.getModulesStatus();
        const alvolo_menu = modulesPermissions.enabledModules['alvolo_menu'] || false;

        if(alvolo_menu) {
            this.showShopStatusAlvolo = true;
            this.settingsAlvoloStateService.shopStatus$.subscribe((shopStatus) => {
                this.shopStatus = shopStatus;
                this.updateAlvoloStatus();
            });
        } else {
            this.showShopStatusAlvolo = false;
        }

        //Init external print status
        this.updateExternalPrintStatus();

        //Init print errors store
        this.updatePendingPrints();
    }

    ngOnInit() {
        this.settingsAlvoloStateService.shopStatus$
        .pipe(takeUntil(this.destroy$))
        .subscribe((shopStatus) => {
            this.shopStatus = shopStatus;
        });

        StorageManagerService.storageUpdates$.pipe(
            takeUntil(this.destroy$),
            filter((upd) => upd.entityName === 'pending_prints'),
        ).subscribe((upd) => this.updatePendingPrints());
    }

    showNewFeature(feature: string): boolean {
        return this.configurationManagerService.isModuleAngular(feature);
    }

    isUserPermitted(permissionName: keyof ConfigurationPermissions) {
        return this.configurationManagerService.isUserPermitted(permissionName);
    }

    logoutCloseSessionsDeep() {
        this.sessionManager.logoutCloseSessionsDeep(true);
    }

    logoutActiveUserSession() {
        this.sessionManager.logoutActiveUserSession(true);
    }

    switchUser() {
        this.state.go('app.lockScreen');
    }

    goToState(state: string) {
        this.state.go(state);
    }

    menuClick($event: MouseEvent) {
        // $event.stopPropagation();
        this.menuClickOpenSettings.emit();
    }

    openOfflineDialog() {
        return this.openDialogsService.openOfflineDialog();
    }

    openPrintErrorsDialog() {
        this.pendingPrintsDialogService.openDialog();
    }

    openQrcodeWaiterShortcutDialog() {
        this.qrcodeWaiterShortcutDialogService.openDialog();
    }

    automaticPrinterMenu() {
        this.automaticPrintOpenSettings.emit();
    }

    async openAutomaticPrintSettingsDialog() {
        const documentPrinters = await this.entityManagerService.printers.fetchCollectionOffline({ type_in: ['fiscal', 'rt', 'receipt'] });
        const nonFiscalDocuments = await this.entityManagerService.nonfiscalDocuments.fetchCollectionOffline();

        return this.automaticPrintSettingsDialogService.openDialog({data: {documentPrinters, nonFiscalDocuments}});
    }

    editAlvoloSale() {
        const payload = { 
            "payload": { 
                "sales_enabled": !this.shopStatus.payload.sales_enabled
            }, 
            "crc": this.shopStatus.crc
        }

        this.settingsAlvoloStateService.editAlvoloSale(payload);
        this.waitFourSecondApiRateLimit = true;
    }

    editAlvoloBookings() {
        const payload = { 
            "payload": { 
                "bookings_enabled": !this.shopStatus.payload.bookings_enabled
            }, 
            "crc": this.shopStatus.crc
        }

        this.settingsAlvoloStateService.editAlvoloBookings(payload);
        this.waitFourSecondApiRateLimit = true;
    }

    async onExternalPrintSettingChange(settingName: 'orders' | 'sales') {
        switch (settingName) {
            case 'sales':
                const defaultPrinterId = this.configurationManagerService.getShopPreference('cashregister.batch_sale_print.default_printer_id')
                const defaultDocumentId = this.configurationManagerService.getShopPreference('cashregister.batch_sale_print.default_document_id')

                if (!defaultPrinterId || !defaultDocumentId) {
                    const res = await this.openAutomaticPrintSettingsDialog();

                    if (!res) {
                        return;
                    }
                }

                this.saleBatchPrintService.setExternalSalesPrint(!this.saleBatchPrintService.isExternalSalesPrintEnabled());
                break;
            case 'orders':
                this.saleBatchPrintService.setExternalOrdersPrint(!this.saleBatchPrintService.isExternalOrdersPrintEnabled());
                break;
        }

        this.updateExternalPrintStatus();
    };

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
