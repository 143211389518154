@if(isMobilePortrait()){
    <mat-button-toggle-group [hideSingleSelectionIndicator]="true" appearance="standard" class="tw-transform tw-w-full tw-rounded-lg tw-text-sm tw-text-medium">
        <mat-button-toggle
            (click)="backActionButton!.click()"
            [value]="backActionButton!.name"
            class="tw-truncate tw-w-full"
            [ngClass]="backActionButton?.class||''">
            <div class="tw-flex tw-flex-col">
                <span *ngIf="backActionButton?.icon" class="material-symbols-rounded tw-mt-4">
                    <mat-icon>{{backActionButton?.icon()}}</mat-icon>
                </span>
                <span class="tw-ml-2 tw-mr-2 tw-truncate tw-w-[calc(100%-1rem)]">{{backActionButton!.name| translate}}</span>
            </div>
        </mat-button-toggle>
        <mat-button-toggle
            (click)="payAndPrintActionButton!.click()"
            [value]="payAndPrintActionButton!.name"
            [disabled]="payAndPrintActionButton?.isDisable?.()"
            class="tw-truncate tw-w-full tw-flex tw-justify-center tw-items-center"
            [ngClass]="payAndPrintActionButton?.class||''"
        >
            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
                @if(!isLoading) {
                    <span *ngIf="payAndPrintActionButton?.icon" class="material-symbols-rounded tw-mt-4">
                        <mat-icon>{{payAndPrintActionButton?.icon()}}</mat-icon>
                    </span>
                    <span class="tw-ml-2 tw-mr-2 tw-truncate tw-w-[calc(100%-1rem)]">{{payAndPrintActionButton!.name| translate}}</span>
                } @else {
                    <mat-spinner id="white-spinner" class="tw-h-10"></mat-spinner>
                }
            </div>
        </mat-button-toggle>
    </mat-button-toggle-group>
}
@else {
    <div class='tw-relative tw-border-solid tw-border tw-border-gray-200 tw-rounded-lg'>
        <div class="tw-flex tw-justify-between">
            <div class="tw-pl-4 tw-pr-1 tw-py-3">
                <div class="tw-pb-1 min-[1700px]:tw-text-[30px] min-[1500px]:tw-text-[25px] min-[1250px]:tw-text-[20px] tw-text-[16px]">{{'CASHREGISTER.PAYMENTS.TO_PAY' | translate}}</div>
                <div class="tw-pt-1 min-[1700px]:tw-text-[48px] min-[1500px]:tw-text-[40px] min-[1250px]:tw-text-[26px] tw-text-[26px]">{{saleToPay || 0 | tilbyCurrency}}</div>
            </div>
            <div class="tw-flex">
                <div class="tw-pt-[15px] tw-pb-0 tw-pr-4">
                    <div class="tw-p-1 min-[1700px]:tw-text-[30px] min-[1500px]:tw-text-[25px] min-[1250px]:tw-text-[20px] tw-text-[16px]">{{'CASHREGISTER.PAYMENTS.PAID' | translate}}</div>
                    <div class="tw-p-1 min-[1700px]:tw-text-[38px] min-[1500px]:tw-text-[32px] min-[1250px]:tw-text-[26px] tw-text-[20px]">{{salePaid || 0 | tilbyCurrency}}</div>
                </div>
                <div class="tw-pt-[15px] tw-pb-0 tw-pr-2">
                    <div class="tw-p-1 min-[1700px]:tw-text-[30px] min-[1500px]:tw-text-[25px] min-[1250px]:tw-text-[20px] tw-text-[16px]">{{'CASHREGISTER.PAYMENTS.CHANGE' | translate}}</div>
                    <div class="tw-p-1 min-[1700px]:tw-text-[38px] min-[1500px]:tw-text-[32px] min-[1250px]:tw-text-[26px] tw-text-[20px]">{{saleChange || 0 | tilbyCurrency}}</div>
                </div>
            </div>
        </div>
        <div>
            <button mat-flat-button appearance="standard" [ngClass]="payAndPrintActionButton?.isDisable?.() ? 'tw-bg-gray-300' : 'tilby-body-background-primary-color'" class="tw-py-4 tw-px-8 tw-h-full  tw-rounded-lg tw-font-semibold tw-text-sm tw-w-full tw-text-white" [disabled]="payAndPrintActionButton?.isDisable?.()" (click)="payAndPrintActionButton!.click()">
                @if(!isLoading) {
                    <span>{{'CASHREGISTER.PAYMENTS.PAY_AND_PRINT' | translate}}</span>
                } @else {
                    <mat-spinner class="tw-h-6"></mat-spinner>
                }
            </button>
        </div>
    </div>
}
