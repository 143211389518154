import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { validate as validateUuid, v4 as generateUuid } from 'uuid';
import { paymentMethodTypes } from 'src/app/core/constants';
import { BookingUtils } from 'src/app/shared/booking-utils.service';

angular.module('orders').factory('ActiveOrder', ActiveOrder);

ActiveOrder.$inject = ["$rootScope", "$state", "$translate", "entityManager", "NoFiscalPrinters", "documentPrinter", "orderItemManager", "radioListSelector", "printerErrorOrders", "connection", "checkManager", "util", "orderUtils", "saleUtils", "fiscalUtils", "FiscalPrinters", "alertDialog", "splitOrder", "newOrder", "toast", "addSelectCustomer", "OperatorManager", "ExternalOrdersManager"];

function ActiveOrder($rootScope, $state, $translate, entityManager, NoFiscalPrinters, documentPrinter, orderItemManager, radioListSelector, printerErrorOrders, connection, checkManager, util, orderUtils, saleUtils, fiscalUtils, FiscalPrinters, alertDialog, splitOrder, newOrder, toast, addSelectCustomer, OperatorManager, ExternalOrdersManager) {

    var creatingOrder = false;
    var itemsMap = {};

    var updateItemsMap = function() {
        itemsMap = _(activeOrder.currentOrder.order_items).groupBy('item_id').mapValues(function(itemGroup) {
            return {
                total: _(itemGroup).sumBy('quantity'),
                exits: _(itemGroup).groupBy('exit').mapValues(function(exitGroup) {
                    return _.sumBy(exitGroup, 'quantity');
                }).value()
            };
        }).value();
    };

    const saveOrderToStorage = async (order) => {
        let result;

        if(order.id) {
            result = await entityManager.orders.putOneOfflineFirst(order);
        } else {
            result = await entityManager.orders.postOneOfflineFirst(order);
        }

        $rootScope.$broadcast('activeOrder:order-saved', order);

        return result;
    };

    const getOrderPricelist = async () => {
        let priceListToUse;

        if(!_.isEmpty(activeOrder.currentOrder)) {
            const order = activeOrder.currentOrder;
            let orderRoom;

            let bookingShifts = await entityManager.bookingShifts.fetchCollectionOffline();
            let suggestedShift = BookingUtils.getSuggestedShift(bookingShifts, moment(order.open_at), order);
            let orderTypePriceList = _.toInteger(checkManager.getPreference(`orders.${order.type}_pricelist`));

            if(order.room_id) {
                try {
                    orderRoom = await entityManager.rooms.fetchOneOffline(order.room_id);
                } catch(err) {
                    //TODO: log warning
                }
            }

            //Search for customer pricelist
            if(order.order_customer?.default_pricelist) {
                priceListToUse = order.order_customer?.default_pricelist;
            } else if(orderRoom?.default_pricelist) {
                priceListToUse = orderRoom.default_pricelist;
            } else if(orderTypePriceList) {
                priceListToUse = orderTypePriceList;
            } else if (suggestedShift?.default_pricelist) {
                priceListToUse = suggestedShift.default_pricelist;
            }
        }

        return priceListToUse;
    };

    const determinePricelistToUse = async (skipNotify) => {
        let priceListToUse = await getOrderPricelist();

        $rootScope.$broadcast("orders:use-pricelist", { priceList: priceListToUse || 'default', skipNotify: skipNotify });
    };

    const askOperatorIfRequired = async () => {
        if (checkManager.getShopPreference('orders.ask_operator_for_each_order')) {
            await OperatorManager.changeOperator();
        }
    };

    const updateGroupItemsLinks = () => {
        const orderItems = activeOrder.currentOrder.order_items;

        if(orderItems) {
            let menuItemsUuids = _.chain(orderItems).filter({ is_group_item: true }).map('uuid').map((val, idx) => ({ value: val, index: (idx + 1) })).keyBy('value').value();

            for(let orderItem of orderItems) {
                let menuRef = menuItemsUuids[orderItem.uuid] || menuItemsUuids[orderItem.order_item_parent_uuid];

                orderItem.$menuIndex = menuRef?.index;
            }
        }
    };

    const getOrderItemToAdd = async (item, priceList, options) => {
        const opData = OperatorManager.getOperatorData();
        const targetDepartment = `department${_.toInteger(priceList) === 1 ? '' : priceList}`;
        const department = item[targetDepartment] || item['department'];

        //Get target price
        const isGroupItem = item.is_group_item || item.split_group_components;
        const menuMode = activeOrder.groupItemUuid && !isGroupItem;

        const orderItemTStamp = options?.date || new Date().toISOString();

        const orderItem = {
            added_at: orderItemTStamp,
            category_id: item.category?.id,
            category_name: item.category?.name,
            cost: item.cost,
            department_id: department?.id,
            department_name: department?.name,
            exit: isGroupItem ? null : (options?.exit !== undefined ? options.exit : activeOrder.activeExit),
            half_portion: false,
            ingredients: [],
            is_group_item: isGroupItem,
            item_id: item.id,
            lastupdate_at: orderItemTStamp,
            lastupdate_by: opData.id,
            name: item.name,
            operator_id: opData.id,
            operator_name: opData.full_name,
            order_item_parent_uuid: menuMode ? activeOrder.groupItemUuid || null : null,
            order_name: item.order_name,
            price: options.price ?? item[`price${priceList}`],
            uuid: generateUuid(),
            variations: [],
            vat_perc: department?.vat?.value ?? item.vat_perc,
        };

        orderItem.net_price = util.round((orderItem.price * 100) / (100 + orderItem.vat_perc));

        let variationRequired = _.find(item.variations, { required: true });

        if (variationRequired) {
            let answer = await orderItemManager.show(orderItem, { disableUnbundle: true });

            if(answer) {
                angular.copy(answer.rowItem, orderItem);
            }
        } else {
            orderItem.quantity = 1;

            for (let variation of (item.variations || [])) {
                let found = _.find(variation.variation_values, { default_value: true });

                if (found) {
                    orderItem.variations.push({
                        name: variation.name,
                        value: found.value,
                        price_difference: found.price_difference,
                        linked_item_uuid: found.linked_item_uuid,
                        variation_id: variation.id,
                        variation_value_id: found.id
                    });
                }
            }
        }

        return orderItem;
    };

    const activeOrder = {
        currentOrder: {},
        activeExit: null,
        changed: false,
        dirty: false,
        groupItemUuid: null,
        getOrderPricelist,
        resetGroupItemUuid: () => (activeOrder.groupItemUuid = null),
        createNewOrder: async(params) => {
            if (creatingOrder) {
                throw 'ORDER_CREATION_PENDING';
            }

            const createFunction = (
                checkManager.getPreference('orders.skip_creation_dialog') &&
                !['take_away', 'delivery'].includes(params?.type) &&
                !checkManager.getPreference('orders.require_table_selection')
            ) ? 'create' : 'show';

            creatingOrder = true;

            try {
                await askOperatorIfRequired();
                let order = await newOrder[createFunction](params);
                let result = await saveOrderToStorage(order);

                await activeOrder.switchOrdersInParking(result, { skipOperatorChange: true });

                $rootScope.$broadcast('orders:created', _.cloneDeep(result));
            } finally {
                creatingOrder = false;
            }
        },
        awaitOrder: async () => {
            if(!activeOrder.isActiveOrder()) {
                return activeOrder.createNewOrder();
            }
        },
        editOrder: async (order) => {
            let result = await saveOrderToStorage(order);
            activeOrder.loadOrder(result, { notifyPriceList: true });
        },
        loadOrder: function loadOrder(order, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            activeOrder.resetGroupItemUuid();

            if(activeOrder.isActiveOrder() && !['closed', 'checkout', 'missed'].includes(activeOrder.currentOrder.status)) {
                $rootScope.$broadcast("activeOrder:order-parked", activeOrder.currentOrder);
            }

            activeOrder.currentOrder = order || {};

            if(!options.keepDirty) {
                activeOrder.dirty = false;
            }

            if(_.isEmpty(order) && checkManager.getPreference("orders.reset_pricelist_on_close")) {
                $rootScope.$broadcast("orders:reset-pricelist");
            } else {
                determinePricelistToUse(!options.notifyPriceList ?? true);
            }

            if(!_.isEmpty(order)) {
                activeOrder.calculateAllOrderPrices();
                activeOrder.compareOrderToPrevious();
            }

            $rootScope.$broadcast("activeOrder:order-opened", order);
        },
        loadOrderById: async (orderId) => {
            let order = await entityManager.orders.fetchOneOfflineFirst(orderId);

            if (order) {
                await activeOrder.switchOrdersInParking(order);
            }
        },
        addCustomer: async (customer) => {
            let orderCustomer = _.clone(customer);

            if (validateUuid(orderCustomer.id)) {
                orderCustomer.uuid = orderCustomer.id;
                orderCustomer.customer_id = null;
            } else {
                orderCustomer.customer_id = orderCustomer.id;
            }

            delete orderCustomer.id;

            activeOrder.currentOrder.order_customer = orderCustomer;
            activeOrder.dirty = true;

            //Show customer notes if present
            try {
                if (customer.notes) {
                    await alertDialog.show(customer.notes);
                }
            } catch(err) {
                //Nothing to do
            }

            //Check if the customer has multiple shipping addresses and, if that's the case, ask which one is to use
            if(['take_away', 'delivery'].includes(activeOrder.currentOrder.type)) {
                let shippingAddresses = [{
                    address: {
                        shipping_prov: null,
                        shipping_street: null,
                        shipping_number: null,
                        shipping_zip: null,
                        shipping_city: null,
                        shipping_country: null,
                        shipping_address_id: null
                    },
                    name: "Nessuna consegna",
                    idx: -1
                }];

                let slotifyString = (string, slot) => `${string}${slot}`;

                for(let idx = 0; idx < 10; idx++) {
                    let slot = idx === 0 ? "" : `_${idx}`;

                    const shipping_prov_label = slotifyString('shipping_prov', slot);
                    const shipping_street_label = slotifyString('shipping_street', slot);
                    const shipping_number_label = slotifyString('shipping_number', slot);
                    const shipping_zip_label = slotifyString('shipping_zip', slot);
                    const shipping_city_label = slotifyString('shipping_city', slot);
                    const shipping_country_label = slotifyString('shipping_country', slot);

                    if(orderCustomer[shipping_street_label]) {
                        if(idx === 0) { //Set shipping_address_id to 0 in case the user cancels the selection afterwards
                            orderCustomer.shipping_address_id = 0;
                        }

                        let addressObj = {
                            shipping_prov: _.get(orderCustomer, shipping_prov_label),
                            shipping_street: _.get(orderCustomer, shipping_street_label),
                            shipping_number: _.get(orderCustomer, shipping_number_label),
                            shipping_zip: _.get(orderCustomer, shipping_zip_label),
                            shipping_city: _.get(orderCustomer, shipping_city_label),
                            shipping_country: _.get(orderCustomer, shipping_country_label),
                            shipping_address_id: idx
                        };

                        if(idx) {
                            delete orderCustomer[shipping_prov_label];
                            delete orderCustomer[shipping_street_label];
                            delete orderCustomer[shipping_number_label];
                            delete orderCustomer[shipping_zip_label];
                            delete orderCustomer[shipping_city_label];
                            delete orderCustomer[shipping_country_label];
                        }

                        let addressStr = `${addressObj.shipping_street} ${addressObj.shipping_number || ""} ${addressObj.shipping_zip || ""} ${addressObj.shipping_city || ""} ${addressObj.shipping_prov ? `(${addressObj.shipping_prov})` : ""}`;
                        let isDisabled = checkManager.getPreference('orders.allow_street_only_shipping_addresses') ? !addressObj.shipping_street : !addressObj.shipping_street || !addressObj.shipping_city;

                        if(isDisabled) {
                            addressStr += " (Incompleto)";
                        }

                        shippingAddresses.push({
                            address: addressObj,
                            name: addressStr,
                            $disabled: isDisabled
                        });
                    }
                }

                if(shippingAddresses.length > 1 || activeOrder.currentOrder.type === 'take_away') {
                    let addAction = {
                        callback: function() {
                            addSelectCustomer.show(activeOrder.currentOrder.order_customer, { editMode: true }).then(function(result) {
                                activeOrder.addCustomer(result);
                            });
                        }
                    };

                    let sAddr = await radioListSelector.show(shippingAddresses, { label: $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SELECT_SHIPPING_ADDRESS'), default: 0, addAction: addAction });

                    Object.assign(orderCustomer, sAddr.address);
                }
            }

            if (customer.default_pricelist) {
                determinePricelistToUse();
            }
        },
        removeCustomer: function removeCustomer() {
            let customerPriceList = activeOrder.currentOrder.order_customer?.default_pricelist;
            activeOrder.currentOrder.order_customer = undefined;
            activeOrder.dirty = true;

            if(customerPriceList) {
                determinePricelistToUse();
            }
        },
        deleteOrder: async (options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            if(options.reason) {
                activeOrder.currentOrder.notes = options.reason;
                activeOrder.dirty = true;
            }

            if(activeOrder.dirty) {
                await entityManager.orders.saveOneOffline(activeOrder.currentOrder, { dirty: true });
            }

            await entityManager.orders.deleteOneOfflineFirst(activeOrder.currentOrder.id);

            $rootScope.$broadcast("activeOrder:order-deleted", _.cloneDeep(activeOrder.currentOrder));

            activeOrder.loadOrder(null);
        },
        addItemToOrder: async(item, priceList) => {
            //Make sure we have an open order (create one if not)
            await activeOrder.awaitOrder();

            //Initialize order items array if necessary
            if(!Array.isArray(activeOrder.currentOrder.order_items)) {
                activeOrder.currentOrder.order_items = [];
            }

            //Get target price
            const menuMode = activeOrder.groupItemUuid && !item.is_group_item && !item.split_group_components;
            const targetPrice = menuMode ? 0 : item[`price${priceList}`];

            const itemsToAdd = [];

            let orderItem;

            //Check if we can increase the quantity of an existing order item instead
            if (!checkManager.getPreference("orders.add_item_as_new") && !item.is_group_item && !item.split_group_components) {
                orderItem = activeOrder.currentOrder.order_items.find((orderItem) => (
                    orderItem.item_id === item.id &&
                    orderItem.exit == activeOrder.activeExit &&
                    orderItem.order_item_parent_uuid == activeOrder.groupItemUuid &&
                    orderItem.price === targetPrice &&
                    _.isEmpty(orderItem.variations) &&
                    _.isEmpty(orderItem.ingredients)
                ));

                if (orderItem?.item_id) {
                    return activeOrder.editOrderItem(orderItem, { quantity: orderItem.quantity + 1 });
                }
            }

            const orderItemTStamp = new Date().toISOString();

            //Create the order item to add in the order
            orderItem = await getOrderItemToAdd(item, priceList, { date: orderItemTStamp, price: targetPrice });

            if (!orderItem.quantity) {
                return;
            }

            //If the item is a menu, enable menu mode
            if(item.is_group_item || item.split_group_components) {
                activeOrder.groupItemUuid = orderItem.uuid;
            }

            //Handle menus with components splitting
            if(item.split_group_components) {
                //Find the items linked to the menu and add the resulting order items
                let variationsWithItems = _.remove(orderItem.variations, 'linked_item_uuid');

                for(let variation of variationsWithItems) {
                    const linkedItemSearch = await entityManager.items.fetchCollectionOffline({ uuid: variation.linked_item_uuid });

                    if(linkedItemSearch.length) {
                        const defaultExit = _.find(item.variations, { id: variation.variation_id })?.default_exit;
                        let itemToAdd = await getOrderItemToAdd(linkedItemSearch[0], priceList, { date: orderItemTStamp, exit: defaultExit, price: 0 });

                        if(itemToAdd?.quantity) {
                            itemsToAdd.push(itemToAdd);
                        }
                    }
                }

                activeOrder.resetGroupItemUuid();
            }

            //Add the resulting order items and finalize
            itemsToAdd.unshift(orderItem);

            activeOrder.currentOrder.order_items.push(...itemsToAdd);
            activeOrder.calculateAllOrderPrices();
            activeOrder.compareOrderToPrevious();
            activeOrder.dirty = true;

            $rootScope.$broadcast('activeOrder:item-added', orderItem);
        },
        addOrderItem: function addOrderItem(orderItem) {
            const time = new Date().toISOString();
            const opData = OperatorManager.getOperatorData();

            Object.assign(orderItem, {
                added_at: time,
                lastupdate_at: time,
                lastupdate_by: opData.id,
                operator_id: opData.id,
                operator_name: opData.full_name,
                uuid: generateUuid()
            });

            activeOrder.currentOrder.order_items.push(orderItem);
            activeOrder.dirty = true;
            activeOrder.calculateAllOrderPrices();
            activeOrder.compareOrderToPrevious();
        },
        editOrderItem: function editOrderItem(orderItem, newData, options) {
            const opData = OperatorManager.getOperatorData();
            let previousOrderItem = _.clone(orderItem);

            if(!_.isObject(newData)) {
                newData = {};
            }

            if(options?.replace) {
                angular.copy(newData, orderItem); //Full replace
            } else {
                //Incremental update
                let fieldsToOmit = [];

                if(!options?.overrideMenuChecks) {
                    let isItemASelectedMenu = (activeOrder.groupItemUuid && orderItem.uuid === activeOrder.groupItemUuid);
                    let isItemUnderUnselectedMenu = (orderItem.order_item_parent_uuid && orderItem.order_item_parent_uuid !== activeOrder.groupItemUuid);

                    if(isItemASelectedMenu || isItemUnderUnselectedMenu) {
                        fieldsToOmit.push('quantity');
                    }

                    if(orderItem.order_item_parent_uuid) {
                        fieldsToOmit.push('price');
                    }
                }

                let updateData = _.omit(newData, fieldsToOmit);

                if(_.isEmpty(updateData)) {
                    return;
                }

                Object.assign(orderItem, updateData);

                //If we changed the quantity of a menu, change all menu items quantity accordingly
                if(orderItem.is_group_item && updateData.quantity) {
                    let multiplierFactor = (orderItem.quantity / previousOrderItem.quantity);
                    let itemsToChange = activeOrder.currentOrder.order_items.filter((oi) => oi.order_item_parent_uuid === orderItem.uuid);

                    for(let oi of itemsToChange) {
                        activeOrder.editOrderItem(oi, { quantity: Math.ceil(oi.quantity * multiplierFactor) }, { overrideMenuChecks: true });
                    }
                }
            }

            //Remove the item if the quantity is 0
            if(!orderItem.quantity) {
                return activeOrder.removeOrderItem(orderItem);
            }

            //Update lastupdate_at/lastupdate_by properly
            Object.assign(orderItem, {
                lastupdate_at: moment().toISOString(),
                lastupdate_by: opData.id
            });

            //Finalize
            activeOrder.dirty = true;
            activeOrder.calculateAllOrderPrices();
            activeOrder.compareOrderToPrevious();
            $rootScope.$broadcast("activeOrder:item-changed", { currentOrderItem: orderItem, previousOrderItem: previousOrderItem } );
        },
        removeOrderItem: (orderItem) => {
            _.pull(activeOrder.currentOrder.order_items, orderItem);
            _.remove(activeOrder.currentOrder.order_items, { order_item_parent_uuid: orderItem.uuid });

            if(orderItem.uuid === activeOrder.groupItemUuid) {
                activeOrder.resetGroupItemUuid();
            }

            //Finalize
            activeOrder.dirty = true;
            activeOrder.calculateAllOrderPrices();
            activeOrder.compareOrderToPrevious();
            $rootScope.$broadcast("activeOrder:item-removed", orderItem);
        },
        applyPriceList: async(priceList) => {
            const targetDepartment = _.toInteger(priceList) === 1 ? `department`: `department${priceList}`;
            let itemsMap = await util.getItemsFromIds(activeOrder.currentOrder.order_items);

            for(let orderItem of activeOrder.currentOrder.order_items) {
                if(orderItem.item_id && !orderItem.order_item_parent_uuid) {
                    let item = itemsMap[orderItem.item_id];

                    if(item) {
                        let newPrice = item[`price${priceList}`];

                        if(_.isFinite(newPrice)) {
                            let department = item[targetDepartment] || item['department'];

                            activeOrder.editOrderItem(orderItem, {
                                department_id: department?.id,
                                department_name: department?.name,
                                price: newPrice
                            });
                        }
                    }
                }
            }
        },
        switchOrdersInParking: async (orderFromParked, options) => {
            if (orderFromParked && !options?.skipOperatorChange) {
                await askOperatorIfRequired();
            }

            if (activeOrder.isActiveOrder()) {
                if (activeOrder.currentOrder.uuid === orderFromParked?.uuid) {
                    return;
                }

                if (activeOrder.dirty) {
                    //Drop variations if asked in the function call or if it's the preferred behavior
                    let dropVariations = options?.dropVariations || checkManager.getPreference("orders.drop_variations_on_park");

                    if (dropVariations) {
                        activeOrder.cloneActiveToPrevious();
                    }

                    await saveOrderToStorage(activeOrder.currentOrder);
                }
            }

            activeOrder.activeExit = _.toInteger(checkManager.getPreference("orders.default_exit")) || null;
            activeOrder.loadOrder(orderFromParked);
        },
        parkActiveOrder: async () => {
            await activeOrder.switchOrdersInParking(null);

            if (checkManager.getPreference("orders.open_tables_after_park")) {
                $rootScope.$broadcast("goToTables");
            }
        },
        isActiveOrder: () => (activeOrder.currentOrder.uuid ? true : false),
        isEmpty: function isEmpty() {
            return _.isEmpty(activeOrder.currentOrder.order_items);
        },
        setExit: function setExit(exit) {
            activeOrder.activeExit = exit;
        },
        isExitSent: function isExitSent(exit) {
            return (activeOrder.currentOrder.previous_order?.sentExits ?? 0) & Math.pow(2, exit.value);
        },
        cloneActiveToPrevious: function cloneActiveToPrevious(order) {
            const opData = OperatorManager.getOperatorData();
            let refOrder = !_.isNil(order) ? order : activeOrder.currentOrder;

            _.set(refOrder, ['previous_order', 'order_items'], orderUtils.getCleanOrderItems(refOrder));

            Object.assign(refOrder, {
                last_sent_at: new Date().toISOString(),
                last_sent_by: opData.id
            });

            if(refOrder === activeOrder.currentOrder) {
                activeOrder.changed = false;
            }
        },
        linkTableToOrder: async (tableInfo) => {
            if(!_.isObject(tableInfo)) {
                tableInfo = {};
            }

            if(activeOrder.isActiveOrder()) {
                Object.assign(activeOrder.currentOrder, {
                    table_id: tableInfo.table_id ?? null,
                    room_id: tableInfo.room_id ?? null,
                    table_name: tableInfo.table_name ?? null,
                    room_name: tableInfo.room_name ?? null
                });
                activeOrder.dirty = false;

                try {
                    await saveOrderToStorage(_.cloneDeep(activeOrder.currentOrder));
                } finally {
                    determinePricelistToUse();
                }
            }
        },
        mergeOrders: async (ordersListAndTable) => {
            switch (ordersListAndTable.selectedOrdersList.length) {
                case 0:
                    break;
                case 1:
                    let order = await entityManager.orders.fetchOneOffline(ordersListAndTable.selectedOrdersList[0]);
                    activeOrder.loadOrder(order);
                    break;
                default:
                    let orders = await entityManager.orders.fetchCollectionOffline({ id_in: ordersListAndTable.selectedOrdersList });

                    if(orders.length) {
                        let sortedOrders = _.sortBy(orders, 'order_number');
                        const mergedOrder = orderUtils.mergeOrders(sortedOrders, ordersListAndTable.selectedTable);

                        let slaveOrders = orders.filter((order) => order.uuid !== mergedOrder.uuid);

                        Object.assign(mergedOrder, {
                            covers: _.sumBy(orders, 'covers'),
                            id: _.head(sortedOrders).id
                        });

                        //Remove variations from order
                        activeOrder.cloneActiveToPrevious(mergedOrder);

                        //Update the master order and delete the slave orders
                        let updatedOrder = await saveOrderToStorage(mergedOrder);
                        activeOrder.loadOrder(updatedOrder);

                        const slaveOrderNote = $translate.instant('ORDERS.ACTIVE_ORDER.MERGED_ORDER');

                        for(let order of slaveOrders) {
                            order.notes = slaveOrderNote;

                            try {
                                await entityManager.orders.saveOneOffline(order, { dirty: true });
                                await entityManager.orders.deleteOneOfflineFirst(order.id);
                            } catch(err) {
                            }
                        }
                    }
                    break;
            }
        },
        splitOrderFunc: async () => {
            try {
                if (activeOrder.currentOrder.type !== "normal") {
                    throw 'CANNOT_SPLIT_TAKE_AWAY';
                }

                if (_.isEmpty(activeOrder.currentOrder.order_items)) {
                    throw 'ORDER_EMPTY';
                }

                let orders = await splitOrder.show(activeOrder.currentOrder);

                //Sync order ids in case of a sync occurred while the dialog was open
                syncOrderIDs(activeOrder.currentOrder, orders.originalOrder);

                //Copy dialog's original order to activeOrder's current order and set as dirty
                Object.assign(activeOrder, {
                    currentOrder: orders.originalOrder,
                    dirty: true
                });

                //Set all items of the new order as sent
                activeOrder.cloneActiveToPrevious(orders.splitOrder);

                //Save the new order
                let newOrder = await saveOrderToStorage(orders.splitOrder);

                //Load the new order (the variations of the original order are dropped)
                await activeOrder.switchOrdersInParking(newOrder, { dropVariations: true, skipOperatorChange: true });
            } catch(err) {
                switch(err) {
                    case 'ORDER_EMPTY':
                    case 'CANNOT_SPLIT_TAKE_AWAY':
                        alertDialog.show($translate.instant(`ORDERS.ACTIVE_ORDER.${err}`));
                    break;
                    default:
                    break;
                }
            }
        },
        compareOrderToPrevious: function compareOrderToPrevious(order) {
            var refOrder = order || activeOrder.currentOrder;

            var currentOrderCloned = {
                order_items: orderUtils.getCleanOrderItems(refOrder)
            };

            var previousOrderCloned;

            if (refOrder.previous_order) {
                previousOrderCloned = {
                    order_items: orderUtils.getCleanOrderItems(refOrder.previous_order)
                };
            } else {
                previousOrderCloned = {
                    order_items: []
                };
            }

            var changed = !_.isEqual(currentOrderCloned, previousOrderCloned);

            if(!order) {
                activeOrder.changed = changed;
            }

            return changed;
        },
        getItemsMap: function(itemId) {
            if(activeOrder.isActiveOrder()) {
                return itemsMap[itemId];
            } else {
                return {};
            }
        },
        calculateAllOrderPrices: function calculateAllOrderPrices(targetOrder) {
            if(!targetOrder) {
                targetOrder = activeOrder.currentOrder;
            }
            orderUtils.calculateOrderPrices(targetOrder);
            updateGroupItemsLinks();

            if(targetOrder === activeOrder.currentOrder) {
                updateItemsMap();
            }
        },
        sendOrderToPrinter: async (order, options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            const opData = OperatorManager.getOperatorData();
            const refOrder = order || activeOrder.currentOrder;

            let dirty = !_.isNil(order) ? false : activeOrder.dirty;

            //Create 2 copies of the order: one is sent to the printing function and the other is saved to storage
            let orderToSend = _.cloneDeep(refOrder);

            //If the order has changed update previous_order and last sent info
            const changed = activeOrder.compareOrderToPrevious(refOrder);

            if(changed) {
                activeOrder.cloneActiveToPrevious(order || null);

                dirty = true;
            }

            let orderToSave = _.cloneDeep(refOrder);

            //Save order before sending it to the printers
            if(!orderToSave.operator_id) {
                Object.assign(orderToSave, {
                    operator_name: opData.full_name,
                    operator_id: opData.id
                });

                dirty = true;
            }

            if(_.isNil(order)) {
                activeOrder.dirty = dirty;
            }

            //Save the order if there are changes
            if(dirty) {
                try {
                    await saveOrderToStorage(orderToSave);

                    if(_.isNil(order)) {
                        activeOrder.dirty = false;
                    }
                } catch(err) {
                    //Nothing to do
                }
            }

            //Send order to the printers
            orderToSend.operator_name = opData.full_name;

            let results;

            if(changed) {
                results = await NoFiscalPrinters.printOrder(orderToSend);
            } else {
                results = await NoFiscalPrinters.reprintOrder(orderToSend);
            }

            let success = (!printerErrorOrders.hasError(results));

            if (success && checkManager.getPreference("orders.open_tables_after_send") && options.goToTables !== false) {
                $rootScope.$broadcast("goToTables");
            }

            return success;
        },
        sendExitToPrinter: async (exit) => {
            const opData = OperatorManager.getOperatorData();
            //Create a copy of the order for the print function
            let orderToSend = _.cloneDeep(activeOrder.currentOrder);

            //Set the operator name to the order that is sent to the printer
            orderToSend.operator_name = opData.full_name;

            await new Promise((resolve, reject) => {
                //Send the order to the printer
                NoFiscalPrinters.printGoExit(orderToSend, exit).then(async (results) => {
                    if(!printerErrorOrders.hasError(results)) {
                        //If the active order is still the one we sent use it for saving, otherwise get the order from the storage manager
                        let refOrder;

                        if (activeOrder.currentOrder.uuid === orderToSend.uuid) {
                            refOrder = activeOrder.currentOrder;
                        } else {
                            //TODO: it might be a better idea to move this lookup logic elsewhere
                            let orders = await entityManager.orders.fetchCollectionOffline({ uuid: orderToSend.uuid });

                            if(orders.length) {
                                refOrder = orders[0];
                            }
                        }

                        if(refOrder) {
                            activeOrder.cloneActiveToPrevious(refOrder);

                            Object.assign(refOrder.previous_order, {
                                sentExits: (refOrder.previous_order?.sentExits ?? 0) | Math.pow(2, exit)
                            });

                            await saveOrderToStorage(_.cloneDeep(refOrder));

                            if(refOrder === activeOrder.currentOrder) {
                                activeOrder.dirty = false;
                            }
                        }
                    }

                    resolve();
                });
            });
        },
        createSaleFromOrder: async (orderToConvert, saleTemplateOptions) => {
            if (orderToConvert) {
                const timeNow = new Date().toISOString();
                const opData = OperatorManager.getOperatorData();
                let currentOrderCloned = _.cloneDeep(orderToConvert);

                let resultSale = await saleUtils.getSaleTemplate(saleTemplateOptions);
                let originalItems = await util.getItemsFromIds(currentOrderCloned.order_items);

                Object.assign(resultSale, _.pick(currentOrderCloned, ['uuid', 'name', 'channel', 'covers', 'external_id', 'booking_id', 'table_id', 'table_name', 'room_id', 'room_name', 'deliver_at']));

                if (validateUuid(currentOrderCloned.id)) {
                    resultSale.order_uuid = currentOrderCloned.id;
                } else {
                    resultSale.order_id = currentOrderCloned.id;
                    resultSale.order_uuid = currentOrderCloned.uuid;
                }

                resultSale.order_type = currentOrderCloned.type;

                if (checkManager.isModuleEnabled('cashregister')) {
                    resultSale.assigned_id = opData.id;
                    resultSale.assigned_name = opData.full_name;
                }

                //OrderItem -> SaleItem conversion
                const cleanupOISubEntity = (subentity) => _.omit(subentity, ['id', 'order_id', 'order_item_id']);

                resultSale.sale_items = currentOrderCloned.order_items.map((orderItem) => {
                    //Carry over common fields
                    let saleItem = _.pick(orderItem, ['name', 'item_id', 'category_id', 'category_name', 'department_id', 'department_name', 'cost', 'quantity', 'half_portion', 'vat_perc', 'is_group_item', 'notes', 'price']);

                    Object.assign(saleItem, {
                        added_at: timeNow,
                        price_changes: [],
                        seller_id: orderItem.operator_id,
                        seller_name: orderItem.operator_name,
                        sale_item_parent_uuid: orderItem.order_item_parent_uuid,
                        type: 'sale',
                        uuid: orderItem.uuid || generateUuid(),
                        ingredients: _.map(orderItem.ingredients, cleanupOISubEntity),
                        variations: _.map(orderItem.variations, cleanupOISubEntity)
                    });

                    //Get fields from original items
                    let originalItem = originalItems[orderItem.item_id];

                    if (originalItem) {
                        Object.assign(saleItem, {
                            department: originalItem.department,
                            sku: originalItem.sku,
                            barcode: _.isEmpty(originalItem.barcodes) ? null : _.head(originalItem.barcodes).barcode,
                            not_discountable: originalItem.not_discountable
                        });
                    }

                    return saleItem;
                });

                //Cover item
                if(currentOrderCloned.covers > 0 && currentOrderCloned.type === 'normal') {
                    let coverConfig = await orderUtils.getOrderCoverConfig() || {};

                    switch(coverConfig.type) {
                        case 'item':
                            let coverSaleItem = orderUtils.getCoverSaleItem(currentOrderCloned, coverConfig.data);
                            resultSale.sale_items.unshift(coverSaleItem);
                        break;
                        case 'price_change':
                            let coverPc = coverConfig.data;
                            let newIndexCover = (_.get(_.maxBy(resultSale.price_changes, 'index'), 'index') + 1);
                            coverPc.index = _.isNaN(newIndexCover) ? 0 : newIndexCover;

                            resultSale.price_changes.push(coverPc);
                        break;
                        case 'error':
                            try {
                                await alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.COVER_ADD_ERROR'));
                            } catch(err) {
                                //Nothing to do
                            }
                        break;
                    }
                }

                //Customer
                if (currentOrderCloned.order_customer) {
                    resultSale.sale_customer = _.omit(currentOrderCloned.order_customer, ['id']);

                    let discountDescription = $translate.instant('ORDERS.ACTIVE_ORDER.CUSTOMER_DISCOUNT');

                    if (currentOrderCloned.order_customer.discount_perc) {
                        if(_.find(resultSale.sale_items, { not_discountable: true })) {
                            resultSale.price_changes.push({
                                index: 100,
                                type: "disc_perc_nd",
                                value: currentOrderCloned.order_customer.discount_perc,
                                description: discountDescription
                            });

                            for(let saleItem of resultSale.sale_items) {
                                if(!saleItem.not_discountable) {
                                    saleItem.price_changes.push({
                                        index: 100,
                                        type: "discount_perc",
                                        value: currentOrderCloned.order_customer.discount_perc,
                                        description: discountDescription
                                    });
                                }
                            }
                        } else {
                            let newIndex = (_.get(_.maxBy(resultSale.price_changes, 'index'), 'index') + 1);

                            resultSale.price_changes.push({
                                index: _.isNaN(newIndex) ? 0 : newIndex,
                                type: "discount_perc",
                                value: currentOrderCloned.order_customer.discount_perc,
                                description: discountDescription
                            });
                        }
                    }
                }

                saleUtils.calculateSalePrices(resultSale);

                //Channel payment method
                if(currentOrderCloned.channel && currentOrderCloned.channel !== 'pos' && currentOrderCloned.paid) {
                    let channel = await entityManager.channels.fetchOneOffline(currentOrderCloned.channel);

                    if(channel?.default_payment_method_id) {
                        let paymentMethod = await entityManager.paymentMethods.fetchOneOffline(channel.default_payment_method_id);

                        if(paymentMethod) {
                            let channelPayment = {
                                amount: resultSale.final_amount,
                                date: timeNow,
                                paid: true,
                                payment_method_id: paymentMethod.id,
                                payment_method_name: paymentMethod.name,
                                payment_method_type_id: paymentMethod.payment_method_type_id,
                                payment_method_type_name: _.get(paymentMethodTypes, [paymentMethod.payment_method_type_id, 'name']),
                                unclaimed: fiscalUtils.isMethodUnclaimed(paymentMethod.payment_method_type_id)
                            };

                            if(channelPayment.payment_method_type_id === 17) {
                                channelPayment.payment_data = currentOrderCloned.channel;
                            }

                            resultSale.payments.push(channelPayment);
                        }
                    }
                }

                return resultSale;
            }
        },
        printNonFiscalOrder: function printNonFiscalOrder(targetPrinter) {
            _.forEach(activeOrder.currentOrder.order_items, function(orderItem) {
                orderItem.prebilled = true;
            });

            var localOrder = _.cloneDeep(activeOrder.currentOrder);
            saveOrderToStorage(localOrder);

            activeOrder.createSaleFromOrder(localOrder, { skipFiscalProvider: true }).then(function(sale) {
                Object.assign(sale, _.pick(localOrder, ['table_id', 'table_name', 'room_id', 'room_name', 'covers']));

                documentPrinter.printNonFiscalSale(sale, targetPrinter).then(function(result) {
                    toast.show({ message: $translate.instant('ORDERS.ACTIVE_ORDER.NON_FISCAL_PRINT_OK') });
                }, function(error) {
                    alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.NON_FISCAL_CONN_ERROR'));
                });
            });
        },
        checkoutOrder: async () => {
            if(!activeOrder.isActiveOrder()) {
                return;
            }

            //Copy the current order and deselect it
            const currentOrderCloned = _.cloneDeep(activeOrder.currentOrder);
            activeOrder.loadOrder(null);

            if(connection.isOnline() || checkManager.isModuleEnabled('cashregister')) {
                //Perform normal checkout if the device is online or we have access to the cashregister
                const checkoutSale = await activeOrder.createSaleFromOrder(currentOrderCloned);
                let sale = await entityManager.sales.postOneOfflineFirst(checkoutSale);

                Object.assign(currentOrderCloned, {
                    status: 'checkout',
                    checkout_at: new Date().toISOString()
                });

                if (checkManager.isModuleEnabled('cashregister')) {
                    $state.go('app.cashregister.content.showcase', {
                        action: 'open-sale-id',
                        id: sale.uuid
                    });
                } else {
                    if (checkManager.getPreference("orders.open_tables_after_park")) {
                        $rootScope.$broadcast("goToTables");
                    }
                }
            } else {
                //We are offline and we don't have access to the cashregister. Try to send the order to every fiscal printer
                let printers = await entityManager.printers.fetchCollectionOffline();
                let fPrinters = printers.filter((printer) => ['fiscal', 'rt'].includes(printer.type));

                if(!fPrinters.length) {
                    return alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.CHECKOUT_FISCAL_NO_PRINTERS'));
                }

                //Send the order to each printer and report the result to the user
                let results = [];

                for(let printer of fPrinters) {
                    try {
                        let res = await FiscalPrinters.printOrder(currentOrderCloned, printer.id);
                        results.push(res);
                    } catch(err) {
                        results.push(err);
                    }
                }

                let isSuccess = results.find((res) => res.status === 'success');
                let printed_ko = results.filter((res) => res.status === 'error').map((res) => res.printer_name);

                if(!isSuccess) {
                    return alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.CHECKOUT_UNSUCCESSFUL_ON') + printed_ko.join(' '));
                }

                let printed_ok = _(results).filter({ status: 'success' }).map('printer_name').value();
                let printed_ko_string = "";

                if (!_.isEmpty(printed_ko)) {
                    printed_ko_string = $translate.instant('ORDERS.ACTIVE_ORDER.CHECKOUT_UNSUCCESSFUL_ON_SHORT') + printed_ko.join(' ');
                }

                alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.CHECKOUT_SUCCESSFUL_ON') + printed_ok.join(' - ') + printed_ko_string);

                Object.assign(currentOrderCloned, {
                    status: 'missed',
                    checkout_at: new Date().toISOString(),
                    name: `M - ${_.truncate(currentOrderCloned.name, { length: 26, omission: '' })}`
                });
            }

            let orderSent = await saveOrderToStorage(currentOrderCloned);

            //Remove open orders with the same table if is a single-order
            if(orderSent.room_id) {
                let room = await entityManager.rooms.fetchOneOfflineFirst(orderSent.room_id);

                if(!room) {
                    return;
                }

                let orderTable = room.tables.find((table) => table.id === currentOrderCloned.table_id);

                if(orderTable?.order_type === 'single' && !checkManager.getPreference('orders.load_old_orders')) {
                    let orders = await entityManager.orders.fetchCollectionOffline({ table_id: orderSent.table_id, status: 'open' });

                    for(let order of orders) {
                        try {
                            if(order.id !== orderSent.id) {
                                order.notes = $translate.instant('ORDERS.ACTIVE_ORDER.AUTO_DELETE');
                                await entityManager.orders.saveOneOffline(order, { dirty: true });
                                await entityManager.orders.deleteOneOfflineFirst(order.id);
                            }
                        } catch(err) {}
                    }
                }
            }
        }
    };

    const syncOrderIDs = (origin, target) => {
        target.id = origin.id;

        _.forEach(target.order_items, function(orderItem) {
            let found = _.find(origin.order_items, { uuid: orderItem.uuid });

            if (found) {
                orderItem.id = found.id;
            }
        });
    };

    $rootScope.$on("OfflineFirst-orders:completed", function(event, data) {
        if (activeOrder.currentOrder.id && [data.uuid, data.entity.id].includes(activeOrder.currentOrder.id)) {
            syncOrderIDs(data.entity, activeOrder.currentOrder);
        }
    });

    $rootScope.$on("entity-updated:orders", async function(event, data) {
        let newOrder;
        // TODO is delta needed?
        if(data["delta"]){
            let isActive= activeOrder.isActiveOrder();
            newOrder = data["delta"];
            if ( isActive && data["delta"].uuid === activeOrder.currentOrder.uuid) {
                if (data["delta"].status === 'checkout') {
                    activeOrder.loadOrder(null);
                    $rootScope.$broadcast("activeOrder:changed", "checkout");
                } else {
                    activeOrder.loadOrder(data["delta"]);
                    $rootScope.$broadcast("activeOrder:changed", "updated");
                }

            }
        }else{
            newOrder = await entityManager.orders.fetchOneOffline(data.id);
            if (activeOrder.isActiveOrder()) {
                if (data.id === activeOrder.currentOrder.id) {
                    switch(data.action) {
                        case "UPDATED":
                                if (newOrder) {
                                    if (newOrder.status === 'checkout') {
                                        activeOrder.loadOrder(null);
                                        $rootScope.$broadcast("activeOrder:changed", "checkout");
                                    } else {
                                        var oldOrder = activeOrder.currentOrder;
                                        _.remove(oldOrder.order_items, function(orderItem) { return orderItem.id; });
                                        newOrder.order_items = _(newOrder.order_items).unionBy(oldOrder.order_items, 'uuid').sortBy('added_at').value();
                                        activeOrder.loadOrder(newOrder, { keepDirty: true });
                                    }
                                }
                            break;
                        case "DELETED": case "CLOSED":
                            activeOrder.loadOrder(null);
                            $rootScope.$broadcast("activeOrder:changed", _.toLower(data.action));
                            break;
                        default:
                            break;
                    }
                }
            }

        }
        if(ExternalOrdersManager.isExternalOrdersPrintEnabled() && data.externalClient && _.get(newOrder, 'operator_id') === 0) {
            var orderToSend = (activeOrder.isActiveOrder() && data.id === activeOrder.currentOrder.id) ? null : newOrder;

            if(_.startsWith($state.current.name, 'app.orders') && _.isNil(orderToSend)) {
                $rootScope.$broadcast('sendOrder');
            } else {
                activeOrder.sendOrderToPrinter(orderToSend, { goToTables: false });
            }
        }
    });

    return activeOrder;
}
