import { Component, Inject, Input, OnInit } from "@angular/core";
import { $state, restManager } from "app/ajs-upgraded-providers";
import { CustomersFields } from "../customer-form/customers-form.model";
import { TranslateService } from "@ngx-translate/core";
import {
    ConfigurationManagerService,
    EntityManagerService,
    ThemeModeService
} from "src/app/core";
import {QueryPagination} from "../../../../../../shared/model/model";
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { ChainCampaigns, FidelitiesPoints } from "tilby-models";
import { SalesToBeInvoiced } from "./model/sales_to_be_invoiced.model";

@Component({
    selector: 'app-customer-summary',
    templateUrl: './customer-summary.component.html',
    styleUrls: ['./customer-summary.component.scss']
})
export class CustomerSummaryComponent implements OnInit{

    lastSale?: string;
    hasLastSale?: boolean;
    pointsToShow=0;
    salesToBeInvoicedCustomer!: SalesToBeInvoiced;
    isLoading: boolean = true;
    notEmptySalesObj: boolean = false;

    isNewCashregister = this.configurationManagerService.isModuleAngular('tables_and_cashregister');

    @Input() credit?: number;
    _points:FidelitiesPoints[] | undefined=[];
    @Input() set points(points: FidelitiesPoints[] | undefined) {
        this._points=points;
        this.pointsToShow = points?.find(p=>p.campaign_id===this._activeCampaign?.id)?.points || 0;

    };
    _activeCampaign?:ChainCampaigns;
    @Input() set activeCampaign(campaign: ChainCampaigns | undefined) {
        this._activeCampaign=campaign;
        this.pointsToShow = this._points?.find(p=>p.campaign_id===campaign?.id)?.points || 0;
    };
    @Input() customer?: CustomersFields;

    // @Input() set selectedCampaignId(id:number){
    //     this.pointsToShow = this._points.find(point => point.campaign_id === id)?.points;
    // };

    constructor(
        public themeModeService:ThemeModeService,
        private configurationManagerService: ConfigurationManagerService,
        private tilbyDatePipe: TilbyDatePipe,
        private entityManager: EntityManagerService,
        @Inject($state) private state: any,
        private translate: TranslateService,
        @Inject(restManager) private restManagerService: any,
    ) {
    }

    ngOnInit(): void {

        let querylastSale: any&QueryPagination = {
            "pagination": true,
            "per_page": 1,
            "page": 0,
            "sale_customer.uuid":  this.customer?.uuid,
            "orderby_desc": "closed_at"
        }

        this.entityManager.sales.fetchCollectionOnline(querylastSale).then((result) => {
            if('results' in result) {
                this.lastSale = result.results[0] ? this.tilbyDatePipe.transform(result.results[0].closed_at) : this.translate.instant("CUSTOMERS.DETAILS.SUMMARY.HAS_NOT_LAST_SALE");
                this.hasLastSale = result.results[0] ? true : false;
            }
        });

        const today = new Date(new Date().setHours(24, 59, 59, 999)).toISOString();
        const firstDayOfYear = this.fromDateToUTC(new Date(new Date().getFullYear(), 0, 1)).toISOString();

        this.restManagerService.getList(`analytics/sales/to_be_invoiced?closed_at_max=${today}&closed_at_since=${firstDayOfYear}&customer_id=${this.customer?.id}`)
        .then((sales: {results: SalesToBeInvoiced[]}) => {
            if(sales.results.length === 0) return;
            this.salesToBeInvoicedCustomer = sales.results[0];
            (Object.keys(this.salesToBeInvoicedCustomer.transaction_total).length === 0 && Object.keys(this.salesToBeInvoicedCustomer.transaction_count).length === 0) && (this.notEmptySalesObj = true)
        })
        .finally(() => this.isLoading = false);
    }

    fromDateToUTC(date: Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    callWithTel() {
        window.open('tel://' + (this.customer?.phone || this.customer?.mobile), '_blank');
    }

    sendWithWa() {
        window.open('https://wa.me/' + (this.customer?.mobile), '_blank');
    }

    get telDisabled() {
        return !(this.customer?.phone || this.customer?.mobile);
    }

    get waDisabled() {
        return !this.customer?.mobile;
    }

    goToHistorySaleByCustomer() {
        this.state.go('app.new.history.customer', {customer: this.customer, salesToBeInvoicedCustomer: this.salesToBeInvoicedCustomer});
    }

    goToBookingsByCustomer() {
        this.state.go(this.configurationManagerService.isModuleAngular('bookings') ? 'app.new.bookings.showcase.customer' : 'app.bookings.showcase.customer', {customer: this.customer});
    }

    goToOrdersByCustomer() {
        this.state.go('app.orders.content', {
            type: 'normal',
            orderId: undefined,
            customer: this.customer
        });
    }

    goToCashregisterByCustomer() {
        this.state.go('app.new.cashregister.content.showcase', {
            action: 'create-new-sale',
            saleType: 'take_away',
            customer: this.customer
        });
    }
}
