import {
    inject,
    Injectable,
    Renderer2,
    RendererFactory2
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeModeService {
    private readonly rendererFactory = inject(RendererFactory2);
    private renderer: Renderer2;
    public isDarkTheme = false;

    constructor(
    ) {
        this.renderer = this.rendererFactory.createRenderer(null,null);
        this.renderer.addClass(document.body, 'mat-app-background');
    }

    switchTheme(theme: string) {
        if (theme === 'dark') {
            this.renderer.addClass(document.body, 'dark-theme');
            this.isDarkTheme = true;
        } else {
            this.renderer.removeClass(document.body, 'dark-theme');
            this.isDarkTheme = false;
        }
    }
}
