import {
    Component,
    Renderer2,
    OnInit,
    inject
} from '@angular/core';

import { TicketsFields, TicketsFormGroup } from './settings-tickets-form';
import { TicketsFormService } from './services';
import { errorsLogger } from "app/ajs-upgraded-providers";

import {
    EntityManagerService,
    ModuleEvents,
    ToolbarEventsService
} from 'src/app/core';

import {
    AlertDialogService,
    ConfirmDialogService
} from 'src/app/dialogs';

import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { DevLogger } from "src/app/shared/dev-logger";


@Component({
  selector: 'app-settings-tickets',
  templateUrl: './settings-tickets.component.html',
  styleUrls: ['./settings-tickets.component.scss'],
  providers: [OnDestroyService]
})
export class SettingsTicketsComponent implements OnInit {
    private readonly _snackBar = inject(MatSnackBar);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly errorsLoggerService = inject(errorsLogger);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly renderer = inject(Renderer2);
    private readonly ticketFormService = inject(TicketsFormService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translate = inject(TranslateService);

    filterList = '';
    filterToggle = false;
    firstEntry = false;
    isNewEntryInProgress = false;
    isProcessing = false;
    pristineTicket: TicketsFields = { id: -1, name: '' };
    selected = false;
    selectedTicket: TicketsFields = { id: -1, name: '' };
    ticketForm?: CustomFormGroup<TicketsFormGroup>;
    ticketsList: Array<TicketsFields> = [];

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.getTickets().then(tickets => this.ticketsList = tickets);
    }

    private async getTickets(): Promise<Array<TicketsFields>> {
        let tickets: Array<TicketsFields> | undefined = undefined;
        try {
            tickets = await this.entityManagerService.tickets.fetchCollectionOffline();
            this.log('Tickets', tickets);
        } catch (err) {
            this.errorsLoggerService.err(err);
        }

        return tickets && Array.isArray(tickets) ? tickets : [];
    };

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {}

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('TICKETS');
    }

    unsaveTicket = async (ticket: TicketsFields) => {
        const confirm = await this.confirmDialogService.openDialog({ data: {
                messageLabel: 'SETTINGS.TICKETS.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.TICKETS.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.TICKETS.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.ticketForm?.markAsPristine();
            this.selectTicket(ticket, false);
        }
    };

    selectTicket = (ticket: TicketsFields, isFormDirty = false) => {
        if(isFormDirty) {
            this.unsaveTicket(ticket);
        } else {
            const _ticket = structuredClone(ticket);
            this.pristineTicket = _ticket;
            this.selectedTicket = _ticket;
            if(!this.selected) {
                this.ticketForm = this.ticketFormService.createForm(_ticket);
            } else {
                this.ticketFormService.updateForm(_ticket);
            }
        }
        this.selected = true;
    };

    unsaveWhenAddNew = async () => {
        const confirm = await this.confirmDialogService.openDialog({ data: {
                messageLabel: 'SETTINGS.TICKETS.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.TICKETS.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.TICKETS.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.ticketForm?.markAsPristine();
            this.addNewTicketProceed();
        }
    }

    addNewTickets = (isFormDirty = false) => {
        if(isFormDirty) {
            this.unsaveWhenAddNew();
        }
        if(!this.ticketForm?.dirty && !this.isNewEntryInProgress) {
            this.addNewTicketProceed();
        }
    };

    private addNewTicketProceed = () => {
        const newTicket: TicketsFields = {
            isNew: true,
            name: this.translate.instant('SETTINGS.TICKETS.NEW_NAME'),
        };
        this.filterList = '';
        this.filterToggle = false;
        const newLen = this.ticketsList.push(newTicket);
        this.ticketsList = [...this.ticketsList];
        this.selectTicket(this.ticketsList[newLen - 1], false);
        this.isNewEntryInProgress = true;
        this.ticketForm?.markAsDirty();
    }

    isSelected = (ticket: TicketsFields) => ticket.id === this.selectedTicket.id;

    lockProcessing = () => {
        this.isProcessing = true;
    };

    unlockProcessing = () => {
        this.isProcessing = false;
        this.isNewEntryInProgress = false;
    };

    save = () => {
        this.lockProcessing();
        const _ticketForm = this.ticketForm?.value.generalForm;
        this.log('Form', _ticketForm);
        const cloneSelectedTicket = structuredClone(this.selectedTicket);
        const ticketToSave: TicketsFields = { ...cloneSelectedTicket, ..._ticketForm };

        this.log('ticketToSave', ticketToSave);

        const saveFunction = cloneSelectedTicket.isNew ? 'postOneOnline' : 'putOneOnline';
        const successSave = (result: TicketsFields) => {
            let ticketToSelect: TicketsFields | undefined = undefined;
            if(cloneSelectedTicket.isNew) {
                const lastIndex = this.ticketsList.length - 1;
                ticketToSelect = this.ticketsList[lastIndex];
            } else {
                ticketToSelect = this.ticketsList.find((ticket: TicketsFields) => ticket.id === result.id);
            }
            if(ticketToSelect) {
                //result.default_payment_method_id = result.default_payment_method_id ? result.default_payment_method_id : -1;
                Object.assign(ticketToSelect, result);
                delete ticketToSelect.isNew;
                this.selectTicket(ticketToSelect, false);
            }
            this.ticketForm?.markAsPristine();
            this.ticketFormService.disableCircuit();
            this.isProcessing = false;
            this.isNewEntryInProgress = false;
            const labelSuccess = this.translate.instant('SETTINGS.TICKETS.SAVE_SUCCESSFUL');
            this._snackBar.open(labelSuccess, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom' });
        };
        const errorSave = () => {
            this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.TICKETS.SAVE_FAILED',
                    confirmLabel: 'SETTINGS.TICKETS.ALERT_CONFIRM_LABEL'
                }
            });
            this.isProcessing = false;
        };

        this.entityManagerService.tickets[saveFunction](ticketToSave)
        .then(successSave, errorSave)
        .catch((err: any) => {
            this.errorsLoggerService.err(err);
            this.isProcessing = false;
        });
    };

    delete = async () => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.TICKETS.DELETE_CONFIRM',
                confirmLabel: 'SETTINGS.TICKETS.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.TICKETS.CANCEL_LABEL'
            }
        });

        if (!confirm) {
            return;
        }

        this.lockProcessing();
        const _selectedTicket = this.selectedTicket;

        try {
            if (_selectedTicket.id) {
                await this.entityManagerService.tickets.deleteOneOnline(_selectedTicket.id);
                const indexOfticketToDelete = this.ticketsList.findIndex(ticket => ticket.id === _selectedTicket.id);

                if (indexOfticketToDelete !== -1) {
                    this.ticketsList.splice(indexOfticketToDelete, 1);
                }
            } else {
                this.ticketsList.pop();
            }

            this.ticketsList = [...this.ticketsList];
            this.ticketForm?.markAsPristine();
            this.isNewEntryInProgress = false;
            this.filterList = '';
            this.selected = false;

            if (this.firstEntry) {
                this.firstEntry = false;
            }
        } catch (err) {
            this.errorsLoggerService.err(err);

            this.alertDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.TICKETS.DELETE_FAILED',
                    confirmLabel: 'SETTINGS.TICKETS.ALERT_CONFIRM_LABEL'
                }
            });
        } finally {
            this.unlockProcessing();
        }
    };

    filterTickets = () => {
        this.filterToggle = !this.filterToggle;
        if(this.filterToggle) {
            setTimeout(() => this.renderer.selectRootElement("input[name=search]").focus(), 0);
        } else {
            this.filterList = '';
        }
    };

    private log(...args: any[]) {
        DevLogger.log('TICKETS COMPONENT', ...args);
    }
}
