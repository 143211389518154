import {
    Component,
    effect,
    Input,
    OnDestroy,
    OnInit,
    signal,
    untracked,
    ViewChild,
} from '@angular/core';

import {
    CommonModule
} from '@angular/common';

import {
    CdkDrag,
    Point
} from "@angular/cdk/drag-drop";

import {
    RoomsTables,
    Sales
} from 'tilby-models';

import { TilbyBookingTableElementComponent, Color } from '@tilby/tilby-ui-lib/components/tilby-booking-table-element';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";

@Component({
    selector: 'app-booking-room-element',
    standalone: true,
    imports: [CommonModule, CdkDrag, TilbyBookingTableElementComponent, TilbyGesturesDirective],
    providers: [OnDestroyService],
    templateUrl: './booking-room-element.component.html',
    styleUrls: ['./booking-room-element.component.scss']
})
export class BookingRoomElementComponent implements OnInit, OnDestroy {
    protected colorInNormalMode = signal<Color | undefined>(undefined);
    protected defaultDragPosition: Point = { x: 0, y: 0 };
    protected exitSents: number = 0;
    protected isTableMultiple = false;
    protected maxExitsNumber: number = 0;
    protected tableHanging = false;
    protected tableMultipleBusyCovers = 0;

    public color = signal<Color | undefined>(undefined);
    public sale0?: Sales;

    @ViewChild('tableElement', { read: TilbyBookingTableElementComponent }) tableRef!: TilbyBookingTableElementComponent;

    @Input({ required: true }) table!: RoomsTables;
    @Input() isTablesSelected!: boolean;
    @Input() bookingInfo!: { table_id: number, booking_id: number, people: number, booked_for: string }[];

    constructor() {
        effect(() => this.color.set(untracked(this.colorInNormalMode) || untracked(this.color)), { allowSignalWrites: true });
        effect(() => this.colorInNormalMode.set((['grey', 'blue'].indexOf(this.color() || '') < 0) ? this.color() : (untracked<Color | undefined>(this.colorInNormalMode))), { allowSignalWrites: true });
    }

    ngOnInit() {
        this.defaultDragPosition = { x: this.table.xpos || 0, y: this.table.ypos || 0 };
    }

    onClick($event: MouseEvent) {
    }

    ngOnDestroy() {
    }
}
