import {Component, inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

import {
    ConfigurationManagerService,
    EnvironmentInfoService,
    PrinterDocumentTypeId
} from "src/app/core";

import {
    $state,
    $stateParams,
    customerDisplayManager,
    savedSalesDialog
} from "app/ajs-upgraded-providers";

import {
    AlertDialogService,
    DocumentPrintersManagerDialogStateService,
} from "src/app/dialogs";

import { ActiveSaleActionButtonsWrapperComponent } from "../active-sale-action-buttons-wrapper";
import {subscribeInComponent} from "@tilby/tilby-ui-lib/utilities";
import { DualService } from "src/app/dual.service";

import {
    ActiveSaleService,
    ActiveSaleStoreService,
    CashregisterStateService
} from "src/app/features/cashregister/services";

import {
    CustomerDisplayManagerService
} from "app/modules/cashregister/service/customer-display-manager/customer-display-manager";

@Component({
    template: ``,
    standalone:true
})
export class ActiveSaleBase implements OnInit, OnDestroy {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly customerDisplayManagerService: CustomerDisplayManagerService = inject(customerDisplayManager);
    private readonly documentPrintersManagerDialogStateService = inject(DocumentPrintersManagerDialogStateService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly savedSalesDialogService = inject(savedSalesDialog); // OLD DIALOG UPGRADED
    private readonly state = inject($state);
    private stateParams = inject($stateParams);
    private readonly translateService = inject(TranslateService);
    protected readonly cashregisterStateService = inject(CashregisterStateService);
    protected readonly dualService = inject(DualService);
    public readonly activeSaleService = inject(ActiveSaleService);

    @ViewChild(ActiveSaleActionButtonsWrapperComponent) activeSaleActionButtonsWrapperComponent?:ActiveSaleActionButtonsWrapperComponent;

    protected saleUpdates$ = ActiveSaleStoreService.saleUpdates$;

    protected isPaymentsState : boolean = false;

    // START - LIFECYCLE
    constructor() {
        this.subscriptions();
    }
    async ngOnInit() {
        await this.handleStateChange();
    }
    ngOnDestroy() {
        this.activeSaleService.resetGroupItemUuid();
    }
    // END - LIFECYCLE

    private async handleStateChange() {
        switch (this.stateParams.action) {
            case 'create-new-sale':
                await new Promise((resolve) => setTimeout(resolve, 500));
                await this.activeSaleService.createNewSale(this.stateParams);
                break;
            case 'open-saved-sale':
                await this.showSavedSales();
                break;
            case 'open-sale-id':
                if (this.stateParams.id) {
                    await this.activeSaleService.loadSale(this.stateParams.id, { skipResetDocumentData: true });

                    if (this.stateParams.advancedpayments) {
                        await this.activeSaleActionButtonsWrapperComponent?.goToPayments();
                    } else if (this.stateParams.saleType) {
                        let docType: PrinterDocumentTypeId

                        switch (this.stateParams.saleType) {
                            case 'summary':
                                docType = 'summary_invoice';
                                break;
                            case 'summary_e_rc':
                            case 'summary_e_nrc':
                            case 'generic_receipt':
                            case 'generic_invoice':
                            case 'generic_document':
                                docType = this.stateParams.saleType;
                                break;
                            case 'void_doc':
                            case 'refund_doc':
                            case 'credit_note':
                                docType = 'fiscal_receipt';
                                break;
                            case 'e_credit_note':
                                docType = 'e_invoice';
                                break;
                            default:
                                docType = 'fiscal_receipt';
                        }

                        try {
                            const docData = await this.documentPrintersManagerDialogStateService.getPrinterDocumentData('default', docType);
                            this.activeSaleService.setPrinterDocumentData(docData);
                        } catch (error) {
                            this.activeSaleService.resetDocumentData();
                            let errorMessage;

                            switch (error) {
                                case "UNKNOWN_DOCUMENT_TYPE":
                                    errorMessage = this.translateService.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_DOCUMENT_TYPE');
                                    break;
                                case "PRINTER_NOT_CAPABLE":
                                    errorMessage = this.translateService.instant('CASHREGISTER.ACTIVE_SALE.PRINTER_NOT_CAPABLE');
                                    break;
                                case "PRINTER_NOT_FOUND":
                                    errorMessage = this.translateService.instant('CASHREGISTER.ACTIVE_SALE.PRINTER_NOT_FOUND');
                                    break;
                                case "UNKNOWN_ERROR":
                                    errorMessage = this.translateService.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR');
                                    break;
                                case "ID_NOT_SET":
                                    errorMessage = this.translateService.instant('CASHREGISTER.ACTIVE_SALE.ID_NOT_SET');
                                    break;
                                default:
                                    errorMessage = this.translateService.instant('CASHREGISTER.ACTIVE_SALE.UNKNOWN_ERROR');
                                    break;
                            }

                            this.alertDialogService.openDialog({ data: { messageLabel: this.translateService.instant('CASHREGISTER.ACTIVE_SALE.ATTENTION') + errorMessage } });
                        }
                    }
                }

                break;
            default:
                break;
        }
        this.stateParams = {
            ...this.stateParams,
            advancedpayments: null,
            id: null,
            saleType: null
        };
        this.state.params.action = null; //(TASK TC-1954)
    };
    private subscriptions(){
        subscribeInComponent(this.dualService.currentRoute$, (currentRoute) => {
            this.isPaymentsState = currentRoute.includes("payments");
        });

        if(
            (this.environmentInfoService.isElectronApp() && this.configurationManagerService.getPreference('customer_display.enable_color_display')) || 
            this.configurationManagerService.getPreference('customer_display.enable_network_display')
        ) {
            subscribeInComponent(this.saleUpdates$, (data) => this.customerDisplayManagerService.updateColorDisplay(data.currentSale));
        }

        if(this.configurationManagerService.getPreference('customer_display.enable_two_rows_display')) {
            subscribeInComponent(ActiveSaleService.activeSaleEvents$, (event) => this.customerDisplayManagerService.updateTwoRowsDisplay(event));
        }
    }
    private async showSavedSales() {
        if (!this.isShowcase()) {
            return;
        }
        const saleId = await this.savedSalesDialogService.show();
        return this.activeSaleService.loadSale(saleId);
    };
    private isShowcase() {
        return this.state.current.name === 'app.cashregister.content.showcase';
    };
}
