<tilby-dialog-toolbar mat-dialog-title
    [title]="dialogTitle"
    (confirm)="confirm()"
/>
<tilby-dialog-content>
    <form [formGroup]="downloadBookingForm" (ngSubmit)="confirm()">
        <mat-radio-group formControlName="format">
            <mat-radio-button value="xls" class="md-primary">XLS</mat-radio-button>
            <mat-radio-button value="pdf" class="md-primary">PDF</mat-radio-button>
        </mat-radio-group>
    </form>
</tilby-dialog-content>
