import {
    Component,
    OnInit,
    inject
} from '@angular/core';

import {
    ConfigurationManagerService,
    EntityManagerService,
    RIGHT_SIDENAV_PORTAL_DATA_TOKEN,
    RightSidenavService
} from 'src/app/core/services';

import {BtnHistorySaleDetail} from 'src/app/features/history/history-sales/model/history-sales.model';
import {Sales, TilbyAllReviewsResponse, TilbyReview} from 'tilby-models';
import {HistorySalesService} from 'src/app/features/history/history-sales/services/history-sales.service';
import {GridServerSideComponent} from 'src/app/shared/components/grid-server-side/grid-server-side.component';
import {GridService} from "src/app/shared/components/grid/grid.service";
import {OpenDialogsService} from "src/app/dialogs";

import {
    errorsLogger,
    restManager,
    util
} from "app/ajs-upgraded-providers";

import { removeFieldsWithPrefix } from 'src/app/shared/string-utils';
import { HistoricalChangesDialogService } from 'src/app/dialogs/historical-changes-dialog/historical-changes-dialog.component';
import { SaleTransactionUtilsService } from 'src/app/features/cashregister';

@Component({
    selector: 'app-history-sale-detail',
    templateUrl: './history-sale-detail.component.html',
    styleUrls: ['./history-sale-detail.component.scss'],
    host: {class: 'tw-flex tw-flex-col tw-h-full tw-items-center tw-justify-between tw-overflow-hidden'}
})
export class HistorySaleDetailComponent implements OnInit {
    btnsHeader: BtnHistorySaleDetail[] = [];
    btnsFooter: BtnHistorySaleDetail[] = [];

    private readonly configurationManager = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly errorsLoggerService = inject(errorsLogger);
    private readonly gridService = inject(GridService);
    private readonly historicalChangesDialogService = inject(HistoricalChangesDialogService);
    private readonly historySalesService = inject(HistorySalesService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly rightSidenavService = inject(RightSidenavService<HistorySaleDetailComponent>);
    private readonly saleTransactionUtilsService = inject(SaleTransactionUtilsService);
    private readonly utilService = inject(util);
    private readonly restManager = inject(restManager);
    protected readonly data = inject(RIGHT_SIDENAV_PORTAL_DATA_TOKEN) as unknown as { sale: Sales, gridRef: GridServerSideComponent } 
    reviewsResponse: TilbyReview[] = [];
    fullStarRating: number[] = [];
    emptyStarRating: number[] = [];
    isLoadingReviews: boolean = false;


    private readonly hasToolbarMenu = this.configurationManager.isUserPermitted("show_developer_options");

    async fetchSaleReviews() {
        this.isLoadingReviews = true;

        //If this returns, isLoadingReviews is kept to true. This is intentional as it hides the review section.
        if(!this.configurationManager.getPreference('history.enable_reviews')) {
            return;
        }

        try {
            const responseReview: TilbyAllReviewsResponse = await this.restManager.getOne(`reviews?uuid=${this.data.sale.uuid}`);

            if(responseReview.results) {
                this.reviewsResponse = responseReview.results as TilbyReview[];
                this.fullStarRating = new Array(this.reviewsResponse[0].rating).fill(0);

                if(this.fullStarRating.length < 5) {
                    this.emptyStarRating = new Array(5 - this.fullStarRating.length).fill(0);
                }
            } else {
                this.reviewsResponse = [];
            }
        } catch (err) {
            this.errorsLoggerService.err(err);
        } finally {
            this.isLoadingReviews = false;
        }
    }

    ngOnInit(): void {
        this.btnsHeader = this.headerToggleButtonsCreation();
        this.btnsFooter = this.footerToggleButtonsCreation();
        this.fetchSaleReviews();
    }

    headerToggleButtonsCreation(): BtnHistorySaleDetail[] {
        return [
            {
                id: 0,
                show: true,
                label: 'HISTORY.SALES.DETAIL.NAME',
                icon: () => '',
                callback: () => {}
            },
            {
                id: 1,
                show: this.hasToolbarMenu,
                label: '',
                icon: () => 'menu',
                callback: () => {}
            },
            {
                id: 2,
                show: true,
                label: '',
                icon: () => 'close',
                callback: () => this.closeSidenav()
            }
        ];
    }

    footerToggleButtonsCreation(): BtnHistorySaleDetail[] {
        return [
            ...(!this.historySalesService.isCreditNote(this.data.sale) ? [{
                id: 0,
                label: this.historySalesService.isEInvoice(this.data.sale) ? 'HISTORY.SALES.SHOWCASE.ACTIONS.CREDIT_NOTE' : 'HISTORY.SALES.SHOWCASE.ACTIONS.CANCEL',
                icon: () => 'undo',
                callback: () => this.historySalesService.refundVoid(this.data.sale, this.data.gridRef)
            }] : []),
            ...(!this.historySalesService.isEInvoice(this.data.sale) ? [{
                id: 1,
                label: 'HISTORY.SALES.SHOWCASE.ACTIONS.COURTESY_RECEIPT',
                icon: () => 'pages',
                callback: () => this.historySalesService.printCourtesyReceipt(this.data.sale)
            }] : []),
            {
                id: 2,
                label: 'HISTORY.SALES.SHOWCASE.ACTIONS.PRINTED_DOCUMENTS',
                icon: () => 'receipt_long',
                callback: () => this.historySalesService.showSaleDocuments(this.data.sale)
            }
        ];
    }

    async openSaleJsonDetail() {
        await this.openDialogsService.openGenericJsonDetailDialog({data: { title:'HISTORY.SALES.DETAIL.TITLE_OPEN_JSON_DETAIL', nameFile: 'sale_json_data', json: removeFieldsWithPrefix(this.data.sale || {}, '$')  } });
    }

    async openEditHistoryDetail() {
        try {
            const transactions = await this.entityManagerService.saleTransactions.fetchCollectionOnline({ pagination: false, sale_uuid: this.data.sale.uuid});

            if(Array.isArray(transactions)) {
                let closedTransaction = transactions.find((transaction) => transaction.sale_details?.status === 'closed');

                if(!closedTransaction) {
                    const unfinishedSale = this.saleTransactionUtilsService.buildSaleFromTransactions(transactions);

                    if(unfinishedSale) {
                        closedTransaction = this.saleTransactionUtilsService.createTransactionFromSalesComparison(this.data.sale, unfinishedSale);
                        transactions.push(closedTransaction);
                    }
                }

                await this.historicalChangesDialogService.openDialog({data: {title: 'HISTORY.TRANSACTIONS_VIEWER.TITLE', transactions, sale:this.data.sale}});
            }
        } catch (err) {
            this.errorsLoggerService.err(err);
        }
    }

    getCustomerCaption() {
        return this.data.sale?.customer_tax_code || this.utilService.getCustomerCaption(this.data.sale?.sale_customer);
    }

    private closeSidenav() {
        this.rightSidenavService.isOpen = false;
        this.gridService.history_sales$.closeDetail.next();
    }
}

