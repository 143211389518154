import {Inject, Injectable} from "@angular/core";
import {SupplierDefault, SuppliersFields, SuppliersForm, SuppliersValidators} from "../suppliers-form.model";
import { Validators} from "@angular/forms";
import {
    codeToCountry,
    countryCodesShort
} from "src/app/core/constants"
import {noWhitespaceValidator, RequireMatch} from "@tilby/tilby-ui-lib/utilities";
import {ConfigurationManagerService} from "../../../core";
import {util} from "../../../../../app/ajs-upgraded-providers";
import {
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    hideFields,
    labeling
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {headersTranslate} from "../../../shared/components";

@Injectable({
    providedIn: 'root'
})
export class SupplierFormService {
    suppliersForm = new CustomFormGroup<SuppliersForm>({});
    validators: SuppliersValidators = {
        nameMaxLength: 255,
        phoneMaxLength: 15,
        faxMaxLength: 13,
        emailMaxLength: 255,
        websiteMaxLength: 255,
        address: {
            streetMaxLength: 255,
            numberMaxLength: 25,
            zipMaxLength: 10,
            cityMaxLength: 255,
            provMinLength: 2,
            provMaxLength: (this.configurationManagerService.getPreference('general.address_country') || '').toUpperCase() === 'IT' ? 2 : 255,
            countryMaxLength: 255
        }
    }
    constructor(
        @Inject(util) private utilService: any,
        private configurationManagerService: ConfigurationManagerService,
    ) {}

    createForm(supplier:SuppliersFields=new SupplierDefault(),hiddenFields?:Array<keyof SuppliersFields>) {
        const {...s} = supplier || {} as SuppliersFields;

        if(s.country) {
            s.country = codeToCountry('country', s.country) || '';
        }

        this.suppliersForm = new CustomFormGroup<SuppliersForm>({
                address_city: new CustomFormControl(s.address_city || null, {validators:Validators.maxLength(this.validators.address.cityMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.address_city?.value?.length || 0}/ ${this.validators.address.cityMaxLength}`}),
                address_number: new CustomFormControl(s.address_number || null, {validators:Validators.maxLength(this.validators.address.numberMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.address_number?.value?.length || 0}/ ${this.validators.address.numberMaxLength}`}),
                address_prov: new CustomFormControl(s.address_prov || null, {validators: [Validators.minLength(this.validators.address.provMinLength),Validators.maxLength(this.validators.address.provMaxLength)]},{...new CustomFormControlProps(),hint:()=>`${this.address_prov?.value?.length || 0}/ ${this.validators.address.provMaxLength}`}),
                country: new CustomFormControl(s.country || null, {validators:RequireMatch(countryCodesShort)},{...new CustomFormControlProps(),inputType:'autocomplete',inputChoices:countryCodesShort.map(({code,name})=>({key:code,value: name}))}),
                address_street: new CustomFormControl(s.address_street || null, {validators:Validators.maxLength(this.validators.address.streetMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.address_street?.value?.length || 0}/ ${this.validators.address.streetMaxLength}`}),
                address_zip: new CustomFormControl(s.address_zip || null, {validators:Validators.maxLength(this.validators.address.zipMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.address_zip?.value?.length || 0}/ ${this.validators.address.zipMaxLength}`}),
                conto_vendita: new CustomFormControl(s.name?.indexOf('[CV] ') > -1 || false,{},{...new CustomFormControlProps(),inputType:'checkbox'}),
                // created_at: new CustomFormControl(s.created_at || null),
                email1: new CustomFormControl(s.email1 || null, {validators: [Validators.email, Validators.maxLength(this.validators.emailMaxLength)]},{...new CustomFormControlProps(),hint:()=>`${this.email1?.value?.length || 0}/ ${this.validators.emailMaxLength}`}),
                email2: new CustomFormControl(s.email2 || null, {validators: [Validators.email, Validators.maxLength(this.validators.emailMaxLength)]},{...new CustomFormControlProps(),hint:()=>`${this.email2?.value?.length || 0}/ ${this.validators.emailMaxLength}`}),
                email_pec: new CustomFormControl(s.email_pec || null, {validators: [Validators.email, Validators.maxLength(this.validators.emailMaxLength)]},{...new CustomFormControlProps(),hint:()=>`${this.email_pec?.value?.length || 0}/ ${this.validators.emailMaxLength}`}),
                fax: new CustomFormControl(s.fax || null, {validators:Validators.maxLength(this.validators.faxMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.fax?.value?.length || 0}/ ${this.validators.faxMaxLength}`}),
                id: new CustomFormControl(s.id || null),
                name: new CustomFormControl(s.name?.split('[CV] ').pop() || null, {validators: [Validators.required, noWhitespaceValidator, Validators.maxLength(this.validators.nameMaxLength)]},{...new CustomFormControlProps(),hint:()=>`${this.name?.value?.length || 0}/ ${this.validators.nameMaxLength}`}),
                notes: new CustomFormControl(s.notes || null),
                phone1: new CustomFormControl(s.phone1 || null, {validators:Validators.maxLength(this.validators.phoneMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.phone1?.value?.length || 0}/ ${this.validators.phoneMaxLength}`}),
                phone2: new CustomFormControl(s.phone2 || null, {validators:Validators.maxLength(this.validators.phoneMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.phone2?.value?.length || 0}/ ${this.validators.phoneMaxLength}`}),
                // updated_at: new CustomFormControl(s.updated_at || null),
                vat_code: new CustomFormControl(s.vat_code || null, {validators: [Validators.required, noWhitespaceValidator, Validators.pattern(this.utilService.getVatRegexp())]},{...new CustomFormControlProps(),}),
                website: new CustomFormControl(s.website || null, {validators:Validators.maxLength(this.validators.websiteMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.website?.value?.length || 0}/ ${this.validators.websiteMaxLength}`}),
            },
            { /*validators: MyProvFormValidator(this.validators.address)*/ }
        );

        labeling<SuppliersForm>(this.suppliersForm?.controls,headersTranslate.suppliers_showcase);
        if(hiddenFields){ hideFields<SuppliersForm>(this.suppliersForm?.controls,hiddenFields)}
        return this.suppliersForm;
    }

    get name() {
        return this.suppliersForm?.controls.name;
    }

    get vat_code() {
        return this.suppliersForm?.controls.vat_code;
    }

    get phone1() {
        return this.suppliersForm?.controls.phone1;
    }

    get phone2() {
        return this.suppliersForm?.controls.phone2;
    }

    get fax() {
        return this.suppliersForm?.controls.fax;
    }

    get email1() {
        return this.suppliersForm?.controls.email1;
    }

    get email2() {
        return this.suppliersForm?.controls.email2;
    }

    get email_pec() {
        return this.suppliersForm?.controls.email_pec;
    }

    get website() {
        return this.suppliersForm?.controls.website;
    }

    get address_street() {
        return this.suppliersForm?.controls.address_street;
    }

    get address_number() {
        return this.suppliersForm?.controls.address_number;
    }

    get address_zip() {
        return this.suppliersForm?.controls.address_zip;
    }

    get address_city() {
        return this.suppliersForm?.controls.address_city;
    }

    get address_prov() {
        return this.suppliersForm?.controls.address_prov;
    }

    get country() {
        return this.suppliersForm?.controls.country;
    }

}
