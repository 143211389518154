import {
	Component,
	inject,
	Injectable
} from '@angular/core';

import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent
} from "@angular/material/dialog";

import {
	BaseDialogService,
	NonNullableConfigData,
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
	lastValueFrom
} from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

export interface DataConfirmDialog {
	messageLabel: string;
	messageParams?: { [key: string]: string };
	cancelLabel?: string;
	confirmLabel?: string;
}

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	imports: [
		MatButtonModule,
		MatDialogActions,
		MatDialogContent,
		MatDialogClose,
		TranslateModule
	],
	standalone: true
})
export class ConfirmDialogComponent {
	public data: DataConfirmDialog = inject(MAT_DIALOG_DATA);
}

@Injectable({
	providedIn: 'root'
})
export class ConfirmDialogService extends BaseDialogService {
	private readonly dialogRef = inject(MatDialog);

	public openDialog(options: NonNullableConfigData<DataConfirmDialog>): Promise<boolean | void> {
		const config: NonNullableConfigData<DataConfirmDialog> = {
			...this.switchMobileDesktopDimensions({ width: '600px' }),
			...options,
			data: options.data
		};

		return lastValueFrom(this.dialogRef.open(ConfirmDialogComponent, config).afterClosed());
	}

	/**
	 * @deprecated needed for backwards compatibility, use `openDialog` instead
	 * 
	 */
	public show(message: string, options?: { yesLabel?: string, noLabel?: string }): Promise<boolean | void> {
		return this.openDialog({
			data: {
				messageLabel: message,
				cancelLabel: options?.noLabel,
				confirmLabel: options?.yesLabel
			}
		});
	}
}