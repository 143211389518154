import {
    inject, 
    Injectable
} from "@angular/core";

import {
    ConfigurationManagerService,
    EntityManagerService
} from "src/app/core/services";

import {
    $state,
    saleUtils
} from "app/ajs-upgraded-providers";

import {
    Sales,
    SalesCustomer,
    SalesEInvoice,
    SalesItems,
    SalesPayments,
    SalesPriceChanges
} from "tilby-models";

import { TranslateService } from "@ngx-translate/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";

type SaleData = {
    e_invoice?: SalesEInvoice
    name?: string,
    payments?: SalesPayments[],
    price_changes?: SalesPriceChanges[],
    notes?: string,
    sale_customer?: SalesCustomer | string,
    sale_items?: SalesItems[],
    overrides?: Partial<Sales>
}

type CreateSaleOptions = {
    saleType: string,
    returnOnly?: boolean
}

@Injectable({
    providedIn: 'root'
})
export class HistoryUtilsService {
    private readonly $stateService = inject($state);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly saleUtilsService = inject(saleUtils);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translate = inject(TranslateService);

    private getSaleName (sale: Sales, saleName: string, options?: CreateSaleOptions) {
        switch (options?.saleType) {
            case 'credit_note': case 'e_credit_note':
                return `${saleName.slice(0, 27)} NC`;
            case 'refund_doc':
                return `${saleName.slice(0, 27)} R`;
            case 'void_doc':
                return `${saleName.slice(0, 27)} AN`;
            case 'summary':
                return `${this.translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE')} ${sale.sale_number} (R)`;
            case 'summary_e_rc': case 'summary_e_nrc':
                return `${this.translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE')} ${sale.sale_number} (RE)`;
            default:
                return `${this.translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE')} ${sale.sale_number}`;
        }
    }

    public async createSale(saleData: SaleData, options?: CreateSaleOptions): Promise<Sales> {
        const sale: Sales = await this.saleUtilsService.getSaleTemplate();
        const isItalianFiscalization = ['IT', 'MT'].includes(this.configurationManagerService.getShopCountry());

        if (saleData.e_invoice) {
            const { id, sale_id, invoice_progressive, invoice_yearly_progressive, send_progressive, date, lastcreate_at, external_id, external_message, external_status, intermediary_name, last_status_update, status, ...eInvoice } = saleData.e_invoice;
            sale.e_invoice = eInvoice;
        }

        if (saleData.price_changes) {
            sale.price_changes = this.saleUtilsService.getCleanSubEntity(saleData.price_changes);
        }

        if (Array.isArray(saleData.sale_items)) {
            sale.sale_items = saleData.sale_items.map((saleItem) => {
                const newSaleItem = this.saleUtilsService.getCleanSaleItem(saleItem, !!saleData.price_changes);

                for (const priceChange of newSaleItem.price_changes) {
                    delete priceChange.promotion_id;
                }

                return newSaleItem;
            });
        }

        sale.name = this.getSaleName(sale, saleData.name ||  '', options);
        sale.notes = saleData.notes;
        sale.is_summary = ['summary', 'summary_e_rc', 'summary_e_nrc'].includes(options?.saleType || '');
        sale.payments = this.saleUtilsService.getCleanSubEntity(saleData.payments);

        switch(typeof saleData.sale_customer) {
            case 'string':
                sale.customer_tax_code = saleData.sale_customer;
                break;
            case 'object':
                if(saleData.sale_customer) { // Make sure saleData.sale_customer is not null
                    const { id, ...customer } = saleData.sale_customer;
                    sale.sale_customer = customer;
                }
                break;
        }

        if (this.configurationManagerService.isModuleEnabled('cashregister')) {
            sale.assigned_id = sale.seller_id;
            sale.assigned_name = sale.seller_name;
        }

        if (['summary_e_rc', 'summary_e_nrc'].includes(options?.saleType || '') && isItalianFiscalization) {
            const scontrini: Record<string, { date: string, type: string}> = {};

            for(const item of saleData.sale_items || []) {
                const seqNum = item.reference_sequential_number || item.reference_text;

                if(seqNum != null) {
                    scontrini[seqNum] = {
                        date: this.tilbyDatePipe.transform(item.reference_date),
                        type: (typeof seqNum === 'string') && /[0-9]{4}-[0-9]{4}/.test(seqNum) ? "Doc.Com" : "Scontrino"
                    };
                }
            }

            const cause: string[] = [];

            for(const seqNum in scontrini) {
                const data = scontrini[seqNum];
                cause.push(`Rif. ${data.type} n. ${seqNum} del ${data.date}`);
            }

            sale.e_invoice = {
                invoice_cause: cause.join('\n')
            };
        }

        Object.assign(sale, saleData.overrides || {});

        this.saleUtilsService.calculateSalePrices(sale);

        return new Promise(async (resolve, reject) => {
            resolve(sale);

            if (!options?.returnOnly) {
                let saleType = options?.saleType;

                if (!isItalianFiscalization) {
                    saleType = sale.is_summary ? 'generic_invoice' : 'generic_receipt';
                }

                await this.entityManagerService.sales.postOneOfflineFirst(sale);

                if (this.configurationManagerService.isModuleEnabled('cashregister')) {
                    const targetState = this.configurationManagerService.isModuleAngular('tables_and_cashregister') ? 'app.new.cashregister.content.showcase' : 'app.cashregister.content.showcase';

                    this.$stateService.go(targetState, {
                        action: 'open-sale-id',
                        saleType: saleType,
                        id: sale.uuid
                    });
                }
            }
        });
    }
}
