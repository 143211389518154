import * as angular from 'angular';

angular.module('bookings', ['application']);

angular.module('bookings').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {

    $translatePartialLoaderProvider.addPart('bookings');

    $stateProvider.state('app.bookings', {
        url: '/old_bookings',
        redirectTo: 'app.bookings.showcase'
    });

    $stateProvider.state('app.bookings.showcase', {
        url: '/showcase',
        views: {
            "appContent@app": {
                template: require('./partial/bookings-showcase/bookings-showcase.html'),
                controller: 'bookingsShowcaseCtrl'
            }
        },
        resolve: {
            rooms: ["entityManager", function(entityManager) {
                return entityManager.rooms.fetchCollectionOffline();
            }]
        }
    });

    $stateProvider.state('app.bookings.showcase.customer', {
        url: '/customer',
        params: {
            customer: null
        },
        views: {
            "appContent@app": {
                template: require('./partial/bookings-showcase/bookings-showcase.html'),
                controller: 'bookingsShowcaseCtrl'
            }
        },
        resolve: {
            rooms: ["entityManager", function(entityManager) {
                return entityManager.rooms.fetchCollectionOffline();
            }]
        }
    });

    /* Add New States Above */
}]);
