<ng-container *ngIf="{
            currentRoute: currentRoute$ | async,
} as data">
    <mat-icon (click)="closeMenuSidenavAndSubmenus()" class="tw-cursor-pointer tw-m-8 tw-overflow-visible">close</mat-icon>
    <app-toolbar-utente class="tw-flex tw-px-4 tw-pb-6 tw-box-border" [operatorName]="operatorName||''"
                        [shopName]="shopName" (goTo)="goTo.emit($event)"/>
    <mat-divider/>
    <mat-nav-list class="tw-flex-1 tw-overflow-y-auto">
        <app-nav-list
            #navList
            [tilbyVersion]="tilbyVersion"
            [country]="country"
            [currentRoute]="data.currentRoute||''"
            [submenus]="submenus"
            [user]="user"
            (closeLeftMenu)="closeMenuSidenavAndSubmenus()"
            (goTo)="goTo.emit($event)"
        ></app-nav-list>
    </mat-nav-list>
    <mat-divider/>
    <div class="app-version tw-flex tw-justify-end tw-items-center tw-text-xs">
        <span>{{ tilbyVersion }}</span>
        <mat-icon class="tw-ml-1 tw-mr-3 tw-cursor-pointer" alt="" (click)="showDeviceInfo()">info</mat-icon>
    </div>
</ng-container>
