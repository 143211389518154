import * as angular from 'angular';

angular.module('settingsNew', ['application']);

angular.module('settingsNew').config(["$stateProvider", function ($stateProvider: any) {
    $stateProvider.state('app.new.settings', {
        url: '/settings',
        redirectTo: 'users'
    });

    $stateProvider.state('app.new.settings.users_management', {
        url: '/users-management',
        component: 'settingsUsersManagement',
        params: {
            tab: { type: 'int', value: null },
        },
    });

    $stateProvider.state('app.new.settings.model_document', {
        url: '/model-document',
        component: 'settingsModelDocument'
    });

    $stateProvider.state('app.new.settings.label_panel', {
        url: '/label-panel',
        component: 'settingsLabelPanel'
    });

    $stateProvider.state('app.new.settings.my_subscription', {
        url: '/my-subscription',
        component: 'mySubscription'
    });

    $stateProvider.state('app.new.settings.channels_management', {
        url: '/channels',
        component: 'settingsChannels'
    });

    $stateProvider.state('app.new.settings.tickets', {
        url: '/tickets',
        component: 'settingsTickets'
    });

    $stateProvider.state('app.new.settings.user', {
        url: '/user',
        component: 'settingsUser'
    });

    $stateProvider.state('app.new.settings.kiosk', {
        url: '/kiosk',
        component: 'settingsKiosk'
    });

    $stateProvider.state('app.new.settings.shifts', {
        url: '/shifts',
        component: 'settingsShifts'
    });

    $stateProvider.state('app.new.settings.alvolo', {
        url: '/alvolo',
        component: 'settingsAlvolo'
    });
}]);
