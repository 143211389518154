import { CommonModule } from "@angular/common";
import { Component, Injectable, OnInit, inject } from "@angular/core";
import { MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { lastValueFrom } from "rxjs";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { WellbyCardResponse, WellbyPmsApiService } from "src/app/shared/services/digital-payments/wellby/wellby-pms-api.service";
import { ConfigurationManagerService } from "src/app/core";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { MatDividerModule } from "@angular/material/divider";

@Component({
    selector: 'app-wellby-pms-payments-dialog',
    standalone: true,
    imports: [CommonModule, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent, MatDialogModule, MatIconModule, TilbyDatePipe,
        MatFormFieldModule, FormsModule, TranslateModule, ReactiveFormsModule, MatInputModule,
        MatButtonModule, MatListModule, MatRadioModule, TilbyCurrencyPipe, MatDividerModule],
    templateUrl: './wellby-pms-payments-dialog.component.html',
    styleUrls: ['./wellby-pms-payments-dialog.component.scss']
})
class WellbyPmsPaymentsDialogComponent implements OnInit {

    private readonly wellbyPmsApiService = inject(WellbyPmsApiService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly translateService = inject(TranslateService);
    private readonly matDialogRef = inject(MatDialogRef);

    status: 'initialize' | 'search-card' | null = null;

    loginForm!: FormGroup;
    searchForm!: FormGroup;

    credentialsOk = true;
    credentialsErrorMessage : string = '';
    cardOk = true;
    searchError : string | undefined = undefined;

    sessionId : string | undefined = undefined;

    cards: WellbyCardResponse[] = [];
    cardSelected: WellbyCardResponse | undefined = undefined;

    constructor(private fb: FormBuilder) {
        this.status = this.configurationManagerService.getPreference('digital_payments.wellby.server_address') ? 'search-card' : 'initialize';
    }

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            serverAddress: ['', Validators.required],
            port: ['', Validators.required],
            plantCode: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            userType: ['', Validators.required]
        });

        this.searchForm = this.fb.group({
            cardNumber: [''],
            surname: ['']
        });
    }

    ngAfterViewInit(): void {
        this.changeSearchCardInput();
    }

    getTitle() {
        switch (this.status) {
            case 'initialize':
            case 'search-card':
                return this.translateService.instant('CASHREGISTER.WELLBY_PMS_PAYMENTS.TITLE');
            default:
                return '';
        }
    }

    async confirm() {

        if (this.sessionId) {
            const response = await this.wellbyPmsApiService.logout({
                plantCode: this.loginForm.value.plantCode,
                sessionId: this.sessionId
            });

            if (response.esitoCodice === '0') {
                this.matDialogRef.close(this.cardSelected);
            } else {
                console.error('ERROR_DURING_CLOSE_SESSION');
            }
        }

    }

    async onLogin() {

        try {
            const response = await this.wellbyPmsApiService.checkLoginCredentials({
                serverAddress: this.loginForm.value.serverAddress,
                port: this.loginForm.value.port,
                plantCode: this.loginForm.value.plantCode,
                username: this.loginForm.value.username,
                password: this.loginForm.value.password,
                userType: this.loginForm.value.userType
            })

            if (response?.esitoCodice === '0') {
                this.configurationManagerService.setShopPreference('digital_payments.wellby.server_address', this.loginForm.value.serverAddress);
                this.configurationManagerService.setShopPreference('digital_payments.wellby.port', this.loginForm.value.port);
                this.configurationManagerService.setShopPreference('digital_payments.wellby.plant_code', this.loginForm.value.plantCode);
                this.configurationManagerService.setShopPreference('digital_payments.wellby.username', this.loginForm.value.username);
                this.configurationManagerService.setShopPreference('digital_payments.wellby.password', this.loginForm.value.password);
                this.configurationManagerService.setShopPreference('digital_payments.wellby.user_type', this.loginForm.value.userType);
                this.wellbyPmsApiService.refreshConnectionPreferences();
                this.status = 'search-card';
                this.sessionId = response.sessionId;
            } else {
                this.credentialsErrorMessage = 'CASHREGISTER.WELLBY_PMS_PAYMENTS.ERROR_CHECK_CREDENTIALS';
                this.credentialsOk = false;
            }

        } catch (e) {
            this.credentialsErrorMessage = 'CASHREGISTER.WELLBY_PMS_PAYMENTS.ERROR_CHECK_CREDENTIALS_HTTP';
            this.credentialsOk = false;
        }
    }

    async onSearchCustomer() {

        this.searchError = undefined;
        this.cards = [];
        this.cardSelected = undefined;

        try {
            let responseCard = undefined;

            if (!this.sessionId) {
                const response = await this.wellbyPmsApiService.login();
                if (response?.esitoCodice !== '0') {
                    throw 'CASHREGISTER.WELLBY_PMS_PAYMENTS.SESSION_ID_NOT_FOUND';
                }
                this.sessionId = response.sessionId;
            }

            if (this.sessionId) {

                if (this.searchForm.get('cardNumber')?.value && !this.searchForm.get('surname')?.value) {
                    const card = this.wellbyPmsApiService.getCodificaUid(this.searchForm.get('cardNumber')?.value);
                    responseCard = await this.wellbyPmsApiService.getListOfAccountsCard(this.sessionId, card);
                } else if (this.searchForm.get('surname')?.value && !this.searchForm.get('cardNumber')?.value) {
                    const responsePersonalData = await this.wellbyPmsApiService.getIdPersonalData(this.sessionId, this.searchForm.get('surname')?.value);
                    for (let i = 0; i < responsePersonalData.idCustomers.length; i++) {
                        const tempResponseCard = await this.wellbyPmsApiService.getListOfAccountsPersonalData(this.sessionId, responsePersonalData.idCustomers[i]);
                        i === 0 ? responseCard = tempResponseCard : responseCard!.accounts = responseCard!.accounts.concat(tempResponseCard.accounts);
                    }
                }

                if (responseCard?.esito === '0') {
                    this.cards = responseCard.accounts;
                    if (this.searchForm.get('cardNumber')?.value ) {
                        const card = this.wellbyPmsApiService.getCodificaUid(this.searchForm.get('cardNumber')?.value);
                        this.cards = this.updateCardsWithTagNumber(this.cards, card);
                    }
                    this.cardOk = true;
                } else {
                    this.cardOk = false;
                }

            }

        } catch (e : any) {
            this.searchError = e;
        }
    }

    onSearchCardChange() {
        this.searchForm.get('surname')?.setValue('');
    }

    onSearchSurnameChange() {
        this.searchForm.get('cardNumber')?.setValue('');
    }

    changeSearchCardInput() {
        const formElements = document.getElementsByClassName("parent-hide-bottom-div");
        if (formElements.length > 0) {
            for (let i = 0; i < formElements.length; i++) {
                const matForm = formElements[i];
                const targetDivs = matForm.querySelectorAll(
                '.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align'
                );
                targetDivs.forEach((div) => div.classList.add('hide-bottom-div'));
            }
        }
    }

    /**
     * Update card only accountType is debit and userId has not value
     *
     */
    updateCardsWithTagNumber(cardsResponse: WellbyCardResponse[], tagNumber: string): WellbyCardResponse[] {
        cardsResponse.forEach((cardResponse: WellbyCardResponse) => {
            if (cardResponse.accountType === "1" && !cardResponse.userId) {
                cardResponse.tagNumber = tagNumber;
            }
        });
        return cardsResponse;
    }
}

export type DataDialogData = {amount?:number};

@Injectable({
    providedIn: 'root'
})
export class WellbyPmsPaymentsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: DataDialogData){
        if(!data) {
            data={amount:0};
        }
        const config:NonNullableConfigData<any>={...this.switchMobileDesktopDimensions(), disableClose:true, data };
        return lastValueFrom(this.dialogRef.open(WellbyPmsPaymentsDialogComponent,config).afterClosed()).then((res : WellbyCardResponse) => {
            return res ? { cardCode : res } : undefined;
        });
    }
}
