import {
  Component,
  OnInit,
} from "@angular/core";

import {
  MatSnackBarRef,
} from "@angular/material/snack-bar";

@Component({
    selector: 'app-grid-save-user-preference',
    templateUrl: './grid-save-user-preference.component.html',
    styleUrls: ['./grid-save-user-preference.component.scss']
  })
  export class GridSaveUserPreferenceComponent implements OnInit {

    constructor(
        public sbRef: MatSnackBarRef<GridSaveUserPreferenceComponent>
      ) {
      }

    saveUserPreference() {
        this.sbRef.dismissWithAction();
    }

    onActionBtnClick() {
        this.sbRef.dismiss();
    }

    ngOnInit() {}
  }
