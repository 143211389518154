import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { SclDictionary } from 'app/libs/SclDictionary';
import { DevLogger } from 'src/app/shared/dev-logger';

angular.module('dashboard').factory("dashboardDataManager", dashboardDataManager);

dashboardDataManager.$inject = ["$http", "$rootScope", "$translate", "checkManager", "entityManager", "restManager", "util"];

function dashboardDataManager($http, $rootScope, $translate, checkManager, entityManager, restManager, util) {
    const stockFields = ['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity'];

    let dataCache;

    const getDataCache = () => _.cloneDeep(dataCache);

    const sumPath = (data, path) => util.round(_.toFinite(_.sumBy(data, path)));

    const getReducer = (identityFields, sumFields) => (objs, key) => {
        let x = _.chain(objs).head().pick(identityFields).value();

        _.forEach(sumFields, (field) => {
            x[field] = sumPath(objs, field);
        });

        return x;
    };

    const reducePrinters = (inputData) => {
        let paymentIdFields = ['name', 'unclaimed', 'payment_method_id', 'type_id', 'ticket_name'];
        let reduceVats = getReducer(['name'], ['amount', 'net_amount', 'vat_amount']);
        let reduceTickets = getReducer(['name'], ['count', 'amount']);
        let reduceAmounts = getReducer(['name'], ['amount']);
        let reduceChannels = getReducer(['channel'],['count','amount']);
        let ordersReducer = getReducer([],['count','amount','covers']);
        let salesReducer = getReducer([],['count','amount']);
        let paymentsReducer = getReducer(paymentIdFields, ['amount']);
        let paymentIdentity = (payment) => _(payment).pick(paymentIdFields).thru(JSON.stringify).value();

        return {
            isTotal: true,
            name: $translate.instant('DASHBOARD.TOTAL'),
            cash_movements: {
                sales: sumPath(inputData, 'cash_movements.sales'),
                tickets: _(inputData).map('cash_movements.tickets').compact().flatten().groupBy('name').values().map(reduceTickets).value()
            },
            refunds: {
                amount: sumPath(inputData, 'refunds.amount'),
                count: sumPath(inputData, 'refunds.count'),
            },
            voids: {
                amount: sumPath(inputData, 'voids.amount'),
                count: sumPath(inputData, 'voids.count'),
            },
            channels: _(inputData).map('channels').map(_.values).flatten().groupBy('channel').mapValues(reduceChannels).value(),
            departments: _(inputData).map('departments').compact().flatten().groupBy('name').values().map(reduceAmounts).value(),
            fiscal_receipts: {
                count: sumPath(inputData, 'fiscal_receipts.count'),
                unclaimed: sumPath(inputData, 'fiscal_receipts.unclaimed'),
                vats: _(inputData).map('fiscal_receipts.vats').compact().flatten().groupBy('name').values().map(reduceVats).value()
            },
            food_cost: sumPath(inputData, 'food_cost'),
            invoices: {
                count: sumPath(inputData, 'invoices.count'),
                unclaimed: sumPath(inputData, 'invoices.unclaimed'),
                vats: _(inputData).map('invoices.vats').compact().flatten().groupBy('name').values().map(reduceVats).value()
            },
            orders: _(inputData).map('orders').compact().thru(ordersReducer).value(),
            payments: _(inputData).map('payments').flatten().groupBy(paymentIdentity).map(paymentsReducer).value(),
            price_changes: _(inputData).map('price_changes').compact().flatten().groupBy('type').values().map(getReducer(['type'], ['amount'])).value(),
            sales: _(inputData).map('sales').compact().thru(salesReducer).value(),
            summary_rc_invoices: {
                count: sumPath(inputData, 'summary_rc_invoices.count'),
                vats: _(inputData).map('summary_rc_invoices.vats').compact().flatten().groupBy('name').values().map(reduceVats).value()
            },
            summary_nrc_invoices: {
                count: sumPath(inputData, 'summary_nrc_invoices.count'),
                vats: _(inputData).map('summary_nrc_invoices.vats').compact().flatten().groupBy('name').values().map(reduceVats).value()
            }
        };
    };

    const getMergedResources = (resourcesMap) => {
        let resourcesArray = [];

        for(let x in resourcesMap) {
            resourcesMap[x].name = x;
            resourcesArray.push(resourcesMap[x]);
        }

        resourcesArray = _.orderBy(resourcesArray, 'name');

        //Move altro/other at the end of the array
        for(let y of resourcesArray) {
            if(['Altro', 'other'].includes(y.name)) {
                _.pull(resourcesArray, y);
                resourcesArray.push(y);
            }
        }

        let mergedPrinters = reducePrinters(resourcesArray);
        resourcesArray.unshift(mergedPrinters);

        resourcesArray = _.map(resourcesArray, (printer) => {
            printer.refunds_voids = {
                amount: util.round(_.chain(printer).get('refunds.amount').toFinite().value() + _.chain(printer).get('voids.amount').toFinite().value()),
                count: _.chain(printer).get('refunds.count').toInteger().value() + _.chain(printer).get('voids.count').toInteger().value()
            };

            printer.claimed_payments_amount = _.chain(printer.payments)
                .filter((p) => (p.unclaimed === 0 && p.type_id !== 99))
                .reduce((prev, value) => (prev + value.amount), 0)
                .value();

            printer.unclaimed_payments_amount = _.chain(printer.payments)
                .filter((p) => (p.unclaimed === 1 && p.type_id !== 99))
                .reduce((prev, value) => (prev + value.amount), 0)
                .value();

            printer.other_changes_amount = _.chain(printer.payments)
                .filter({ type_id: 99 })
                .reduce((prev, value) => (prev + value.amount), 0)
                .value();

            return printer;
        });

        return resourcesArray;
    };

    const getDateRange = () => {
        let dayStartTime = util.getDayStartTime();
        let dayStart = moment(dayStartTime).toISOString();
        let dayEnd = moment(dayStartTime).add(1, 'day').toISOString();

        return {
            dayStart,
            dayEnd,
        };
    };

    const getTodaySellersData = () => {
        const dateRange = getDateRange();

        return restManager.analyticsGet('sellers', { date_since: dateRange.dayStart, date_max: dateRange.dayEnd });
    };

    const fetchData = async () => {
        const dateRange = getDateRange();

        let results = {};

        //Process stock
        let stockResult = new SclDictionary();
        let stockCollection = await entityManager.stock.fetchCollectionOffline();

        for(let stock of stockCollection) {
            if(stock.combination_id) {
                stockResult.set('combination_id_' + stock.combination_id, _.pick(stock, stockFields));
            } else if(stock.item_id) {
                stockResult.set('item_id_' + stock.item_id, _.pick(stock, stockFields));
            }
        }

        results.stocks = stockResult.values();

        //Shop printers status
        try {
            let shopName = _.get($rootScope, ['userActiveSession', 'shop', 'name']);

            let response = await $http({ method: 'GET', url: `https://printerremotemanager.scloby.com/ps/query/?shop=${shopName}` });
            results.printers_status = _.get(response, ['data', 'results']);
        } catch(error) {

        }

        //Weather info
        try {
            let city = checkManager.getPreference("general.address_city");

            if(!_.isNil(city)) {
                let response = await $http.get(`https://weatherapi.scloby.com/meteo/?city=${city}`);

                if(response.data.error === "false"){
                    results.today_weather = {
                        status_code: response.data.response.status_code,
                        t_min: response.data.response.t_min,
                        t_max: response.data.response.t_max,
                        text: response.data.response.status_description
                    };
                }
            }
        } catch(error) {

        }

        //Today dashboard
        let analyticsRequests = [
            { endpoint: 'analytics/dashboard', target: 'today_dashboard', queryParams: { date_since: dateRange.dayStart, date_max: dateRange.dayEnd } },
            { endpoint: 'analytics/payments', target: 'today_payments', queryParams: { date_since: dateRange.dayStart, date_max: dateRange.dayEnd } },
            { endpoint: 'analytics/printers', target: 'today_printers', queryParams: { date_since: dateRange.dayStart, date_max: dateRange.dayEnd } },
        ];

        for(let request of analyticsRequests) {
            try {
                let response = await restManager.getList(request.endpoint, request.queryParams);
                results[request.target] = response;
            } catch(error) {
                DevLogger.log("errors from dashboard", error);
            }
        }

        results.today_printers = results.today_printers?.printers;

        dataCache = results;

        return _.cloneDeep(dataCache);
    };

    return {
        getDataCache,
        getMergedResources,
        getTodaySellersData,
        fetchData
    };
}