import { Component, Injectable, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateFilterFn, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, } from '@ngx-translate/core';
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogProgressBarComponent, TilbyDialogTabsComponent, TilbyDialogToolbarComponent } from '@tilby/tilby-ui-lib/components/tilby-dialog';
import { restManager } from 'app/ajs-upgraded-providers';
import { lastValueFrom } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AlertDialogService } from '../alert-dialog';

export type DeleteSalesDialogData = {};

type DefaultType = {
    value: string,
    label: string
}

@Component({
    standalone: true,
    selector: 'app-delete-sales-dialog',
    templateUrl: './delete-sales-dialog.component.html',
    styleUrl: './delete-sales-dialog.component.scss',
    imports: [
        TilbyDialogTabsComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        MatFormFieldModule, 
        MatInputModule,
        MatDatepickerModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        TranslateModule
    ],
})
export class DeleteSalesDialog {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly restManagerService = inject(restManager);
    protected readonly data: DeleteSalesDialogData = inject(MAT_DIALOG_DATA);

    deleteSalesFormGroup!: FormGroup;

    types: DefaultType[] = [
        {value: "take_away", label: "Asporto"},
        {value: "delivery", label: "Delivery"},
    ];

    constructor() {
        const defaultDay = new Date(Date.now() - 86400000);

        this.deleteSalesFormGroup = this.formBuilder.group({
            selectedDate: [defaultDay, Validators.required],
            selectedType: ['take_away']
        });
    }

    rangeFilter: DateFilterFn<Date | null> = (date: Date | null) => {
        if (date === null) return false;
    
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
    };

    protected async confirm() {
        if(this.deleteSalesFormGroup.valid){
            const date = this.deleteSalesFormGroup.controls.selectedDate.value;
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            
            const config = {
                "from": `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T23:59:59Z`,
                "type": this.deleteSalesFormGroup.controls.selectedType.value
            }

            try{
                const result = await this.restManagerService.post(`massive_delete/sales`, config);
                
                this.matDialogRef.close(result);
            } catch(error: any){
                if(error.data.error.code === 151) {
                    this.alertDialogService.openDialog({ data: { messageLabel: "TABLES.DELETE_SALES.ERROR_FROM" } });
                }
            }

        } else {
            this.deleteSalesFormGroup.markAllAsTouched();
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class DeleteSalesDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<DeleteSalesDialogData>) {
        const data: DeleteSalesDialogData = config?.data||{};
        const configEdited: NonNullableConfigData<DeleteSalesDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(DeleteSalesDialog, configEdited).afterClosed()
        );
    }
}
