import {
    Component,
    HostBinding,
    computed,
    inject
} from '@angular/core';

import {
    PaymentsListWrapperComponent
} from "../components/cashregister-payments/payments-list-wrapper/payments-list-wrapper.component";
import {AngularSplitModule} from "angular-split";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {UpgradeUiViewOneDirective} from "../../../../Ajs<--->A14";
import {ActiveSaleStoreService, CashregisterStateService} from "../services";
import {ScreenOrientationService} from "../../../core";
import {
    PaymentsActionButtonsWrapperComponent
} from "../components/cashregister-payments/payments-action-buttons-wrapper/payments-action-buttons-wrapper.component";
import {TilbyActiveSaleHeaderInfoComponent} from "@tilby/tilby-ui-lib/components/tilby-order";
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';
import { DualService } from 'src/app/dual.service';

@Component({
  selector: 'app-cashregister-payments-mobile-view',
  standalone: true,
  imports: [
        PaymentsListWrapperComponent,
        AngularSplitModule,
        MatButtonModule,
        CommonModule,
        UpgradeUiViewOneDirective,
        PaymentsActionButtonsWrapperComponent,
        TilbyActiveSaleHeaderInfoComponent
    ],
  templateUrl: './cashregister-payments-mobile-view.component.html',
  styleUrl: './cashregister-payments-mobile-view.component.scss'
})
export class CashregisterPaymentsMobileViewComponent {
    private readonly screenOrientationService = inject(ScreenOrientationService);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    protected readonly dualService = inject(DualService);

    isPaymentsState?:boolean=false;

    @HostBinding('style.--toolbarHeight') heightToolbarPx = `0px`;

    protected height=50;
    protected isTablet=this.cashregisterStateService.isTablet;
    protected saleUpdates$ = ActiveSaleStoreService.saleUpdates$;
    protected actionBarHeight = computed(() => this.cashregisterStateService.setActionBarHeight(this.screenOrientationService.getOrientation()))

    constructor() {

        subscribeInComponent(this.dualService.currentRoute$, (currentRoute) => {
            this.isPaymentsState = currentRoute.includes("payments");
        });
    }
}
