<tilby-dialog-toolbar mat-dialog-title
    [title]="dialogTitle"
    [hideConfirm]="true"
    [customActions]="customActions"
/>
<tilby-dialog-content>
    @if(dialogMessage) {
        <div class="tw-flex tw-justify-center tw-items-center tw-pb-2">
            <span>{{dialogMessage | translate}}</span>
        </div>
    }
    <div class="tw-flex">
        <mat-form-field class="tw-pr-2">
            <mat-select name="Type" [(value)]="type" placeholder="Type" (selectionChange)="fetchSales()" (selectionChange)="onChange($event, 'type')">
                <mat-option [value]="'ALL'">{{'DIALOG.SELECT_SALE.TYPE_FILTERS.ALL' | translate}}</mat-option>
                <mat-option [value]="'TABLE'">{{'DIALOG.SELECT_SALE.TYPE_FILTERS.TABLE' | translate}}</mat-option>
                <mat-option [value]="'DELIVERY'">{{'DIALOG.SELECT_SALE.TYPE_FILTERS.DELIVERY' | translate}}</mat-option>
                <mat-option [value]="'TAKE_AWAY'">{{'DIALOG.SELECT_SALE.TYPE_FILTERS.TAKE_AWAY' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
        @if (type === 'TABLE') {
            <mat-form-field class="tw-pr-2">
                <mat-select [(value)]="room" (selectionChange)="fetchSales()" (selectionChange)="onChange($event, 'room')" placeholder="Room">
                    <mat-option [value]="'ALL'">{{'DIALOG.SELECT_SALE.ROOM_FILTERS.ALL' | translate}}</mat-option>
                    @for(room of rooms; track room.id) {
                        <mat-option [value]="room.id">{{room.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        <mat-form-field>
            <mat-select name="Time" [(value)]="time" placeholder="Time" (selectionChange)="fetchSales()" (selectionChange)="onChange($event, 'time')">
                <mat-option [value]="'TODAY'">{{'DIALOG.SELECT_SALE.TIME_FILTERS.TODAY' | translate}}</mat-option>
                <mat-option [value]="'THIS_WEEK'">{{'DIALOG.SELECT_SALE.TIME_FILTERS.LAST_WEEK' | translate}}</mat-option>
                <mat-option [value]="'THIS_MONTH'">{{'DIALOG.SELECT_SALE.TIME_FILTERS.LAST_MONTH' | translate}}</mat-option>
                <mat-option [value]="'ALWAYS'">{{'DIALOG.SELECT_SALE.TIME_FILTERS.ALWAYS' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div style="height: calc(100% - 83px)">
        @if (sales.length > 0) {
            <cdk-virtual-scroll-viewport class="tw-h-full adjust" [itemSize]="65" [minBufferPx]="screenHeight" [maxBufferPx]="screenHeight">
                <div *cdkVirtualFor="let sale of sales;" class="tw-w-full">
                    <div class="tw-flex tw-justify-between">
                        <div class="tw-w-full tw-flex tw-cursor-pointer tw-overflow-hidden" (click)="selectSale(sale)">
                            <div class="tw-flex tw-items-center tw-justify-center tw-pr-2">
                                <mat-icon>{{getSaleIcon(sale)}}</mat-icon>
                            </div>
                            <div class="tw-pt-2 tw-pb-2 tw-overflow-hidden">
                                <div class="tw-text-ellipsis tw-overflow-hidden tw-font-bold tw-whitespace-nowrap">
                                    {{getInfoSale(sale)}}
                                    @if (sale.bill_lock) {
                                        <mat-icon class="tw-ml-2 tw-text-lg tw-align-sub">lock</mat-icon>
                                    }
                                </div>
                                <div class="tw-flex">
                                    <div>
                                        {{formattedDate(sale.created_at)}}
                                    </div>
                                    <div class="tw-font-bold tw-pl-3">
                                        {{getSaleName(sale)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        @if(!selectModeOnly && isDeleteSalePermitted) {
                            <div class="tw-flex tw-cursor-pointer tw-items-center tw-justify-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="deleteSale(sale)">
                                        <span>{{'DIALOG.SELECT_SALE.ACTION.DELETE' | translate}}</span>
                                    </button>
                                </mat-menu>
                            </div>
                        }
                    </div>
                    <mat-divider />
                </div>
            </cdk-virtual-scroll-viewport>
        } @else {
            <div class="tw-flex tw-items-center tw-justify-center tw-h-full">{{'DIALOG.SELECT_SALE.NO_SALE_IN_FILTERS' | translate}}</div>
        }
    </div>
</tilby-dialog-content>
