import { Component, OnDestroy, OnInit, ViewChild, computed, inject } from '@angular/core';
import {
    ConfigurationManagerService,
    DevicePreferences,
    EntityManagerService,
    EnvironmentInfoService,
    ScreenOrientationService,
    StorageManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { WebServerSlaveDevices, WebServerSlaveDevicesResponse } from 'tilby-models';
import { v4 as generateUuid } from 'uuid';
import { OpenDialogsService } from 'src/app/dialogs/services/open-dialogs.service';
import { InsertNewDeviceFields } from 'src/app/dialogs/insert-new-device-waiter-dialog/insert-new-device-waiter-dialog.model';
import { ButtonUmList, QRCodeData } from '../../../../models/settings-users-management.model';
import { SettingsUmListComponent } from '../../settings-um-list/settings-um-list.component';
import { SettingsUsersManagementService } from '../../../../services/settings-users-management.services';
import { Subject, filter, takeUntil } from 'rxjs';
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, InputType, KeyValueIconImage } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { TranslateService } from '@ngx-translate/core';
import { keyBy } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

type DevicePreferencesName = 'permission.prebill' |
    'permission.sale_item_deletion' |
    'permission.skip_printing' |
    'permission.change_pricelist' |
    'order.default_pricelist' |
    'catalog.showcase_order_by' |
    'order.return_to' |
    'tables.default_view' |
    'order.default_prebill_printer';

type ConfigDevicePreferences = {
    preference: DevicePreferencesName;
    inputType: InputType;
    label: string;
    inputChoices?: Array<KeyValueIconImage<string>>;
}

const timestamp24 = 24 * 60 * 60 *1000;

@Component({
  selector: 'app-settings-um-qrcode-waiter',
  templateUrl: './settings-um-qrcode-waiter.component.html',
  styleUrls: ['./settings-um-qrcode-waiter.component.scss']
})
export class SettingsUmQrcodeWaiterComponent implements OnInit, OnDestroy {
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly translateService = inject(TranslateService);
    private _snackBar = inject(MatSnackBar);
    private onDestroy$ = new Subject<void>();
    @ViewChild(SettingsUmListComponent) componentSettingUmList!: SettingsUmListComponent;

    value = '';

    devices: WebServerSlaveDevicesResponse = [];
    allDevices: WebServerSlaveDevicesResponse = [];
    deviceSelected: WebServerSlaveDevices | undefined | null;

    qrCode: string = '';
    tilbyVersion = require('app/tilby.properties.json').tilbyVersion;
    localAddressIP = '';
    userId: number;

    enabledQrCodeGenerator = true;
    openFilter = false;
    filterText = '';

    hasBack = false;

    itemsIcon = 'smartphone';
    customButtons: ButtonUmList[] = [];

    isLoading = false;
    deviceForm = new CustomFormGroup<CustomForm<Partial<{[prop in DevicePreferencesName]:string}>>>({});
    configDevicePreferences: Array<ConfigDevicePreferences> = [];
    preferenceByID: Partial<Record<DevicePreferencesName, DevicePreferences>> = {};

    constructor(
        private entityManagerService: EntityManagerService,
        private userActiveSessionManagerService: UserActiveSessionManagerService,
        private openDialogsService: OpenDialogsService,
        private environmentInfoService: EnvironmentInfoService,
        private screenOrientationService: ScreenOrientationService,
        private settingsUsersManagementService: SettingsUsersManagementService
    ) {
        this.userId = this.userActiveSessionManagerService.getSession()?.id!;
        this.configDevicePreferences = [];
    }

    async getPrinters() {
        try {
            const printers = await this.entityManagerService.printers.fetchCollectionOffline();
            return printers;

        } catch(err) {
            return [];
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    async ngOnInit() {
        // ---------- start priceListNames ----------
        const priceLists = [];
        for(let i = 1; i <= 10; i++) {
            priceLists.push({
                index: i,
                fieldName: `price${i}`,
                value: this.configurationManagerService.getShopPreference(`price_list_${i}_name`) || this.translateService.instant(`SETTINGS.USERS_MANAGEMENT.PRICE_LIST_${i}`),
                hidden: this.configurationManagerService.getShopPreference(`price_list_${i}_hide`) ? true : false
            });
        }
        const priceListChoices = priceLists.filter(priceList => !priceList.hidden).map(priceList => ({
            key: priceList.value,
            value: String(priceList.index)
        }));
        // ---------- end priceListNames ----------
        const orderByChoices = [
            { key: 'SETTINGS.USERS_MANAGEMENT.CATALOG_SHOWCASE_ORDER_BY.INDEX',     value: '+index' },
            { key: 'SETTINGS.USERS_MANAGEMENT.CATALOG_SHOWCASE_ORDER_BY.NAME',      value: '+name'  },
            { key: 'SETTINGS.USERS_MANAGEMENT.CATALOG_SHOWCASE_ORDER_BY.PRICE_ASC', value: '+price' },
            { key: 'SETTINGS.USERS_MANAGEMENT.CATALOG_SHOWCASE_ORDER_BY.PRICE_DEC', value: '-price' },
        ];
        const returnToChoices = [
            { key: 'SETTINGS.USERS_MANAGEMENT.ORDER_RETURN_TO.TABLES', value: 'tables' },
            { key: 'SETTINGS.USERS_MANAGEMENT.ORDER_RETURN_TO.BILLS',  value: 'bills'  },
            { key: 'SETTINGS.USERS_MANAGEMENT.ORDER_RETURN_TO.STAY',   value: 'stay'   },
        ];
        const tablesViewChoices = [
            { key: 'SETTINGS.USERS_MANAGEMENT.TABLES_DEFAULT_VIEW.LIST', value: 'list' },
            { key: 'SETTINGS.USERS_MANAGEMENT.TABLES_DEFAULT_VIEW.GRID', value: 'grid' },
        ];

        const prebillPrinterChoices = [
            { key: 'SETTINGS.USERS_MANAGEMENT.PREBILL_PRINTER_DEFAULT_VALUE', value: 'none' },
        ];

        const printers = await this.getPrinters();
        printers.forEach(printer => {
            prebillPrinterChoices.push({ key: printer.name, value: String(printer.id) });
        });

        this.configDevicePreferences = [
            {
                preference: 'permission.prebill',
                inputType: 'checkbox',
                label: 'SETTINGS.USERS_MANAGEMENT.PERMISSION_PREBILL',
            },
            {
                preference: 'order.default_prebill_printer',
                inputType: 'select',
                inputChoices: prebillPrinterChoices,
                label: 'SETTINGS.USERS_MANAGEMENT.PREBILL_PRINTER',
            },
            {
                preference: 'permission.sale_item_deletion',
                inputType: 'checkbox',
                label: 'SETTINGS.USERS_MANAGEMENT.PERMISSION_SALE_ITEM_DELETION',
            },
            {
                preference: 'permission.skip_printing',
                inputType: 'checkbox',
                label: 'SETTINGS.USERS_MANAGEMENT.PERMISSION_SKIP_PRINTING',
            },
            {
                preference: 'permission.change_pricelist',
                inputType: 'checkbox',
                label: 'SETTINGS.USERS_MANAGEMENT.PERMISSION_CHANGE_PRICELIST',
            },
            {
                preference: 'order.default_pricelist',
                inputType: 'select',
                inputChoices: priceListChoices,
                label: 'SETTINGS.USERS_MANAGEMENT.DEFAULT_PRICELIST',
            },
            {
                preference: 'catalog.showcase_order_by',
                inputType: 'select',
                inputChoices: orderByChoices,
                label: 'SETTINGS.USERS_MANAGEMENT.CATALOG_SHOWCASE_ORDER_BY.LABEL',
            },
            {
                preference: 'order.return_to',
                inputType: 'select',
                inputChoices: returnToChoices,
                label: 'SETTINGS.USERS_MANAGEMENT.ORDER_RETURN_TO.LABEL',
            },
            {
                preference: 'tables.default_view',
                inputType: 'select',
                inputChoices: tablesViewChoices,
                label: 'SETTINGS.USERS_MANAGEMENT.TABLES_DEFAULT_VIEW.LABEL',
            }
        ];

        try {
            let localAddress = await this.environmentInfoService.getNetworkInfo();
            this.localAddressIP = localAddress.client_lan_ip;
        } catch(err) {
            this.enabledQrCodeGenerator = false;
            console.error('local address IP not supported in web version');
        }

        this.customButtons.push({ icon: 'filter_list', callback: this.button1Callback.bind(this), type: 'filter', enabled: true});
        this.customButtons.push({ icon: 'add', callback: this.button2Callback.bind(this), type: 'add', enabled: this.enabledQrCodeGenerator });

        await this.loadDevices();

        StorageManagerService.storageUpdates$.pipe(
            filter(data => data.entityName == 'slave_devices'),
            takeUntil(this.onDestroy$)
        ).subscribe((data) => {
            if(data.id) {
                this.reloadSingleDevice(data.id as string);
            }
        });
    }

    protected isLandscape = computed(() => !!this.screenOrientationService.getOrientation().includes('landscape'));

    /**
     * Load all devices
     */
    async loadDevices() {
        const result = await this.entityManagerService.slaveDevices.fetchCollectionOffline();
        this.devices = this.allDevices = result;
        this.isLoading = true;
    }

    /**
     *  Load single device
     *
     * @param id
     */
    async reloadSingleDevice(id: string) {
        let indexDeviceSelected = this.devices.findIndex(device => device.id === id);
        const result = await this.entityManagerService.slaveDevices.fetchOneOfflineFirst(id);
        const index = this.devices.findIndex(device => device.id === id);
        const indexAll = this.allDevices.findIndex(device => device.id === id);
        this.devices = [...this.devices];
        this.devices[index] = this.allDevices[indexAll] = result;
        if (this.componentSettingUmList?.filterText)
            this.devices = this.allDevices.filter(item => item.name.toLowerCase().includes(this.componentSettingUmList.filterText.toLowerCase()));
        if (indexDeviceSelected >= 0)
            this.deviceSelected = this.isLandscape() ? this.componentSettingUmList.selectedItem = this.devices[indexDeviceSelected] : this.devices[indexDeviceSelected];
    }

    /**
     * Create a new device
     * @param data
     */
    async createDevice(data: InsertNewDeviceFields) {
        let dataNewSlaveDevice = this.getDataForQRCode( generateUuid(),data.name, null, null, generateUuid(),Math.floor((TilbyDatePipe.toUtcDateMillis() + timestamp24) / 1000),this.localAddressIP,TilbyDatePipe.date(), this.userId);
        let qrcode: WebServerSlaveDevices = await this.entityManagerService.slaveDevices.postOneOfflineFirst({...dataNewSlaveDevice});
        this.setQRCode(this.localAddressIP, qrcode.name, qrcode.code, this.tilbyVersion);
        this.devices = [...this.devices, qrcode];
        this.allDevices = [...this.allDevices, qrcode];
        this.deviceSelected = this.devices[this.devices.length-1];
        this.value = this.deviceSelected.name;
        this.applyFilter();
    }

    /**
     * Regenerate a new device
     */
    regenerate = async () => {
        this.putQrcode(this.deviceSelected!.uuid,this.deviceSelected!.name, null, null, generateUuid(),Math.floor((TilbyDatePipe.toUtcDateMillis() + timestamp24) / 1000),this.deviceSelected!.server_ip,TilbyDatePipe.date(), this.userId);
    };

    /**
     * Put a new name in device
     */

    putNameInDevice = async () => {
        this.putQrcode(this.deviceSelected!.uuid, this.value, this.deviceSelected!.session_token, this.deviceSelected!.token_expire_at,this.deviceSelected!.code,this.deviceSelected!.code_expire_at,this.deviceSelected!.server_ip,TilbyDatePipe.date(), this.userId);
    }

    /**
     * Delete a device
     */
    delete = async () => {
        if(!this.deviceSelected?.uuid) {
            return;
        }

        await this.entityManagerService.slaveDevices.deleteOneOfflineFirst(this.deviceSelected?.uuid);
        this.devices = this.devices.filter(device => device.uuid !==this.deviceSelected?.uuid);
        this.allDevices = this.allDevices.filter(device => device.uuid !==this.deviceSelected?.uuid);
        this.onDeviceClick(this.devices[0]);
        await this.reloadSingleDevice(this.devices[0].uuid);
    }

    /** filter */

    filter() {
        this.openFilter = !this.openFilter;
    }

    applyFilter(): void {
        const index = this.allDevices.findIndex(device => device.uuid === this.deviceSelected?.uuid);
        this.devices = this.allDevices.filter(device =>
            device.name.toLowerCase().includes(this.filterText.toLowerCase())
        );
        this.deviceSelected = this.allDevices[index];
        this.devices = [...this.devices];
    }

    async setDevice(device: WebServerSlaveDevices) {
        this.value = device.name;
        this.deviceSelected = device;
        let devicePreferencesFilterdByDeviceUuid: Array<DevicePreferences> | undefined = undefined;
        try {
            const devicePreferences = await this.entityManagerService.devicePreferences.fetchCollectionOffline();
            devicePreferencesFilterdByDeviceUuid = devicePreferences.filter(p => p.id.startsWith(device.uuid) && p.device_uuid === device.uuid);
            this.preferenceByID = keyBy(devicePreferencesFilterdByDeviceUuid, (p) => p.id.substring(p.id.indexOf('.') + 1));
        } catch (e) {
            console.error(e);
        }
        this.setFormControl(devicePreferencesFilterdByDeviceUuid);
        this.setQRCode(this.localAddressIP, device.name, device.code, this.tilbyVersion);
    }

    setFormControl(devicePreferences: Array<DevicePreferences> | undefined) {
        this.configDevicePreferences.forEach(configDevicePreference => {
            const {preference, label, inputType, inputChoices} = configDevicePreference;
            let value = undefined;
            const controlProps = {
                ...new CustomFormControlProps(),
                label,
                inputType,
                class:'tw-w-full md:tw-w-[48%] lg:tw-w-[32%] tw-mb-2 tw-self-start',
            };

            if(inputChoices) {
                controlProps.inputChoices = inputChoices;
            } else {
                value = false;
            }

            if(devicePreferences && devicePreferences.length > 0 && this.preferenceByID[preference]) {
                value = this.preferenceByID[preference]?.value;
                if(value === 'on' || value === 'off') {
                    value = value === 'on' ? true : false;
                }
            } else {
                if(preference === 'order.default_prebill_printer') {
                    value = 'none';
                }
            }
            this.deviceForm?.addControl(preference, new CustomFormControl(value, {}, controlProps));
            this.deviceForm?.controls[preference]?.setValue(value);
        });
    }

    save() {
        if(this.deviceForm?.valid && this.deviceForm?.dirty) {
            this.configDevicePreferences.forEach(async configDevicePreference => {
                const {preference} = configDevicePreference;
                const preferenceToSaveForce = ['order.default_prebill_printer'];
                if(this.deviceForm?.controls[preference]?.dirty || preferenceToSaveForce.includes(preference)) {
                    let value = this.deviceForm?.value[preference];
                    if(value !== null) {
                        const device_uuid = this.deviceSelected!.uuid;
                        const id = `${device_uuid}.${preference}`;
                        value = typeof value === 'boolean' ? (value ? 'on' : 'off') : value;
                        const valueToSave: DevicePreferences = { id, value, device_uuid };
                        try {
                            await this.entityManagerService.devicePreferences.postOneOfflineFirst(valueToSave);
                            this.deviceForm.markAsPristine();
                            const labelSuccess = this.translateService.instant('SETTINGS.SETTINGS.SAVE_EDIT_SUCCESSFUL');
                            this._snackBar.open(labelSuccess, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'});
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            });
        }
    }

    setQRCode(server: string, deviceName: string, code: string, version: string): void {
        this.qrCode = `{
          "server": "${server}:2013",
          "device_name": "${deviceName}",
          "code": "${code}",
          "version": "${version}"
        }`;
    }

    getDataForQRCode(uuid: string, name: string | null, session_token: string | undefined | null, token_expire_at: number | undefined | null, code: string, code_expire_at: number, server_ip: string, updated_at: string, updatedby_id: number): QRCodeData {
        let data = {
            "uuid": uuid,
            "name": name,
            "session_token": session_token || null,
            "token_expire_at": token_expire_at || null,
            "code": code,
            "code_expire_at": code_expire_at,
            "server_ip": server_ip,
            "updated_at": updated_at,
            "updatedby_id": updatedby_id
        };
        return data;
    }

    putQrcode = async (uuid : string, name : string, session_token: string | undefined | null,  token_expire_at: number | undefined | null, code: string, code_expire_at: number, server_ip: string, date: string, user_id: number) => {
        let data = this.getDataForQRCode( uuid, name, session_token, token_expire_at, code, code_expire_at, server_ip, date, user_id);
        let qrcode: WebServerSlaveDevices = await this.entityManagerService.slaveDevices.putOneOfflineFirst({...data,id:`${data.uuid}`,uuid:data.uuid});
        this.setQRCode(this.localAddressIP, qrcode.name, qrcode.code, this.tilbyVersion);
        const index = this.devices.findIndex(device => device.uuid === this.deviceSelected?.uuid);
        const indexAll = this.allDevices.findIndex(device => device.uuid === this.deviceSelected?.uuid);
        this.deviceSelected = this.devices[index] = this.allDevices[indexAll] = qrcode;
        this.devices = [...this.devices];
    }

    backFromList() {
        this.hasBack = false;
    }

    onDeviceClick(device: WebServerSlaveDevices) {
        if (!this.isLandscape()) {
            this.hasBack = true;
        }
        this.setDevice(device);
    }

    hasChangeDeviceName() {
        return this.deviceSelected?.name === this.value ? false : true;
    }

    hasSessionToken() {
        return this.deviceSelected?.session_token !== null;
    }

    open() {
        this.openDialogsService.openInsertNewDeviceWaiterDialog().then((res) => res && this.createDevice(res))
    }


    buildFieldLegend1(item: WebServerSlaveDevices): string {
        return item.name;
    }

    buildFieldLegend2(item: WebServerSlaveDevices): string {
        return this.settingsUsersManagementService.getDateDifference(item.updated_at.toString());
    }

    customFilterFunction(item: WebServerSlaveDevices): boolean {
        return item.name.toLowerCase().includes(this.filterText.toLowerCase())
    }

    async onSmartphoneSelected(device: WebServerSlaveDevices) {
        if (!this.isLandscape()) {
            this.hasBack = true;
        }
        this.setDevice(device);
    }

    button1Callback() {}

    button2Callback() {
        this.open();
    }

}
