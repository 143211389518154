<!-- FORM CUSTOMER DETAILS -->
<app-tab-customer *ngIf="!isNew; else customersTempForm" (tabPanelChange)="tabPanel=$any($event)" [customer]="customer">
    <ng-container Riepilogo [ngTemplateOutlet]="riepilogo"></ng-container>
    <ng-container Anagrafica *ngTemplateOutlet="customersTempForm; context: {country}"></ng-container>
    <ng-container Credito [ngTemplateOutlet]="hasModulePrepaid ? prepaid : default"></ng-container>
    <ng-container Punti [ngTemplateOutlet]="hasModuleFidelity ? fidelity : default"></ng-container>
</app-tab-customer>

<ng-template #riepilogo>
    <app-customer-summary
        [customer]="customer"
        [activeCampaign]="activeCampaign"
        [credit]="credit"
        [points]="points"
    ></app-customer-summary>
</ng-template>

<ng-template #customersTempForm let-shop_country="country">
    <app-customer-form
        [userCountry]="shop_country"
        [filteredOptions]="filteredOptions"
        [fidelityLabel]="fidelityLabel"
        [paymentMethods]="paymentMethods"
        [priceLists]="priceLists"
        [customersForm]="customersForm"
        [customer]="customer"
        [customTypes]="customTypes"
        [customFields]="customFields"
        [validators]="validators"
        (delete)="deleteRecord()"
        (submit)="onSubmit($event)"
        [phoneNumber]="phoneNumber"
    ></app-customer-form>
</ng-template>

<ng-template #prepaid>

        <app-customer-prepaid-credit
            #prepaidCreditComponent
            *ngIf="chainShops"
            [customer]="customer"
            [chainShops]="chainShops"
            [credit]="credit">
        </app-customer-prepaid-credit>
</ng-template>

<ng-template #fidelity>
        <app-customer-fidelity-points
            #fidelityPointsComponent
            *ngIf="chainShops"
            [customer]="customer"
            [campaigns]="campaigns"
            [(selectedCampaignId)]="activeCampaignId"
            [chainShops]="chainShops"
            [user]="user"
            [(points)]="points"
        ></app-customer-fidelity-points>
</ng-template>

<ng-template #default>
    <button mat-flat-button color="primary" class="tw-absolute tw-top-[50%] tw-left-[50%] -tw-translate-x-[50%] -tw-translate-y-[50%] tw-text-2xl tw-p-7">Richiedi attivazione Modulo</button>
</ng-template>

