import { Injectable, inject } from "@angular/core";
import { EntityManagerService } from "../entity/entity-manager.service";
import { ShopPreferences, ShopSettings, UserSetting } from "tilby-models";
import {
    ConfigurationAnalytics,
    ConfigurationFunctions,
    ConfigurationModules,
    ConfigurationPermissions,
    ConfigurationPreferences,
    ConfigurationSettings,
    UserActiveSessionManagerService
} from "src/app/core";
import { Subject } from "rxjs";
import { EntityBase } from "../entity";

export type ConfigurationStore<T> = Map<string, T[keyof T]>;
export type ConfigurationStoreKeyValue = { id: string, value: string | null };

@Injectable({
    providedIn: 'root'
})
export class ConfigurationManagerStoreService {
    public shopModules: ConfigurationStore<ConfigurationModules> = new Map(); //Boolean dataset on/off
    public shopFunctions: ConfigurationStore<ConfigurationFunctions> = new Map(); //Boolean dataset on/off
    public shopSettings: ConfigurationStore<ConfigurationSettings> = new Map(); //Value dataset string
    public shopAnalytics: ConfigurationStore<ConfigurationAnalytics> = new Map();
    public userModules: ConfigurationStore<ConfigurationModules> = new Map(); //Boolean dataset on/off
    public userFunctions: ConfigurationStore<ConfigurationFunctions> = new Map(); //Boolean dataset on/off
    public userPermissions: ConfigurationStore<ConfigurationPermissions> = new Map(); //Boolean dataset on/off
    public userSettings: ConfigurationStore<ConfigurationSettings> = new Map(); //Value dataset string
    public userAnalytics: ConfigurationStore<ConfigurationAnalytics> = new Map();
    public shopPreferences: ConfigurationStore<ConfigurationPreferences> = new Map(); //Value dataset string
    public userPreferences: ConfigurationStore<ConfigurationPreferences> = new Map(); //Value dataset string

    private entityManager = inject(EntityManagerService);
    private userActiveSessionManager = inject(UserActiveSessionManagerService);

    //Subjects and observables
    private static readonly shopSettingsUpdateSubject = new Subject<ConfigurationStoreKeyValue>();
    private static readonly shopPreferencesUpdateSubject = new Subject<ConfigurationStoreKeyValue>();
    private static readonly userSettingsUpdateSubject = new Subject<ConfigurationStoreKeyValue>();
    private static readonly userPreferencesUpdateSubject = new Subject<ConfigurationStoreKeyValue>();
    public static readonly shopSettingsUpdate$ = ConfigurationManagerStoreService.shopSettingsUpdateSubject.asObservable();
    public static readonly shopPreferencesUpdate$ = ConfigurationManagerStoreService.shopPreferencesUpdateSubject.asObservable();
    public static readonly userSettingsUpdate$ = ConfigurationManagerStoreService.userSettingsUpdateSubject.asObservable();
    public static readonly userPreferencesUpdate$ = ConfigurationManagerStoreService.userPreferencesUpdateSubject.asObservable();

    constructor(
    ) {
        EntityBase.entityUpdates$.subscribe((data) => {
            switch(data.entityName) {
                case 'shop_settings':
                    this.onShopSettingsUpdate(data);
                    break;
                case 'shop_preferences':
                    this.onShopPreferencesUpdate(data);
                    break;
                case 'user_settings':
                    this.onUserSettingsUpdate(data);
                    break;
                case "user_preferences":
                    this.onUserPreferencesUpdate(data);
                    break;
                default:
                    break;
            }
        });
    }

    private async onShopSettingsUpdate(data: any) {
        if (!data.id) {
            return this.initShopSettings();
        }

        const shopSetting = await this.entityManager.shopSettings.fetchOneOffline(data.id);

        if (shopSetting) {
            this.updateShopSetting(shopSetting);
        }
    }

    private async onShopPreferencesUpdate(data: any) {
        if (!data.id) {
            return this.initShopPreferences();
        }

        const shopPreference = await this.entityManager.shopPreferences.fetchOneOffline(data.id);

        if (shopPreference) {
            this.updateShopPreference(shopPreference);
        }
    }

    private async onUserSettingsUpdate(data: any) {
        if (!data.id) {
            return this.initUserSettings(this.userActiveSessionManager.getSession()?.id!);
        }

        const userSetting = await this.entityManager.userSettings.fetchOneOffline(data.id);

        if (userSetting) {
            this.updateUserSetting(userSetting);
        }
    }

    private async onUserPreferencesUpdate(data: any) {
        if (!data.id) {
            return this.initUserPreferences(this.userActiveSessionManager.getSession()?.id!);
        }

        const userPreference = await this.entityManager.userPreferences.fetchOneOffline(data.id);

        if (userPreference) {
            this.updateUserPreference(userPreference);
        }
    }

    /**
     * Parses the given preference/setting value and returns a boolean or string based on the value.
     *
     * @param {string} value - The value to be parsed.
     * @return {boolean | string} - The parsed value. Returns true if the value is 'on', false if the value is 'off',
     *  and the original value otherwise.
     */
    private parseValue(value: string): boolean | string {
        switch (value) {
            case 'on':
                return true;
            case 'off':
                return false;
            default:
                return value;
        }
    }

    /**
     * Clears the user settings stores.
     */
    private clearUserSettingsStores() {
        this.userModules.clear();
        this.userFunctions.clear();
        this.userPermissions.clear();
        this.userSettings.clear();
        this.userAnalytics.clear();
    }

    /**
     * Clears the shop settings stores.
     */
    private clearShopSettingsStores() {
        this.shopModules.clear();
        this.shopFunctions.clear();
        this.shopSettings.clear();
        this.shopAnalytics.clear();
    }

    /**
     * Initializes the shop settings stores.
     *
     * @return {Promise<void>} A promise that resolves when the shop preferences are initialized.
     */
    public async initShopSettings() {
        this.clearShopSettingsStores();

        const shopSettings = await this.entityManager.shopSettings.fetchCollectionOffline();

        for (let setting of shopSettings) {
            this.updateShopSetting(setting, false);
        }
    }

    /**
     * Initializes the user settings stores for the specified user ID.
     *
     * @return {Promise<void>} A promise that resolves when the shop preferences are initialized.
     */
    public async initUserSettings(userId: number) {
        this.clearUserSettingsStores();

        const userSettings = await this.entityManager.userSettings.fetchCollectionOffline({ 'user_id': userId });

        for (let setting of userSettings) {
            this.updateUserSetting(setting, false);
        }
    }

    /**
     * Initializes the shop preferences stores.
     *
     * @return {Promise<void>} A promise that resolves when the shop preferences are initialized.
     */
    public async initShopPreferences() {
        this.shopPreferences.clear();

        const shopPreferences = await this.entityManager.shopPreferences.fetchCollectionOffline();

        for (let preference of shopPreferences) {
            this.updateShopPreference(preference, false);
        }
    }

    /**
     * Initializes the user preferences stores for the specified user ID.
     *
     * @param {number} userId - The ID of the user.
     * @return {Promise<void>} A promise that resolves once the user preferences have been initialized.
     */
    public async initUserPreferences(userId: number) {
        this.userPreferences.clear();

        const userPreferences = await this.entityManager.userPreferences.fetchCollectionOffline({ 'user_id': userId });

        for (let preference of userPreferences) {
            this.updateUserPreference(preference, false);
        }
    }

    /**
     * Retrieves a filtered store from the given ConfigurationStore.
     *
     * @param {ConfigurationStore<T>} store - The ConfigurationStore to filter.
     * @param {string} filter - The filter to apply to the store.
     * @return {Record<string, T[keyof T]>} The filtered store as a Record.
     */
    public getFilteredStore<T>(store: ConfigurationStore<T>, filter?: string): Partial<T> {
        if (!filter) {
            return Object.fromEntries(store) as Partial<T>;
        }

        const filteredEntries: Partial<T> = {};

        for (const [key, value] of store) {
            if (key.startsWith(filter)) {
                filteredEntries[key as keyof T] = value;
            }
        }

        return filteredEntries;
    }

    /**
     * Updates the shop preferences store with the provided pair.
     *
     * @param {ShopPreferences} pair - The pair containing the id and value of the user preference.
     * @param {boolean} emitChange - Whether to emit a change event or not.
     */
    public updateShopPreference(pair: (Omit<ShopPreferences, 'value'> & { value: string | null }), emitChange: boolean = true): void {
        if (pair.id == null) {
            return;
        }

        if(pair.value == null) {
            this.shopPreferences.delete(pair.id as keyof ConfigurationPreferences);
        } else {
            this.shopPreferences.set(pair.id as keyof ConfigurationPreferences, this.parseValue(pair.value));
        }

        if(emitChange) {
            ConfigurationManagerStoreService.userPreferencesUpdateSubject.next({ id: pair.id, value: pair.value ?? null });
        }
    }

    /**
     * Updates the user preference store with the provided pair.
     *
     * @param {UserSetting} pair - The pair containing the id and value of the user preference.
     * @param {boolean} emitChange - Whether to emit a change event or not.
     */
    public updateUserPreference(pair: UserSetting, emitChange: boolean = true): void {
        if (pair.id == null) {
            return;
        }

        if(pair.value == null) {
            this.userPreferences.delete(pair.id as keyof ConfigurationPreferences);
        } else {
            this.userPreferences.set(pair.id as keyof ConfigurationPreferences, this.parseValue(pair.value));
        }

        if(emitChange) {
            ConfigurationManagerStoreService.userPreferencesUpdateSubject.next({ id: pair.id, value: pair.value ?? null });
        }
    }

    /**
     * Updates the shop settings stores based on the provided pair.
     *
     * @param {ApiSetting} pair - The pair containing the id and value of the shop setting.
     * @param {boolean} emitChange - Whether to emit a change event or not.
     * @return {void}
     */
    public updateShopSetting(pair: ShopSettings, emitChange: boolean = true): void {
        if (pair.id == null || pair.value == null) {
            return;
        }

        if (pair.id.startsWith('application.modules.')) {
            this.shopModules.set(pair.id.replace("application.modules.", '') as keyof ConfigurationModules, this.parseValue(pair.value) as boolean);
        } else if (pair.id.startsWith('application.functions.')) {
            this.shopFunctions.set(pair.id.replace("application.functions.", '') as keyof ConfigurationFunctions, this.parseValue(pair.value) as boolean);
        } else if (pair.id.startsWith('application.settings.')) {
            this.shopSettings.set(pair.id.replace("application.settings.", '') as keyof ConfigurationSettings, this.parseValue(pair.value));
        } else if (pair.id.startsWith('analytics3.')) {
            this.shopAnalytics.set(pair.id.replace("analytics3.", '') as keyof ConfigurationAnalytics, this.parseValue(pair.value) as ConfigurationAnalytics[keyof ConfigurationAnalytics]);
        }

        if(emitChange) {
            ConfigurationManagerStoreService.shopSettingsUpdateSubject.next({ id: pair.id, value: pair.value });
        }
    }

    /**
     * Updates the user settings stores based on the given pair.
     *
     * @param {ApiSetting} pair - The pair containing the id and value of the user setting.
     * @param {boolean} emitChange - Whether to emit a change event or not.
     * @returns {void}
     */
    public updateUserSetting(pair: UserSetting, emitChange: boolean = true): void {
        if (pair.id == null || pair.value == null) {
            return;
        }

        if (pair.id.startsWith('application.modules.')) {
            this.userModules.set(pair.id.replace("application.modules.", '') as keyof ConfigurationModules, this.parseValue(pair.value) as boolean);
        } else if (pair.id.startsWith('application.functions.')) {
            this.userFunctions.set(pair.id.replace("application.functions.", '') as keyof ConfigurationFunctions, this.parseValue(pair.value) as boolean);
        } else if (pair.id.startsWith('application.settings.')) {
            this.userSettings.set(pair.id.replace("application.settings.", '') as keyof ConfigurationSettings, this.parseValue(pair.value));
        } else if (pair.id.startsWith('application.users.permissions.')) {
            this.userPermissions.set(pair.id.replace("application.users.permissions.", '') as keyof ConfigurationPermissions, this.parseValue(pair.value) as boolean);
        } else if (pair.id.startsWith('analytics3.')) {
            this.userAnalytics.set(pair.id.replace("analytics3.", '') as keyof ConfigurationAnalytics, this.parseValue(pair.value) as ConfigurationAnalytics[keyof ConfigurationAnalytics]);
        }

        if(emitChange) {
            ConfigurationManagerStoreService.userSettingsUpdateSubject.next({ id: pair.id, value: pair.value });
        }
    }
}
