import {
    inject,
    Injectable
} from '@angular/core';

import { restManager } from 'app/ajs-upgraded-providers';

type VatInfoResponse = {
    type: string;
    company_name?: string;
    country?: string;
    billing_street?: string;
    billing_number?: string;
    billing_city?: string;
    billing_prov?: string;
    billing_zip?: string;
    billing_country?: string;
    email_pec?: string;
    tax_code?: string;
    vat_code?: string;
}

@Injectable({
    providedIn: 'root'
})
export class CustomerUtilsService {
    private readonly restManager = inject(restManager);

/**
 * Retrieves VAT information for a given VAT code.
 * This function uses the `restManager` to fetch VAT data and constructs a
 * `VatInfoResponse` object, filtering out any fields with empty values.
 * 
 * @param vatCode The VAT code to validate and retrieve information for.
 * @returns A promise that resolves to a `VatInfoResponse` object containing
 *          company and address details if found, or `null` if not found.
 * 
 */
    public async getVatInfo(vatCode: string) {
        const result = await this.restManager.getOne('validate_vat', vatCode);

        if(!result) {
            return null;
        }

        const customer = {
            type: 'company',
            company_name: result.name,
            country: result.address_country.slice(0, 2),
            billing_street: result.address_street,
            billing_number: result.address_number.slice(0, 25),
            billing_city: result.address_city,
            billing_prov: result.address_prov.slice(0, 10),
            billing_zip: result.address_zip.slice(0, 10),
            billing_country: result.address_country.slice(0, 2),
            email_pec: result.email_pec.toLowerCase(),
            tax_code: result.tax_number.slice(0, 16),
            vat_code: result.vat_number
        };

        // remove empty values
        return Object.fromEntries(Object.entries(customer).filter(([key, value]) => value)) as VatInfoResponse;
    }
}