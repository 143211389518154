<div class="layout-fill">
    <div class="tw-flex tw-flex-col tw-h-full tw-justify-around">
        <section class="tw-flex tw-flex-col tw-h-full tw-justify-start tw-items-center tw-gap-4 tw-mt-5">
            @if(showButtons) {
                @if(enabledModules['bookings']) {
                    <button mat-icon-button [ngClass]="{'active': currentRoute.indexOf('bookings')>-1}"
                            class="frame-button tw-text-xs"
                            (click)="goTo.emit(showNewFeature('bookings') ? 'new.bookings' : 'bookings')">
                        <mat-icon>event</mat-icon>
                        <div translate>SIDENAV.FIXED.BOOKINGS</div>
                    </button>
                }
                @if(enabledModules['tables']) {
                    <button mat-icon-button [ngClass]="{'active': currentRoute.indexOf('tables')>-1}"
                            class="frame-button tw-text-xs"
                            (click)="goTo.emit(showNewFeature('tables_and_cashregister') ? 'new.tables' : 'tables.rooms-view')">
                        <mat-icon>view_quilt</mat-icon>
                        <div translate>SIDENAV.FIXED.TABLES</div>
                    </button>
                }
                @if (enabledModules['orders'] && !showNewFeature('tables_and_cashregister')) {
                    <button mat-icon-button [ngClass]="{'active': currentRoute.indexOf('orders')>-1}"
                            class="frame-button tw-text-xs" (click)="goTo.emit('orders')">
                        <mat-icon>event_note</mat-icon>
                        <div translate>SIDENAV.FIXED.ORDERS</div>
                    </button>
                }
                @if(enabledModules['cashregister']) {
                    <button mat-icon-button [ngClass]="{'active': currentRoute.indexOf('cashregister')>-1}"
                            class="frame-button tw-text-xs" (click)="goTo.emit(showNewFeature('tables_and_cashregister') ? 'new.cashregister.content.showcase' : 'cashregister.content.showcase')">
                        <mat-icon>keyboard</mat-icon>
                        <div translate>SIDENAV.FIXED.CASHREGISTER</div>
                    </button>
                }
                @if(!isConnectionOffline() && enabledModules['customers']) {
                    <button mat-icon-button
                            [ngClass]="{'active': currentRoute.indexOf('customers')>-1}" class="frame-button tw-text-xs"
                            (click)="goTo.emit('new.customers.showcase')">
                        <mat-icon>group</mat-icon>
                        <div translate>SIDENAV.FIXED.CUSTOMERS</div>
                    </button>
                }
            }
        </section>
        <section class="frame-button tw-flex tw-flex-col tw-h-full tw-justify-end tw-items-center tw-gap-4 tw-flex-[2] tw-my-10">
            @if(showAutomaticPrinter) {
                <button #automaticPrintSettings mat-menu-item class="tw-justify-around" [disabled]="isConnectionOffline()" (click)="menuTriggerSettings.openMenu()">
                    <span class="material-symbols-outlined button-with-dot">order_play</span>
                    @if(firstDot) {
                        <span class="first-dot"></span>
                    }
                    @if(secondDot) {
                        <span class="second-dot"></span>
                    }
                    <div #menuTriggerSettings="matMenuTrigger" [matMenuTriggerFor]="automaticPrinterSetting"></div>
                </button>
                <mat-menu #automaticPrinterSetting="matMenu" id="matAutomaticPrinterSettings">
                    <button mat-menu-item (click)="onExternalPrintSettingChange('orders')">
                        <mat-icon>{{externalOrdersEnabled ? 'pause' : 'play_arrow'}}</mat-icon>
                        <span translate="{{externalOrdersEnabled ? 'SETTINGS.SETTINGS.DISABLE_COMMAND_PRINT' : 'SETTINGS.SETTINGS.ENABLE_COMMAND_PRINT'}}"></span>
                    </button>
                    <button mat-menu-item (click)="onExternalPrintSettingChange('sales')">
                        <mat-icon>{{externalSalesEnabled ? 'pause' : 'play_arrow'}}</mat-icon>
                        <span translate="{{externalSalesEnabled ? 'SETTINGS.SETTINGS.DISABLE_BILL_PRINT' : 'SETTINGS.SETTINGS.ENABLE_BILL_PRINT'}}"></span>
                    </button>
                    <button mat-menu-item (click)="openAutomaticPrintSettingsDialog()">
                        <mat-icon>settings</mat-icon>
                        <span translate="SETTINGS.SETTINGS.AUTOMATIC_PRINT_SETTINGS"></span>
                    </button>                    
                </mat-menu>
            }
            @if(showShopStatusAlvolo) {
                <button #alvoloSettings mat-menu-item class="tw-justify-center" (click)="menuTriggerSettings.openMenu()">
                    <mat-icon class="tw-m-0" svgIcon="{{ themeModeService.isDarkTheme ? 'alvolo-dark' : 'alvolo' }}"></mat-icon>
                    @if(firstDotAlvolo) {
                        <span class="first-dot-alvolo"></span>
                    }
                    @if(secondDotAlvolo) {
                        <span class="second-dot-alvolo"></span>
                    }
                    <div #menuTriggerSettings="matMenuTrigger" [matMenuTriggerFor]="alvoloSetting"></div>
                </button>
                <mat-menu #alvoloSetting="matMenu" id="matAlvoloSetting">
                    <button mat-menu-item (click)="editAlvoloSale()" disabled="{{waitFourSecondApiRateLimit}}">
                        <mat-icon>{{shopStatus.payload.sales_enabled ? 'pause' : 'play_arrow'}}</mat-icon>
                        <span translate="{{shopStatus.payload.sales_enabled ? 'SETTINGS.SETTINGS.DISABLE_ALVOLO_SALE' : 'SETTINGS.SETTINGS.ENABLE_ALVOLO_SALE'}}"></span>
                    </button>
                    <button mat-menu-item (click)="editAlvoloBookings()" disabled="{{waitFourSecondApiRateLimit}}">
                        <mat-icon>{{shopStatus.payload.bookings_enabled ? 'pause' : 'play_arrow'}}</mat-icon>
                        <span translate="{{shopStatus.payload.bookings_enabled ? 'SETTINGS.SETTINGS.DISABLE_ALVOLO_BOOKINGS' : 'SETTINGS.SETTINGS.ENABLE_ALVOLO_BOOKINGS'}}"></span>
                    </button>
                    <button mat-menu-item (click)="goToState('app.new.settings.alvolo')" disabled="{{waitFourSecondApiRateLimit || state.current.name == 'app.new.settings.alvolo'}}">
                        <mat-icon>settings</mat-icon>
                        <span translate="SETTINGS.SETTINGS.ALVOLO_SETTINGS"></span>
                    </button>                    
                </mat-menu>
            }
            @if(isConnectionOffline()) {
                <button mat-icon-button (click)="openOfflineDialog()">
                    <mat-icon>cloud_off</mat-icon>
                </button>
            }
            @if(pendingPrints > 0) {
                <button mat-icon-button color="warn" (click)="openPrintErrorsDialog()">
                    <mat-icon>print_error</mat-icon>
                </button>
            }
            <button mat-icon-button [ngClass]="{'active': currentRoute.indexOf('user')>-1}"
                    class="frame-button tw-text-xs"  (click)="menuTriggerUser.openMenu()">
                    <button mat-mini-fab class="custom-fab dark-button-green">
                        <span class="tw-text-md avatar-button-text">{{avatar()}}</span>
                    </button>
                <div #menuTriggerUser="matMenuTrigger" [matMenuTriggerFor]="menuUser"></div>
            </button>
            <mat-menu #menuUser="matMenu" id="matMenuUser">
                <div class="tw-flex">
                    <div class="tw-p-2">
                        <button mat-fab class="custom-fab dark-button-green">
                            <span class="tw-text-md avatar-button-text">{{avatar()}}</span>
                        </button>
                    </div>
                    <div class="tw-flex tw-items-center">
                        <div class="tw-p-2 tw-flex tw-flex-col">
                            <div style="font-size:14px; font-weight:700;">{{ operatorName }}</div>
                            <div style="font-size: 12px;">{{ shopName }}</div>
                        </div>
                    </div>
                </div>
                <button class="tw-pl-6" mat-menu-item (click)="goTo.emit('new.settings.user')">
                    <mat-icon>account_circle</mat-icon>
                    <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.USER_PREFERENCE</span>
                </button>
                @if(canSwitchUser) {
                    <button class="tw-pl-6" mat-menu-item  (click)="switchUser()">
                        <mat-icon>cached</mat-icon>
                        <span translate>SIDENAV.USER.SWITCH</span>
                    </button>
                }
                @if(canExitUser && !enableUserLogoutPin) {
                    <button class="tw-pl-6" mat-menu-item (click)="logoutActiveUserSession()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span translate>SIDENAV.USER.LOGOUT</span>
                    </button>
                }
                @if(!enableUserLogoutPin){
                    <button class="tw-pl-6" mat-menu-item
                            (click)="isUserPermitted('sidenav.close_sessions') && logoutCloseSessionsDeep()"
                            [disabled]="!isUserPermitted('sidenav.close_sessions')">
                        <mat-icon>highlight_off</mat-icon>
                        <span translate>SIDENAV.USER.DEEP_LOGOUT</span>
                    </button>
                }
            </mat-menu>
            <button #settings mat-icon-button [ngClass]="{'active': currentRoute.indexOf('settings')>-1}"
                    class="frame-button tw-text-xs"
                    (click)="menuTriggerSettings.openMenu()">
                <mat-icon>settings</mat-icon>
                <div #menuTriggerSettings="matMenuTrigger" [matMenuTriggerFor]="menuSettings"></div>
            </button>
            <mat-menu #menuSettings="matMenu" id="matMenuSettings">
                @if(isTablesModuleEnabled) {
                    <button mat-menu-item  (click)="openQrcodeWaiterShortcutDialog()">
                        <mat-icon>qr_code</mat-icon>
                        <span translate>SIDENAV.MODULES.SETTINGS.QRCODE_WAITER</span>
                    </button>
                }
                @if(settingsEnabled) {
                    <button mat-menu-item [disabled]="isConnectionOffline()" (click)="goTo.emit('new.settings.channels_management')">
                        <mat-icon>merge_type</mat-icon>
                        <span translate>SIDENAV.MODULES.SETTINGS.CHANNELS_MANAGEMENT</span>
                    </button>
                }
                @if(enabledModules['file_importer']) {
                    <button mat-menu-item [disabled]="isConnectionOffline()" (click)="goTo.emit(showNewFeature('settings.file_importer') ? 'new.file_importer.showcase' : 'file_importer.showcase')">
                        <mat-icon>file_upload</mat-icon>
                        <span translate>SIDENAV.MODULES.SETTINGS.FILE_IMPORTER</span>
                    </button>
                }
                <button mat-menu-item [disabled]="isConnectionOffline()" (click)="goTo.emit('new.file_exporter.showcase')">
                    <mat-icon>file_download</mat-icon>
                    <span translate>SIDENAV.MODULES.SETTINGS.FILE_EXPORTER</span>
                </button>
                @if(enabledModules['items']) {
                    <button mat-menu-item [disabled]="isConnectionOffline()" (click)="goTo.emit('items.showcase')">
                        <mat-icon>layers</mat-icon>
                        <span translate>SIDENAV.MODULES.ITEMS_CATALOG</span>
                    </button>
                }
                <button mat-menu-item [disabled]="isConnectionOffline()" (click)="goToState('app.new.settings.users_management')">
                    <mat-icon>people</mat-icon>
                    <span translate>SIDENAV.MODULES.SETTINGS.USERS_MANAGEMENT</span>
                </button>
                <button mat-menu-item [disabled]="isConnectionOffline()" (click)="menuClick($event)">
                    <mat-icon>settings</mat-icon>
                    <span translate>SIDENAV.MODULES.SETTINGS.OTHER_SETTINGS</span>
                </button>
            </mat-menu>
        </section>
    </div>
</div>

