<tilby-dialog-toolbar (confirm)="confirm()" title="TABLES.DELETE_SALES.TITLE"/>
<tilby-dialog-content>
    <form [formGroup]="deleteSalesFormGroup" class="tw-flex tw-flex-col">
        <mat-form-field>
            <mat-label>{{ "TABLES.DELETE_SALES.SELECT_DATE"| translate}}</mat-label>
            <input matInput [matDatepicker]="picker" [matDatepickerFilter]="rangeFilter" formControlName="selectedDate">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ "TABLES.DELETE_SALES.SELECT_TYPE" | translate }}</mat-label>
            <mat-select formControlName="selectedType" name="type">
                <mat-option>{{ "TABLES.DELETE_SALES.NONE" | translate }}</mat-option>
                @for (type of types; track type) {
                    <mat-option [value]="type.value">{{type.label}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</tilby-dialog-content>