import { IServerSideDatasource, RowClickedEvent } from "ag-grid-community";
import {
    Component,
    inject,
    OnInit,
    ViewChild
} from "@angular/core";
import { ConnectionService, EntityManagerService, ModuleEvents, PaginatedResponse,ToolbarEventsService, } from "src/app/core";
import { GridCellExportFormatterService, GridCellFormatterService, GridClickableCashMovementsButtonComponent, GridFixedButtons, GridServerSideComponent, headersTranslate } from "src/app/shared/components";
import { from } from "rxjs";
import { CashMovements } from "tilby-models";
import { CashMovementsFe, DataExport, TableData} from "src/app/shared/model/model";

import {
    GridDownloadDialogService
} from "src/app/dialogs";

import { autobind } from "src/app/models/decorators.model";
import { CashMovementDialogStateService } from "src/app/dialogs/cash-movement/cash-movement.dialog.service";
import { CliccableActionCashMovementscard } from "src/app/shared/components/grid/grid-clickable-button-cash-movements/grid-clickable-button-cash-movements.component";
import { OnDestroyService } from "src/app/core/services/on-destroy.service";

@Component({
    selector: "app-history-cash-movements",
    templateUrl: "./history-cash-movements.component.html",
    styleUrls: ["./history-cash-movements.component.scss"],
    providers: [OnDestroyService],
})
export class HistoryCashMovementsComponent implements OnInit {
    private readonly cashMovementDialogStateService = inject(CashMovementDialogStateService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly connection = inject(ConnectionService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly gridCellExportFormatterService = inject(GridCellExportFormatterService);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);

    @ViewChild("appHistoryCashGridComponent", { static: true }) gridRef!: GridServerSideComponent;
    isSelected = false;
    movColumnsSwitch = false;
    isAdvancedFilterActive = false;
    fixedButtons:GridFixedButtons= {checkbox:{visible:true,lockPosition: 'left'},
    customButton:{visible:true,cellRenderer: GridClickableCashMovementsButtonComponent,lockPosition: 'left'}};
    exportFormatterOptions?: (...any: any[])=> any;

    readonly query: Readonly<any> = {
        page: 0,
        pagination: true,
        per_page: 50,
    };

    datasource: IServerSideDatasource = {
        getRows: (params) =>
            this.gridRef.getRowsForServersideDatasource(
                params,
                [this.query],
                (a, b?) =>
                    from( this.entityManager.cashMovements.fetchCollectionOnline(a) as Promise<PaginatedResponse<CashMovements>>),
                {
                    sort_type: "desc",
                    sort_col: "date",
                },
                'HistoryCashMovementsNormalizedData'
            ),
    };

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe((e) => this.callbackToToolbarClick(e));
        this.toolbarEventsService.searchBarValue$.next("");
        this.toolbarEventsService.searchBarIcon$.next("tune");
        this.toolbarEventsService.searchBarIconFilled$.next("tune");
        this.toolbarEventsService.searchBarAction$.next({openToolPanel: "filters"});
        this.toolbarEventsService.searchBarActionFilled$.next({openToolPanel: "filters"});
        this.exportFormatterOptions = this.gridCellExportFormatterService.historyCashMovementsFormatter;
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("HISTORY-CASH-MOVEMENTS");
    }

    actions({ action, data }: CliccableActionCashMovementscard) {
        switch (action) {
            case "details": {
                this.openDetail(data!);
                break;
            }
        }
    }

    async openDetail(cash_movement: CashMovements) {
        await this.cashMovementDialogStateService.openDialog({
            data: {
                title: "DIALOG.CASH_MOVEMENT.TITLE",
                cash_movement
            },
        });
    }

    rowClicked({ data, node }: RowClickedEvent) {
        this.openDetail(data);
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => this.connection.isOffline(),
                    name: "cloud_off",
                    icon: () => "cloud_off",
                    click: (_) => null,
                },
            ],
            panelButtons: [
                {
                    isIt: () => true,
                    name: "columns_panel",
                    icon: () => "view_week",
                    label: () => "TOPBAR.ACTIONS.COLUMN_SELECTED",
                    click: (_) =>
                        this.toolbarEventsService.events.next({
                            openToolPanel: "columns",
                        }),
                },
                {
                    isIt: () => true,
                    name: "movable_columns",
                    icon: () => "drag_indicator",
                    label: () =>
                        this.movColumnsSwitch
                            ? "TOPBAR.ACTIONS.DISABLED_COLUMN_MOVEMENT"
                            : "TOPBAR.ACTIONS.ENABLED_COLUMN_MOVEMENT",
                    click: (_) =>
                        this.toolbarEventsService.events.next({
                            type: "movColumnsSwitch",
                            movColumnsSwitch: (this.movColumnsSwitch =
                                !this.movColumnsSwitch),
                        }),
                },
                {
                    isIt: () => true,
                    name: "save_columns_position",
                    icon: () => "save",
                    label: () => "TOPBAR.ACTIONS.SAVE_COLUMN_PREFERENCE",
                    click: (_) =>
                        this.toolbarEventsService.events.next({ save: true }),
                },
                {
                    isIt: () => true,
                    name: "advanced_filter",
                    icon: () =>
                        this.isAdvancedFilterActive
                            ? "filter_alt_off"
                            : "filter_list_alt",
                    label: () =>
                        this.isAdvancedFilterActive
                            ? "TOPBAR.ACTIONS.ADVANCED_FILTERS_DISABLED"
                            : "TOPBAR.ACTIONS.ADVANCED_FILTERS_ENABLED",
                    click: (_) =>
                        this.toolbarEventsService.events.next({
                            type: "advFiltersSwitch",
                            advFiltersSwitch: (this.isAdvancedFilterActive =
                                !this.isAdvancedFilterActive),
                        }),
                },
                {
                    isIt: () => true,
                    name: "export",
                    icon: () => "download",
                    label: () => 'TOPBAR.ACTIONS.EXPORT',
                    click: (_) =>
                        this.toolbarEventsService.events.next({ export: true }),
                },
            ],
        });
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents> = {}) {
        if ("search" in event)
            this.gridRef.onFilterTextBoxChanged(event.search);
        else if ("export" in event)
            await this.gridDownloadDialogService
                .openDialog("history_cash_movements", { data: this.tableData.map(tableData => ({...tableData,sortFilterQuery: this.gridRef.getSortFilterQuery(),countSelectedRows:this.gridRef.gridApi?.getSelectedRows().length})) },'cash_movements')
                .then((res) => !!res && this.onExport(res,'cash_movements'));
        else if ("openToolPanel" in event && event.openToolPanel)
            this.gridRef.openToolPanel(event.openToolPanel);
        else if ("type" in event && event.type === "advFiltersSwitch") {
            this.gridRef.advancedFilterSwitch(event.advFiltersSwitch || false);
        } else if ("type" in event && event.type === "movColumnsSwitch")
            this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
        else if ("save" in event)
            await this.gridRef.openDialogSaveColumnsPreference();
    }

    @autobind
    onExport(dataExport: DataExport,tableToCall?:string) {
        this.gridRef?.onBtnExport(dataExport,tableToCall);
    }

    tableData: TableData[] = [
        {
            rowData$: null,
            dataType: new CashMovementsFe(),
            headersTranslate: headersTranslate.history_cash_movements,
            columnsFormatter: this.gridCellFormatterService.history_cash_movements,
        },
    ];
}
