import { HttpClient } from "@angular/common/http";

import {
    computed,
    effect,
    Injectable,
    signal
} from "@angular/core";

import {
    BehaviorSubject,
    filter,
    firstValueFrom,
    timeout,
    asyncScheduler,
    Subscription,
    skip
} from "rxjs";

import { EnvironmentConfig } from "src/environments/environment-config";

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {
    private static connectionSubject: BehaviorSubject<{
        online: boolean
        timestamp: number
    }> = new BehaviorSubject({ online: navigator.onLine, timestamp: Date.now() });

    public static connectionStatus$ = ConnectionService.connectionSubject.pipe(skip(1));

    private checkScheduleHandler: Subscription | undefined;
    private statusUrl = (EnvironmentConfig.apiUrl || "https://api.scloby.com/v2") + "/status";

    private _isOnline = signal(ConnectionService.connectionSubject.value.online);
    private _timestamp = signal(ConnectionService.connectionSubject.value.timestamp);

    private logEvent(...message: any[]) {
        console.debug('[ ConnectionService ]', ...message);
    }

    constructor(
        private http: HttpClient
    ) {
        effect(() => {
            this.logEvent('Connection status changed to', this._isOnline());
            this._timestamp.set(Date.now());

            ConnectionService.connectionSubject.next({
                online: this._isOnline(),
                timestamp: this._timestamp()
            });
        }, {
            allowSignalWrites: true
        })
    }

    /**
     * Checks the connection to the API.
     *
     * @param {number} timeoutValue - The timeout value in milliseconds (default: 10000)
     * @return {Promise<void>} A promise that resolves when the connection is successful and rejects when there is an error.
     */
    private checkConnection(timeoutValue: number = 10000): Promise<void> {
        const self = this;

        // Cancel the next scheduled check
        if(this.checkScheduleHandler && !this.checkScheduleHandler?.closed) {
            this.checkScheduleHandler.unsubscribe();
        }

        // Return the connection check result as a promise
        return new Promise((resolve, reject) => {
            this.checkScheduleHandler = asyncScheduler.schedule(function() {
                self.http.get(self.statusUrl)
                .pipe(timeout(timeoutValue))
                //For each outcome, update the connection status if changed, resolve/reject the promise and schedule the next check
                .subscribe({
                    next: () => {
                        self._isOnline.set(true);

                        resolve();
                        self.checkScheduleHandler = this.schedule(null, 15000);
                    },
                    error: () => {
                        self._isOnline.set(false);

                        reject();
                        self.checkScheduleHandler = this.schedule(null, 500);
                    }
                });
            });
        });
    }

    /**
     * Check if the application is currently online.
     *
     * @return {boolean} True if the application is online
     */
    public isOnline = computed(() => this._isOnline());

    /**
     * Check if the application is currently offline.
     * @returns {boolean} True if the application is offline
     */
    public isOffline = computed(() => !this._isOnline());

    /**
     * Calculates the timestamp when the connection went offline.
     *
     * @return {number} The timestamp when the connection went offline, or 0 if the application is online.
     */
    public offlineAt = computed(() => this._timestamp());

    /**
     * Calculates the duration of the offline period.
     *
     * @return {number} The duration of the offline period in milliseconds.
     */
    public offlineDuration(): number {
        const currentStatus = ConnectionService.connectionSubject.value;

        if(currentStatus.online) {
            return 0;
        }

        return Date.now() - currentStatus.timestamp;
    }

    /**
     * Checks if the application is online by sending a status request to the API.
     * The service connection status is updated accordingly
     *
     * @return {Promise<void>} - A promise that resolves if the application is online, or rejects if the application is offline.
     */
    public async isOnlinePing() {
        return this.checkConnection();
    }

    /**
     * Waits for the application to be online, or returns immediately if the application is already online.
     *
     * @param {number} timeoutValue - The maximum time to wait for a connection in milliseconds. Default is 10000.
     * @return {Promise<void>} - A Promise that resolves when a connection is established, or rejects if the wait timeout is reached.
     */
    public async waitForConnection(timeoutValue: number = 10000) {
        await firstValueFrom(
            ConnectionService.connectionSubject.asObservable().pipe(
                filter(status => status.online),
                timeout(timeoutValue)
            )
        );
    }
}
