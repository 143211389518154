<div class="tw-flex tw-gap-1 tw-flex-wrap tw-pt-1">
    @for (btn of buttons; track $index) {
        @if (btn.isIt()) {
            <tilby-general-input-button
                class="sale-keypad-button tw-basis-[calc(100%/7)] tw-flex-1"
                [class.tw-opacity-25]="btn.isDisable?.()"
                [class.tw-pointer-events-none]="btn.isDisable?.()"
                [class.tw-bg-green-500]="btn.selected?.()"
                [btn]="btn"/>
        }
    }
</div>
