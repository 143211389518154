import {
    CommonModule
} from "@angular/common";

import {
    Component,
    Injectable,
    OnInit,
    inject,
    signal
} from "@angular/core";

import {
    MatDialog,
    MatDialogModule,
    MatDialogRef
} from "@angular/material/dialog";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from "@angular/forms";

import {
    ConfigurationManagerService,
    ConfigurationPreferences,
    EntityManagerService
} from "src/app/core";

import { lastValueFrom } from "rxjs";
import { MatIconModule } from "@angular/material/icon";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";

import {
    MatListModule,
    MatSelectionListChange
} from "@angular/material/list";

import { MatRadioModule } from "@angular/material/radio";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { MatDividerModule } from "@angular/material/divider";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { restManager } from "app/ajs-upgraded-providers";

import {
    Customers,
    Sales,
    SalesCustomer,
    SalesItems
} from "tilby-models";

import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SaleUtilsService } from "src/app/features";
import { keyBy, MathUtils } from "src/app/shared/utils";

const loginFieldsMap = {
    'type': 'appId',
    'account_name': 'accountName',
    'company': 'company',
    'password': 'password',
    'subscription_key': 'subscriptionKey',
    'esp_backend': 'espBackend'
}

const magoOrdersConfigNamespace = 'integrations.mago.orders.';

@Component({
    selector: 'app-mago-orders-dialog',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatRadioModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        TilbyCurrencyPipe,
        TilbyDatePipe,
        TilbyDialogActionButtonsComponent,
        TilbyDialogContentComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogToolbarComponent,
        TranslateModule,
    ],
    templateUrl: './mago-orders-dialog.component.html',
    styleUrls: ['./mago-orders-dialog.component.scss']
})
class MagoOrdersDialogComponent implements OnInit {
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly restManagerService = inject(restManager);
    private readonly translateService = inject(TranslateService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly saleUtilsService = inject(SaleUtilsService);

    protected loginForm?: FormGroup;
    protected operationInProgress = signal(false);
    protected message?: string;

    protected ordersForm?: FormGroup;
    protected ordersList?: MagoSaleOrder[];
    protected selectedOrder?: MagoSaleOrder;

    protected customers: Customers[] = [];
    protected selectedCustomer?: Customers;
    protected filteredCustomers?: Observable<Customers[]>;

    private async createOrdersForm() {
        this.loginForm = undefined;

        const ordersForm = this.formBuilder.group({
            customer: [],
        });

        this.customers = await this.entityManagerService.customers.fetchCollectionOffline().then((customers) => customers.filter(customer => customer.fidelity || customer.external_id));

        this.filteredCustomers = ordersForm.get('customer')!.valueChanges.pipe(
            startWith(''),
            map(value => this._filterCustomers(value || ''))
        );

        this.ordersForm = ordersForm;
    }

    private _filterCustomers(value: string): Customers[] {
        if(typeof value !== 'string') {
            return this.customers;
        }

        const filterValue = value.toLowerCase().split(' ');

        let filteredCustomers = this.customers;

        for(const word of filterValue) {
            filteredCustomers = filteredCustomers.filter(customer =>
                customer.first_name?.toLowerCase().includes(word) ||
                customer.last_name?.toLowerCase().includes(word) ||
                customer.company_name?.toLowerCase().includes(word) ||
                customer.fidelity?.toLowerCase().includes(word) ||
                customer.external_id?.toLowerCase().includes(word) ||
                customer.tax_code?.toLowerCase().includes(word) ||
                customer.vat_code?.toLowerCase().includes(word) ||
                customer.phone?.toLowerCase().includes(word) ||
                customer.mobile?.toLowerCase().includes(word)
            );
        }

        return filteredCustomers;
    }

    displayCustomerName(customer?: Customers): string {
        if(!customer) {
            return '';
        }

        return customer.company_name
            ? `${customer.company_name}${customer?.fidelity ? ` (${customer.fidelity})` : ''}`
            : `${customer.first_name} ${customer.last_name}${customer?.fidelity ? ` (${customer.fidelity})` : ''}`;
    }

    customerSelected(event: any) {
        this.ordersList = undefined;
        this.selectedOrder = undefined;

        this.selectedCustomer = event.option.value;
    }

    private onAppIdChange(loginForm: FormGroup, value: string) {
        const espBackend = loginForm.get('espBackend');

        if (espBackend) {
            espBackend.setValidators(['magoWeb', 'mago4'].includes(value) ? [Validators.required] : []);
            espBackend.updateValueAndValidity();
        }

        const company = loginForm.get('company');

        if (company) {
            company.setValidators(['mago4'].includes(value) ? [Validators.required] : []);
            company.updateValueAndValidity();
        }
    }

    private async createLoginForm() {
        this.ordersList = undefined;
        this.ordersForm = undefined;
        this.selectedCustomer = undefined;

        const formFields: Record<string, any[]> = {};

        for (const [key, value] of Object.entries(loginFieldsMap)) {
            formFields[value] = [this.configurationManagerService.getPreference(`${magoOrdersConfigNamespace}${key}` as keyof ConfigurationPreferences), [Validators.required]];
        }

        const loginForm = this.formBuilder.group(formFields);

        //Initialize required flag for espBackend
        this.onAppIdChange(loginForm, loginForm.get('appId')?.value);

        loginForm.get('appId')?.valueChanges.subscribe((value) => {
            this.onAppIdChange(loginForm, value);
        });

        this.loginForm = loginForm;
    }

    ngOnInit(): void {
        if (this.configurationManagerService.getPreference(`${magoOrdersConfigNamespace}account_name`)) {
            this.createOrdersForm();
        } else {
            this.createLoginForm();
        }
    }

    clearMessage() {
        this.message = undefined;
    }

    onOrderSelect(event: MatSelectionListChange) {
        this.selectedOrder = event.options[0]?.value;
    }

    async confirm() {
        // Check if there is a selected order or if an operation is in progress.
        if (this.operationInProgress() || !this.selectedOrder) {
            return;
        }

        try {
            // Indicate that an operation is in progress.
            this.operationInProgress.set(true);

            // Get the selected customer.
            let saleCustomer: SalesCustomer | undefined = undefined;

            if(this.selectedCustomer) {
                const { created_at, updated_at, deleted_at, id, ...customerData } = this.selectedCustomer;

                saleCustomer = {
                    ...customerData,
                    customer_id: id,
                }
            }

            // Fetch order details based on the selected order.
            const orderDetails: MagoSaleOrderDetail[] = await this.restManagerService.post('mago/api/retailcorepos/OpenSalesOrdersDetails', {
                InternalOrdNo: this.selectedOrder.InternalOrdNo
            }).then((response: MagoSaleOrderDetailsResponse) => response.saleOrdersDetails);

            // Get the sale template for the current sale.
            const saleTemplate = await this.saleUtilsService.getSaleTemplate({ skipFiscalProvider: true });

            // Prepare the update data for the sale template.
            const updateData: Partial<Sales> = {
                bill_lock: true,
                name: `Mago ${this.selectedOrder.Customer}: ${this.selectedOrder.InternalOrdNo}`.slice(0, 30),
                channel: 'mago',
                external_id: this.selectedOrder.InternalOrdNo,
                notes: `OrderDate: ${this.tilbyDatePipe.transform(this.selectedOrder.OrderDate, 'dd/MM/yyyy')}\n`,
                sale_customer: saleCustomer
            }

            // Merge the update data into the sale template.
            Object.assign(saleTemplate, updateData);

            // Fetch departments offline and map them by VAT.
            const departmentsByCode = await this.entityManagerService.departments.fetchCollectionOffline().then((deps) => keyBy(deps, (dep) => dep.code));
            const itemsBySku = await this.entityManagerService.items.fetchCollectionOffline().then((items) => keyBy(items, (item) => item.sku));

            const saleItems: SalesItems[] = [];

            // Create sale items from the order details.
            for (const magoItem of orderDetails) {
                const department = departmentsByCode[magoItem.TaxCode];
                const item = itemsBySku[magoItem.Item];

                // Skip if the department is not found.
                if (!department) {
                    throw { error: 'MISSING_DEPARTMENT', tax_code: magoItem.TaxCode };
                }

                const vat = department.vat?.value || 0;

                // Calculate the item price including tax.
                const itemPrice = MathUtils.round(magoItem.UnitValue * (1 + (vat / 100)));
                const saleItem = this.saleUtilsService.getDynamicSaleItemTemplate(department, itemPrice);

                const updateData: Partial<SalesItems> = {
                    name: magoItem.Item,
                    barcode: magoItem.BarcodeVariant || undefined,
                    category_id: item?.category_id,
                    category_name: item?.category?.name,
                    combination_sku: magoItem.VariantCode || undefined,
                    item_id: item?.id,
                    quantity: magoItem.Qty,
                    sku: item?.sku
                };

                Object.assign(saleItem, updateData);

                // If there is a discount, add it to the sale item.
                if(magoItem.DiscountAmount) {
                    const priceChange = this.saleUtilsService.getGenericPriceChange(
                        saleItem,
                        'discount_fix',
                        MathUtils.round(magoItem.DiscountAmount * (1 + (vat / 100))),
                        this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT')
                    );

                    saleItem.price_changes = [priceChange];
                }

                // Add the created sale item to the list.
                saleItems.push(saleItem);
            }

            // Assign the created sale items to the sale template.
            saleTemplate.sale_items = saleItems;

            // Close the dialog and return the sale template.
            this.matDialogRef.close(saleTemplate);
        } catch(err: any) {
            if('tax_code' in err) {
                this.message = this.translateService.instant('CASHREGISTER.MAGO_ORDERS.MISSING_DEPARTMENT', { taxCode: err.tax_code });
            }
        } finally {
            // Reset the operation status.
            this.operationInProgress.set(false);
        }
    }

    async onLogin() {
        if (this.operationInProgress() || !this.loginForm?.valid) {
            return;
        }

        this.operationInProgress.set(true);

        try {
            const appId = this.loginForm.get('appId')?.value;
            
            if (appId === 'magoCloud') {
                const espBackend = this.loginForm.get('espBackend');
                espBackend?.setValue(undefined);
                espBackend?.updateValueAndValidity();
            }

            if (appId === 'mago4') {
                const subscriptionKey = this.loginForm.get('subscriptionKey');
                subscriptionKey?.setValue(undefined);
                subscriptionKey?.updateValueAndValidity();
            } else {
                const company = this.loginForm.get('company');
                company?.setValue(undefined);
                company?.updateValueAndValidity();
            }

            const payload: MagoLoginRequest = {
                ...this.loginForm?.value,
                appId: 'MagoAPI',
                overwrite: true
            }

            const response = await this.restManagerService.post('mago/login', payload) as MagoLoginResponse;

            if (!response.Result) {
                throw response.Message;
            }

            for (const [key, value] of Object.entries(loginFieldsMap)) {
                const formValue = this.loginForm.get(value);

                if (formValue?.touched) {
                    if(formValue.value) {
                        await this.configurationManagerService.setShopPreferenceSync(`${magoOrdersConfigNamespace}${key}` as keyof ConfigurationPreferences, formValue.value);
                    } else {
                        await this.configurationManagerService.deleteShopPreferenceSync(`${magoOrdersConfigNamespace}${key}` as keyof ConfigurationPreferences);
                    }
                }
            }

            this.message = 'CASHREGISTER.MAGO_ORDERS.ENABLE_SUCCESSFUL';

            setTimeout(() => this.clearMessage(), 2000)

            this.createOrdersForm();
        } catch (e: any) {
            this.message = e;
        } finally {
            this.operationInProgress.set(false);
        }
    }

    async searchOrders() {
        if (this.operationInProgress() || !this.selectedCustomer) {
            return;
        }

        this.ordersList = undefined;
        this.selectedOrder = undefined;

        const searchPayload: Record<string, string | undefined> = {};

        try {
            this.operationInProgress.set(true);

            searchPayload['Customer'] = this.selectedCustomer.external_id || '';
            searchPayload['CardNumber'] = this.selectedCustomer.fidelity || '';

            const response = await this.restManagerService.post('mago/api/retailcorepos/OpenSalesOrdersList', searchPayload) as MagoSaleOrdersResponse;

            this.ordersList = response.saleOrders;
        } catch (error: any) {
            this.message = error?.data?.errorMessage;

            if([401].includes(error.status)) {
                this.createLoginForm();
            }
        } finally {
            this.operationInProgress.set(false);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class MagoOrdersDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(): Promise<Sales | undefined> {
        const config: NonNullableConfigData<any> = { ...this.switchMobileDesktopDimensions({ width: '800px' }), disableClose: true, data: {} };

        return lastValueFrom(this.dialogRef.open(MagoOrdersDialogComponent, config).afterClosed());
    }
}
