import { CommonModule } from "@angular/common";
import {
    Component,
    Injectable,
    ViewChild,
    inject
} from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { QRCodeModule } from "angularx-qrcode";
import { $state } from "app/ajs-upgraded-providers";
import { lastValueFrom } from "rxjs";

import {
    EntityManagerService,
    EnvironmentInfoService,
    StorageManagerService,
    UserActiveSessionManagerService
} from "src/app/core";

import { SettingsUmListComponent } from "src/app/features/settings/settings-users-management/components/settings-users-management/settings-um-list/settings-um-list.component";
import { ButtonUmList, QRCodeData } from "src/app/features/settings/settings-users-management/models/settings-users-management.model";
import { WebServerSlaveDevices, WebServerSlaveDevicesResponse } from "tilby-models";
import { v4 as generateUuid } from 'uuid';
import { Subject, filter, takeUntil } from 'rxjs';
import { MatInputModule } from "@angular/material/input";
import { SettingsUsersManagementService } from "src/app/features/settings/settings-users-management/services/settings-users-management.services";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SkeletonDirective } from "src/app/directives/skeleton.directive";
import { MatDividerModule } from "@angular/material/divider";

export type QrcodeWaiterShortcutDialogData = {};
type Item = WebServerSlaveDevices;
const timestamp24 = 24 * 60 * 60 *1000;

@Component({
    selector: 'app-qrcode-waiter-shortcut-dialog',
    templateUrl: './qrcode-waiter-shortcut-dialog.component.html',
    styleUrls: ['./qrcode-waiter-shortcut-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        MatInputModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        QRCodeModule,
        ScrollingModule,
        NgxSkeletonLoaderModule,
        SkeletonDirective,
    ],
})
export class QrcodeWaiterShortcutDialogComponent {
    @ViewChild(SettingsUmListComponent) componentSettingUmList!: SettingsUmListComponent;

    private readonly qrcodeWaiterShortcutDialogService = inject(QrcodeWaiterShortcutDialogService);
    private readonly settingsUsersManagementService = inject(SettingsUsersManagementService);
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly state = inject($state);

    protected screenHeight=screen.height;
    EMPTY_ITEMS: string[] = Array(10).fill('');
    onDestroy$ = new Subject<void>();
    devices: WebServerSlaveDevicesResponse = [];
    allDevices: WebServerSlaveDevicesResponse = [];
    deviceSelected: WebServerSlaveDevices | undefined | null;
    value: string = '';
    isLoading: boolean = false;
    qrCode: string = '';
    tilbyVersion = require('app/tilby.properties.json').tilbyVersion;
    localAddressIP = '';
    userId!: number;
    enabledQrCodeGenerator = true;
    openFilter: boolean = false;
    filterText: string = '';
    hasBack: boolean = false;
    itemsIcon = 'smartphone';
    customButtons: ButtonUmList[] = [];
    selectedItem: Item | undefined;
    protected createActionButtons = [
        {
            isIt: () => true,
            isDisable: () => false,
            name: '',
            icon: () => 'settings',
            click: () => this.goToUsersManagement(),
        },
    ];

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    async ngOnInit() {
        try {
            let localAddress = await this.environmentInfoService.getNetworkInfo();
            this.localAddressIP = localAddress.client_lan_ip;
        } catch(err) {
            this.enabledQrCodeGenerator = false;
            console.error('local address IP not supported in web version');
        }

        await this.loadDevices();

        StorageManagerService.storageUpdates$.pipe(
            filter(data => data.entityName == 'slave_devices'),
            takeUntil(this.onDestroy$)
        ).subscribe((data) => {
            if(data.id) {
                this.reloadSingleDevice(data.id as string);
            }
        });

        this.userId = this.userActiveSessionManagerService.getSession()?.id!;
    }

    async loadDevices() {
        const result = await this.entityManagerService.slaveDevices.fetchCollectionOffline();
        this.devices = this.allDevices = result;
        this.isLoading = true;
    }

    async reloadSingleDevice(id: string) {
        let indexDeviceSelected = this.devices.findIndex(device => device.id === id);
        const result = await this.entityManagerService.slaveDevices.fetchOneOfflineFirst(id);
        const index = this.devices.findIndex(device => device.id === id);
        const indexAll = this.allDevices.findIndex(device => device.id === id);
        this.devices = [...this.devices];
        this.devices[index] = this.allDevices[indexAll] = result;
        if (this.componentSettingUmList?.filterText)
            this.devices = this.allDevices.filter(item => item.name.toLowerCase().includes(this.componentSettingUmList.filterText.toLowerCase()));
        if (indexDeviceSelected >= 0)
            this.deviceSelected = this.devices[indexDeviceSelected];
    }

    regenerate = async () => {
        this.putQrcode(this.deviceSelected!.uuid,this.deviceSelected!.name, null, null, generateUuid(),Math.floor((TilbyDatePipe.toUtcDateMillis() + timestamp24) / 1000),this.deviceSelected!.server_ip,TilbyDatePipe.date(), this.userId);
    };

    setDevice(device: WebServerSlaveDevices) {
        this.value = device.name;
        this.deviceSelected = device;
        this.setQRCode(this.localAddressIP, device.name, device.code, this.tilbyVersion);
    }

    setQRCode(server: string, deviceName: string, code: string, version: string): void {
        this.qrCode = `{
            "server": "${server}:2013",
            "device_name": "${deviceName}",
            "code": "${code}",
            "version": "${version}"
        }`;
    }

    getDataForQRCode(uuid: string, name: string | null, session_token: string | undefined | null, token_expire_at: number | undefined | null, code: string, code_expire_at: number, server_ip: string, updated_at: string, updatedby_id: number): QRCodeData {
        let data = {
            "uuid": uuid,
            "name": name,
            "session_token": session_token || null,
            "token_expire_at": token_expire_at || null,
            "code": code,
            "code_expire_at": code_expire_at,
            "server_ip": server_ip,
            "updated_at": updated_at,
            "updatedby_id": updatedby_id
        };
        return data;
    }

    putQrcode = async (uuid : string, name : string, session_token: string | undefined | null,  token_expire_at: number | undefined | null, code: string, code_expire_at: number, server_ip: string, date: string, user_id: number) => {
        let data = this.getDataForQRCode( uuid, name, session_token, token_expire_at, code, code_expire_at, server_ip, date, user_id);
        let qrcode: WebServerSlaveDevices = await this.entityManagerService.slaveDevices.putOneOfflineFirst({...data,id:`${data.uuid}`,uuid:data.uuid});
        this.setQRCode(this.localAddressIP, qrcode.name, qrcode.code, this.tilbyVersion);
        const index = this.devices.findIndex(device => device.uuid === this.deviceSelected?.uuid);
        const indexAll = this.allDevices.findIndex(device => device.uuid === this.deviceSelected?.uuid);
        this.deviceSelected = this.devices[index] = this.allDevices[indexAll] = qrcode;
        this.devices = [...this.devices];
    }

    onDeviceClick(device: WebServerSlaveDevices) {
        this.setDevice(device);
    }

    hasSessionToken() {
        return this.deviceSelected?.session_token !== null;
    }

    async onSmartphoneSelected(device: WebServerSlaveDevices) {
        this.setDevice(device);
    }

    onItemClick(item: WebServerSlaveDevices) {
        this.deviceSelected = item;
        this.setDevice(item);
    }


    buildFieldLegend2(item: WebServerSlaveDevices): string {
        return this.settingsUsersManagementService.getDateDifference(item.updated_at.toString());
    }

    goToUsersManagement(){
        this.state.go('app.new.settings.users_management', {tab: 1});
        this.qrcodeWaiterShortcutDialogService.closeDialog();
    }
}

@Injectable({
    providedIn: 'root'
})
export class QrcodeWaiterShortcutDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: QrcodeWaiterShortcutDialogData){
        (!data) && (data={});
        const config:NonNullableConfigData<QrcodeWaiterShortcutDialogData>={...this.switchMobileDesktopDimensions(), disableClose:true, data};
        return lastValueFrom(this.dialogRef.open(QrcodeWaiterShortcutDialogComponent, config).afterClosed());
    }

    public closeDialog(){
        this.dialogRef.closeAll();
    }
}
