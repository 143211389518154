import { CommonModule, KeyValue } from "@angular/common";
import { Component, Injector, Input, ViewChild, inject, runInInjectionContext } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps, TilbyInputComponent, TilbyMagicFormComponent, TilbyRadioInputComponent, TilbySelectInputComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { $state, restManager } from "app/ajs-upgraded-providers";
import { ConfigurationManagerService, ConfigurationPreferences, ToolbarEventsService } from "src/app/core";
import { ToolbarEventsContextService } from "src/app/core/services/toolbar-events/toolbar-events-context.service";

import {
    AlertDialogService,
    ConfirmDialogService,
    OpenDialogsService
} from "src/app/dialogs";

import { ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { InitSettingFileImporting } from "tilby-models";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { CSVFixedLength, CSVSeparatorType, FileImporterFormFields, FileImporterFormFieldsCSV } from "../../model/file-importer-fields.model";

import { 
    FORM_GROUP_VALIDATORS,
    subscribeInComponent
} from "@tilby/tilby-ui-lib/utilities";

import { distinctUntilChanged } from "rxjs";

type FileImporterFormValue = {};
export type FileImporterForm = CustomFormGroup<CustomForm<FileImporterFormValue>>;
export type FileImporterParams = {};
export type FileImporterDialogOutput = {};
export type FileImporterFormDialogData = InputItemDataMagicForm<FileImporterFormValue>;

type ImportingFileInterface = { id: number, sizeMax: number, url: string };

@Component({
    selector: "app-file-importer-add",
    templateUrl: "settings-file-importer-add.component.html",
    styleUrls: ["settings-file-importer-add.component.scss"],
    standalone: true,
    imports: [ CommonModule, MatProgressBarModule, TilbyMagicFormComponent, TilbyInputComponent, TilbyRadioInputComponent, TilbySelectInputComponent, ReactiveFormsModule, MatButtonModule, MatIconModule, TranslateModule ],
})
export class FileImporterAddComponent {
    @Input() fileConfig!: InitSettingFileImporting;
    @ViewChild('fileInput') fileInput: any;

    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManager = inject (ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly injector = inject(Injector);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly restManagerService = inject (restManager);
    private readonly state = inject($state);
    private readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    fileToUpload!: Blob | null;
    isProcessing: boolean = false;
    showFileImporterAddFormCSV: boolean = false;
    fileImporterFormGroup!: CustomFormGroup<CustomForm<FileImporterFormFields>>;
    fileImporterAddFormCSV!: CustomFormGroup<CustomForm<FileImporterFormFieldsCSV>>;

    import_types_choices: KeyValue<string, string>[] = [
        {key: "FILE_IMPORTER.IMPORT_TYPES.CUSTOMERS", value: "customers"},
        {key: "FILE_IMPORTER.IMPORT_TYPES.ITEMS", value: "items"},
        {key: "FILE_IMPORTER.IMPORT_TYPES.STOCK_MOVEMENTS", value: "stock_movements"},
        {key: "FILE_IMPORTER.IMPORT_TYPES.COMPONENTS", value: "components"},
        {key: "FILE_IMPORTER.IMPORT_TYPES.GIFTCARDS", value: "giftcards"},
    ]
    
    file_types_choices: KeyValue<string, string>[] = 
    [
        {key: "FILE_IMPORTER.ADD.FILE_TYPES.XLS", value: "xls"},
        {key: "FILE_IMPORTER.ADD.FILE_TYPES.XLSX", value: "xlsx"},
        {key: "FILE_IMPORTER.ADD.FILE_TYPES.CSV", value: "csv"}
    ]
    
    decimal_separators_choices: KeyValue<string, string>[] = [
        {key: "FILE_IMPORTER.ADD.DECIMAL_SEPARATORS.DOT", value: "."},
        {key: "FILE_IMPORTER.ADD.DECIMAL_SEPARATORS.COMMA", value: ","},
    ]
    
    import_from_file_choices: KeyValue<string, string>[] = [
        {key: "FILE_IMPORTER.ADD.IMPORT_FROM_FILE", value: "file"},
    ]

    columnDelimiters: KeyValue<string, string>[] = [
        {key: ",", value: ","},
        {key: ";", value: ";"},
        {key: "|", value: "|"},
    ];

    stringDelimiters: KeyValue<string, string>[] = [
        {key: '\'', value: '\''},
        {key: '"', value: '"'},
    ];

    separatorTypeChoices: KeyValue<string, string>[] = [
        {key: "FILE_IMPORTER.ADD.CSV_VALUE_SEPARATED", value: "separator"},
        {key: "FILE_IMPORTER.ADD.CSV_FIXED_LENGTH", value: "separator"},
    ];

    type_separation_choices: KeyValue<string, string>[] = [
        {key: "FILE_IMPORTER.ADD.CSV_COLUMN_DELIMITER", value: 'separator_csv_column_delimiter'},
        {key: "FILE_IMPORTER.ADD.CSV_STRING_DELIMITER", value: 'separator_csv_string_delimiter'},
    ];

    ngOnInit(): void {
        const createToolbarButtons = () => {
            this.toolbarEventsService.moduleTitle.next("NEW_IMPORTER");
            this.toolbarEventsContextService.showToolbarContext$.next(true);
            this.toolbarEventsContextService.buttons$.next({
                barButtons: [
                    {
                        isIt: () => true,
                        isDisable: () => false,
                        name: 'saveGeneralForm',
                        icon: () => 'check',
                        click: () =>  this.confirm()
                    }
                ],
                panelButtons: []
            })
            this.toolbarEventsContextService.backButton$.next({
                isIt: () => true,
                name: "arrow_back",
                icon: () => "arrow_back",
                click: () => this.back()
            });
        };

        createToolbarButtons();

        this.fileImporterFormGroup = this.createFileImporterForm();
        this.fileImporterAddFormCSV = this.createFileImporterAddFormCSV();

        runInInjectionContext(this.injector, () => {
            this.subscriptions();
        })
    }

    subscriptions() {
        subscribeInComponent(
            this.separator_type_controller.valueChanges.pipe(distinctUntilChanged()),
            (value: boolean) => {
                if(value) { 
                    this.delimiters_type_controller.reset(undefined, {onlySelf:true})
                    this.delimitator_column_controller.markAsPristine();
                    this.delimitator_column_controller.markAsUntouched();
                    this.delimitator_string_controller.markAsPristine();
                    this.delimitator_string_controller.markAsUntouched();
                    this.delimitator_column_controller.enable({onlySelf: true, emitEvent: true});
                    this.delimitator_string_controller.enable({onlySelf: true, emitEvent: true});
                    this.csv_insert_delimiters_controller.disable({onlySelf: true, emitEvent: true});
                    this.csv_insert_delimiters_controller.setValue(undefined, {onlySelf: true, emitEvent: true});
                    this.csv_separator_type_group.updateValueAndValidity({onlySelf: true, emitEvent: true});
                    this.csv_separator_type_group.setErrors({ 'invalid': true });
                }
            }
        );

        subscribeInComponent(
            this.delimiters_type_controller.valueChanges.pipe(distinctUntilChanged()),
            (value: boolean) => {
                if(value) { 
                    this.separator_type_controller.reset(undefined, {onlySelf: true});
                    this.csv_insert_delimiters_controller.enable({onlySelf: true, emitEvent: true});
                    this.csv_insert_delimiters_controller.markAsPristine();
                    this.csv_insert_delimiters_controller.markAsUntouched();
                    this.delimitator_column_controller.disable({onlySelf: true, emitEvent: true});
                    this.delimitator_string_controller.disable({onlySelf: true, emitEvent: true});
                    this.delimitator_column_controller.setValue(undefined, {onlySelf: true, emitEvent: true});
                    this.delimitator_string_controller.setValue(undefined, {onlySelf: true, emitEvent: true});
                    this.csv_fixed_length_group.updateValueAndValidity({onlySelf: true, emitEvent: true});
                    this.csv_fixed_length_group.setErrors({ 'invalid': true });
                }
            }
        )

        subscribeInComponent(
            this.fileImporterFormGroup.controls.file_types.valueChanges.pipe(distinctUntilChanged()),
            (value: string) => {
                this.csv_separator_type_group.setErrors({ 'invalid': true });
                this.csv_fixed_length_group.setErrors({ 'invalid': true });

                if(value !== "csv") {
                    this.csv_separator_type_group.controls.separator_type_controller.setValue(undefined, {onlySelf: true, emitEvent: true});
                    this.csv_fixed_length_group.controls.delimiters_type_controller.setValue(undefined, {onlySelf: true, emitEvent: true});
                }
            }
        )

        subscribeInComponent(
            this.fileImporterFormGroup.controls.file_types.valueChanges.pipe(distinctUntilChanged()),
            (type: string) => {
                if(type !== this.fileConfig.type) {
                    this.fileImporterFormGroup.controls.file_types.setValue(this.file_types_choices.find(file_type => file_type.value === type)?.value);
                    this.fileInput.value = "";
                    this.fileToUpload = null;
                }
            }
        )
    }
    
    createFileImporterForm() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        })

        return new CustomFormGroup<CustomForm<FileImporterFormFields>>({
            import_types: new CustomFormControl(
                'items',
                {},
                {
                    ...new CustomFormControlProps(),
                    inputType: 'select',
                    inputChoices: this.import_types_choices,
                    label: 'FILE_IMPORTER.ADD.IMPORT_TYPE',
                    id: 'import_types',
                    class: 'tw-w-1/3'
                }
            ),
            import_from_file: new CustomFormControl(
                'file',
                {},
                {
                    ...new CustomFormControlProps(),
                    inputType: 'select',
                    inputChoices: this.import_from_file_choices,
                    label: 'FILE_IMPORTER.ADD.IMPORT_SOURCE',
                    id: 'import_from_file',
                    class: 'tw-w-1/3'
                }
            ),
            file_types: new CustomFormControl(
                'xls',
                {},
                {
                    ...new CustomFormControlProps(),
                    inputType: 'select',
                    inputChoices: this.file_types_choices,
                    label: 'FILE_IMPORTER.ADD.FILE_TYPE',
                    id: 'file_types',
                    class: 'tw-w-1/3'
                }
            ),
            decimal_separators: new CustomFormControl(
                ',',
                { validators: [Validators.required] },
                {
                    ...new CustomFormControlProps(),
                    inputType: 'select',
                    inputChoices: this.decimal_separators_choices,
                    label: 'FILE_IMPORTER.ADD.DECIMAL_SEPARATOR',
                    id: 'decimal_separators',
                    class: 'tw-w-1/3'
                }
            ),

        })
    }
    
    createFileImporterAddFormCSV() {
        return new CustomFormGroup<CustomForm<FileImporterFormFieldsCSV>>({
            csv_separator_type: new CustomFormGroup<CustomForm<CSVSeparatorType>>({
                separator_type_controller: new CustomFormControl(false, { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'radio', inputChoices: [this.type_separation_choices[0]]}),
                delimitatorColumn: new CustomFormControl({value: this.configurationManager.getUserPreferences()['file_importer.delimitator_column'] || '' , disabled: true}, { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'select', inputChoices: this.columnDelimiters, label:'FILE_IMPORTER.ADD.CSV_COLUMN_DELIMITER', id:'csv_column_delimiter', class:'tw-w-full'}),
                delimitatorString: new CustomFormControl({value: this.configurationManager.getUserPreferences()['file_importer.delimitator_string'] || '', disabled: true}, { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'select', inputChoices: this.stringDelimiters, label:'FILE_IMPORTER.ADD.CSV_STRING_DELIMITER', id:'csv_string_delimiter', class:'tw-w-full'}),
            }, {validators: [FORM_GROUP_VALIDATORS.requiredValidator(['delimitatorColumn', 'delimitatorString'], '||',['separator_type_controller'])]}, {...new CustomFormGroupProps(), class:"tw-flex tw-flex-row tw-w-full"}),
            
            csv_fixed_length: new CustomFormGroup<CustomForm<CSVFixedLength>>({
                delimiters_type_controller: new CustomFormControl(false, {}, {...new CustomFormControlProps(), inputType:'radio', inputChoices: [this.type_separation_choices[1]]}),
                csv_insert_delimiters: new CustomFormControl({value: this.configurationManager.getUserPreferences()['file_importer.separation_fixed'] || '', disabled: true}, {}, {...new CustomFormControlProps(), inputType:'text', label:'FILE_IMPORTER.ADD.CSV_INSERT_DELIMITERS', id:'csv_column_delimiter', class:'tw-w-1/3'}),
            }, {validators: [FORM_GROUP_VALIDATORS.requiredValidator(['csv_insert_delimiters'], '||',['delimiters_type_controller'])]}, {...new CustomFormGroupProps(), class:"tw-w-full"}),
            
        }, {}, {...new CustomFormGroupProps(), class:"tw-w-full"})
    }

    checkExtension = (fileName: string) => {
        if(fileName.includes('.csv')) {
            this.fileConfig.type = 'csv';
            this.showFileImporterAddFormCSV = true;
        } else if (fileName.includes('.xlsx')) {
            this.fileConfig.type = 'xlsx';
        } else if (fileName.includes('.xls')) {
            this.fileConfig.type = 'xls';
        } else {
            this.showFileImporterAddFormCSV = false;
        }

        this.fileImporterFormGroup.controls.file_types.setValue(this.file_types_choices.find(file_type => file_type.value === this.fileConfig.type)?.value)
        this.fileImporterAddFormCSV.controls.csv_separator_type.controls.separator_type_controller.setValue(this.type_separation_choices[0].value);
    };

    checkFileExtension = (event: any) => {
        if(+(event.target.files[0].size / (1024*1024)).toFixed(2) > 10) {
            this.alertDialogService.openDialog({data: {messageLabel:'FILE_IMPORTER.ADD.FILE_TO_BIG'}});
        } else if(event.target.files[0].name) {
            this.fileToUpload = event.target.files[0];
            this.checkExtension(event.target.files[0].name);
            this.fileInput.nativeElement.value = '';
        }
    };

    dataURLToBlob = (dataURL: any): Blob => {
        var BASE64_MARKER = ";base64,";
        var parts, contentType, raw;
        
        if (dataURL!.indexOf(BASE64_MARKER) === -1) {
            parts = dataURL!.split(",");
            contentType = parts[0].split(":")[1];
            raw = decodeURIComponent(parts[1]);

            return new Blob([raw], {type: contentType});
        }

        parts = dataURL!.split(BASE64_MARKER);
        contentType = parts[0].split(":")[1];
        raw = window.atob(parts[1]);
        
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});
    }

    async addFile() {
        return new Promise<ImportingFileInterface>(async (resolve, reject) => {
            if (this.fileToUpload) {
                const mimeType = this.fileToUpload.type;
                let dataToSend = structuredClone(this.fileConfig);
                try {
                    const post_response = await this.restManagerService.post('importing/file', dataToSend)
                    if(post_response.url){
                        if(post_response.sizeMax && (parseInt(post_response.sizeMax) * 1024 < this.fileToUpload.size)) {
                            throw new Error().message = 'FILE_IMPORTER.ADD.FILE_TOO_BIG';
                        } else {
                            let reader = new FileReader();
                            reader.onload = async (e) => {
                                const put_response = await fetch(post_response.url, {
                                    method: "PUT",
                                    headers: {"Content-Type": mimeType},
                                    body: this.dataURLToBlob(e.target?.result),
                                })                        
                                try {
                                    if(put_response.status === 200){
                                        resolve(post_response as ImportingFileInterface);
                                    }
                                } catch (error) {
                                    this.openDialogsService.openSnackBarTilby('FILE_IMPORTER.ADD.FILE_ADD_ERROR', 'MISC.OK', { duration: 3000 })
                                    reject('FILE_IMPORTER.ADD.FILE_ADD_ERROR');
                                }
                            };

                            reader.onerror = () => {
                                reject('FILE_IMPORTER.ADD.FILE_ADD_ERROR');
                            };

                            reader.readAsDataURL(this.fileToUpload);
                        }
                    }
                } catch (e) {
                    this.isProcessing = false;
                    this.alertDialogService.openDialog({data:{messageLabel:'FILE_IMPORTER.ADD.FILE_ADD_ERROR'}});
                }
            } else {
                this.alertDialogService.openDialog({data:{messageLabel:'FILE_IMPORTER.ADD.FILE_MISSING'}});
            }
        })
    }

    async confirm() {
        if(this.fileConfig.typeInsert === 'items' && this.isMagoEnabled()) {
            this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.MAGO_NOT_ENABLED'}});
            return;
        }

        if(this.fileImporterFormGroup.valid) {
            if(this.fileToUpload) {
                this.isProcessing = true;
                
                this.fileConfig.typeInsert = this.fileImporterFormGroup.controls.import_types.value;
                this.fileConfig.decimalSeparator = this.fileImporterFormGroup.controls.decimal_separators.value;
                this.fileConfig.decimalSeparator && this.configurationManager.setUserPreference('file_importer.decimal_separator', this.fileConfig.decimalSeparator);
                this.fileConfig.sheetNumber = 0;

                if(this.fileImporterFormGroup.controls.file_types.value === 'csv') {
                    if((this.separator_type_controller.value && this.delimitator_column_controller.value && this.delimitator_string_controller.value) || (this.delimiters_type_controller.value && this.csv_insert_delimiters_controller.value)) {
                        if(this.csv_separator_type_group.status !== 'DISABLED' && this.csv_separator_type_group.valid){
                            this.fileConfig.delimitatorColumn = this.csv_separator_type_group.controls.delimitatorColumn.value;
                            this.fileConfig.delimitatorString = this.csv_separator_type_group.controls.delimitatorString.value;
                            this.fileConfig.delimitatorColumn && this.configurationManager.setUserPreference('file_importer.delimitator_column', this.fileConfig.delimitatorColumn);
                            this.fileConfig.delimitatorString && this.configurationManager.setUserPreference('file_importer.delimitator_string', this.fileConfig.delimitatorString);

                            this.fileConfig.separationFixed = undefined;
                            this.fileConfig.separationFixed && this.configurationManager.setUserPreference('file_importer.separation_fixed', this.fileConfig.separationFixed);

                        } else if (this.csv_fixed_length_group.status !== 'DISABLED' && this.csv_fixed_length_group.valid) {
                            this.fileConfig.separationFixed = this.csv_fixed_length_group.controls.csv_insert_delimiters.value;
                            this.fileConfig.separationFixed && this.configurationManager.setUserPreference('file_importer.separation_fixed', this.fileConfig.separationFixed);

                            this.fileConfig.delimitatorColumn = undefined;
                            this.fileConfig.delimitatorString = undefined;
                            this.fileConfig.delimitatorColumn && this.configurationManager.setUserPreference('file_importer.delimitator_column', this.fileConfig.delimitatorColumn);
                            this.fileConfig.delimitatorString && this.configurationManager.setUserPreference('file_importer.delimitator_string', this.fileConfig.delimitatorString);
                        }
                    }  else {
                        this.alertDialogService.openDialog({data:{messageLabel:'FILE_IMPORTER.ADD.SEPARATION_TYPE_ERROR'}});
                        this.isProcessing = false;
                        return;
                    }
                } else {
                    if(!this.delimitator_column_controller.value) { 
                        this.fileConfig.delimitatorColumn = "";
                        this.configurationManager.setUserPreference('file_importer.delimitator_column', this.fileConfig.delimitatorColumn);
                    }

                    if(!this.delimitator_string_controller.value) {
                        this.fileConfig.delimitatorString = "";
                        this.configurationManager.setUserPreference('file_importer.delimitator_string', this.fileConfig.delimitatorString);
                    }

                    if(!this.csv_insert_delimiters_controller.value) {
                        this.fileConfig.separationFixed = "";
                        this.configurationManager.setUserPreference('file_importer.separation_fixed', this.fileConfig.separationFixed);
                    }
                }

                const result_addFile = await this.addFile();

                try {
                    if(result_addFile){
                        return this.state.go('app.new.file_importer.fields',{
                            id: result_addFile.id,
                            fileConfig: structuredClone(this.fileConfig)
                        });
                    }
                } catch (error) {
                    if(typeof error === 'object') {
                        this.alertDialogService.openDialog({data:{messageLabel:'FILE_IMPORTER.ADD.FILE_ADD_ERROR'}});
                    }
                } finally {
                    this.isProcessing = false;
                }
            } else {
                this.alertDialogService.openDialog({data:{messageLabel:'FILE_IMPORTER.ADD.FILE_MISSING'}});
                this.isProcessing = false;
            }
        } else {
            this.fileImporterFormGroup.controls.decimal_separators.invalid && this.fileImporterFormGroup.controls.decimal_separators.markAsTouched();
            !this.fileToUpload && this.alertDialogService.openDialog({data:{messageLabel:'FILE_IMPORTER.ADD.FILE_MISSING'}});
        }
    }

    isMagoEnabled = () => {
        const integrations_mago_publish_sales = this.configurationManager.getPreference("integrations.mago.publish_sales" as keyof ConfigurationPreferences);
        const integrations_mago_publish_customers = this.configurationManager.getPreference('integrations.mago.publish_customers' as keyof ConfigurationPreferences);

        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    get importTypes() {return this.fileImporterFormGroup.controls.import_types; }
    get importFromFile() {return this.fileImporterFormGroup.controls.import_from_file; }
    get fileTypes() {return this.fileImporterFormGroup.controls.file_types; }
    get decimalSeparators() {return this.fileImporterFormGroup.controls.decimal_separators; }

    get csv_separator_type_group() {return this.fileImporterAddFormCSV.controls.csv_separator_type}
    get separator_type_controller() {return this.csv_separator_type_group.controls.separator_type_controller }
    get delimitator_column_controller() {return this.csv_separator_type_group.controls.delimitatorColumn }
    get delimitator_string_controller() {return this.csv_separator_type_group.controls.delimitatorString }
    
    get csv_fixed_length_group() {return this.fileImporterAddFormCSV.controls.csv_fixed_length}
    get delimiters_type_controller() {return this.csv_fixed_length_group.controls.delimiters_type_controller }
    get csv_insert_delimiters_controller() {return this.csv_fixed_length_group.controls.csv_insert_delimiters }

    back() {
        this.confirmDialogService.openDialog({
            data: {
                messageLabel: "FILE_IMPORTER.BACK_CONFIRM",
                confirmLabel: "DIALOG.CUSTOMER_DELETE.CONFIRM",
                cancelLabel: "DIALOG.CUSTOMER_DELETE.CANCEL",
            },
        })
        .then((res) => {
            if (res) {
                this.state.go(`app.new.file_importer`);
                this.toolbarEventsContextService.buttons$.next({
                    panelButtons: [],
                    barButtons: [],
                });
                this.toolbarEventsService.buttons$.next({
                    barButtons: [],
                    panelButtons: [],
                });
                this.toolbarEventsContextService.showToolbarContext$.next(false);
                this.toolbarEventsService.showButtonsBar$.next(false);
            }
        });
    }
}
