import { Injectable, inject } from "@angular/core";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { MatDialogRef } from "@angular/material/dialog";
import { DevLogger } from "src/app/shared/dev-logger";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { NonNullableConfigData } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { MagicPortalDialogComponent } from "../magic-portal-dialog";
import { AddBookingsTimeDialogComponent } from "./add-bookings-time-dialog.component";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { Validators } from "@angular/forms";
import { KeyValue } from "@angular/common";
import { AddressType, BookingTimetableType, GeneralFormFields } from "src/app/features/settings/settings-alvolo/model/general-form-fields";
import { SettingsAlvoloStateService } from "src/app/features/settings/settings-alvolo/service/settings-alvolo.state.service";

export type AddBookingsTimeFormValue = {
    name: string;
    date: Date | null;
    week_day: string | null;
    start: string | null;
    end: string | null;
    hourly_fractions: string | number | null;
    type?: string | null,
    label?: [{ lang: string, value: string }],
    capacity?: number | null,
    max_people_per_fraction?: number | null,
    booking_duration?: number | null
};
export type AddBookingsTimeForm = CustomFormGroup<CustomForm<AddBookingsTimeFormValue>>;
export type AddBookingsTimeParams = {title: string, bookingTime?: BookingTimetableType, index?: number};
export type AddBookingsTimeFormDialogData = InputItemDataMagicForm<AddBookingsTimeFormValue>;

@Injectable({
    providedIn: 'root'
})
export class AddBookingsTimeDialogStateService {
    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<AddBookingsTimeDialogComponent>>
    private readonly settingsAlvoloStateService = inject(SettingsAlvoloStateService);    
    private readonly openDialogsService = inject(OpenDialogsService);
    private shop_preference!: GeneralFormFields<AddressType[]>;
    private booking_time_index!: number;
    private _form?: AddBookingsTimeForm;
    private hourly_fractions: string[] = ['SETTINGS.ALVOLO.HOURS.FORM.HOURLY_FRACTIONS_OPTION', '0','5','10','15','20','25','30','35','40','45','50','55','60'];
    private weekdays: string[] = ['SETTINGS.ALVOLO.HOURS.WEEK_DAY.MONDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.TUESDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.WEDNESDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.THURSDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.FRIDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.SATURDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.SUNDAY'];
    private isDisabled: boolean = false;

    private log(...args: any[]) {
        DevLogger.log('ITEM_LABELS_DIALOG_STATE_SERVICE', ...args);
    }

    public async openDialog(config:NonNullableConfigData<AddBookingsTimeParams>) {
        this.isDisabled = false;
        const {title, bookingTime, index} = config.data;
                this.booking_time_index = index !== undefined ? index : -1;
        
        this.log('OPEN_DIALOG',config.data);

        this.createForm();
        this.shop_preference = this.settingsAlvoloStateService.shop_preference!;

        bookingTime && this._form?.patchValue(this.mappingFormValueEdit(bookingTime));

        const configPortal: NonNullablePortalConfigData<AddBookingsTimeFormDialogData, AddBookingsTimeDialogComponent> = {
            data: {
                form: this._form!,
                component: AddBookingsTimeDialogComponent,
                titleLabel: title,
                confirmDisabled: () => this.isDisabled,
                dontCloseDialogOnConfirm$: new BehaviorSubject<boolean>(true),
            }
        };
        this.dialogRef = 
        this.openDialogsService.openPortalDialogRef<AddBookingsTimeDialogComponent, PortalConfigData<AddBookingsTimeFormDialogData, AddBookingsTimeDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '800px' }),
            ...configPortal
        });

        return lastValueFrom(this.dialogRef.afterClosed()).then((res: PortalConfigData<InputItemDataMagicForm<AddBookingsTimeFormValue>, AddBookingsTimeDialogComponent>) => res ? this._form?.valid && this._form.value : null);
    }

    private createForm() {
        const days_of_weeks: KeyValue<string, string>[] = this.weekdays.map((day, i) => ({ key: day, value: i.toString()}));
        const hourly_fractions_choices: KeyValue<string, string>[] = this.hourly_fractions.map((fraction, i) => ({ key: fraction, value: i === 0 ? '' : fraction}));

        this._form = new CustomFormGroup<CustomForm<AddBookingsTimeFormValue>>({
            name: new CustomFormControl('', { validators: [ Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.HOURS.FORM.NAME', id:'input-name', class:'tw-w-full'}),
            week_day: new CustomFormControl(null, {validators: [Validators.min(0), Validators.max(6)]}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.WEEK_DAY', inputChoices: days_of_weeks, customActions: {suffix: {icon: 'close', callback: () => this._form?.controls.week_day?.reset()}}, id:'input-week_day',class:'tw-w-1/3'}),
            date: new CustomFormControl(null, {}, {...new CustomFormControlProps(), inputType:'date', label:'SETTINGS.ALVOLO.HOURS.FORM.DATE', id:'input-date', class:'tw-w-1/3'}) ,
            start: new CustomFormControl(null, {}, {...new CustomFormControlProps(), matErrorClass:'tw-text-red-500' , inputType:'time', label:'SETTINGS.ALVOLO.HOURS.FORM.START', id:'input-start', class:'tw-w-1/3'}) ,
            end: new CustomFormControl(null, {}, {...new CustomFormControlProps(), inputType:'time', label:'SETTINGS.ALVOLO.HOURS.FORM.END', id:'input-end', class:'tw-w-1/3'}) ,
            hourly_fractions: new CustomFormControl(null, {}, {...new CustomFormControlProps(), inputType:'select', inputChoices: hourly_fractions_choices, label:'SETTINGS.ALVOLO.HOURS.FORM.HOURLY_FRACTIONS', id:'input-hourly_fractions', class:'tw-w-1/3'}), 
            capacity: new CustomFormControl(null, {validators: [Validators.required]}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.PEOPLE_CAPACITY', id:'input-capacity', class:'tw-w-1/3'}),
            booking_duration: new CustomFormControl(null, {}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.BOOKING_DURATION', id:'input-booking_duration', class:'tw-w-1/3'}),
            max_people_per_fraction: new CustomFormControl(null, {}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.MAX_PEOPLE_PER_FRACTION', id:'input-max_people_per_fraction', class:'tw-w-1/3'}),
        });
    }

    public async confirm(formValue: Partial<AddBookingsTimeFormValue>) {
        if(this._form?.valid) {
            this.isDisabled = true;
            this.mappingFormValueAPI(formValue);
            this.settingsAlvoloStateService.updateExistingShop(this.shop_preference).then(() => this.dialogRef!.close(this.shop_preference));
        } else {
            this._form?.markAllAsTouched();
        }
    }

    mappingFormValueAPI(formValue: Partial<AddBookingsTimeFormValue>) {
        this.shop_preference.bookings_time?.forEach((booking_time, index) => { (!booking_time.hasOwnProperty('name') || !booking_time.name) && this.shop_preference.bookings_time?.splice(index, 1)});

        const booking_time: BookingTimetableType = {
            name: formValue.name!,
            date: formValue.date ? this.formatDate(formValue.date!) : null,
            week_day: formValue.week_day ? this.mapWeekDays(+formValue.week_day, false)! : '*',
            start: formValue.start ? formValue.start : '00:00',
            end: formValue.end ? formValue.end : '23:59',
            hourly_fractions: formValue.hourly_fractions === null ? null : formValue.hourly_fractions === "" ? "" : +formValue.hourly_fractions!,
            capacity: formValue.capacity ? +formValue.capacity : null,
            max_people_per_fraction: formValue.max_people_per_fraction ? +formValue.max_people_per_fraction : null,
            booking_duration: formValue.booking_duration ? +formValue.booking_duration : null
        }

        if(this.booking_time_index !== -1) {
            this.shop_preference.bookings_time![this.booking_time_index] = booking_time
        } else {
            !this.shop_preference.bookings_time && (this.shop_preference.bookings_time = []);
            this.shop_preference.bookings_time?.push(booking_time);
        }
    }

    mapWeekDays(day: number, isEdit: boolean) {
        let week_day;
        switch (day) {
            //monday
            case 0:
                isEdit ? week_day = "6" : week_day = "1";
                break;
            //tuesday
            case 1:
                isEdit ? week_day = "0" : week_day = "2";
                break;
            //wednesday
            case 2:
                isEdit ? week_day = "1" : week_day = "3";
                break;
            //thursday
            case 3:
                isEdit ? week_day = "2" : week_day = "4";
                break;
            //friday
            case 4:
                isEdit ? week_day = "3" : week_day = "5";
                break;
            //saturday
            case 5:
                isEdit ? week_day = "4" : week_day = "6";
                break;
            //sunday
            case 6:
                isEdit ? week_day = "5" : week_day = "0";
                break;
        }
        return week_day;
    }

    formatDate(dateToFormat: Date){
        const date = new Date(dateToFormat);
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();
        
        if (dd < 10) dd = +'0' + dd;
        if (mm < 10) mm = +'0' + mm;
        
        return dd + '/' + mm + '/' + yyyy;
    }

    mappingFormValueEdit(formValue: Partial<BookingTimetableType>): AddBookingsTimeFormValue{
        const timeSlotMapped: AddBookingsTimeFormValue = {
            name: formValue.name!,
            date: formValue.date! ? new Date(+formValue.date!.split('/')[2], +formValue.date!.split('/')[1]-1, +formValue.date!.split('/')[0]) : null,
            week_day: formValue.week_day! && this.mapWeekDays(+formValue.week_day!, true)!,
            start: formValue.start!,
            end: formValue.end!,
            hourly_fractions: formValue.hourly_fractions === null ? null : formValue.hourly_fractions === "" ? "" : formValue.hourly_fractions!.toString(),
            capacity: formValue.capacity!,
            booking_duration: formValue.booking_duration!,
            max_people_per_fraction: formValue.max_people_per_fraction!,
        }
        return timeSlotMapped;
    }
}