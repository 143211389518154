import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { keyBy, uiLanguages } from '@tilby/tilby-ui-lib/utilities';
import { authRequestDialog, errorsLogger, restManager } from "app/ajs-upgraded-providers";
import {
	AlertDialogService
} from 'src/app/dialogs';
import { ConfigurationManagerService, EntityManagerService, ToolbarEventsService, UiLanguage } from 'src/app/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, TilbyMagicFormComponent } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { countryCodesShort } from 'src/app/core/constants';
import { BillingData, BillingFormFields, Contacts, Payment } from './model/billing-form-fields';
import { ToolbarEventsContextService } from 'src/app/core/services/toolbar-events/toolbar-events-context.service';

type UserData = {
	first_name?: string,
	last_name?: string,
	username?: string,
	email?: string,
	phone?: string,
	pin?: string | null,
	thumbnail?: string,
	uiLanguage?: UiLanguage
}

const tabCount = 2;

@Component({
	selector: 'app-settings-my-subscription',
	templateUrl: './settings-my-subscription.component.html',
	styleUrls: ['./settings-my-subscription.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule, MatIconModule, MatCardModule, MatTabsModule, TilbyMagicFormComponent, TilbyCurrencyPipe, TilbyGesturesDirective],
})
export class SettingsMySubscriptionComponent implements OnInit {
	public readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
	private readonly alertDialogService = inject(AlertDialogService);
	private readonly configurationManager = inject(ConfigurationManagerService);
	private readonly toolbarEventsService = inject(ToolbarEventsService);
	private readonly entityManagerService = inject(EntityManagerService);
	private readonly authRequestDialogService = inject(authRequestDialog);
	private readonly translateService = inject(TranslateService);
	private readonly errorsLoggerService = inject(errorsLogger);
	private readonly restManagerService = inject(restManager);
	@Input() user: any = this.restManagerService.user;
	showForm: boolean = false;
	showError: boolean = false;
	forms = {};
	userFirstName!: string;
	thumbnail!: string | null;
	paymentOptions = [
		{ value: "sdd", name: this.translateService.instant('SETTINGS.MY_SUBSCRIPTION.SSD') },
		{ value: "manual", name: this.translateService.instant('SETTINGS.MY_SUBSCRIPTION.MANUAL') },
		{ value: "creditcard_token", name: this.translateService.instant('SETTINGS.MY_SUBSCRIPTION.CREDITCARD_TOKEN') }
	];
	billingData: any = null;
	remoteBillingData: any = null;
	countryCodes: { code: number; name: any; }[] = [];
	countryCodesDict!: Record<string, { code: number; name: any; }>;
	selectedTabIndex: number = 0;
	tabIndexCopy: number = 0;
	billingForm!: CustomFormGroup<CustomForm<BillingFormFields>>;
	tabPanel?: string;
	countries = countryCodesShort.map(({ code, name }) => ({ key: code, value: name }));


	constructor() {
		this.toolbarEventsService.moduleTitle.next("MY_SUBSCRIPTION");

		const generalUiLanguage = this.configurationManager.getPreference('general.ui_language') || 'it';
		const lang = Object.values(uiLanguages).filter((uiLang: UiLanguage) => uiLang.id === generalUiLanguage);

		this.getUserData().then((userData: UserData) => {
			this.user = userData;
			if (!this.user.phone) {
				this.user.phone = ' ';
			}
			this.user.uiLanguage = lang[0];
			this.userFirstName = userData.first_name || "";
			this.thumbnail = userData.thumbnail || null;

			this.getUserSession().then(userSession => {
				this.user.username = userSession.username;
				this.user.thumbnail = userSession.thumbnail;
			});
		});

		this.countryCodes = this.countryCodes.map((val, key) => ({ code: key, name: val.name }));
		this.countryCodesDict = keyBy(this.countryCodes, (country) => country.code);

		this.reloadRemoteData();
	}

	ngOnInit(): void {
		this.toolbarEventsService.showButtonsBar$.next(false);
	}

	async getUserData() {
		try {
			const userData = await this.restManagerService.getOne('sessions/current_user');
			if (userData) return userData;
		} catch (err) {
			this.errorsLoggerService.err(err);
		}
	}

	async getUserSession() {
		try {
			const userSession = await this.entityManagerService.userSessions.getActiveSession();
			if (userSession) return userSession;
		} catch (err) {
			this.errorsLoggerService.err(err);
		}
	}

	reloadRemoteData = () => {
		this.restManagerService.getOne('billing_data').then((billingData: any) => {
			this.loadData(billingData);
		})
			.catch((error: any) => {
				if (error.data && error.data.error && error.data.error.message && error.data.error.message.owner_data) {
					this.billingData = error.data.error.message.owner_data;
				}

				this.showForm = false;
				this.showError = true;
			});
	};

	loadData = (billingData: any) => {
		this.billingData = billingData
		this.remoteBillingData = billingData;
		this.showForm = true;
		this.showError = false;
		let country = this.billingData.address_country;
		this.billingData._addressCountry = country['countryCodesDict'] || undefined;
		let remoteCountry = this.remoteBillingData.address_country;
		this.remoteBillingData._addressCountry = remoteCountry['countryCodesDict'] || undefined;
		this.billingForm = this.createFormGroup();
	};

	createFormGroup = () => {
		return new CustomFormGroup<CustomForm<BillingFormFields>>({
			company_name: new CustomFormControl(
				this.billingData.company_name || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.NAME',
					id: 'input-company_name',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			owner_first_name: new CustomFormControl(
				this.billingData.owner_first_name || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.OWNER_FIRST_NAME',
					id: 'input-first_name',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			owner_last_name: new CustomFormControl(
				this.billingData.owner_last_name || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.OWNER_LAST_NAME',
					id: 'input-last_name',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			tax_code: new CustomFormControl(
				this.billingData.tax_code || '',
				{},
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.TAX_CODE',
					id: 'input-tax_code',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			vat_code: new CustomFormControl(
				this.billingData.vat_code || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.VAT_CODE',
					id: 'input-vat_code',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			address_street: new CustomFormControl(
				this.billingData.address_street || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.ADDRESS_FULL',
					id: 'input-address_full',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			address_zip: new CustomFormControl(
				this.billingData.address_zip || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.ADDRESS_ZIP',
					id: 'input-address_zip',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			address_city: new CustomFormControl(
				this.billingData.address_city || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.ADDRESS_CITY',
					id: 'input-address_city',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			address_prov: new CustomFormControl(
				this.billingData.address_prov || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'text',
					label: 'SETTINGS.MY_SUBSCRIPTION.ADDRESS_COUNTY',
					id: 'input-address_county',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			address_country: new CustomFormControl(
				this.countries.find((country) => country.key === this.billingData.address_country)
					?.value || '',
				{ validators: [Validators.required] },
				{
					...new CustomFormControlProps(),
					inputType: 'autocomplete',
					inputChoices: this.countries,
					label: 'SETTINGS.MY_SUBSCRIPTION.ADDRESS_COUNTRY',
					id: 'input-billing_country',
					class: 'tw-w-1/3 tw-pb-2',
				}
			),
			contacts: new CustomFormGroup<CustomForm<Contacts>>({
				email_address: new CustomFormControl(
					this.billingData.email_address || '',
					{ validators: [Validators.required, Validators.email] },
					{
						...new CustomFormControlProps(),
						inputType: 'email',
						label: 'SETTINGS.MY_SUBSCRIPTION.EMAIL',
						id: 'input-email',
						class: 'tw-w-1/3 tw-pb-2',
					}
				),
				phone_number: new CustomFormControl(
					this.billingData.phone_number || '',
					{
						validators: [
							Validators.required,
							Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"),
						],
					},
					{
						...new CustomFormControlProps(),
						inputType: 'text',
						label: 'SETTINGS.MY_SUBSCRIPTION.PHONE',
						id: 'input-phone',
						class: 'tw-w-1/3 tw-pb-2',
					}
				),
				email_pec: new CustomFormControl(
					this.billingData.email_pec || '',
					{ validators: [Validators.required, Validators.email] },
					{
						...new CustomFormControlProps(),
						inputType: 'email',
						label: 'SETTINGS.MY_SUBSCRIPTION.EMAIL_PEC',
						id: 'input-email_pec',
						class: 'tw-w-1/3 tw-pb-2',
					}
				),
				sdi_code: new CustomFormControl(
					this.billingData.sdi_code || '',
					{ validators: [Validators.minLength(6), Validators.maxLength(7)] },
					{
						...new CustomFormControlProps(),
						inputType: 'text',
						label: 'SETTINGS.MY_SUBSCRIPTION.SDI_CODE',
						id: 'input-sdi_code',
						class: 'tw-w-1/3 tw-pb-2',
					}
				),
			}, {}, {
				...new CustomFormControlProps(),
				label: 'SETTINGS.MY_SUBSCRIPTION.CONTACTS',
				classLabel: 'tw-py-3 tw-text-[20px]',
			}),
			payment: new CustomFormGroup<CustomForm<Payment>>({
				default_payment_method: new CustomFormControl(
					this.billingData.default_payment_method || '',
					{ validators: [Validators.required] },
					{
						...new CustomFormControlProps(),
						readonly: true,
						inputType: 'select',
						inputChoices: this.paymentOptions.map(
							({ value, name }) => ({ key: name, value: value })
						),
						label: 'SETTINGS.MY_SUBSCRIPTION.PAYMENT_OPTION',
						id: 'input-payment_option',
						class: 'tw-w-1/3 tw-pb-2',
					}
				),
				iban_code: new CustomFormControl(
					this.billingData.iban_code || '',
					{ validators: [Validators.required] },
					{
						...new CustomFormControlProps(),
						inputType: 'text',
						label: 'SETTINGS.MY_SUBSCRIPTION.IBAN',
						id: 'input-iban',
						class: 'tw-w-1/3 tw-pb-2',
					}
				),
				bic_code: new CustomFormControl(
					this.billingData.bic_code || '',
					{},
					{
						...new CustomFormControlProps(),
						inputType: 'text',
						label: 'SETTINGS.MY_SUBSCRIPTION.BIC_CODE',
						id: 'input-bic_code',
						class: 'tw-w-1/3 tw-pb-2',
					}
				),
			}, {}, {
				...new CustomFormControlProps(),
				label: 'SETTINGS.MY_SUBSCRIPTION.BILLING',
				classLabel: 'tw-py-3 tw-text-[20px]',
			}),
		})
	}

	changeTab(event: MatTabChangeEvent) {
		if (event.index === 1) {
			const originalForm = this.createFormGroup();
			Object.keys(this.billingForm.controls).forEach((key) => {
				this.billingForm.get(key)?.setValue(originalForm.get(key)?.value);
			});

			this.billingForm.markAsPristine();
			this.billingForm.markAsUntouched();
			this.billingForm.updateValueAndValidity();

			this.toolbarEventsService.showButtonsBar$.next(true);
			this.toolbarEventsService.buttons$.next({
				panelButtons: [],
				barButtons: [
					{
						isIt: () => true,
						name: 'save',
						icon: () => 'check',
						click: this.savePreferences
					}
				]
			});
		} else {
			this.toolbarEventsService.showButtonsBar$.next(false);
		}
	}

	mapFormValue(): BillingData {
		return {
			reseller_scloby_id: this.billingData.reseller_scloby_id || null,
			company_name: this.billingFormControls.company_name.value || null,
			vat_code: this.billingFormControls.vat_code.value || null,
			tax_code: this.billingFormControls.tax_code.value || null,
			sdi_code: this.billingFormControlsContacts.controls.sdi_code.value || null,
			email_address: this.billingFormControlsContacts.controls.email_address.value || null,
			email_pec: this.billingFormControlsContacts.controls.email_pec.value || null,
			phone_number: this.billingFormControlsContacts.controls.phone_number.value || null,
			owner_first_name: this.billingFormControls.owner_first_name.value || null,
			owner_last_name: this.billingFormControls.owner_last_name.value || null,
			owner_scloby_id: this.billingData.owner_scloby_id,
			address_street: this.billingFormControls.address_street.value || null,
			address_number: this.billingData.address_number || null,
			address_zip: this.billingFormControls.address_zip.value || null,
			address_city: this.billingFormControls.address_city.value || "",
			address_prov: this.billingFormControls.address_prov.value || null,
			address_country: this.countries.find(country => country.value === this.billingFormControls.address_country?.value)?.key || undefined,
			iban_code: this.billingFormControlsPayment.controls.iban_code.value || null,
			bic_code: this.billingFormControlsPayment.controls.bic_code.value || null,
			payment_token: this.billingData.payment_token,
			adhoc_customer: this.billingData.adhoc_customer,
			default_payment_method: this.billingFormControlsPayment.controls.default_payment_method.value || null,
			billing_frequency: this.billingData.billing_frequency,
			billing_per_environment: this.billingData.billing_per_environment,
			tax_free: this.billingData.tax_free,
			charge_authorization: this.billingData.charge_authorization || null,
			last_contract_accepted: this.billingData.last_contract_accepted || null,
			update_required: this.billingData.update_required,
			show_pricelists: this.billingData.show_pricelists,
			notes: this.billingData.notes || null,
			balance: this.billingData.balance || null,
		}
	}

	savePreferences = () => {
		if (this.billingForm.valid) {
			this.authRequestDialogService.show({ billingData: this.billingData })
				.then((result: any) => {
					if (result) {
						let data = this.mapFormValue();
						this.restManagerService.put('billing_data', null, data).then(() => {
							this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.MY_SUBSCRIPTION.SAVED' } });
							this.reloadRemoteData();
						}, (error: any) => {
							this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.MY_SUBSCRIPTION.SAVE_ERROR' } });
						});
					}
				}).catch((error: any) => { });
		} else {
			this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.MY_SUBSCRIPTION.INVALID_FORM' } });
		}
	};

	swipeRight() {
		(this.selectedTabIndex) && (this.selectedTabIndex -= 1);
	}

	swipeLeft() {
		(this.selectedTabIndex < (tabCount - 1)) && (this.selectedTabIndex += 1);
	}

	get billingFormControls() {
		return this.billingForm.controls;
	}

	get billingFormControlsContacts() {
		return this.billingForm.controls.contacts;
	}

	get billingFormControlsPayment() {
		return this.billingForm.controls.payment;
	}
}