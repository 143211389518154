import * as angular from 'angular';
import * as async from 'async';
import * as _ from 'lodash';

function kioskCatalogCtrl($scope, $state, $translate, $filter, kioskSettings, kioskUtils, items, stockDictionary, entityManager, alertDialog, categories, barcodeManager, showAllergensDialog, migrationHelper) {
    const ActiveSale = migrationHelper.getActiveSale();

    var itemBorderSize = _.toInteger(kioskSettings['catalog.item_border_size'] || 0);
    var itemMarginSize = _.toInteger(kioskSettings['catalog.item_margin_size'] || 0);
    var itemBorderColor = kioskSettings['catalog.item_border_color'] || '#666666';
    var baseTextColor = kioskSettings['general.base_text_color'] || '#000';
    var activeTextColor = kioskSettings['general.active_text_color'] || '#fff';
    var activeTextBgColor = kioskSettings['general.active_text_bgcolor'] || '#b2d0dc';
    var zoomLevel = kioskSettings['general.zoom_level'] || 1.5;
    var isImageCover = _.isBoolean(kioskSettings['catalog.item_image_cover']) ? kioskSettings['catalog.item_image_cover'] : false;
    var defaultViewMode = kioskSettings['catalog.default_view_mode'] || 'gridView';
    var itemImagePlaceholder = kioskSettings['catalog.item_image_placeholder'] || '';
    var gridTextAlignment = kioskSettings['catalog.grid_text_alignment'];

    Object.assign($scope, {
        headerParams: {
            bannerUrl: kioskSettings['catalog.banner_url'],
            bannerBgColor: kioskSettings['general.header_background_color'],
            bannerHeight: kioskSettings['general.header_height'],
            logoUrl: _.toString(kioskSettings['general.media_url_logo']),
            zoomLevel: zoomLevel,
            customerAssistantEnabled: kioskSettings['general.customer_assistant'],
            title: kioskUtils.getLabelText(kioskSettings, 'catalog.title_label', 'KIOSK.CATALOG.TITLE')
        },
        activeTextColor: activeTextColor,
        activeTextBgColor: activeTextBgColor,
        categorySelectedStyle: {
            'color': activeTextColor,
            'background-color': activeTextBgColor
        },
        catalogStyle: { 'zoom': zoomLevel },
        gridTextStyle: { 'text-align': gridTextAlignment },
        rowSpan: zoomLevel > 1.6 ? 2 : 1,
        barcodeManager: barcodeManager,
        disableUnavailable: !(kioskSettings['catalog.sell_out_of_stock'] || false),
        enableCategories: _.isBoolean(kioskSettings['catalog.enable_categories']) ? kioskSettings['catalog.enable_categories'] : true,
        gridBorderStyle: { 'border': itemBorderSize ? itemBorderSize + 'px solid ' + itemBorderColor : 'none' },
        leftBorderStyle: { 'border-left': itemBorderSize ? itemBorderSize + 'px solid ' + itemBorderColor : 'none' },
        gridMarginStyle: itemMarginSize ? itemMarginSize + 'px' : '0px',
        itemsPerRowDefaul: kioskSettings['catalog.items_per_row'] || 3,
        itemTileSize: kioskSettings['catalog.item_tile_size'] || "1:1",
        backButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.back_button_label', 'KIOSK.BACK'),
        nextButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.next_button_label', 'KIOSK.NEXT'),
        totalLabel: kioskUtils.getLabelText(kioskSettings, 'cart.total_label', 'KIOSK.TOTAL'),
        orderBy:  kioskSettings['catalog.grid_order'] || '+price',
        sale: ActiveSale,
        showItemImage: _.isBoolean(kioskSettings['catalog.show_item_image']) ? kioskSettings['catalog.show_item_image'] : true,
        showPrices: _.isBoolean(kioskSettings['catalog.show_prices']) ? kioskSettings['catalog.show_prices'] : true,
        showItemName: _.isBoolean(kioskSettings['catalog.show_names']) ? kioskSettings['catalog.show_names'] : true,
        showItemDescription: _.isBoolean(kioskSettings['catalog.show_description']) ? kioskSettings['catalog.show_description'] : true,
        showViewMode: _.isBoolean(kioskSettings['catalog.show_view_mode']) ? kioskSettings['catalog.show_view_mode'] : true,
        showUnavailable: _.isBoolean(kioskSettings['catalog.show_out_of_stock']) ? kioskSettings['catalog.show_out_of_stock'] : true,
        backgroundUrl: kioskSettings['catalog.background_url']
    });

    if($scope.backgroundUrl) {
        $scope.mediaType = kioskUtils.getMediaType($scope.backgroundUrl);
    }
    if( _.isNil($scope.mediaType) ) {
        // important
        $scope.catalogStyle.height = '100%';
    }

    $scope.itemsPerRow = defaultViewMode === 'listView' ? 1 : $scope.itemsPerRowDefaul;
    $scope.viewMode = defaultViewMode;
    $scope.listViewSelected = {'color': $scope.viewMode === 'listView'? activeTextBgColor : baseTextColor};
    $scope.gridViewSelected = {'color': $scope.viewMode === 'gridView' ? activeTextBgColor : baseTextColor};
    $scope.changeViewMode = function(viewMode) {
        switch (viewMode) {
            case 'listView':
                $scope.itemsPerRow = 1;
                $scope.listViewSelected = { 'color': activeTextBgColor };
                $scope.gridViewSelected = { 'color': baseTextColor };
                break;
            case 'gridView':
                $scope.itemsPerRow = $scope.itemsPerRowDefaul;
                $scope.listViewSelected = { 'color': baseTextColor};
                $scope.gridViewSelected = { 'color': activeTextBgColor };
                break;
            default: $scope.itemsPerRow = $scope.itemsPerRowDefaul;
        }
        $scope.viewMode = viewMode;
    };

    //Setup pricelist
    var priceList = _.toInteger(kioskSettings['catalog.default_pricelist']) || 1;
    var priceListStr = 'price' + priceList;

    var filterVisibleItems = function(items) {
        var orderBy = {
            field: _.startsWith($scope.orderBy, 'price', 1) ? 'price' + priceList : _.trim($scope.orderBy, '+-'),
            order: $scope.orderBy[0] === '-' ? "desc" : "asc"
        };

        return _(items).filter(function(item) {
            var stockCheck = ($scope.showUnavailable ? true : ($scope.getItemStockStatus(item) !== 'stock-info-unavailable'));

            return stockCheck && _.isFinite(item[priceListStr]);
        }).orderBy([orderBy.field], [orderBy.order]).value();
    };

    $scope.isItemDisabled = function(item) {
        var canBePurchased = true;

        if($scope.disableUnavailable) {
            if (item.stock_type === 'simple') {
                var itemStock = stockDictionary.get('item_id_' + item.id);

                if (itemStock) {
                    if (itemStock.stock_quantity > 0) {
                        var itemCartQuantity = _(ActiveSale.currentSale.sale_items).filter({ item_id: item.id }).sumBy('quantity');

                        canBePurchased = (itemStock.stock_quantity > itemCartQuantity);
                    } else {
                        canBePurchased = false;
                    }
                }
            }
        }

        return !canBePurchased;
    };

    $scope.getItemStockStatus = function(item) {
        var itemStock;

        if (!_.isEmpty(item.stock_type) && (itemStock = stockDictionary.get('item_id_' + item.id))) {
            switch (itemStock.available) {
                case 'available':
                    return 'stock-info-available';
                case 'unavailable':
                    return 'stock-info-unavailable';
                case 'alert':
                    return 'stock-info-alert';
                default:
                    return 'stock-info-nostock';
            }
        } else {
            return 'stock-info-nostock';
        }
    };

    $scope.selectCategory = function(category) {
        $scope.selectedCategory = category;
        $scope.visibleItems = filterVisibleItems(items[category.id]);
    };

    $scope.getItemPrice = function(item) {
        return $filter('sclCurrency')(item[priceListStr]);
    };

    $scope.calculateitemTileSize = function() {
        return ($scope.itemsPerRow === 1) ? '125px' : $scope.itemTileSize;
    };

    $scope.addToCart = async (item, combinationId, quantity, barcode) => {
        if ($scope.isItemDisabled(item)) {
            return;
        }

        if (!combinationId && !_.isEmpty(item.combinations)) {
            combinationId = _.head(item.combinations).id;
        }

        await ActiveSale.addItemToSale(item, priceList, combinationId, quantity, barcode);

        if(kioskSettings['catalog.single_product_checkout']) {
            $state.go(kioskUtils.getNextModule());
        }
    };

    $scope.hasItemsInCart = function() {
        return !ActiveSale.isEmpty();
    };

    $scope.next = function() {
        $scope.$parent.enableTimeouts();
        $state.go(kioskUtils.getNextModule());
    };

    $scope.back = function() {
        $scope.$parent.enableTimeouts();
        $state.go(kioskUtils.getPreviousModule());
    };

    $scope.getBackGroundUrl = function(showItemImage, url) {
        var styleObj = {};
        if (showItemImage) {
            styleObj['background-image'] = url ? 'url('+ url + ')' : 'url(' + itemImagePlaceholder + ')';
        }
        if (isImageCover && $scope.itemsPerRow > 1) {
            styleObj['background-size'] = 'cover';
            styleObj['background-position'] = '50% 10%';
        }
        return styleObj;
    };

    $scope.getQuantityItemInSale = function(item) {
        var quantityItem = 0,
        saleItems = _.filter($scope.sale.currentSale.sale_items, { 'item_id': item.id, 'name': item.name });
        _.forEach(saleItems, function(saleItem) { quantityItem += saleItem.quantity ? saleItem.quantity : 0; });
        return quantityItem;
    };

    $scope.decreaseQuantity = function(item) {
        var saleItem = _.find($scope.sale.currentSale.sale_items, ['item_id', item.id]);

        if(_.isInteger(saleItem.quantity) && saleItem.quantity > 1) {
            ActiveSale.editSaleItem(saleItem, { quantity: saleItem.quantity - 1 });
        } else {
            ActiveSale.removeSaleItem(saleItem);
        }
    };

    $scope.visibleCategories = _.chain(categories)
        .sortBy('index')
        .filter({ display_kiosk: true })
        .filter((category) => _.chain(items[category.id]).size().inRange(1, 101).value())
    .value();

    //CONTROLLER INIT
    if($scope.enableCategories) {
        $scope.selectedCategory = _.head($scope.visibleCategories);
        $scope.visibleItems = filterVisibleItems(items[$scope.selectedCategory.id]);
    } else {
        $scope.visibleItems = [];

        for(let category of $scope.visibleCategories) {
            $scope.visibleItems.push(...filterVisibleItems(items[category.id]));
        }
    }

    if(kioskUtils.compareStatesOrder() > 0) {
        ActiveSale.newSale({ dontSaveSale: true }).then((sale) => {
            const saleType = kioskUtils.getKioskSaleData()?.type;

            if(saleType) {
                ActiveSale.updateSaleDetails({ name: `${sale.name} (${$translate.instant(`KIOSK.SERVICE_SELECTION.${saleType.toUpperCase()}`)})`.slice(0, 30) });
            }
        });
    }
    //CONTROLLER INIT END

    $scope.$on("barcodeManager:Item", function(event, eventData) {
        const foundData = eventData.itemData;
        const foundArray = foundData.data;

        if (foundArray.length) {
            async.waterfall([function(next) {
                async.eachSeries(foundArray, function(found, itemDone) {
                    if (found.quantity && !_.isFinite(found.quantity)) { //Avoid insert of items
                        alertDialog.show($translate.instant('KIOSK.CATALOG.BARCODE_NOT_FOUND')).finally(itemDone);
                    } else {
                        entityManager.items.fetchOneOffline(found.item_id).then(function(item) {
                            if(!_.isNil(found.price)) {
                                item[priceListStr] = found.price;
                            }

                            if (_.isFinite(item[priceListStr])) {
                                $scope.addToCart(item, found.combination_id, found.quantity, eventData.barcodeInput);
                            } else {
                                return alertDialog.show($translate.instant('KIOSK.CATALOG.BARCODE_NOT_FOUND'));
                            }
                        }).finally(itemDone);
                    }
                }, function(err) {
                    next();
                });
            }, function(next) {
                if(foundArray.length !== foundData.total) {
                    alertDialog.show($translate.instant('KIOSK.CATALOG.BARCODE_NOT_FOUND')).finally(next);
                } else {
                    next();
                }
            }]);
        } else {
            alertDialog.show($translate.instant('KIOSK.CATALOG.BARCODE_NOT_FOUND'));
        }
    });

    $scope.$on("activeSale:use-pricelist", function(event, data) {
        var newPriceList;

        if(data.priceList === 'default') {
            newPriceList = kioskSettings['catalog.default_pricelist'] || 1;
        } else {
            newPriceList = _.toInteger(data.priceList);
        }

        if(newPriceList !== priceList) {
            priceList = newPriceList;
            priceListStr = 'price' + priceList;
            ActiveSale.applyPriceList(priceList);
        }
    });

    $scope.showAllergens = function(event, item) {
        event.stopPropagation();

        var params = {
            name: item.name,
            allergens: item.allergens
        };
        showAllergensDialog.show(params);
    };

    $scope.infoIconStyle = { color: baseTextColor };
}

kioskCatalogCtrl.$inject = ["$scope", "$state", "$translate", "$filter", "kioskSettings", "kioskUtils", "items", "stockDictionary", "entityManager", "alertDialog", "categories", "barcodeManager", "showAllergensDialog", "migrationHelper"];

angular.module('kiosk').controller('KioskCatalogCtrl', kioskCatalogCtrl); 
