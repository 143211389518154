<div class="tw-flex tw-w-full tw-mb-1">
    <app-tilby-keypad-display [value]="form.value.cashValue" class="tw-flex tw-w-full"/>
    @if(!isMobilePortrait()) {
        <tilby-general-input-button class="sale-keypad-button tw-bg-transparent tw-truncate" [btn]="sideKeyboardButton"/>
    }
</div>
<div class="tw-grid tw-grid-rows-3 tw-grid-cols-4 tw-grid-flow-col tw-gap-1">
    @for(btn of buttons; track $index){
        @if(btn.isIt()){
            <tilby-general-input-button
                    class="sale-keypad-button tw-w-full tw-truncate"
                    [class.tw-opacity-25]="btn.isDisable?.()"
                    [class.tw-pointer-events-none]="btn.isDisable?.()"
                    [class.tw-bg-green-500]="btn.selected?.()"
                    [btn]="btn"/>
        }
    }
</div>
