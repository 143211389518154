<div class="barcode-reader">
    @if(isAppleMobile) {
        @if(!hideButton) {
            <button mat-icon-button (click)="focusBarcodeInput()">
                <mat-icon fontSet="material-symbols-outlined">barcode</mat-icon>
            </button>
        }
        <input type="text" class="barcode-input" [(ngModel)]="barcodeInput" placeholder="barcodeInput" id={{idSelector}} (keyup.enter)="barcodeSubmitted()"/>
    }
</div>
