import {
    Component,
    inject,
    Injectable,
    OnDestroy,
    OnInit
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';

import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
    BaseDialogService,
    NonNullableConfigData
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
    lastValueFrom,
    Subject
} from 'rxjs';

type BaseAction = {
    label: string;
    disabled?: boolean;
}

type DataWaitDialog = WaitDialogState & {
    stateSubject?: Subject<Partial<WaitDialogState>>;
    timeout?: {
        target: any;
        // process;
    };
    promise?: Promise<any>;
}

export type WaitDialogState = {
    message: string;
    secondaryMessage?: string;
    cancelAction?: BaseAction;
    customActions?: (BaseAction & { callback(): any })[];
}

@Component({
    imports: [
        TranslateModule,
        MatProgressBarModule
    ],
    selector: 'app-wait-dialog',
    standalone: true,
    templateUrl: './wait-dialog.component.html',
    styleUrls: ['./wait-dialog.component.scss'],
    host: { class: 'tw-min-w-[40vw] tw-flex tw-justify-center tw-items-center tw-h-full tw-flex-col tw-px-6 tw-py-6' }
})
class WaitDialogComponent implements OnInit, OnDestroy {
    public data: DataWaitDialog = { ...inject(MAT_DIALOG_DATA) };
    private readonly dialogRef = inject(MatDialogRef);

    timeoutProgress = 0;
    timeoutTarget = 0;
    intervalId?: NodeJS.Timer;

    constructor(
    ) {
    }

    ngOnInit() {
        if (this.data.promise) {
            this.data.promise.then((result) => {
                this.dialogRef.close(result || true);
            }, (error) => {
                this.dialogRef.close({ error });
            });
        }

        if (this.data.timeout) {
            this.timeoutTarget = (this.data.timeout.target) * 1000;

            const progressUpdate = () => {
                this.timeoutProgress += 100;

                if (this.timeoutProgress >= this.timeoutTarget) {
                    clearInterval(this.intervalId);
                    this.dialogRef.close(true);
                }
            };

            this.intervalId = setInterval(progressUpdate, 100);
        }

        if (this.data.stateSubject) {
            this.data.stateSubject.subscribe((state) => {
                this.updateState(state);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        if (this.data.stateSubject) {
            this.data.stateSubject.complete();
        }
    }

    updateState(state: Partial<DataWaitDialog>) {
        this.data = {
            ...this.data,
            ...state 
        };

        if(state.cancelAction) {
            delete this.data.customActions;
        }

        if(state.customActions) {
            delete this.data.cancelAction;
        }
    };
}

@Injectable({
    providedIn: 'root'
})
export class WaitDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(options: NonNullableConfigData<DataWaitDialog>) {
        const config: NonNullableConfigData<DataWaitDialog> = {
            ...this.switchMobileDesktopDimensions({ width: '800px' }),
            disableClose: true,
            data: options.data
        };

        return lastValueFrom(this.dialogRef.open(WaitDialogComponent, config).afterClosed()).then((res) => res || { error: 'CANCELED' });
    }
}
