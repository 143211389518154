import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { OpenDialogsService } from "../../services";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Permissions } from "../models";

@Component({
    selector: 'app-add-new-static-token-dialog.component',
    templateUrl: './add-new-static-token-dialog.component.html',
    styleUrls: ['./add-new-static-token-dialog.component.scss']
})
export class AddNewStaticTokenDialogComponent implements OnInit {

    addNewStaticTokenDialogForm!: FormGroup;

    permissions : Permissions[ ]= [
        { value: 'SclStaticRO', title: '', description: '', translationKey: 'SCL_STATIC_RO' },
        { value: 'SclStaticRW', title: '', description: '', translationKey: 'SCL_STATIC_RW' },
        { value: 'SclStaticECom', title: '', description: '', translationKey: 'SCL_STATIC_ECOM' },
        { value: 'SclStaticBookings', title: '', description: '', translationKey: 'SCL_STATIC_BOOKINGS' },
        { value: 'SclStaticStock', title: '', description: '', translationKey: 'SCL_STATIC_STOCK' },
        { value: 'SclStaticCustomers', title: '', description: '', translationKey: 'SCL_STATIC_CUSTOMERS' },
        { value: 'SclStaticStockCustomers', title: '', description: '', translationKey: 'SCL_STATIC_STOCK_CUSTOMERS' },
        { value: 'TheFork', title: '', description: '', translationKey: 'SCL_STATIC_THE_FORK' },
        { value: 'Mago', title: '', description: '', translationKey: 'SCL_STATIC_MAGO' },
        { value: 'Deliverect', title: '', description: '', translationKey: 'SCL_STATIC_DELIVERECT' }
    ];

    constructor(public dialogRef: MatDialogRef<OpenDialogsService>, private formBuilder: FormBuilder, private translate: TranslateService) {
        this.populateTranslations();
    }

    private populateTranslations() {
        this.permissions.forEach(permission => {
            permission.title = this.translate.instant(`SETTINGS.USERS_MANAGEMENT.PERMISSIONS.${permission.translationKey}.TITLE`);
            permission.description = this.translate.instant(`SETTINGS.USERS_MANAGEMENT.PERMISSIONS.${permission.translationKey}.DESCRIPTION`);
        });
    }

    ngOnInit(): void {
        this.addNewStaticTokenDialogForm = this.formBuilder.group({
            description: ['', Validators.required],
            client_id: ['', Validators.required]
        });
    }

    submit() {
        if (this.addNewStaticTokenDialogForm.valid) {
            this.dialogRef.close(this.addNewStaticTokenDialogForm.value)
        } else {
             this.addNewStaticTokenDialogForm.markAllAsTouched();
        }
    }

    isFormInvalid(): boolean {
        return this.addNewStaticTokenDialogForm.invalid;
    }
}
