import {Component, EventEmitter, OnInit, Output, inject, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {NavListComponent} from "../nav-list";
import {ToolbarUtenteComponent} from "../toolbar";
import {
    ConfigurationManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';
import {User} from "src/app/models";
import {deviceInfoDialog} from "app/ajs-upgraded-providers";
import { EnvironmentConfig } from 'src/environments/environment-config';
import {DualService} from "src/app/dual.service";

const { tilbyVersion } = require('app/tilby.properties.json');

export type Submenus={
    marketing: boolean,
    history: boolean,
    settings: boolean,
    stock: boolean
}

@Component({
  selector: 'app-sidenav-menu',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDividerModule, MatListModule, NavListComponent, ToolbarUtenteComponent],
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
    host: {class:'tw-h-full tw-flex tw-flex-col'}
})
export class SidenavMenuComponent implements OnInit{
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly deviceInfoDialogService = inject(deviceInfoDialog);
    private readonly dualService = inject(DualService);

    protected tilbyVersion = tilbyVersion;
    protected user: User = this.userActiveSessionManagerService.getSession()!;
    protected operatorName:string='';
    protected shopName = '';
    protected country = this.configurationManagerService.getShopCountry();

    protected currentRoute$ = this.dualService.currentRoute$;

    @ViewChild('navList') navList?: NavListComponent;

    protected submenus:Submenus = this.getSubMenusClosed();

    @Output() protected closeMenuSidenav = new EventEmitter<void>();
    @Output() protected goTo = new EventEmitter<string>();

    async ngOnInit() {
        this.shopName = this.getShopName();
        this.operatorName = this.getOperatorName();
        this.tilbyVersion += this.getTilbyVersion();
    }

    private getShopName(){
        return this.configurationManagerService.getPreference('general.shopname') || this.user.shop!.name!;
    }

    private getOperatorName(){
        return (this.user?.first_name && this.user?.last_name)
            ? this.user.first_name + ' ' + this.user.last_name
            : this.user.username;
    }

    private getTilbyVersion(){
        switch (EnvironmentConfig.clientId) {
            case 'Scloby3PG':
                return 'm';
            case 'SclobyDevLocal':
                return 'dev';
            case 'SclobyApp3':
                return 'w';
            case 'SclobyApp3Sec':
                return 'ws';
            case 'SclobyElectron3':
                return 'e';
            case 'Scloby3Beta':
                return 'beta';
            default:
                return '';
        }

    }


    private getSubMenusClosed():Submenus{
        return {marketing: false, history: false, settings: false, stock: false};
    }

    protected closeMenuSidenavAndSubmenus(){
        this.closeMenuSidenav.emit();
    }

    protected showDeviceInfo() {
        this.deviceInfoDialogService.show({user: this.user});
    }
}
