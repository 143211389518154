import * as angular from 'angular';

angular.module('items').directive('topbarItems', ["$mdSidenav", "$state", "$stateParams", "$translate", "connection", "util", "environmentInfo", "offlineDialog", "checkManager", function($mdSidenav, $state, $stateParams, $translate, connection, util, environmentInfo, offlineDialog, checkManager) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
		template: require('./topbar-items.html'),
        controller: ["$scope", "$element", "$attrs", function($scope, $element, $attrs) {
            $scope.sections = [{
                name: $translate.instant('ITEMS.TOPBAR_ITEMS.PRODUCTS'),
                state: 'items.showcase'
            },{
                name: $translate.instant('ITEMS.TOPBAR_ITEMS.CATEGORIES'),
                state: 'items.categories'
            },{
                name: $translate.instant('ITEMS.TOPBAR_ITEMS.DEPARTMENTS'),
                state: 'items.departments'
            },{
                name: $translate.instant('ITEMS.TOPBAR_ITEMS.COMPONENTS'),
                state: 'items.components'
            },{
                name: $translate.instant('ITEMS.TOPBAR_ITEMS.RAW_MATERIALS'),
                state: 'items.raw-materials'
            },{
                name: $translate.instant('ITEMS.TOPBAR_ITEMS.VAT_RATE'),
                state: 'items.vat-rate-management'
            }];

            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.onBarcodeScanned = function(result) {
                $scope.context.onSubmitBarcode(result);
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };

            $scope.goTo = function(destination) {
                $mdSidenav('left').close();
                $state.go("app." + destination);
            };

            $scope.canShare = function() {
                return environmentInfo.canShare();
            };

            $scope.canDownload = function() {
                return environmentInfo.canDownloadFiles();
            };

            $scope.goToImporter = function() {
                const destination = checkManager.isModuleAngular('settings.file_importer') ? 'app.new.file_importer' : 'app.file_importer.showcase';
                $state.go(destination);
            };

            $scope.goToShowcase = function() {
                $state.go("app.items.showcase", $stateParams.showcaseState);
            };

            $scope.getCurrentState = function() {
                return $state.current.name;
            };

            $scope.orderByName = function() {
                if($scope.context.order_by !== 0) {
                    $scope.context.order_by = 0;
                    $scope.context.update();
                }
            };

            $scope.orderByPriceAsc = function() {
                if($scope.context.order_by !== 1) {
                    $scope.context.order_by = 1;
                    $scope.context.update();
                }
            };

            $scope.orderByPriceDesc = function() {
                if($scope.context.order_by !== 2) {
                    $scope.context.order_by = 2;
                    $scope.context.update();
                }
            };

            $scope.orderBySku = function() {
                if($scope.context.order_by !== 3) {
                    $scope.context.order_by = 3;
                    $scope.context.update();
                }
            };
        }]
    };
}]);
