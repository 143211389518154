import {
    inject,
    Injectable
} from '@angular/core';

import { $state } from '../../app/ajs-upgraded-providers';

import {
    BehaviorSubject,
    distinctUntilChanged
} from 'rxjs';

import { Transition } from 'angular-ui-router';
import { DevLogger } from './shared/dev-logger';

@Injectable({
    providedIn: 'root'
})
export class DualService {
    private readonly state = inject($state);

    // State subjects (private as they can only emit route changes)
    private readonly currentRoute = new BehaviorSubject<string>('');
    private readonly previousRoute = new BehaviorSubject<string>('');
    private readonly nextRoute = new BehaviorSubject<string>('');

    // State observables (public)
    public currentRoute$ = this.currentRoute.asObservable().pipe(distinctUntilChanged());
    public previousRoute$ = this.previousRoute.asObservable().pipe(distinctUntilChanged());
    public nextRoute$ = this.nextRoute.asObservable().pipe(distinctUntilChanged());

    private logEvent(...message: any[]) {
        DevLogger.debug('[ DualService ]', ...message);
    }

    public init() {
        this.state.router.transitionService.onSuccess({}, (transition: Transition) => {
            this.previousRoute.next(transition.from().name || '');
            this.currentRoute.next(transition.to().name || '');
        });

        this.state.router.transitionService.onExit({}, (transition: Transition) => {
            this.nextRoute.next(transition.targetState().name());

            document.getElementsByTagName('body')[0].classList.remove('grid-columns-movable')
        });

        this.currentRoute$.subscribe(value => this.logEvent('Current Route:', value));
        this.previousRoute$.subscribe(value => this.logEvent('Previous Route:', value));
        this.nextRoute$.subscribe(value => this.logEvent('Next Route:', value));
    }
}
