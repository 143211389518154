import * as moment from 'moment-timezone';

import {
    inject,
    Injectable
} from '@angular/core';

import {
    restManager,
    util
} from 'app/ajs-upgraded-providers';

import {
    isEqual
} from 'src/app/shared/utils';

import {
    UserActiveSessionManagerService
} from 'src/app/core';

type ProgressiveInfo = {
    order_number?: number;
    order_number_offline?: number;
    sale_number?: number;
    sale_number_offline?: number;
    online_last_update?: string;
    offline_last_reset?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ProgressivesManagerService {
    private readonly restManagerService = inject(restManager);
    private readonly userActiveSession = inject(UserActiveSessionManagerService);
    private readonly utils = inject(util);

    private progressivesCache?: ProgressiveInfo;

    /**
     * Checks if the progressive is expired based on the provided time.
     * @param time - The time to check against the current day start time.
     * @returns true if expired, false otherwise.
     */
    private isProgressiveExpired(time?: string): boolean {
        if (time == null) {
            return true;
        }

        return moment(time).isBefore(this.utils.getDayStartTime());
    }

    /**
     * Reads progressive data from local storage, returning a copy of the data.
     * If no data is found, returns an empty object.
     * @returns The current progressives data.
     */
    private readProgressives(): ProgressiveInfo {
        const shopName = this.userActiveSession.getSession()?.shop.name;
        let progressives = this.progressivesCache;

        if (progressives == null) {
            try {
                progressives = JSON.parse(localStorage.getItem(`progressives::${shopName}`) || '{}');
            } catch (e) {
                progressives = {};
            }
        }

        return { ...progressives };
    }

    /**
     * Writes the provided progressive data to local storage and updates the cache.
     * @param progressives - The progressive data to write.
     */
    private writeProgressives(progressives: ProgressiveInfo) {
        const shopName = this.userActiveSession.getSession()?.shop.name;
        this.progressivesCache = { ...progressives };

        localStorage.setItem(`progressives::${shopName}`, JSON.stringify(progressives));
    }

    /**
     * Retrieves the current progressives, checking for updates and resetting if necessary.
     * @returns The updated progressives data.
     */
    public async getProgressives() {
        const progressives = this.readProgressives();
        const oldProgressives = { ...progressives };

        // Offline Progressive Check
        if (this.isProgressiveExpired(progressives.offline_last_reset)) {
            Object.assign(progressives, {
                offline_last_reset: new Date().toISOString(),
                order_number_offline: 0,
                sale_number_offline: 0
            });
        }

        // Online Progressive Update
        if (this.isProgressiveExpired(progressives.online_last_update)) {
            const onlineProgressives = await this.restManagerService.getList("progressive").catch(() => ({}));

            Object.assign(progressives, onlineProgressives);
        }

        // Write updates to local storage if changes occurred
        if (!isEqual(progressives, oldProgressives)) {
            this.writeProgressives(progressives);
        }

        return progressives;
    }

    /**
     * Updates progressives based on the provided data, handling both online and offline updates.
     * @param prgObj - The object containing progressive data to update.
     */
    public updateProgressives(prgObj: any): void {
        const updateData: Partial<ProgressiveInfo> = {};

        if (prgObj.entity) { // Progressive received through PubNub
            if (!this.isProgressiveExpired(prgObj.time)) {
                switch (prgObj.entity) {
                    case 'sales':
                        updateData.sale_number = prgObj.progressive;
                        break;
                    case 'orders':
                        updateData.order_number = prgObj.progressive;
                        break;
                }
                updateData.online_last_update = prgObj.time;
            }
        } else {
            // Online Progressive (API call)
            if (Number.isFinite(prgObj.order_number) && Number.isFinite(prgObj.sale_number)) {
                Object.assign(updateData, {
                    order_number: prgObj.order_number,
                    sale_number: prgObj.sale_number,
                    online_last_update: new Date().toISOString()
                });
            }

            // Offline progressives
            if (Number.isFinite(prgObj.order_number_offline)) {
                Object.assign(updateData, {
                    order_number_offline: prgObj.order_number_offline
                });
            }

            if (Number.isFinite(prgObj.sale_number_offline)) {
                Object.assign(updateData, {
                    sale_number_offline: prgObj.sale_number_offline
                });
            }
        }

        if (!Object.keys(updateData).length) {
            return;
        }

        const progressives = this.readProgressives();
        const oldProgressives = { ...progressives };

        Object.assign(progressives, updateData);

        // Write updates to local storage if changes occurred
        if (!isEqual(progressives, oldProgressives)) {
            this.writeProgressives(progressives);
        }
    }
}
