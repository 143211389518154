import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';

import {ILoadingCellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams, ILoadingCellRendererParams} from "ag-grid-community";

@Component({
    selector: 'app-skeleton-grid-row',
    templateUrl: './skeleton-grid-row.component.html',
    styleUrls: ['./skeleton-grid-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonGridRowComponent implements ILoadingCellRendererAngularComp {
params?: ILoadingCellRendererParams;
    constructor() {
    }

    ngOnInit(): void {
    }

    agInit(params: ILoadingCellRendererParams): void {
        this.params = params;
    }

    public refresh(params: ICellRendererParams): boolean {
        this.agInit(params);

        return true;
    }

}
