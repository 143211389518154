import {
    Component,
    inject,
    OnInit,
    ViewChild
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { $state, restManager } from 'app/ajs-upgraded-providers';
import { catchError, from, of } from 'rxjs';
import { ModuleEvents, ToolbarEventsService } from 'src/app/core';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

import {
    AlertDialogService
} from 'src/app/dialogs';

import { Rest } from 'src/app/models';
import { GridClientSideComponent, GridFixedButtons, headersTranslate } from 'src/app/shared/components';
import { GridClickableButtonStaticTokenComponent } from 'src/app/shared/components/grid/grid-clickable-button-static-token/grid-clickable-button-static-token.component';
import { GridCellFormatterService } from 'src/app/shared/components/grid/utils/grid-cell-formatter.service';
import { StaticTokenFe, TableData } from 'src/app/shared/model/model';

@Component({
  selector: 'app-settings-um-static-token',
  templateUrl: './settings-um-static-token.component.html',
  styleUrls: ['./settings-um-static-token.component.scss']
})
export class SettingsUmStaticTokenComponent implements OnInit {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly restManagerService =inject(restManager);
    private readonly state = inject($state);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translateService = inject(TranslateService);

    @ViewChild('appGridComponent', {static: true}) gridRef!: GridClientSideComponent;
    fixedButtons:GridFixedButtons= {checkbox:{visible:false,lockPosition: 'left'},customButton:{visible:true,cellRenderer: GridClickableButtonStaticTokenComponent,lockPosition: 'left'}}
    gridPath = this.state.$current.name.split('.').pop() === 'suppliers';

    results: any = null;

    tableData: TableData[] = [
    {
        rowData$: from<Promise<StaticTokenFe[]>>(this.restManagerService.getList("permissions/static_tokens")).pipe(
            catchError((error) => {
              console.error(this.translateService.instant('SETTINGS.USERS_MANAGEMENT.ERROR_GET_TOKEN'));
              return of([]);
            })
          ),
        dataType: new StaticTokenFe(),
        headersTranslate: headersTranslate.settings_um_static_token,
        columnsFormatter: this.gridCellFormatterService.token_static,
    }];

    deleteTokenStatic(ids: number[]) {
        ids.forEach(async (id) => {
            try {
                let result = await this.restManagerService.deleteOne('permissions/static_tokens', null, { id: id });
                if (result.message !== 'ok') {
                    console.error(result.message);
                }
            } catch (err) {
                console.error(err);
                this.alertDialogService.openDialog({data: {messageLabel:this.translateService.instant('SETTINGS.USERS_MANAGEMENT.ERROR_DELETE_TOKEN')}});
            }
        });
    }

    refreshGrid() {
        this.tableData = [
            {
                rowData$: from<Promise<StaticTokenFe[]>>(this.restManagerService.getList("permissions/static_tokens")).pipe(
                    catchError((error) => {
                      console.error(this.translateService.instant('SETTINGS.USERS_MANAGEMENT.ERROR_GET_TOKEN'));
                      return of([]);
                    })
                  ),
                dataType: new StaticTokenFe(),
                headersTranslate: headersTranslate.settings_um_static_token,
                columnsFormatter: this.gridCellFormatterService.token_static,
            }]
    }

    async ngOnInit() {
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => {console.debug(e); this.callbackToToolbarClick(e)})
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> = {}) {
        if ("delete" in event) this.gridRef.onRemoveSelected();
    }

    crud({method, id}: Rest) {
        if (method === 'delete') this.gridRef.onRemoveSelected(id);
    }

}
