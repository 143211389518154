import { inject, Injectable } from '@angular/core';
import { LotteryCodeDialogComponent } from './lottery-code-dialog.component';
import { lastValueFrom } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { InputItemDataMagicForm } from 'src/app/shared/model/model';
import { MagicPortalDialogComponent } from '../magic-portal-dialog';
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from '../services';
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Validators } from '@angular/forms';
import {
    BarcodeManagerService,
    EnvironmentInfoService
} from 'src/app/core';

export type LotteryCodeFormValue = { lotteryCode: string };
export type LotteryCodeForm = CustomFormGroup<CustomForm<LotteryCodeFormValue>>;
export interface LotteryCodeDialogValidators { lotteryCodeMaxLength: number};
export type LotteryCodeReturn = { lotteryCode: string};
export type LotteryCodeParams = { currentLotteryCode?: string};
export type LotteryCodeDialogData = InputItemDataMagicForm<LotteryCodeFormValue>;

@Injectable({
    providedIn: 'root',
})
export class LotteryCodeDialogStateService {

    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly barcodeManagerService = inject(BarcodeManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);

    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<LotteryCodeDialogComponent>>
    protected _form?: LotteryCodeForm;

    validators: LotteryCodeDialogValidators = {lotteryCodeMaxLength: 8 }

    public openDialog(config: NonNullableConfigData<LotteryCodeParams>) {
        const { currentLotteryCode } = config.data;
        this.createForm(currentLotteryCode);
        const configPortal: NonNullablePortalConfigData<LotteryCodeDialogData, LotteryCodeDialogComponent> = {
            data: {
                form: this._form!,
                component: LotteryCodeDialogComponent,
                titleLabel: 'CASHREGISTER.LOTTERY_CODE_DIALOG.TITLE',
                confirmDisabled: () => !this._form?.valid
            }
        };

        this.dialogRef = this.openDialogsService.openPortalDialogRef<LotteryCodeDialogComponent, PortalConfigData<InputItemDataMagicForm<LotteryCodeFormValue>, LotteryCodeDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '800px' }),
            ...configPortal
        });
        return lastValueFrom(this.dialogRef.afterClosed()).then((res : PortalConfigData<InputItemDataMagicForm<LotteryCodeFormValue>, LotteryCodeDialogComponent>) => res ? res.form.valid && res.form.value : null);
    }

    private createForm(currentLotteryCode: string | undefined) {
        this._form = new CustomFormGroup<CustomForm<LotteryCodeFormValue>>({
            lotteryCode: new CustomFormControl(currentLotteryCode, { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(8)] }, { ...new CustomFormControlProps(), id:'input-lottery-code', label: 'CASHREGISTER.LOTTERY_CODE_DIALOG.INPUT', hint:()=>`${this.lotteryCode?.value?.length || 0}/ ${this.validators.lotteryCodeMaxLength}`, ...(this.environmentInfoService.isCameraBarcodeSupported() && { customActions: { suffix: { icon: "photo_camera", callback: async () => await this.openCameraBarcode() } } }) } )
        });
    }

    get lotteryCode() {
        return this._form!.controls.lotteryCode;
    }


    private async openCameraBarcode() {
        let lotteryCode = await this.barcodeManagerService.openCameraBarcode();
        this._form?.patchValue({lotteryCode: lotteryCode});
    }
}
