import * as angular from 'angular';
import { WebServerRequest } from './webserver-models';
import { WebServerMessageManager } from './webserver-message-manager'
import { asapScheduler } from 'rxjs';
import {ConfigurationManagerService } from "src/app/core/services";

export class WebServerService {
    constructor(
        private errorsLogger: any,
        private environmentInfo:any,
        private messageManager: WebServerMessageManager,
        private checkManager: ConfigurationManagerService
    ) {
    }

    private logger(...args: (string|boolean)[]) {
        this.errorsLogger.debug("[WebServer]: ", ...args);
    }

    private handleMessage(r: WebServerRequest){
        this.logger("handling api message");
        let input = Object.assign({}, r);
        asapScheduler.schedule(async () => await this.messageManager.controller(input));
        
    }
    /**
     * TODO: create chron che check if the process in up or down
     */
    bootstrap = () => {
        const enable_android = !!(this.checkManager.getShopPreference("local_server.enable_android"));
        const enable_ios = !!(this.checkManager.getShopPreference("local_server.enable_ios"));
        const enable_electron = !!(this.checkManager.getShopPreference("local_server.enable_electron"));
        const server_port = +(this.checkManager.getShopPreference("local_server.port") as string) || 2013;
        const server_timeout = +(this.checkManager.getShopPreference("local_server.timeout") as string) || 20000;
        const entitiesAllowedRaw = (this.checkManager.getShopPreference("local_server.entities") as string) || "sales,sale_transactions,items,rooms,categories,status,shop_preferences,components,departments,pending_prints,device_preferences,printers";

        if(this.environmentInfo.isAndroid() && enable_android){
            const self = this;
            const webserver: any  = (window as any)["tilbywebserver"];
            if(webserver != undefined){
                webserver.onRequest(
                    function(request: string){
                        self.logger("This is the request: ", request);
                        let r: any= JSON.parse(request);
                        let res: WebServerRequest = {
                            entityName: r["entityName"],
                            method: r["method"],
                            headers: {
                                authorization: r.headers.authorization || "",
                                waiter_version: r.headers["x-waiter-version"] || ""
                            },
                            uuidRequest: r["uuidRequest"]
                        };
                        if(r["body"]) res["body"] = r["body"];
                        if(r["queryString"]) res["queryString"] = r["queryString"];
                        if(r["entityId"]) res["entityId"] = r["entityId"];
                        self.handleMessage(res)
                    }
                )
                webserver.startServer(()=>{
                    self.logger("success start webserver android")
                },()=>{
                    self.logger("error start webserver android")
                }, server_port, server_timeout, entitiesAllowedRaw.split(","));
            }
        }
        if(this.environmentInfo.isAppleMobile() && enable_ios){
            const self = this;
            self.logger("i'm on ios")
            const webserver: any  = (window as any)["tilbywebserver"];
            if(webserver != undefined){
                webserver.onRequest(
                    function(request: any){
                        self.logger("This is the request: ", request);
                        let r: any= request;
                        let res: WebServerRequest = {
                            entityName: r["entityName"],
                            method: r["method"],
                            headers: {
                                authorization: r.headers.Authorization || "",
                                waiter_version: r.headers["X-Waiter-Version"] || ""
                            },
                            uuidRequest: r["uuidRequest"]
                        };
                        if(r["body"]) {
                            if(typeof r["body"] == "string"){
                                res["body"] = JSON.parse(r["body"]);
                            }else{
                                res["body"] = r["body"];
                            }
                        }
                        if(r["queryString"]){
                            res["queryString"] = r["queryString"].split("&").reduce((p:any, c:any)=>{
                                let k = c.split("=")[0];
                                let v = c.split("=")[1];
                                p[k] = v;
                                return p;
                            }, {});
                        };
                        if(r["entityId"]) res["entityId"] = r["entityId"];
                        self.logger("This is the request formatted: ", JSON.stringify(res));
                        self.handleMessage(res)
                    }
                )
                webserver.startServer(()=>{
                    self.logger("success start webserver ios")
                },()=>{
                    self.logger("error start webserver ios")
                }, server_port, server_timeout, entitiesAllowedRaw.split(","));
            }
        }
        if(this.environmentInfo.isElectronApp() && enable_electron){
            const self = this;
            self.logger("init webserver");
            const webserver: any  = (window as any).require('electron');
            webserver.ipcRenderer.on('log-webserver', (event:any, data: any)=>{
                self.logger("log data web server", data);
            })
            webserver.ipcRenderer.on('request-webserver', (event:any, data: any)=>{
                let r: WebServerRequest = {
                    method: data.method,
                    entityName: data.entityName,
                    uuidRequest: data.uuidRequest,
                    headers: {
                        authorization: data.headers.authorization || "",
                        waiter_version: data.headers["x-waiter-version"] || ""
                    }
                }
                if(data["body"]) r["body"] = data["body"];
                if(data["queryString"]) r["queryString"] = data["queryString"];
                if(data["entityId"]) r["entityId"] = data["entityId"];
                self.handleMessage(r);
            })
            webserver.ipcRenderer.invoke('start-webserver', { message: "start", server_port: `${server_port}`, entitiesAllowed: entitiesAllowedRaw, server_timeout: `${server_timeout}`} ).then((x:any)=>{
                self.logger("started", x);
            }).catch((err:any)=>{
                self.logger("started error", err);
            });     
            
        }
    };
}

WebServerService.$inject = ["errorsLogger", "environmentInfo", "WebServerMessageManager", "checkManager"];
angular.module('core').service('WebServerService', WebServerService);