import { Inject, Injectable } from '@angular/core';
import { IndexeddbManagerService } from './indexeddb-manager.service';
import { errorsLogger } from 'app/ajs-upgraded-providers';
import {
    Entity,
    EntityName,
    UserActiveSessionManagerService
} from 'src/app/core';
import { Subject } from 'rxjs';

type StorageCache = Map<string | number, any>;
type StorageQuery = Record<string, any>;
type StorageCompundKeysDict = Record<string, string[]>

type StorageUpdateAction = 'UPDATED' | 'DELETED';

export type StorageUpdateNotification = {
    action: StorageUpdateAction
    entity?: Entity
    entityName: EntityName,
    id?: (string | number),
}

type StorageManagerOptions = {
    transactionToken?: Promise<void>
}

@Injectable({
    providedIn: "root"
})
export class StorageManagerService {
    private storageCache: Record<string, Promise<StorageCache>> = {};
    private entityTransactions: Record<string, Promise<void> | undefined> = {};
    private storageBackend;

    //Private subject and public observable for notifying storage updates
    private static readonly storageUpdatesSubject = new Subject<StorageUpdateNotification>();
    public static readonly storageUpdates$ = StorageManagerService.storageUpdatesSubject.asObservable();

    private static readonly compoundKeys: StorageCompundKeysDict = {
        user_preferences: ['user_id', 'id'],
        user_settings: ['user_id', 'id']
    };

    private static readonly compoundReloads: Record<string, string> = {
        user_preferences: 'user_id',
        user_settings: 'user_id'
    };

    constructor(
        IndexedDBManagerService: IndexeddbManagerService,
        private userActiveSession: UserActiveSessionManagerService,
        @Inject(errorsLogger) private errorsLogger: any,
    ) {
        this.storageBackend = IndexedDBManagerService;
    }

    /**
     * Retrieves the entity ID for the given entity name and ID.
     *
     * @param {string} entityName - The name of the entity.
     * @param {(string | number)} id - The ID of the entity.
     * @param {object} entity - The entity object (optional).
     * @return {string} - The entity ID.
     */
    private getEntityId(entityName: EntityName, id: (string | number), entity?: any) {
        if (!StorageManagerService.compoundKeys[entityName]) {
            return id;
        }

        const compoundValues = this.getCompoundValues(entityName, entity);

        return StorageManagerService.compoundKeys[entityName].map((key) => key === 'id' ? id : compoundValues[key]).join('#');
    }

    /**
     * Retrieves the compound values for a given entity.
     *
     * @param {string} entityName - The name of the entity.
     * @param {object} entity - The entity object (optional).
     * @return {object} - The compound values for the entity.
     */
    private getCompoundValues(entityName: EntityName, entity?: any): Record<string, string | number> {
        switch (entityName) {
            case 'user_settings':
            case 'user_preferences':
                return {
                    user_id: entity?.user_id || this.userActiveSession.getSession()?.id,
                }
            default:
                return {};
        }
    }

    /**
     * Initializes the cache for the specified entity name if it hasn't been done already.
     *
     * @param {string} entityName - The name of the entity to check the cache for.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<Map<any, any>>} - A promise that resolves to the cache for the entity.
     */
    private async checkCacheInit(entityName: EntityName, smOptions?: StorageManagerOptions) {
        if (!this.storageCache[entityName]) {
            this.storageCache[entityName] = new Promise(async (resolve, reject) => {
                try {
                    const result = await this.storageBackend.getCollection(entityName);
                    const primKey = this.storageBackend.getPrimaryKeyName(entityName);
                    const cache = new Map();

                    for (let item of result) {
                        cache.set(item[primKey], item);
                    }

                    resolve(cache);
                } catch (error) {
                    this.logEvent('err', `[ checkCacheInit ] [ ${entityName} ] cannot read storage`);
                    delete this.storageCache[entityName];
                    reject();
                }
            });
        }

        if (this.entityTransactions[entityName] && smOptions?.transactionToken !== this.entityTransactions[entityName]) {
            await this.entityTransactions[entityName];
        }

        return this.storageCache[entityName];
    }

    /**
     * Filters the given collection based on the provided filter object.
     *
     * @param {IterableIterator<any>} collection - The collection to be filtered.
     * @param {any} filterObj - The object containing the filter criteria.
     * @return {Array<any>} - The filtered collection.
     */
    private filterResult(collection: IterableIterator<any>, filterObj?: any) {
        if (typeof filterObj !== 'object' || Object.keys(filterObj).length === 0) {
            return [...collection];
        }

        // Remove pagination from the filter
        const { pagination, ...filterObjLocal } = filterObj;

        // Create the queries
        const exactQuery: StorageQuery = {};
        const sinceQuery: StorageQuery = {};
        const maxQuery: StorageQuery = {};
        const likeQuery: StorageQuery = {};
        const inQuery: StorageQuery = {};

        for (let key in filterObjLocal) {
            const val = filterObjLocal[key];

            if (key.endsWith('_since')) {
                sinceQuery[key.replace('_since', '')] = val;
            } else if (key.endsWith('_max')) {
                maxQuery[key.replace('_max', '')] = val;
            } else if (key.endsWith('_like')) {
                likeQuery[key.replace('_like', '')] = val;
            } else if (key.endsWith('_in')) {
                const inValues = Array.isArray(val) ? val : [val];
                const inMap: Record<any, boolean> = {};

                for (let val of inValues) {
                    inMap[val] = true;
                }

                inQuery[key.replace('_in', '')] = inMap;
            } else {
                exactQuery[key] = val;
            }
        }

        // Remove the exact query fields from the other filters
        const exactQueryKeys = Object.keys(exactQuery);

        for (let key in exactQueryKeys) {
            delete sinceQuery[key];
            delete maxQuery[key];
            delete likeQuery[key];
        }

        // Filter the collection
        const maxQueryKeys = Object.keys(maxQuery);
        const sinceQueryKeys = Object.keys(sinceQuery);
        const likeQueryKeys = Object.keys(likeQuery);
        const inQueryKeys = Object.keys(inQuery);

        const filteredResult = [];

        for (let entity of collection) {
            if (
                exactQueryKeys.every((key) => entity[key] === exactQuery[key]) &&
                sinceQueryKeys.every((key) => entity[key] >= sinceQuery[key]) &&
                maxQueryKeys.every((key) => entity[key] <= maxQuery[key]) &&
                likeQueryKeys.every((key) => String(entity[key] ?? '').indexOf(likeQuery[key]) !== -1) &&
                inQueryKeys.every((key) => inQuery[key][entity[key]])
            ) {
                filteredResult.push(entity);
            }
        }

        return filteredResult;
    }

    /**
     * Logs an event with the specified level and arguments.
     *
     * @param {string} level - The level of the event to log.
     * @param {any[]} args - The arguments to include in the event log.
     */
    private logEvent(level: string, ...args: any[]) {
        this.errorsLogger[level]('[ StorageManager ]', ...args);
    }

    private notifyEvent(entityName: EntityName, action: ('UPDATED' | 'DELETED'), id?: (string | number), entity?: any) {
        StorageManagerService.storageUpdatesSubject.next({ entityName, action, id, entity });
    }

    /**
     * Retrieves a single entity from the storage cache based on the provided entity name and ID.
     *
     * @param {EntityName} entityName - The name of the entity to retrieve.
     * @param {(string | number)} id - The ID of the entity to retrieve.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @returns {Promise<any | undefined>} - A promise that resolves to the entity if found, or undefined if not.
     */
    public async getOne(entityName: EntityName, id: (string | number), smOptions?: StorageManagerOptions) {
        if (!id) {
            return undefined;
        }

        const entityCache = await this.checkCacheInit(entityName, smOptions);
        const result = entityCache.get(this.getEntityId(entityName, id));

        if (!result) {
            return undefined;
        }

        return structuredClone(result);
    }

    /**
     * Saves a single entity to the entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {any} entity - The entity object to be saved.
     * @param {any} options - Optional options for saving the entity.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<any>} - A promise that resolves to the saved entity.
     */
    public async saveOne(entityName: EntityName, entity: any, options?: { dirty?: boolean, synchronizing?: boolean }, smOptions?: StorageManagerOptions) {
        if (!entity.id) {
            this.logEvent('err', `[ saveOne ] [ ${entityName} ] entity.id is not defined.`);
            throw 'ID_NOT_DEFINED';
        }

        const entityCache = await this.checkCacheInit(entityName, smOptions);
        const dbIndexes = this.storageBackend.getIndexesByName(entityName);
        const primKey = this.storageBackend.getPrimaryKeyName(entityName);

        const storedEntity = structuredClone(entity);

        if (StorageManagerService.compoundKeys[entityName]) {
            Object.assign(storedEntity, {
                id_compound: this.getEntityId(entityName, storedEntity.id, storedEntity),
                ...this.getCompoundValues(entityName, storedEntity)
            });
        }

        if (dbIndexes.dirty) {
            storedEntity.dirty = options?.dirty ? 1 : 0;
        }

        if(dbIndexes.deleted) {
            storedEntity.deleted = storedEntity.deleted ? 1 : 0;
        }

        if (options?.synchronizing) {
            storedEntity.synchronizing = 1;
        } else {
            delete storedEntity.synchronizing;
        }

        entityCache.set(storedEntity[primKey], storedEntity); //Stores entity in cache

        //Stores entity in storage
        await this.storageBackend.saveOne(entityName, storedEntity);
        this.notifyEvent(entityName, 'UPDATED', storedEntity.id, structuredClone(storedEntity));

        return entity;
    }

    /**
     * Execute a function inside a transaction. This ensures that the function runs
     * only after any ongoing transactions have finished and that any new operations
     * will wait for the function to finish before running.
     *
     * @param entityName The name of the entity store to wait for.
     * @param transactionFn The function to execute inside the transaction.
     * @return A promise that resolves when the transaction has finished.
     */
    public async executeTransaction<T>(entityName: EntityName, transactionFn: (transactionToken: Promise<void>) => Promise<T>) {
        //Await for the cache to be initialized and wait for any ongoing transactions
        await this.checkCacheInit(entityName);

        let resolveFn: () => void;

        this.entityTransactions[entityName] = new Promise((resolve) => { resolveFn = resolve; });

        let result: T;

        try {
            //Execute the transaction function
            result = await transactionFn(this.entityTransactions[entityName]!);
        } finally {
            delete this.entityTransactions[entityName];
            resolveFn!();
        }

        return result;
    };

    /**
     * Deletes an entity with the specified ID from the entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {(string | number)} id - The ID of the entity to delete.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<any>} A Promise that resolves to the id of the deleted entity.
     */
    public async deleteOne(entityName: EntityName, id: (string | number), smOptions?: StorageManagerOptions) {
        const entityCache = await this.checkCacheInit(entityName, smOptions);
        const entityId = this.getEntityId(entityName, id);

        const entityToDelete = entityCache.get(entityId);

        if (!entityToDelete) {
            return null;
        }

        entityCache.delete(entityId);
        this.notifyEvent(entityName, 'DELETED', id, entityToDelete);

        return this.storageBackend.deleteOne(entityName, entityId);
    }

    /**
     * Updates a single entity in the specified entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {(string | number)} id - The ID of the entity.
     * @param {any} fieldObj - An object containing the fields to update.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<any>} A promise that resolves with the updated entity.
     */
    public async updateOne(entityName: EntityName, id: (string | number), fieldObj: any, smOptions?: StorageManagerOptions) {
        const entityCache = await this.checkCacheInit(entityName, smOptions);
        const entityId = this.getEntityId(entityName, id);

        const updated = await this.storageBackend.updateOne(entityName, entityId, fieldObj);

        if (!updated) {
            this.logEvent('err', `[ updateOne ] [ ${entityName} ] entity not updated on storage.`);
            throw false;
        }

        let entity = entityCache.get(entityId);

        if (!entity) {
            this.logEvent('err', `[ updateOne ] [ ${entityName} ] entity not updated on cache.`);
            throw false;
        }

        Object.assign(entity, fieldObj);
        this.notifyEvent(entityName, 'UPDATED', id, structuredClone(entity));

        return structuredClone(entity);
    }

    /**
     * Deletes a collection of entities from the entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {(number | string)[]} idList - An array of IDs representing the entities to be deleted.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<void>} A promise that resolves when the deletion is complete.
     */
    public async deleteCollection(entityName: EntityName, idList: (number | string)[], smOptions?: StorageManagerOptions) {
        const entityCache = await this.checkCacheInit(entityName, smOptions);
        const idListLocal = idList.map((id) => this.getEntityId(entityName, id));

        for (let id of idListLocal) {
            entityCache.delete(id);
        }

        if (idListLocal.length) {
            this.notifyEvent(entityName, 'DELETED');
        }

        return this.storageBackend.deleteCollection(entityName, idListLocal);
    }

    /**
     * Clears an entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {boolean} clearAll - Optional. Determines whether to clear all records only those with the specific compound value (e.g. user_id). Default is false.
     * @return {Promise<void>} A promise that resolves when the collection is cleared.
     */
    public async clearCollection(entityName: EntityName, clearAll: boolean = false) {
        const compoundReload = StorageManagerService.compoundReloads[entityName];

        if (compoundReload && !clearAll) {
            const compoundValues = await this.getCompoundValues(entityName);
            const reloadValue = compoundValues[compoundReload];

            await this.deleteCollectionByIndex(entityName, reloadValue, compoundReload);
            return;
        }

        if (!this.storageCache[entityName]) {
            this.storageCache[entityName] = new Promise((resolve) => {
                const cache: StorageCache = new Map();
                resolve(cache);
            });
        }

        const entityCache = await this.checkCacheInit(entityName);

        entityCache.clear();

        this.notifyEvent(entityName, 'DELETED');

        await this.storageBackend.clearCollection(entityName);
    }

    /**
     * Finds all entities by index.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {any} value - The value to match against the index.
     * @param {string} index - The index to search within.
     * @return {any} An array of entities that match the given index and value.
     */
    public findAllByIndex(entityName: EntityName, value: any, index: string) {
        return this.storageBackend.findAllByIndex(entityName, value, index);
    }

    /**
     * Deletes a collection by index.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {(string | number)} value - The value to match against the index.
     * @param {string} index - The index of the collection to delete.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<Array<string | number>>} - A promise that resolves to an array of IDs that were deleted.
     */
    public async deleteCollectionByIndex(entityName: EntityName, value: (string | number), index: string, smOptions?: StorageManagerOptions) {
        const entityCache = await this.checkCacheInit(entityName, smOptions);
        const ids = await this.storageBackend.deleteCollectionByIndex(entityName, value, index);

        for (let idToRemove of ids) {
            entityCache.delete(idToRemove.toString());
        }

        this.notifyEvent(entityName, 'DELETED');

        return ids;
    }

    /**
     * Retrieves a collection from the entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {any} filter - (Optional) An object representing the filter to apply on the collection.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<any>} A promise that resolves to the filtered collection.
     */
    public async getCollection(entityName: EntityName, filter?: any, smOptions?: StorageManagerOptions) {
        const entityCache = await this.checkCacheInit(entityName, smOptions);

        if (typeof filter != 'object') {
            filter = {};
        }

        let result = entityCache.values();

        if (filter.dirty != undefined) {
            filter.dirty = filter.dirty ? 1 : 0;
        }

        if (filter.deleted != undefined) {
            filter.deleted = filter.deleted ? 1 : 0;
        }

        return structuredClone(this.filterResult(result, filter));
    }

    /**
     * Retrieves the size of an entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {number} The size of the entity store.
     */
    public async getCollectionSize(entityName: EntityName, smOptions?: StorageManagerOptions) {
        const entityCache = await this.checkCacheInit(entityName, smOptions);

        return entityCache.size;
    }

    /**
     * Saves a collection of entities to the entity store.
     *
     * @param {string} entityName - The name of the entity store.
     * @param {any[]} collection - The collection to be saved.
     * @param {any} options - (Optional) Additional options for saving the collection.
     * @param {StorageManagerOptions} [smOptions] - Optional parameters for the storage manager.
     * @return {Promise<any[]>} - A promise that resolves to the saved collection.
     */
    public async saveCollection(entityName: EntityName, collection: any[], options?: { dirty?: boolean }, smOptions?: StorageManagerOptions) {
        const entityCache = await this.checkCacheInit(entityName, smOptions);

        const primKey = this.storageBackend.getPrimaryKeyName(entityName);
        const dbIndexes = this.storageBackend.getIndexesByName(entityName);

        if (!options) {
            options = {};
        }

        let collectionToSave = collection;

        if (dbIndexes.dirty) {
            // Avoid overwriting of dirty entries
            // Find all dirty entries and make a map of ids
            const dirtyEntities = await this.findAllByIndex(entityName, 1, "dirty");
            const idMap: Record<string, any> = {};

            for (const entity of dirtyEntities) {
                idMap[entity[primKey]] = true;
            }

            // Remove all entities with an id that is in the dirty collection
            if (Object.keys(idMap).length) {
                collectionToSave = collection.filter((row) => !idMap[row[primKey]]);
            }

            const dirty = options?.dirty ? 1 : 0;

            for (const entity of collectionToSave) {
                entity.dirty = dirty;
            }
        }

        if(dbIndexes.deleted) {
            for(const entity of collectionToSave) {
                entity.deleted = 0;
            }
        }

        if (StorageManagerService.compoundKeys[entityName]) {
            for (const entity of collectionToSave) {
                Object.assign(entity, {
                    id_compound: this.getEntityId(entityName, entity.id, entity),
                    ...this.getCompoundValues(entityName, entity)
                });
            }
        }

        for (let entity of collectionToSave) {
            entityCache.set(entity[primKey], entity);
        }

        await this.storageBackend.saveCollection(entityName, collectionToSave);

        if (collectionToSave.length) {
            this.notifyEvent(entityName, 'UPDATED');
        }

        return collectionToSave;
    }

    /**
     * Retrieves the information about a specific table in the database.
     *
     * @param {string} entityName - The name of the table.
     * @return {any} The table information.
     */
    public getTableInfo(entityName: EntityName) {
        return this.storageBackend.getTableInfo(entityName);
    }

    /**
     * Clears the storage cache and destroys the storage
     *
     * @return {Promise<void>} - A promise that resolves when the storage is successfully destroyed.
     */
    public async destroyStorage() {
        window.localStorage.clear();
        await this.storageBackend.destroyStorage();

        this.storageCache = {};
    }
}