import angular from "angular";
import { ConfigurationManagerService, UserActiveSessionManagerService } from "src/app/core";
import { CampgestPmsPaymentsDialogService } from "src/app/dialogs/cashregister/campgest-pms-payments-dialog";
import { DigitalPaymentHandler, DigitalPaymentHandlerOptions, DigitalPaymentHandlerResult } from "src/app/shared/model/digital-payments.model";
import { DocumentPrintHook, DocumentPrinterOptions } from "src/app/shared/model/document-printer.model";
import { CampgestApiError, CampgestPmsApiService } from "src/app/shared/services/digital-payments/campgest/campgest-pms-api.service";
import { Sales } from "tilby-models";

export type CampgestPaymentDataType = {
    cardCode: string,
    balance: number,
    balance_day: number,
    server_time: number,
}

export class CampgestPMSPayment implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private campgestPmsApiService: CampgestPmsApiService,
        private userActiveSession: UserActiveSessionManagerService,
        private campgestPmsPaymentsDialog: CampgestPmsPaymentsDialogService,
        private $filter: any,
        private configurationManagerService: ConfigurationManagerService
    ) {
    }

    async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        const currentSale = options.sale;

        try {
            let result = await this.campgestPmsPaymentsDialog.openDialog({amount: amount});

            if (!result) {
                throw 'CANCELED';
            }

            const shopCode = this.configurationManagerService.getPreference('digital_payments.campgest.shop_code') || '';
            const generalShopName = this.configurationManagerService.getPreference('general.shopname') || '';

            // PosNewTrID : Open new transaction with code shop and code card
            const openTransaction = await this.campgestPmsApiService.openNewTransaction({aShopCode: shopCode, aCardCode: result.cardCode});
            const idTransaction = parseInt(openTransaction);

            // PosOperationTr : Pay request
            const paymentData = await this.campgestPmsApiService.callOperation({aTrID: idTransaction, aCardCode: result.cardCode, AnAmount: amount, ACause: `Vendita ${generalShopName}`, ATransactionType: 'A', AComments: `${this.userActiveSession.getSession()?.shop.name || ''} ${currentSale.uuid}`});

            // PosConfirmTrId : Close transaction (automatic 60 s)
            await this.campgestPmsApiService.closeTransaction({aTrID: idTransaction});

            let responseString = '';

            try {
                paymentData.cardCode = result.cardCode;
                responseString = JSON.stringify(paymentData);
            } catch (err) {
                //Nothing to do
            } finally {

            return {
                acquirer_name: 'Campgest PMS',
                payment_data: responseString,
                tail: this.createTail(paymentData),
                unclaimed: false
            };
        }

        } catch (error: any) {
            if (error instanceof CampgestApiError) {
                throw error.message;
            } else {
                throw 'UNKNOWN_ERROR';
            }
        }
    }

    createTail(paymentData : CampgestPaymentDataType) {

        const card = paymentData.cardCode.replace(/^.{0,4}/, '****');
        const balance = paymentData.balance;
        const balance_day = paymentData.balance_day;
        const server_time = paymentData.server_time;

        let tailLines = [];

        tailLines.push("");
        tailLines.push(`*** ACQUISTO CAMPGEST ***`);
        tailLines.push(`Card: ${card}`);
        tailLines.push(`Residuo sulla carta: ${this.$filter('sclCurrency')(balance)}`);
        tailLines.push(`Residuo giornaliero: ${this.$filter('sclCurrency')(balance_day)}`);
        tailLines.push(`Ora server: ${server_time}`);
        return tailLines.join('\n');
    }

    public isEnabled() {
        return true;
    }

    public async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<string | undefined> {
        const shopCode = this.configurationManagerService.getPreference('digital_payments.campgest.shop_code') || '';

        try {
            const payment = sale.payments?.find((payment) => payment.payment_method_type_id == 39);

            if(!payment) {
                return;
            }

            let paymentData = this.getSaleCampgestTransactionData(sale);

            // PosNewTrID : Open new transaction with code shop and code card
            const openTransaction = await this.campgestPmsApiService.openNewTransaction({aShopCode: shopCode, aCardCode: paymentData!.cardCode});
            const idTransaction = parseInt(openTransaction);

            // PosOperationTr : Failover request
            await this.campgestPmsApiService.callOperation({aTrID: idTransaction, aCardCode: paymentData!.cardCode, AnAmount: sale.amount, ACause: 'Storno per errore di stampa', ATransactionType: 'S', AComments: (this.userActiveSession.getSession()?.shop.name || '') + ' ' + sale.uuid});

            // PosConfirmTrId : Close transaction (automatic 60 s
            await this.campgestPmsApiService.closeTransaction({aTrID: idTransaction});

            return 'CAMPGEST_PMS_ROLLBACK_OK';
        } catch (error) {
            throw 'CAMPGEST_PMS_ROLLBACK_KO';
        }
    }

    private getSaleCampgestTransactionData(sale: Sales): CampgestPaymentDataType | undefined {
        const campgestPMSPayment = sale.payments?.find((payment) => payment.payment_method_type_id == 39);

        if (!campgestPMSPayment) {
            return;
        }

        return JSON.parse(campgestPMSPayment.payment_data!);
    }

}

CampgestPMSPayment.$inject = ['campgestPmsApiService', 'userActiveSession', 'campgestPmsPaymentsDialog', '$filter', 'configurationManagerService'];

angular.module('digitalPayments').service('campgestPMS', CampgestPMSPayment);

