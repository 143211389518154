import {
    Component,
} from '@angular/core';

import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
    selector: 'app-grid-clickable-type-button',
    templateUrl: './grid-clickable-type-button.component.html',
    styleUrls: ['./grid-clickable-type-button.component.scss'],
})
export class GridClickableTypeButtonComponent implements ICellRendererAngularComp {
    _params!: ICellRendererParams;
    hide = false;

    get params() {
        return this._params;
    }

    set params(v) {
        this._params = v;
        // if (this.crudService.tableProperties.idsDeleting.indexOf(Number(this.params.data?.id))>-1) this.hide=true;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }
}
