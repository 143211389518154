import {
    Component,
    computed,
    HostBinding,
    inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {AngularSplitModule, IOutputData} from "angular-split";
import {ShopCartWrapperComponent} from "../components/active-sale/shop-cart-wrapper";
import {ItemsByCategory} from "../cashregister.component";
import {ActiveSaleActionButtonsWrapperComponent} from "../components/active-sale/active-sale-action-buttons-wrapper";
import {CashregisterShowcaseComponent} from "../components/cashregister-showcase";
import { ActiveSaleBase } from '../components/active-sale/active-sale-base/active-sale-base.component';
import {ScreenOrientationService} from "src/app/core";

@Component({
  selector: 'app-cashregister-mobile-view',
  standalone: true,
  imports: [
    CommonModule,
    AngularSplitModule,
    MatButtonModule,
    ShopCartWrapperComponent,
    ActiveSaleActionButtonsWrapperComponent,
    CashregisterShowcaseComponent
  ],
  templateUrl: './cashregister-mobile-view.component.html',
  styleUrls: ['./cashregister-mobile-view.component.scss']
})
export class CashregisterMobileViewComponent extends ActiveSaleBase implements OnInit,OnDestroy {
    private readonly screenOrientationService = inject(ScreenOrientationService);

    @HostBinding('style.--toolbarHeight') heightToolbarPx = `0px`;

    @Input({required: true}) itemsByCategory!: ItemsByCategory;

    protected isTablet=this.cashregisterStateService.isTablet;
    protected actionBarHeight = computed(() => this.cashregisterStateService.setActionBarHeight(this.screenOrientationService.getOrientation(), 20));

    protected shopCartHeight$=this.cashregisterStateService.resizeSplitView$;

    constructor() {
        super();
    }

    // START - LIFECYCLE
    async ngOnInit(){
        await super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    // END - LIFECYCLE

    protected onResizeSplitViewer(title:'DragEnd',$event: IOutputData) {
        const {sizes: [heightCart,_heightItems]} = $event || {};
        this.cashregisterStateService.resizeSplitView$.next(Number(heightCart));
        // setTimeout(()=>this.indexDbService.putRecord<Entity.IndexDb.Session.Type.OrderSplitView>('session',{id: "order_split_view",height:this.getHeight()},'order_split_view'),1000);
    }
}
