import {
    Component,
    Input,
    OnInit,
    computed,
    inject,
    signal
} from '@angular/core';
import { BaseInputButton } from '@tilby/tilby-ui-lib/models';
import { PaymentsActionButtonsComponent } from '../payments-action-buttons/payments-action-buttons.component';
import { CommonModule } from '@angular/common';
import { $state, fiscalUtils } from 'app/ajs-upgraded-providers';
import { Sales } from 'tilby-models';

import {
    ConnectionService
} from 'src/app/core';

import {
    AlertDialogService,
    PrinterErrorFiscalDialogService
} from 'src/app/dialogs';

import {
    ActiveSalePaymentService
} from '../../../services/active-sale-payment.service';

import {
    ActiveSaleService,
    CashregisterStateService,
    SalePaymentService
} from 'src/app/features/cashregister';

@Component({
  selector: 'app-payments-action-buttons-wrapper',
  templateUrl: './payments-action-buttons-wrapper.component.html',
  styleUrls: ['./payments-action-buttons-wrapper.component.scss'],
  imports: [CommonModule, PaymentsActionButtonsComponent],
  providers: [ActiveSalePaymentService],
  standalone: true
})
export class PaymentsActionButtonsWrapperComponent implements OnInit {
    private readonly activeSalePaymentService = inject(ActiveSalePaymentService);
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly connectionService = inject(ConnectionService);
    private readonly fiscalUtils = inject(fiscalUtils);
    private readonly printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);
    private readonly salePaymentService = inject(SalePaymentService);
    private readonly stateService = inject($state);

    ngOnInit(): void {
        this.saleToPay = this.salePaymentService.getToPay(this.sale!);
        this.salePaid = this.salePaymentService.getPaid(this.sale!);
        this.saleChange = this.salePaymentService.getSaleChange(this.sale!);
    }

    protected backActionButton?: BaseInputButton | undefined;
    protected payAndPrintActionButton: BaseInputButton | undefined;

    protected saleToPay?:number;
    protected salePaid?:number;
    protected saleChange?:number | null;

    protected isLoading = this.activeSaleService.paymentInProgress;

    private _sale?: Sales;

    @Input({ required: true })
    set sale(saleValue: Sales | undefined) {
        this._sale = saleValue;

        if (saleValue) {
            this.saleChanged(saleValue);
        }
    }

    get sale() {
        return this._sale;
    }

    private async saleChanged(saleValue: Sales) {
        this.saleToPay = this.salePaymentService.getToPay(saleValue);
        this.salePaid = this.salePaymentService.getPaid(saleValue);
        this.saleChange = this.salePaymentService.getSaleChange(saleValue);

        this.backActionButton = {
            name: 'CASHREGISTER.PAYMENTS.BACK',
            click: () => this.back(),
            icon: signal('arrow_back'),
        };

        this.payAndPrintActionButton = {
            name: 'CASHREGISTER.PAYMENTS.PAY_AND_PRINT',
            click: () => this.payAndPrint(),
            class: this.saleToPay! > 0 ? 'tw-bg-gray-300' : 'tilby-body-background-primary-color',
            icon: signal('check'),
            isDisable: this.saleToPay! > 0 ? signal(true) : computed(() => this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons())
        };
    }

    async payAndPrint() {
        if (this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons()) {
            return;
        }

        if(this.activeSaleService.isPaymentsOnSaleEmpty()) {
            return this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE.IMPOSSIBLE_TO_PRINT_WITHOUT_PAYMENT_TYPE' } });
        }

        if (!this.connectionService.isOnline() && this.activeSaleService.hasOnlineDigitalPayment()) {
            return this.alertDialogService.openDialog({ data: {messageLabel: 'CASHREGISTER.ACTIVE_SALE.DIGITAL_PAYMENT_NOT_AVAILABLE_OFFLINE' } });
        }

        this.activeSaleService.paymentInProgress.set(true);

        try {
            await this.activeSalePaymentService.beforePayment({ skipCheckPayments: true });
            await this.activeSaleService.processPayments();
            await this.activeSaleService.processSaleChange();
            await this.activeSalePaymentService.emitDocument();

            this.stateService.go('app.new.cashregister.content.showcase', {},{inherit: false});
        } catch(error) {
            this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: this.activeSaleService.printerDocumentData?.printer.id}}});
        } finally {
            this.activeSaleService.paymentInProgress.set(false);
        }
    }

    private back() {
        this.cashregisterStateService.back();
    }

    showPayAllOnPayments() {
        return (
            !!this.activeSaleService.currentSale.payments?.length &&
            (
                this.activeSaleService.getToPay() <= 0 ||
                (
                    this.fiscalUtils.isPaymentRoundingEnabled() &&
                    this.activeSaleService.currentSale.payments.every((payment) => this.fiscalUtils.isCashPayment(payment.payment_method_type_id))
                )
            )
        );
    };
}
