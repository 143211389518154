<div class="user tw-flex tw-flex-col">
    <div class="header tw-flex tw-flex-col tw-align-center tw-items-center tw-p-4">
        @if(userData.thumbnail) {
            <img class="avatar thumbnail" [src]="userData.thumbnail" alt="User avatar">
        } @else {
            <div class="avatar">
                <button mat-fab class="custom-fab" *skeleton="!userData.first_name;appearance:'circle';theme:{width:'128px', height:'128px', background: 'darkgray'}">
                    <span class="tw-text-5xl tw-text-white">{{getAvatar()}}</span>
                </button>
            </div>
        }
        <h2 class="full-name" *skeleton="!userData.first_name; theme:{width:'200px', height:'18px', background: 'darkgray', margin:'20px'}">{{ getFullName() }}</h2>
        <p translate>SETTINGS.USER_SETTINGS.SUBTITLE</p>
    </div>
    <div class="content tw-flex tw-flex-col md:tw-flex-row md:tw-flew-wrap md:tw-items-stretch tw-p-4">
        <div class="left tw-flex tw-flex-col md:tw-w-1/2 tw-p-0 md:tw-pr-2">
            <mat-card class="card-wrapper">
                <mat-card-header>
                  <mat-card-title><h3 translate>SETTINGS.USER_SETTINGS.BASE_INFO</h3></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-nav-list class="tw-w-full">
                        <mat-list-item (click)="openEditUserDialog('first_name')">
                            <div class="tw-flex tw-flex-row tw-w-full">
                                <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.FIRST_NAME</span>
                                <span class="tw-w-3/5" *skeleton="!userData.first_name; theme:{width:'110px', height:'18px', background: 'darkgray', margin:'0px'}">{{userData.first_name}}</span>
                            </div>
                            <span matListItemMeta>
                                <mat-icon matSuffix>chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                        <mat-divider role="separator"></mat-divider>
                        <mat-list-item (click)="openEditUserDialog('last_name')">
                            <div class="tw-flex tw-flex-row tw-w-full">
                                <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.LAST_NAME</span>
                                <span class="tw-w-3/5" *skeleton="!userData.last_name; theme:{width:'110px', height:'18px', background: 'darkgray', margin:'0px'}">{{userData.last_name}}</span>
                            </div>
                            <span matListItemMeta>
                                <mat-icon matSuffix>chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                        <mat-divider role="separator"></mat-divider>
                        <mat-list-item>
                            <div class="tw-flex tw-flex-row tw-w-full">
                                <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.USERNAME</span>
                                <span class="tw-w-3/5" *skeleton="!userData.username; theme:{width:'110px', height:'18px', background: 'darkgray', margin:'0px'}">{{ userData.username }}</span>
                            </div>
                            <span matListItemMeta>
                                <mat-icon matSuffix></mat-icon>
                            </span>
                        </mat-list-item>
                        <mat-divider role="separator"></mat-divider>
                        <mat-list-item (click)="openEditUserDialog('language')">
                            <div class="tw-flex tw-flex-row tw-w-full">
                                <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.LANGUAGE</span>
                                <span class="tw-w-3/5" *skeleton="!userData.uiLanguage; theme:{width:'110px', height:'18px', background: 'darkgray', margin:'0px'}">{{ userData.uiLanguage?.value }}</span>
                            </div>
                            <span matListItemMeta>
                               <mat-icon matSuffix>chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                        <mat-divider role="separator"></mat-divider>
                        <mat-list-item (click)="openThemeDialog()">
                            <div class="tw-flex tw-flex-row tw-w-full">
                                <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.THEME</span>
                                <span class="tw-w-3/5" *skeleton="isChangeTheme; theme:{width:'110px', height:'18px', background: 'darkgray', margin:'0px'}" translate>{{ this.themeModeService.isDarkTheme ? 'SETTINGS.USER_SETTINGS.DARK_THEME' : 'SETTINGS.USER_SETTINGS.LIGHT_THEME'}}</span>
                            </div>
                            <span matListItemMeta>
                                <mat-icon matSuffix>chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="card-wrapper">
                <mat-card-header>
                  <mat-card-title><h3 translate>SETTINGS.USER_SETTINGS.CONTACT_INFO</h3></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-nav-list class="tw-w-full">
                        <mat-list-item (click)="openEditUserDialog('email')">
                            <div class="tw-flex tw-flex-row tw-w-full">
                                <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.EMAIL</span>
                                <span class="tw-w-3/5" *skeleton="!userData.email; theme:{width:'110px', height:'18px', background: 'darkgray', margin:'0px'}">{{ userData.email }}</span>
                            </div>
                            <span matListItemMeta>
                                <mat-icon matSuffix>chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                        <mat-divider role="separator"></mat-divider>
                        <mat-list-item (click)="openEditUserDialog('phone')">
                            <div class="tw-flex tw-flex-row tw-w-full">
                                <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.PHONE</span>
                                <span class="tw-w-3/5" *skeleton="!userData.phone; theme:{width:'110px', height:'18px', background: 'darkgray', margin:'0px'}">{{ userData.phone }}</span>
                            </div>
                            <span matListItemMeta>
                                <mat-icon matSuffix>chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-card-content>
            </mat-card>
            @if(enableUserLogoutPin) {
                <mat-card class="card-wrapper">
                    <mat-card-header>
                        <mat-card-title><h3 translate>SETTINGS.USER_SETTINGS.SESSION</h3></mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-nav-list class="tw-w-full">
                            <mat-list-item (click)="logout()">
                                <div class="tw-flex tw-flex-row tw-w-full">
                                    <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.LOGOUT</span>
                                </div>
                                <span matListItemMeta>
                                    <mat-icon matSuffix>chevron_right</mat-icon>
                                </span>
                            </mat-list-item>
                            <mat-divider role="separator"></mat-divider>
                            <mat-list-item (click)="deepLogout()">
                                <div class="tw-flex tw-flex-row tw-w-full">
                                    <span class="tw-w-2/5" translate>SETTINGS.USER_SETTINGS.DEEP_LOGOUT</span>
                                </div>
                                <span matListItemMeta>
                                    <mat-icon matSuffix>chevron_right</mat-icon>
                                </span>
                            </mat-list-item>
                        </mat-nav-list>
                    </mat-card-content>
                </mat-card>
            }
        </div>
        <div class="right tw-flex tw-flex-col md:tw-w-1/2 tw-p-0 md:tw-pl-2">
            <mat-card class="card-wrapper">
                <mat-card-header>
                  <mat-card-title><h3 translate>SETTINGS.USER_SETTINGS.SECURITY</h3></mat-card-title>
                  <mat-card-subtitle translate>SETTINGS.USER_SETTINGS.SECURITY_MSG</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-nav-list class="tw-w-full">
                        @if(canChangePassword) {
                            <mat-list-item (click)="changePassword()">
                                <span translate>SETTINGS.USER_SETTINGS.PASSWORD</span>
                                <span matListItemMeta>
                                    <mat-icon matSuffix>chevron_right</mat-icon>
                                </span>
                            </mat-list-item>
                            <mat-divider role="separator"></mat-divider>
                        }
                        <mat-list-item (click)="openEditUserDialog('pin')">
                            <span translate>SETTINGS.USER_SETTINGS.PIN_QUICK</span>
                            <span matListItemMeta>
                                <mat-icon matSuffix>chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="card-wrapper">
                <mat-card-header>
                  <mat-card-title><h3 translate>SETTINGS.USER_SETTINGS.NEWS_AND_UPDATES</h3></mat-card-title>
                  <mat-card-subtitle translate>SETTINGS.USER_SETTINGS.NEWS_AND_UPDATES_MSG</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div class="space"></div>
                </mat-card-content>
                <mat-divider role="separator"></mat-divider>
                <mat-card-actions>
                    <button mat-button color="primary" (click)="changeEmailPreferences()">
                        <span translate>SETTINGS.USER_SETTINGS.CHANGE_EMAIL_PREFERENCES</span>
                    </button>
                </mat-card-actions>
            </mat-card>
            <mat-card class="card-wrapper">
                <mat-card-header>
                  <mat-card-title><h3 translate>SETTINGS.USER_SETTINGS.RESET_USER_PREFERENCE_TITLE</h3></mat-card-title>
                  <mat-card-subtitle translate>SETTINGS.USER_SETTINGS.RESET_USER_PREFERENCE_MSG</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div class="space"></div>
                </mat-card-content>
                <mat-divider role="separator"></mat-divider>
                <mat-card-actions>
                    <button mat-button color="primary" (click)="resetUserPreference()">
                        <span translate>SETTINGS.USER_SETTINGS.RESET_USER_PREFERENCE_BUTTON</span>
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
